import { Divider, Grid } from '@mui/material';
import { DraftsBlock } from '@/components/home/drafts/DraftsBlock';
import { EventBlock } from '@/components/home/event/EventBlock';
import { InboxBlock } from '@/components/home/inbox/InboxBlock';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { NavigationBlock } from '@/components/home/NavigationBlock';
import { OutboxBlock } from '@/components/home/outbox/OutboxBlock';
import { ROUTE_DRAFTS, ROUTE_INBOX, ROUTE_OUTBOX } from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { SystemEventDTO } from '@/types/event/system/SystemEventDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { convertSystemEvent } from '@/utils/event-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetNewsQuery, useGetSystemEventsQuery } from '@/services/api/eventApiSlice';
import React, { FC } from 'react';

interface Props {
  inboxCount?: LetterCountDTO;
  outboxCount?: LetterCountDTO;
  senders?: SenderInfoDTO[];
  letterDraftsCount?: number;
  isLoadingSenders?: boolean;
}

export const HomePage: FC<Props> = (props: Props) => {
  const { inboxCount, outboxCount, senders, isLoadingSenders, letterDraftsCount } = props;

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const {
    data: events,
    isLoading,
    isFetching,
  } = useGetSystemEventsQuery(authUser?.id, {
    skip: !authUser,
  });

  const { data: news } = useGetNewsQuery();

  const allEvents: SystemEventDTO[] = [...(events || []), ...(news || [])]
    .sort((e1: SystemEventDTO, e2: SystemEventDTO) => {
      const date1: number = e1.timestamp ? new Date(e1.timestamp).getTime() : 0;
      const date2: number = e2.timestamp ? new Date(e2.timestamp).getTime() : 0;
      return date2 - date1;
    })
    .slice(0, 3);

  const loading: boolean = isLoading || isFetching;

  return (
    <Grid container={true} direction={'row'} spacing={7}>
      <Grid item={true} xs={3}>
        <Grid container={true} direction={'column'} spacing={3}>
          <Grid item={true}>
            <NavigationBlock
              inboxCount={inboxCount}
              outboxCount={outboxCount}
              senders={senders}
              letterDraftsCount={letterDraftsCount}
              isLoadingSenders={isLoadingSenders}
            />
          </Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
          <Grid item={true}>
            <EventBlock
              events={(allEvents || []).map((event: SystemEventDTO) => convertSystemEvent(event))}
              isLoading={loading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={true}>
        <Switch>
          <Route exact={true} path={ROUTE_INBOX}>
            <InboxBlock />
          </Route>
          <Route exact={true} path={ROUTE_OUTBOX}>
            <OutboxBlock isSearchFieldEnable={senders && senders.length > 1} />
          </Route>
          <Route exact={true} path={ROUTE_DRAFTS}>
            <DraftsBlock />
          </Route>
          <Redirect to={ROUTE_INBOX} />
        </Switch>
      </Grid>
    </Grid>
  );
};
