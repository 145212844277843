import { Box, Grid, Theme, Typography } from '@mui/material';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { LetterHistory } from '@/components/letter/history/LetterHistory';
import { LetterStatusHistoryResponse } from '@/types/letter/LetterStatusHistoryDTO';
import React, { FC, ReactNode } from 'react';

interface Props {
  events?: EventLogDTO[];
  eventLogLoading: boolean;
  letterNumber: number;
  email: string;
  responseStatus: LetterStatusHistoryResponse;
}

export const CheckLetterStatusHistory: FC<Props> = (props: Props) => {
  const { events, eventLogLoading, letterNumber, email, responseStatus } = props;
  const renderContent = (): ReactNode => {
    switch (responseStatus) {
      case LetterStatusHistoryResponse.SUCCESS:
        return events && <LetterHistory events={events} isLoading={eventLogLoading} />;
      case LetterStatusHistoryResponse.LETTER_INITIATIVE:
        return (
          <Box
            sx={{
              padding: (theme: Theme) => theme.spacing(2.5, 2),
              background: (theme: Theme) => theme.colors.grayBackground,
              borderRadius: (theme: Theme) => theme.spacing(1.5),
            }}>
            <Typography variant={'body2'}>{'Проверка статуса недоступна для инициативных писем.'}</Typography>
          </Box>
        );
      case LetterStatusHistoryResponse.LETTER_NOT_FOUND:
        return (
          <Box
            sx={{
              padding: (theme: Theme) => theme.spacing(2.5, 2),
              background: (theme: Theme) => theme.colors.grayBackground,
              borderRadius: (theme: Theme) => theme.spacing(1.5),
            }}>
            <Typography variant={'body2'}>{`Письмо № ${letterNumber} отправителя ${email} не найдено.`}</Typography>
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <Grid container={true}>
      <Grid item={true} paddingBottom={0.5} xs={12}>
        <Typography variant={'h1'}>{`Письмо № ${letterNumber}`}</Typography>
      </Grid>
      <Grid item={true} paddingBottom={2.5} xs={12}>
        <Typography
          variant={'label1'}
          component={'p'}
          color={(theme: Theme) => theme.colors.grayText}>{`Отправитель: ${email}`}</Typography>
      </Grid>
      <Grid item={true} xs={8}>
        {renderContent()}
      </Grid>
    </Grid>
  );
};
