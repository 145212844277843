import * as Yup from 'yup';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { CreateFeedbackDTO } from '@/types/help/CreateFeedbackDTO';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { GET_OFFER_URL } from '@/app/config';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { TypographyLink } from '@/components/common/TypographyLink';
import { UserDTO } from '@/types/user/UserDTO';
import { emailSchema } from '@/validation/emailSchema';
import { formatUserFullName, isBlank } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import { useSendFeedbackMutation } from '@/services/api/helpApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback } from 'react';

interface Params {
  number: string;
}

interface FormValues {
  email?: string;
  letterNumber?: string;
  question?: string;
}

export const FeedbackBlock: FC = () => {
  const { number } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const initialValues: FormValues = {
    email: authUser ? user?.email : '',
    letterNumber: number || '',
    question: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
    question: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    letterNumber: Yup.string(),
    question: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  });
  const topic = 2;
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);
  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    handleReCaptchaVerify().then(async (token) => {
      const { setFieldValue, setFieldTouched } = helpers;
      const dto: CreateFeedbackDTO = {
        topic: topic,
        email: values.email || '',
        author: authUser ? formatUserFullName(user) : values.email || '',
        question: values.question || '',
        letter: values.letterNumber,
      };
      await sendFeedback({ dto: dto, reCaptchaToken: token })
        .unwrap()
        .then(() => {
          setFieldValue('letterNumber', '');
          setFieldTouched('letterNumber', false);
          setFieldValue('question', '');
          setFieldTouched('question', false);
          enqueueSnackbar('Ваше обращение отправлено.\nМы ответим в ближайшее время', {
            variant: 'success',
          });
        })
        .catch(() =>
          enqueueSnackbar('Ошибка отправки отзыва', {
            variant: 'error',
          })
        );
    });
  };
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      {isMobile && (
        <Grid item={true}>
          <Typography variant={'h1'}>{'Обратная связь'}</Typography>
        </Grid>
      )}
      <Grid item={true}>
        <Box
          sx={(theme: Theme) => ({
            background: theme.colors.grayBackground,
            borderRadius: '12px',
            padding: theme.spacing(8, 3, 7),
            width: '100%',
            [theme.breakpoints.down('sm')]: {
              borderRadius: '0px',
              padding: theme.spacing(6, 2, 4),
              marginLeft: theme.spacing(-2),
              width: 'calc(100% + 32px)',
            },
          })}>
          <Formik
            initialValues={initialValues}
            initialErrors={initialErrors}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={handleSubmit}>
            {({
              values,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              submitForm,
              isValid,
            }: FormikProps<FormValues>) => {
              return (
                <Grid container={true} direction={'column'} spacing={isMobile ? 3 : 4}>
                  <Grid item={true}>
                    <Grid container={true} direction={'column'} spacing={isMobile ? 5 : 6}>
                      {!authUser && (
                        <Grid item={true} xs={12} sm={5}>
                          <OutlinedField
                            fullWidth={true}
                            name={'email'}
                            label={'Электронная почта'}
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email ? errors.email : ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setFieldTouched(e.target.name, true, false);
                              setFieldValue(e.target.name, e.target.value, true);
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item={true} xs={12} sm={5}>
                        <OutlinedField
                          fullWidth={true}
                          name={'letterNumber'}
                          label={'Номер письма (необязательно)'}
                          value={values.letterNumber}
                          error={touched.letterNumber && Boolean(errors.letterNumber)}
                          helperText={touched.letterNumber ? errors.letterNumber : ''}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldTouched(e.target.name, true, false);
                            setFieldValue(e.target.name, e.target.value.replace(/[^0-9]/g, ''), true);
                          }}
                        />
                      </Grid>
                      <Grid item={true}>
                        <OutlinedField
                          fullWidth={true}
                          name={'question'}
                          label={'Текст сообщения'}
                          value={values.question}
                          error={touched.question && Boolean(errors.question)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldTouched(e.target.name, true, false);
                            setFieldValue(e.target.name, e.target.value, true);
                          }}
                          multiline={true}
                          rows={10}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Grid
                      container={true}
                      direction={'row'}
                      columnSpacing={isMobile ? 0 : 8}
                      rowSpacing={isMobile ? 3 : 0}
                      alignItems={'center'}
                      justifyContent={'space-between'}>
                      <Grid item={true} xs={12} sm={6}>
                        <Typography
                          component={'div'}
                          variant={'body3'}
                          sx={{
                            color: (theme: Theme) => theme.colors.grayText,
                          }}>
                          {'Нажимая кнопку “Отправить”, я соглашаюсь с '}
                          <a href={GET_OFFER_URL} target={'_blank'} rel={'noopener noreferrer'}>
                            <TypographyLink>{'условиями использования сервиса'}</TypographyLink>
                          </a>
                          {' и принимаю их'}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12} sm={6}>
                        <LoadingButton
                          fullWidth={true}
                          variant={'contained'}
                          type={'submit'}
                          onClick={submitForm}
                          isLoading={isLoading}
                          disabled={isLoading || !isValid}>
                          {'Отправить'}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};
