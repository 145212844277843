import * as Yup from 'yup';
import { AuthorDTO } from '@/types/AuthorDTO';
import { AuthorForm } from '@/components/create/AuthorForm';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { CreateFormValues } from '@/types/create/CreateFormValues';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, Typography } from '@mui/material';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { PageHeader } from '@/components/common/PageHeader';
import { RootState } from '@/app/store';
import { SenderDTO } from '@/types/SenderDTO';
import { SenderForm } from '@/components/create/SenderForm';
import { UserDTO } from '@/types/user/UserDTO';
import { answersCountSchema } from '@/validation/answersCountSchema';
import { emailSchema } from '@/validation/emailSchema';
import { formatUserFullName, removeExtraSpaces } from '@/utils/string-utils';
import { fullNameSchema } from '@/validation/fullNameSchema';
import {
  getAnswersCount,
  getAuthor,
  getSender,
  setAnswerOrderedForm,
  setAnswersCountForm,
  setAuthor,
  setSender,
} from '@/services/createFormSlice';
import { getCurrentUser } from '@/services/authSlice';
import { phoneFieldSchema } from '@/validation/phoneSchema';
import { prisonerMiddleNameSchema } from '@/validation/prisonerMiddleNameSchema';
import { prisonerNameSchema } from '@/validation/prisonerNameSchema';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useHistory } from 'react-router-dom';
import { yearSchema } from '@/validation/yearSchema';
import React, { FC, useState } from 'react';
import TittleInfo from '@/components/create/TittleInfo';

const validationSchema = Yup.object({
  prisonerLastName: prisonerNameSchema,
  prisonerFirstName: prisonerNameSchema,
  prisonerMiddleName: prisonerMiddleNameSchema,
  prisonerBirthDate: yearSchema,
  authorFullName: fullNameSchema,
  authorPhoneNumber: phoneFieldSchema,
  authorEmail: emailSchema.oneOf([Yup.ref('authorConfirmEmail')], 'Поля не совпадают'),
  authorConfirmEmail: emailSchema.oneOf([Yup.ref('authorEmail')], 'Поля не совпадают'),
  answersCount: answersCountSchema,
});

export const CreateFormClient: FC<CreateFormProps> = (props: CreateFormProps) => {
  const {
    tittle,
    nextFormPath,
    previousFormPath,
    previousFormLabel,
    errorRedirectPath,
    isMobile,
    mobileTittle,
    senderTittle,
    recipientTittle,
    fromCreateAnswer,
  } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department = createFormReducer.department;
  const region = createFormReducer.region;

  if (!(department && region)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  const sender: SenderDTO | null = useAppSelector(getSender);
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const answersCount = useAppSelector(getAnswersCount);
  const {
    data: user,
    isLoading,
    isFetching,
  } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const isUserLoading: boolean = isLoading || isFetching;
  const initialValues: CreateFormValues = {
    prisonerLastName: sender?.lastName || '',
    prisonerFirstName: sender?.firstName || '',
    prisonerMiddleName: sender?.middleName || '',
    prisonerBirthDate: sender?.age ? Number(sender?.age) : '',
    authorFullName: author?.name || (user ? formatUserFullName(user) : ''),
    authorPhoneNumber: author?.phone || (user && user.phone ? user.phone : ''),
    authorEmail: author?.email || (user ? user.email : ''),
    authorConfirmEmail: author?.email || (user ? user.email : ''),
    answersCount: answersCount,
  };
  const handleSubmit = (values: CreateFormValues) => {
    if (fromCreateAnswer) {
      dispatch(setAnswerOrderedForm(true));
      dispatch(setAnswersCountForm(values.answersCount ? values.answersCount : 1));
    }
    dispatch(
      setAuthor({
        name: removeExtraSpaces(values.authorFullName),
        phone: values.authorPhoneNumber,
        email: values.authorEmail,
      })
    );
    dispatch(
      setSender({
        lastName: removeExtraSpaces(values.prisonerLastName),
        firstName: removeExtraSpaces(values.prisonerFirstName),
        middleName: removeExtraSpaces(values.prisonerMiddleName),
        age: parseInt(String(values.prisonerBirthDate)),
      })
    );
    if (nextFormPath) {
      history.push(nextFormPath);
    }
  };
  const [emailWarn, setEmailWarn] = useState<boolean>(false);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={false}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<CreateFormValues>) => {
        const { isValid, submitForm, setFieldValue, setFieldTouched, errors, values } = formikProps;
        const handleSetFieldValue = (field: string, value: string | unknown, isErrorField: boolean) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, isErrorField);
        };
        return (
          <Form>
            <Grid container={true} direction={'column'}>
              <Grid item={true} mb={2}>
                <PageHeader
                  tittle={tittle}
                  previousFormLabel={previousFormLabel}
                  previousFormPath={previousFormPath}
                  isMobile={isMobile}
                  mobileTittle={mobileTittle}
                />
              </Grid>
              <Grid item={true}>
                <TittleInfo region={region?.name} department={department?.name} />
              </Grid>
              <Grid item={true} mt={4}>
                <Typography variant={'h2'}>{recipientTittle}</Typography>
              </Grid>
              <Grid item={true} marginTop={'44px'}>
                <SenderForm
                  formikProps={formikProps}
                  handleSetFieldValue={handleSetFieldValue}
                  disabled={isUserLoading}
                  fromCreateAnswer={fromCreateAnswer}
                />
              </Grid>
              <Grid item={true} mt={6}>
                <Typography variant={'h2'}>{senderTittle}</Typography>
              </Grid>
              <Grid item={true} marginTop={'44px'}>
                <AuthorForm
                  formikProps={formikProps}
                  handleSetFieldValue={handleSetFieldValue}
                  handleValidEmail={setEmailWarn}
                  disabled={isUserLoading}
                />
              </Grid>
              {authUser && authUser.email !== values.authorEmail && (
                <Grid item={true} mt={3}>
                  <LetterAlert>
                    <Typography variant={'body1'}>
                      {'Введённая почта не совпадает с указанной в настройках личного кабинета.'}
                    </Typography>
                  </LetterAlert>
                </Grid>
              )}
              <Grid item={true} marginTop={'32px'}>
                <Grid container={true} direction={'row'} justifyContent={'flex-end'}>
                  <Grid item={true} xs={4}>
                    <LoadingButton
                      onClick={submitForm}
                      isLoading={isUserLoading}
                      disabled={isUserLoading}
                      variant={'contained'}
                      color={'primary'}
                      fullWidth={true}
                      size={'medium'}>
                      {'Продолжить'}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
