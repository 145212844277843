import { Button, Grid, Typography } from '@mui/material';
import { CheckIcon } from '@/app/icons/CheckIcon';
import { ROUTE_HOME } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

const AnswerFeedbackSent: FC = () => {
  const history = useHistory();

  const handleReturnMain = () => {
    history.push(ROUTE_HOME);
  };

  return (
    <Grid
      container={true}
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ minHeight: '68vh' }}>
      <Grid item={true} pb={1.5}>
        <CheckIcon
          sx={(theme: Theme) => ({
            width: '64px',
            height: '64px',
            display: 'block',
            color: '#ffffff',
            background: theme.colors.green,
            borderRadius: '50%',
          })}
        />
      </Grid>
      <Grid item={true} pb={4}>
        <Typography variant={'h1'}>{'Спасибо за ваш отзыв!'}</Typography>
      </Grid>
      <Grid item={true}>
        <Button color={'primary'} variant={'contained'} sx={{ width: '206px' }} onClick={() => handleReturnMain()}>
          {'На главную'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AnswerFeedbackSent;
