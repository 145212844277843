import { Checkbox, Divider, Grid, Typography } from '@mui/material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { FullName } from '@/types/FullName';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { NewPasswordDialog } from '@/components/profile/modals/NewPasswordDialog';
import { PhoneNumberDialog } from '@/components/profile/modals/PhoneNumberDialog';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { UserDTO } from '@/types/user/UserDTO';
import { formatPhoneNumber, formatUserFullName, parseFullName } from '@/utils/string-utils';
import { fullNameSchema } from '@/validation/fullNameSchema';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useSetNewFullNameMutation, useSetUserNotificationMutation } from '@/services/api/settingsApiSlice';
import { useSnackbar } from 'notistack';
import EmailDialog from '@/components/profile/modals/EmailDialog';
import LoadingOutlinedField from '@/components/common/field/LoadingOutlinedField';
import React, { FC, Fragment, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  user?: UserDTO;
  handleOpenState: (open: boolean) => void;
  isMobile?: boolean;
  onClose?: () => void;
}

export const SettingsDialog: FC<Props> = (props: Props) => {
  const { open, user, handleOpenState, isMobile, onClose } = props;
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { refetch: reFetchCurrentUser } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });

  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [helpText, setHelpText] = useState<string>('');
  const [authorFullName, setAuthorFullName] = useState<string>('');
  const [mailingCheck, setMailingCheck] = useState<boolean>(user?.sendNotification || false);

  const handleChangeCheck = () => {
    setMailingCheck(!mailingCheck);
  };

  const handleClose = (): void => {
    if (success || mailingCheck != user?.sendNotification) {
      reFetchCurrentUser();
    }
    if (onClose) {
      onClose();
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      if (open) {
        setAuthorFullName(formatUserFullName(user));
        setMailingCheck(user?.sendNotification || false);
        setSuccess(false);
        setError(false);
      }
    }
  }, [open, user]);

  const [setNewFullName, { isLoading, isError }] = useSetNewFullNameMutation();
  const handleCheckFullName = useDebounce<string>((value: string): void => {
    fullNameSchema
      .validate(value)
      .then(() => {
        const fullName: FullName | null = parseFullName(value);
        if (fullName) {
          setNewFullName(fullName)
            .unwrap()
            .then(() => {
              setError(false);
              setSuccess(true);
            })
            .catch((e: { message: string }) => {
              setHelpText(e?.message);
              setError(true);
              setSuccess(false);
            });
        }
      })
      .catch((error) => {
        setHelpText(error.errors[0]);
        setError(true);
        setSuccess(false);
      });
  }, 1000);

  const handleFullName = (fullName: string): void => {
    setAuthorFullName(fullName);
    handleCheckFullName(fullName);
  };

  const [changeNotification] = useSetUserNotificationMutation();
  const handleSubmitSettings = async () => {
    if (user?.sendNotification != mailingCheck) {
      await changeNotification(mailingCheck)
        .unwrap()
        .then(() =>
          enqueueSnackbar('Отправка уведомлений изменена', {
            variant: 'success',
          })
        )
        .catch(() =>
          enqueueSnackbar('Ошибка изменения отправки уведомлений', {
            variant: 'error',
          })
        );
    }
    handleClose();
  };

  const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);
  const [isAddPhone, setAddPhone] = useState<boolean>(false);
  const handleChangePhone = () => {
    setOpenPhoneDialog(true);
    setAddPhone(false);
  };
  const handleAddPhone = () => {
    setOpenPhoneDialog(true);
    setAddPhone(true);
  };

  const [openMailDialog, setOpenMailDialog] = useState<boolean>(false);
  const handleChangeMail = () => {
    setOpenMailDialog(true);
  };

  const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
  const handleChangePassword = () => {
    setOpenPasswordDialog(true);
  };

  const PhoneNumberDialogClose = () => {
    handleOpenState(true);
    setOpenPhoneDialog(false);
    //TODO: do refetch after success changes, not always
    reFetchCurrentUser();
  };

  return (
    <Fragment>
      <ClientDialog
        open={open}
        isMobile={isMobile}
        label={'Настройки профиля'}
        handleCloseButton={handleClose}
        sxContent={{
          marginTop: (theme: Theme) => theme.spacing(isMobile ? -1 : 0),
        }}>
        <Grid container={true} direction={'column'} pt={3}>
          <Grid item={true} mb={3}>
            <LoadingOutlinedField
              fullWidth={true}
              label={'ФИО'}
              name={'fullName'}
              value={authorFullName}
              onChange={(e) => handleFullName(e.target.value)}
              error={isError || error}
              helperText={isError || error ? helpText : ''}
              isLoading={isLoading}
              isSuccess={success}
            />
          </Grid>
          <Grid item={true} mb={2}>
            <Grid container={true} direction={'column'} rowSpacing={1}>
              <Grid item={true}>
                <Typography variant={'label1'} component={'div'} color={(theme: Theme) => theme.colors.grayText}>
                  {'номер телефона'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    {user?.phone ? (
                      <Typography variant={'body1'}>{formatPhoneNumber(user?.phone)}</Typography>
                    ) : (
                      <Typography variant={'body1'} color={(theme: Theme) => theme.colors.grayText}>
                        {'Не заполнен'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'body1'} component={'div'}>
                      {user?.phone ? (
                        <TypographyLink onClick={() => handleChangePhone()}>{'Изменить'}</TypographyLink>
                      ) : (
                        <TypographyLink onClick={() => handleAddPhone()}>{'Добавить'}</TypographyLink>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} mb={2}>
            <Grid container={true} direction={'column'} rowSpacing={1}>
              <Grid item={true}>
                <Typography variant={'label1'} component={'div'} color={(theme: Theme) => theme.colors.grayText}>
                  {'е-мейл'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography variant={'body1'}>{user?.email}</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'body1'} component={'div'}>
                      <TypographyLink onClick={() => handleChangeMail()}>{'Изменить'}</TypographyLink>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} mb={5}>
            <Grid container={true} direction={'column'} rowSpacing={1}>
              <Grid item={true}>
                <Typography variant={'label1'} component={'div'} color={(theme: Theme) => theme.colors.grayText}>
                  {'Пароль'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} justifyContent={'space-between'}>
                  <Grid item={true}>
                    <Typography variant={'body1'}>{'●●●●●●●●'}</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'body1'} component={'div'}>
                      <TypographyLink onClick={() => handleChangePassword()}>{'Изменить'}</TypographyLink>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} mb={5}>
            <Grid
              wrap={'nowrap'}
              container={true}
              columnSpacing={1.5}
              sx={{ cursor: 'pointer', padding: '10px 0px' }}
              onClick={() => handleChangeCheck()}>
              <Grid item={true}>
                <Checkbox disableRipple={true} sx={{ padding: '0px' }} checked={mailingCheck} />
              </Grid>
              <Grid item={true}>
                <Typography variant={'body1'}>{'Присылать уведомления на почту'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {isMobile ? (
            <MobileBar>
              <FixedButton
                color={'primary'}
                variant={'contained'}
                disabled={isLoading}
                onClick={() => handleSubmitSettings()}>
                {'Готово'}
              </FixedButton>
            </MobileBar>
          ) : (
            <Grid item={true}>
              <Grid container={true} justifyContent={'flex-end'}>
                <Grid item={true} sx={{ minWidth: '176px' }}>
                  <LoadingButton
                    fullWidth={true}
                    variant={'contained'}
                    isLoading={isLoading}
                    onClick={() => handleSubmitSettings()}>
                    {'Готово'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ClientDialog>
      <PhoneNumberDialog
        open={openPhoneDialog}
        isMobile={isMobile}
        isAddPhone={isAddPhone}
        onOpen={() => handleOpenState(false)}
        onClose={PhoneNumberDialogClose}
      />
      <EmailDialog
        open={openMailDialog}
        isMobile={isMobile}
        onOpen={() => handleOpenState(false)}
        onClose={() => {
          handleOpenState(true);
          setOpenMailDialog(false);
        }}
      />
      <NewPasswordDialog
        open={openPasswordDialog}
        isMobile={isMobile}
        onOpen={() => handleOpenState(false)}
        onClose={() => {
          handleOpenState(true);
          setOpenPasswordDialog(false);
        }}
      />
    </Fragment>
  );
};

export default SettingsDialog;
