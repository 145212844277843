import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface HelpState {
  region: AtlasRegionDTO | null;
}

const initialState: HelpState = {
  region: null,
};

export const helpSlice = createSlice({
  name: 'help',
  initialState: initialState,
  reducers: {
    setAtlasRegion: (state: HelpState, action: PayloadAction<AtlasRegionDTO | null>): void => {
      state.region = action.payload;
    },
  },
});

export const { setAtlasRegion } = helpSlice.actions;

export default helpSlice.reducer;
