import { CardPayedErrorPage } from '@/components/main/error/CardPayedErrorPage';
import { CardRefillErrorPage } from '@/components/main/error/CardRefillErrorPage';
import { LetterPayedErrorPage } from '@/components/main/error/LetterPayedErrorPage';
import { ROUTE_ERROR_CARD_PAYED, ROUTE_ERROR_CARD_REFILL, ROUTE_ERROR_LETTER_PAYED, ROUTE_HOME } from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { FC } from 'react';

export const ErrorBlock: FC = () => {
  return (
    <Switch>
      <Route path={ROUTE_ERROR_LETTER_PAYED}>
        <LetterPayedErrorPage />
      </Route>
      <Route path={ROUTE_ERROR_CARD_REFILL}>
        <CardRefillErrorPage />
      </Route>
      <Route path={ROUTE_ERROR_CARD_PAYED}>
        <CardPayedErrorPage />
      </Route>
      <Redirect to={ROUTE_HOME} />
    </Switch>
  );
};
