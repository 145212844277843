import { AptekaIcon } from '@/app/icons/service/AptekaIcon';
import { AtlasIcon } from '@/app/icons/service/AtlasIcon';
import { FsinetIcon } from '@/app/icons/service/FsinetIcon';
import { Grid } from '@mui/material';
import { OknoIcon } from '@/app/icons/service/OknoIcon';
import { ServiceNavigationItem } from '@/components/main/service/ServiceNavigationItem';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const ServiceNavigation: FC = () => {
  return (
    <Grid container={true} direction={'row'} spacing={1}>
      <Grid item={true} md={3} sm={6} xs={12}>
        <ServiceNavigationItem
          icon={<AtlasIcon />}
          href={config.links.atlas}
          title={'Атлас'}
          content={'Справочник тюрем'}
        />
      </Grid>
      <Grid item={true} md={3} sm={6} xs={12}>
        <ServiceNavigationItem
          icon={<OknoIcon />}
          href={config.links.window}
          title={'Окно'}
          content={'Запись в бюро передач'}
        />
      </Grid>
      <Grid item={true} md={3} sm={6} xs={12}>
        <ServiceNavigationItem
          icon={<FsinetIcon />}
          href={config.links.fsinet}
          title={'ФСИНЭТ'}
          content={'Связь с заключёнными'}
        />
      </Grid>
      <Grid item={true} md={3} sm={6} xs={12}>
        <ServiceNavigationItem
          icon={<AptekaIcon />}
          href={config.links.drugstore}
          title={'Аптека'}
          content={'Отправка лекарств'}
        />
      </Grid>
    </Grid>
  );
};
