import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const BurgerIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 19'} sx={{ width: '24px', height: '19px' }} {...props}>
    <rect width={'24'} height={'3'} rx={'1.5'} />
    <rect y={'8'} width={'24'} height={'3'} rx={'1.5'} />
    <rect y={'16'} width={'24'} height={'3'} rx={'1.5'} />
  </SvgIcon>
);
