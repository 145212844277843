import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SendAnswerMobileIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 40 40'} sx={{ width: '40px', height: '40px' }} {...props}>
    <g clipPath={'url(#clip0_1973_88559)'}>
      <path
        d={
          'M36.0819 17.8181C37.1194 16.778 36.719 15.0117 35.3344 14.5207L5.75076 4.02943C4.16418 3.46678 2.63466 4.9963 3.19731 6.58288L13.6886 36.1665C14.1796 37.5511 15.9459 37.9515 16.986 36.914L22.9804 30.9345C23.6752 30.2415 23.7645 29.1467 23.1912 28.3502L12.0822 12.9165L27.5181 24.0237C28.3146 24.5968 29.4092 24.5075 30.1022 23.8127L36.0819 17.8181Z'
        }
        fill={'#ADC3FF'}
      />
    </g>
    <defs>
      <clipPath id={'clip0_1973_88559'}>
        <rect width={'40'} height={'40'} fill={'white'} transform={'translate(40 40) rotate(-180)'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
