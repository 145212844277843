import { AppDispatch } from '@/app/store';
import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, MenuItem, Pagination, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material';
import { ImageViewDialog } from '@/components/view/image/ImageViewDialog';
import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { OutboxList } from '@/components/home/outbox/OutboxList';
import { OutboxTable } from '@/components/home/outbox/OutboxTable';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser } from '@/services/authSlice';
import {
  getLetterOutboxRequest,
  setLetterOutboxRequestPageNumber,
  setLetterOutboxRequestPageSize,
  setLetterOutboxSearchRequestAddressee,
  setLetterOutboxSearchRequestStatus,
  setLetterOutboxSortRequest,
} from '@/services/letter/letterOutboxSlice';
import { getOutboxLetterRoute } from '@/app/routes';
import { getStatusLabel } from '@/utils/status-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetAttachFileMutation } from '@/services/api/attachFileApiSlice';
import { useGetLettersQuery, useSetLetterReadMutation } from '@/services/api/letterApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';

interface Props {
  isSearchFieldEnable?: boolean;
}

export const OutboxBlock: FC<Props> = (props: Props) => {
  const { isSearchFieldEnable } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch: AppDispatch = useAppDispatch();
  const history = useHistory();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const request: LetterRequestDTO = useAppSelector(getLetterOutboxRequest);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const { data, isFetching, isLoading } = useGetLettersQuery({ ...request, userId: authUser?.id }, { skip: !authUser });

  const [getAttachFile, { isLoading: isAttachFileLoading }] = useGetAttachFileMutation();
  const [readLetter] = useSetLetterReadMutation();

  const [search, setSearch] = useState<string>('');
  const [letterId, setLetterId] = useState<number | undefined>(undefined);
  const [letterNumber, setLetterNumber] = useState<number | undefined>(undefined);
  const [imageViewName, setImageViewName] = useState<string | undefined>(undefined);
  const [imageViewContent, setImageViewContent] = useState<string | undefined>(undefined);

  const handlePage = (page: number): void => {
    dispatch(setLetterOutboxRequestPageNumber(page - 1));
  };

  const handleAddressee = useDebounce<string>((addressee: string): void => {
    dispatch(setLetterOutboxSearchRequestAddressee(addressee));
    dispatch(setLetterOutboxRequestPageNumber(0));
  }, 500);

  const handleSearch = (addressee: string): void => {
    setSearch(addressee);
    handleAddressee(addressee);
  };

  const handleStatus = (status: LetterSimpleStatus): void => {
    dispatch(setLetterOutboxSearchRequestStatus(status));
    dispatch(setLetterOutboxRequestPageNumber(0));
  };

  const handleSort = (sortRequest: SortRequestDTO): void => {
    dispatch(setLetterOutboxSortRequest(sortRequest));
  };

  const handleLetterClick = async (letter: LetterTableDTO): Promise<void> => {
    history.push(getOutboxLetterRoute(letter.id));
    if (letter.unread) {
      await readLetter(letter.id)
        .unwrap()
        .catch((e: { status: number; data: ErrorDTO }): void => {
          enqueueSnackbar(e.data.message, { variant: 'error' });
        });
    }
  };

  const handleAttachClick = async (letter: LetterTableDTO): Promise<void> => {
    setLetterId(letter.id);
    setLetterNumber(letter.number);
    setImageViewName('Фото');
    setImageViewContent(undefined);
    await getAttachFile(letter.id)
      .unwrap()
      .then((attachFile: AttachFileViewDTO): void => {
        setImageViewContent(attachFile.content);
      })
      .catch((): void => {
        setImageViewContent(undefined);
      });
  };

  useEffect((): void => {
    dispatch(setLetterOutboxRequestPageSize(isMobile ? 5 : 8));
  }, [dispatch, isMobile]);

  useEffect((): void => {
    setSearch(request.searchRequest.addressee || '');
  }, [request.searchRequest.addressee]);

  return (
    <Fragment>
      <Grid container={true} direction={'column'} spacing={2}>
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={1}>
            {isSearchFieldEnable && (
              <Grid item={true} xs={true}>
                <OutlinedField
                  fullWidth={true}
                  size={'small'}
                  placeholder={'Поиск по адресату'}
                  value={search}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    handleSearch(e.target.value);
                  }}
                  onClear={(): void => {
                    handleSearch('');
                  }}
                />
              </Grid>
            )}
            <Grid
              item={true}
              xs={isSearchFieldEnable ? 5 : 12}
              md={isSearchFieldEnable ? 4 : 12}
              sm={isSearchFieldEnable ? 3 : 12}>
              <OutlinedSelect
                size={'small'}
                displayEmpty={true}
                fullWidth={true}
                value={request.searchRequest.status}
                onChange={(e: SelectChangeEvent<unknown>): void => {
                  handleStatus(e.target.value as LetterSimpleStatus);
                }}>
                {Object.values(LetterSimpleStatus).map((value: string) => (
                  <MenuItem key={value} value={value}>
                    {getStatusLabel(value as LetterSimpleStatus)}
                  </MenuItem>
                ))}
              </OutlinedSelect>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          {isMobile ? (
            <OutboxList
              isFetching={isFetching}
              isLoading={isLoading}
              isAttachFileLoading={isAttachFileLoading}
              letterId={letterId}
              letters={data?.content || []}
              onLetterClick={handleLetterClick}
              onAttachClick={handleAttachClick}
            />
          ) : (
            <OutboxTable
              isFetching={isFetching}
              isLoading={isLoading}
              isAttachFileLoading={isAttachFileLoading}
              letterId={letterId}
              letters={data?.content || []}
              sortRequest={
                request.pageRequest.sortRequest || {
                  properties: ['id'],
                  direction: 'DESC',
                }
              }
              onSort={handleSort}
              onLetterClick={handleLetterClick}
              onAttachClick={handleAttachClick}
            />
          )}
        </Grid>
        <Grid item={true} display={'flex'} justifyContent={isMobile ? 'center' : 'right'}>
          <Pagination
            count={data?.totalPages || 1}
            page={(request.pageRequest?.pageNumber || 0) + 1}
            onChange={(_: ChangeEvent<unknown>, page: number): void => {
              handlePage(page);
            }}
          />
        </Grid>
      </Grid>
      <ImageViewDialog
        open={!!imageViewContent}
        content={imageViewContent || ''}
        title={
          <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h1'} fontSize={'24px'} lineHeight={'20px'}>
                {`Вложение письма № ${letterNumber}`}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'label2'}
                fontSize={'12px'}
                lineHeight={'20px'}
                color={(theme: Theme) => theme.colors.grayText}>
                {imageViewName}
              </Typography>
            </Grid>
          </Grid>
        }
        onClose={() => setImageViewContent(undefined)}
      />
    </Fragment>
  );
};
