import { Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useGetClientWorksInfoQuery } from '@/services/api/helpApiSlice';
import CollapseHelpText from '@/components/help/CollapseHelpText';
import ProgressSteps from '@/components/help/ProgressSteps';
import ProgressStepsMobile from '@/components/help/ProgressStepsMobile';
import React, { FC } from 'react';

const WorkBlock: FC = () => {
  const { data: workContent, isFetching } = useGetClientWorksInfoQuery();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Grid container={true} direction={'column'} sx={{ maxWidth: '750px' }}>
      <Grid item={true} mb={isMobile ? 3 : 1.5}>
        {isMobile ? (
          <Typography variant={'h1'}>{'Как работает Ф-письмо'}</Typography>
        ) : (
          <Typography variant={'body1'}>{'Этапы обработки писем:'}</Typography>
        )}
      </Grid>
      <Grid item={true} marginBottom={isMobile ? '20px' : '28px'}>
        {isMobile ? <ProgressStepsMobile /> : <ProgressSteps />}
      </Grid>
      <Grid item={true} id={'createPayContent'}>
        <CollapseHelpText tittle={'Создание и оплата письма'} hide={false}>
          {isFetching ? (
            <Skeleton variant={'rectangular'} width={750} height={120} />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: workContent ? workContent.createPayContent : '',
              }}
            />
          )}
        </CollapseHelpText>
      </Grid>
      <Grid item={true} id={'progressCensoredContent'}>
        <CollapseHelpText tittle={'Обработка и цензура'}>
          {isFetching ? (
            <Skeleton variant={'rectangular'} width={750} height={120} />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: workContent ? workContent.progressCensoredContent : '',
              }}
            />
          )}
        </CollapseHelpText>
      </Grid>
      <Grid item={true} id={'deliveryContent'}>
        <CollapseHelpText tittle={'Вручение'}>
          {isFetching ? (
            <Skeleton variant={'rectangular'} width={750} height={120} />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: workContent ? workContent.deliveryContent : '',
              }}
            />
          )}
        </CollapseHelpText>
      </Grid>
      <Grid item={true} id={'answerContent'}>
        <CollapseHelpText tittle={'Ответ на письмо'}>
          {isFetching ? (
            <Skeleton variant={'rectangular'} width={750} height={120} />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: workContent ? workContent.answerContent : '',
              }}
            />
          )}
        </CollapseHelpText>
      </Grid>
    </Grid>
  );
};

export default WorkBlock;
