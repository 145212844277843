import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const MoneyIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 32 32'} sx={{ width: '32px', height: '32px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M26.9039 15.9992C26.9039 22.0212 22.0221 26.9029 16.0002 26.9029C9.97825 26.9029 5.09649 22.0212 5.09649 15.9992C5.09649 9.97727 9.97825 5.09552 16.0002 5.09552C22.0221 5.09552 26.9039 9.97727 26.9039 15.9992ZM28.8002 15.9992C28.8002 23.0685 23.0694 28.7992 16.0002 28.7992C8.93095 28.7992 3.2002 23.0685 3.2002 15.9992C3.2002 8.92997 8.93095 3.19922 16.0002 3.19922C23.0694 3.19922 28.8002 8.92997 28.8002 15.9992ZM12.3935 21.2971C12.4643 21.3679 12.5553 21.4033 12.6665 21.4033H14.0926C14.2038 21.4033 14.2948 21.3679 14.3656 21.2971C14.4364 21.2263 14.4718 21.1404 14.4718 21.0392V19.3705H16.9446C17.0558 19.3705 17.1418 19.3351 17.2025 19.2643C17.2733 19.1935 17.3087 19.1075 17.3087 19.0064V18.5361C17.3087 18.435 17.2733 18.349 17.2025 18.2782C17.1418 18.2074 17.0558 18.172 16.9446 18.172H14.4718V17.368H16.717C17.516 17.368 18.2088 17.2517 18.7954 17.0191C19.382 16.7764 19.8371 16.4224 20.1607 15.9572C20.4843 15.4818 20.6462 14.8801 20.6462 14.1519C20.6462 13.4035 20.4843 12.7815 20.1607 12.2859C19.8371 11.7803 19.382 11.4061 18.7954 11.1633C18.2088 10.9105 17.516 10.7841 16.717 10.7841H12.6665C12.5553 10.7841 12.4643 10.8195 12.3935 10.8903C12.3328 10.9611 12.3025 11.0521 12.3025 11.1633V15.8055H11.1495C11.0484 15.8055 10.9624 15.8409 10.8916 15.9117C10.8208 15.9723 10.7854 16.0583 10.7854 16.1695V16.9888C10.7854 17.1 10.8208 17.191 10.8916 17.2618C10.9624 17.3326 11.0484 17.368 11.1495 17.368H12.3025V18.172H11.1495C11.0484 18.172 10.9624 18.2074 10.8916 18.2782C10.8208 18.349 10.7854 18.435 10.7854 18.5361V19.0064C10.7854 19.1075 10.8208 19.1935 10.8916 19.2643C10.9624 19.3351 11.0484 19.3705 11.1495 19.3705H12.3025V21.0392C12.3025 21.1404 12.3328 21.2263 12.3935 21.2971ZM16.6412 15.8055H14.4415V12.468H16.6412C17.2783 12.468 17.7436 12.6247 18.0369 12.9383C18.3302 13.2417 18.4768 13.6462 18.4768 14.1519C18.4768 14.698 18.315 15.1127 17.9913 15.3959C17.6778 15.6689 17.2278 15.8055 16.6412 15.8055Z'
      }
    />
  </SvgIcon>
);
