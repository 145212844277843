import { CrosswordIcon } from '@/app/icons/enclosure/CrosswordIcon';
import { EnclosureType } from '@/types/enclosure/EnclosureType';
import { NewsIcon } from '@/app/icons/enclosure/NewsIcon';
import { SudokuIcon } from '@/app/icons/enclosure/SudokuIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

interface Props extends SvgIconProps {
  type: EnclosureType;
}

export const EnclosureIcon: FC<Props> = (props: Props) => {
  const { type, ...other } = props;

  switch (type) {
    case EnclosureType.CROSSWORD:
      return <CrosswordIcon {...other} />;
    case EnclosureType.SUDOKU:
      return <SudokuIcon {...other} />;
    case EnclosureType.NEWS:
      return <NewsIcon {...other} />;
    default:
      return null;
  }
};
