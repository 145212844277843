import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const LockPersonIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 16 16'} sx={{ width: '16px', height: '16px' }} {...props}>
    <path
      d={
        'M10.9534 7.45493C11.7934 7.2616 12.6001 7.31493 13.3334 7.53493V5.33493H11.3334V4.14827C11.3334 2.40827 10.0601 0.854934 8.32675 0.688267C7.86393 0.642175 7.39659 0.693548 6.95483 0.839074C6.51308 0.984601 6.10671 1.22105 5.7619 1.5332C5.4171 1.84535 5.1415 2.22626 4.95288 2.65141C4.76426 3.07655 4.66679 3.53649 4.66675 4.0016V5.33493H2.66675V14.6683H8.17342C7.81965 14.1624 7.56974 13.5915 7.43814 12.9884C7.30654 12.3853 7.29587 11.7622 7.40675 11.1549C7.73341 9.33493 9.16008 7.8616 10.9534 7.45493ZM5.93341 4.0016C5.93341 2.8616 6.86008 1.93493 8.00008 1.93493C9.14008 1.93493 10.0667 2.8616 10.0667 4.0016V5.33493H5.93341V4.0016Z'
      }
    />
    <path
      d={
        'M11.9998 8.66406C10.1598 8.66406 8.6665 10.1574 8.6665 11.9974C8.6665 13.8374 10.1598 15.3307 11.9998 15.3307C13.8398 15.3307 15.3332 13.8374 15.3332 11.9974C15.3332 10.1574 13.8398 8.66406 11.9998 8.66406ZM11.9998 9.9974C12.5532 9.9974 12.9998 10.4441 12.9998 10.9974C12.9998 11.5507 12.5532 11.9974 11.9998 11.9974C11.4465 11.9974 10.9998 11.5507 10.9998 10.9974C10.9998 10.4441 11.4465 9.9974 11.9998 9.9974ZM11.9998 13.9974C11.3132 13.9974 10.7065 13.6507 10.3465 13.1174C10.8332 12.8374 11.3932 12.6641 11.9998 12.6641C12.6065 12.6641 13.1665 12.8374 13.6532 13.1174C13.2932 13.6507 12.6865 13.9974 11.9998 13.9974Z'
      }
    />
  </SvgIcon>
);
