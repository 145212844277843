import { Link, LinkProps } from 'react-router-dom';
import React, { CSSProperties, FC } from 'react';

interface Props extends LinkProps {
  style?: CSSProperties;
}

export const RouteLink: FC<Props> = (props: Props) => {
  const { style, children, ...other } = props;
  return (
    <Link
      style={{
        display: 'flex',
        textDecoration: 'none',
        color: 'inherit',
        ...style,
      }}
      {...other}>
      {children}
    </Link>
  );
};
