import { BalanceHistory } from '@/components/balance/BalanceHistory';
import { CardImportDialog } from '@/components/card/import/CardImportDialog';
import { Grid, Skeleton, Typography } from '@mui/material';
import { ProfileBalance } from '@/components/profile/ProfileBalance';
import { ProfileSettings } from '@/components/profile/ProfileSettings';
import { UserDTO } from '@/types/user/UserDTO';
import { formatUserFullName } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGetUserCardsQuery } from '@/services/api/cardApiSlice';
import React, { FC, Fragment, useEffect, useState } from 'react';

export const ProfilePage: FC = () => {
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const {
    data: user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const userLoading: boolean = isUserFetching || isUserLoading;
  const { data: cards } = useGetUserCardsQuery(authUser?.id, {
    skip: !authUser || !user?.cardImportRequired,
  });
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (cards && cards?.length > 0 && user?.cardImportRequired) {
      setOpen(true);
    }
  }, [cards, user?.cardImportRequired]);
  return (
    <Fragment>
      <Grid container={true} direction={'column'} rowSpacing={'34px'}>
        <Grid item={true}>
          <Typography variant={'h1'}>
            {userLoading ? <Skeleton width={'360px'} /> : formatUserFullName(user)}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={8}>
            <Grid item={true} xs={true}>
              <BalanceHistory />
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={2}>
                <Grid item={true}>
                  <ProfileBalance user={user} />
                </Grid>
                <Grid item={true}>
                  <ProfileSettings user={user} isLoading={userLoading} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CardImportDialog open={open} isMobile={false} cards={cards} onClose={() => setOpen(false)} />
    </Fragment>
  );
};
