import { Button, Grid } from '@mui/material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MobileBar } from '@/components/home/MobileBar';
import { NewPasswordForm } from '@/components/profile/modals/password/NewPasswordForm';
import { Theme } from '@mui/material/styles';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';

interface Props {
  open: boolean;
  isMobile?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export const NewPasswordDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, onClose, onOpen } = props;
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const [isSuccess, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setSuccess(false);
    if (onOpen && open) {
      onOpen();
    }
  }, [onOpen, open]);

  useEffect(() => {
    if (isSuccess) {
      setSuccess(false);
      handleClose();
    }
  }, [isSuccess]);

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const handleClickSubmit = () => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  return (
    <Fragment>
      <ClientDialog
        open={open}
        isMobile={isMobile}
        label={'Изменение пароля'}
        handleCloseButton={handleClose}
        PaperProps={{ sx: { minHeight: isMobile ? '0' : '420px' } }}
        sxContent={(theme: Theme) => ({
          marginTop: theme.spacing(2),
          overflowY: 'visible',
        })}
        actions={
          <Grid container={true} justifyContent={'end'} pb={isMobile ? 7 : 0}>
            {isMobile ? (
              <MobileBar>
                <FixedButton color={'primary'} variant={'contained'} onClick={handleClickSubmit}>
                  {'Далее'}
                </FixedButton>
              </MobileBar>
            ) : (
              <Grid item={true} xs={4}>
                <Button fullWidth={true} variant={'contained'} onClick={handleClickSubmit}>
                  {'Далее'}
                </Button>
              </Grid>
            )}
          </Grid>
        }>
        <NewPasswordForm submitButtonRef={submitButtonRef} handleSubmitNew={setSuccess} />
      </ClientDialog>
    </Fragment>
  );
};
