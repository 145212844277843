import { AtlasDepartmentDTO } from '@/types/atlas/AtlasDepartmentDTO';
import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Divider, Grid, Theme, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  ROUTE_CREATE_LETTER_DEPARTMENT,
  ROUTE_HELP_REGIONS,
  ROUTE_MAIN_CREATE_LETTER_DEPARTMENT,
  getHelpRegionRoute,
} from '@/app/routes';
import { RootState } from '@/app/store';
import { SnapReady } from '@/components/SnapReady';
import { TypographyLink } from '@/components/common/TypographyLink';
import { UserDTO } from '@/types/user/UserDTO';
import { config } from '@/app/config';
import { getCurrentUser } from '@/services/authSlice';
import { setAtlasRegion } from '@/services/helpSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetAtlasRegionsQuery } from '@/services/api/helpApiSlice';
import { useGetMetaDataQuery } from '@/services/api/metaApiSlice';
import NavigatePreviousStep from '@/components/create/NavigatePreviousStep';
import React, { FC, Fragment } from 'react';

interface Params {
  regId: string;
  depId: string;
}

const DepartmentPage: FC = () => {
  const { regId, depId } = useParams<Params>();
  const { data: regions, isError } = useGetAtlasRegionsQuery();
  const { data: meta } = useGetMetaDataQuery();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const dispatch = useAppDispatch();
  const history = useHistory();

  let region: AtlasRegionDTO | null = useAppSelector((state: RootState) => {
    return state.help.region;
  });

  if (regions && !region) {
    const findRegion = regions.find((region: AtlasRegionDTO) => {
      return region.id === parseInt(regId);
    });
    if (findRegion) {
      dispatch(setAtlasRegion(findRegion));
      region = findRegion;
    }
  } else if (isError) {
    history.replace(ROUTE_HELP_REGIONS);
  }

  const department: AtlasDepartmentDTO | undefined = region?.departments.find((department: AtlasDepartmentDTO) => {
    return department.id === parseInt(depId);
  });

  const getCreateLetterUrl = (pismoId: number) => {
    return authUser
      ? `${ROUTE_CREATE_LETTER_DEPARTMENT}?departmentId=${pismoId}`
      : `${ROUTE_MAIN_CREATE_LETTER_DEPARTMENT}?departmentId=${pismoId}`;
  };

  const getMoneyUrl = (moneyId: number) => {
    const url = `${config.links.money}/client/app/transfer/create`;
    return url + '?depId=' + moneyId;
  };

  const getTitle = () => {
    if (meta && region && department) {
      return meta['departmentTitle']
        .replaceAll('{regionName}', region?.name)
        .replaceAll('{departmentName}', department?.name);
    }
    return null;
  };

  return (
    <Fragment>
      {meta && (
        <Helmet>
          <title>{getTitle()}</title>
        </Helmet>
      )}
      {department && region && (
        <Grid container={true} rowSpacing={1}>
          <Grid item={true} xs={12}>
            <NavigatePreviousStep
              to={getHelpRegionRoute(parseInt(regId))}
              sx={{ position: 'relative', margin: '-10px -5px' }}>
              {region.name}
            </NavigatePreviousStep>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography variant={'h2'}>{department.name}</Typography>
          </Grid>
          <Grid item={true} mb={2}>
            <Grid container={true} columnSpacing={1.5} wrap={'nowrap'}>
              <Grid item={true}>
                <Grid container={true} rowSpacing={2}>
                  <Grid item={true} xs={3} lg={1} md={2}>
                    <Typography
                      noWrap={true}
                      variant={'label1'}
                      sx={{ color: (theme: Theme) => theme.colors.grayText }}>
                      {'адрес'}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={9} lg={11} md={10}>
                    <Typography variant={'body1'}>
                      {`${department.postcode}, ${department.address}, ${region.name}`}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={3} lg={1} md={2}>
                    <Typography
                      noWrap={true}
                      variant={'label1'}
                      sx={{ color: (theme: Theme) => theme.colors.grayText }}>
                      {'Телефон'}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={9} lg={11} md={10}>
                    <Typography variant={'body1'}>{department.phone}</Typography>
                  </Grid>
                  <Grid item={true} xs={3} lg={1} md={2}>
                    <Typography
                      noWrap={true}
                      variant={'label1'}
                      sx={{ color: (theme: Theme) => theme.colors.grayText }}>
                      {'Е-мейл'}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={9} lg={11} md={10}>
                    <a href={`mailto:${department.email}`}>
                      <Typography component={'div'} variant={'body1'}>
                        <TypographyLink>{department.email}</TypographyLink>
                      </Typography>
                    </a>
                  </Grid>
                  <Grid item={true} xs={3} lg={1} md={2}>
                    <Typography
                      noWrap={true}
                      variant={'label1'}
                      sx={{ color: (theme: Theme) => theme.colors.grayText }}>
                      {'Веб-сайт'}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={9} lg={11} md={10}>
                    <a href={`${config.links.atlas}/catalog/object/${department.ind}/`}>
                      <Typography component={'span'} variant={'body1'}>
                        <TypographyLink>{`${config.links.atlas}catalog/object/${department.ind}/`}</TypographyLink>
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {department.visible ? (
            department.enableLettersSending ? (
              <Grid item={true}>
                <Grid container={true} direction={'column'} rowSpacing={2}>
                  <Grid item={true} mb={1}>
                    <Grid container={true}>
                      <Grid item={true} xs={8}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <Link to={getCreateLetterUrl(department.pismoId)}>
                      <Typography component={'div'} variant={'body1'}>
                        <TypographyLink>{'Написать письмо заключенному'}</TypographyLink>
                      </Typography>
                    </Link>
                  </Grid>
                  {department.enableMoneySending && (
                    <Grid item={true}>
                      <a href={getMoneyUrl(department.moneyId)} target={'_blank'} rel={'noopener noreferrer'}>
                        <Typography component={'div'} variant={'body1'}>
                          <TypographyLink>{'Сделать денежный перевод'}</TypographyLink>
                        </Typography>
                      </a>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid item={true}>
                <LetterAlert error={true}>{`Учреждение ${department.name} временно недоступно`}</LetterAlert>
              </Grid>
            )
          ) : (
            <Grid
              item={true}
              sx={{
                padding: '16px 24px 12px 24px',
                borderRadius: '6px',
                background: (theme: Theme) => theme.colors.grayBackground,
              }}>
              <Typography variant={'body1'} sx={{ color: (theme: Theme) => theme.colors.grayText }}>
                {'Это учреждение ещё не подключено к системе Ф-письмо'}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      <SnapReady readyIndicator={department} />
    </Fragment>
  );
};

export default DepartmentPage;
