import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CloseIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 18 18'} sx={{ width: '18px', height: '18px' }} {...props}>
    <path
      d={
        'M0.385266 15.6548C-0.11864 16.1704 -0.142078 17.0845 0.396985 17.6118C0.936047 18.1392 1.83839 18.1275 2.35402 17.6235L9.01027 10.9673L15.6548 17.6235C16.1821 18.1392 17.0845 18.1509 17.6118 17.6118C18.1392 17.0728 18.1509 16.1821 17.6235 15.6548L10.9673 8.99855L17.6235 2.35402C18.1509 1.82667 18.1509 0.924329 17.6118 0.396985C17.0728 -0.130359 16.1821 -0.130359 15.6548 0.385266L9.01027 7.04152L2.35402 0.385266C1.83839 -0.11864 0.924329 -0.142078 0.396985 0.396985C-0.130359 0.936047 -0.11864 1.83839 0.385266 2.3423L7.04152 8.99855L0.385266 15.6548Z'
      }
    />
  </SvgIcon>
);
