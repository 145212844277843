import { Tab, TabProps, Theme } from '@mui/material';
import React, { FC } from 'react';

interface Props extends TabProps {
  currentTabIndex: number;
}

export const CreateCardTab: FC<Props> = (props: Props) => {
  const { tabIndex, currentTabIndex, disabled, ...other } = props;
  const maxIndex = 10;
  return (
    <Tab
      disableRipple={true}
      disabled={disabled}
      sx={{
        '&.MuiTab-root': {
          color: (theme: Theme) => theme.colors.primary,
          marginLeft: (theme: Theme) => (tabIndex && tabIndex > 1 ? theme.spacing(-1.5) : 0),
          textTransform: 'none',
          fontSize: (theme: Theme) => theme.spacing(2.5),
          lineHeight: (theme: Theme) => theme.spacing(3.5),
          padding: (theme: Theme) => theme.spacing(2, 2.5),
          borderRadius: (theme: Theme) =>
            tabIndex && tabIndex > currentTabIndex ? theme.spacing(0, 1.5, 0, 0) : theme.spacing(1.5, 0, 0, 0),
          border: (theme: Theme) => (disabled ? 'none' : '2px solid ' + theme.colors.tabsBorderColor),
          borderBottom: (theme: Theme) => '2px solid ' + theme.colors.tabsBorderColor,
          boxShadow: 'inset 0px -2px 0px #ffffff',
          background: '#ffffff',
          zIndex: tabIndex ? maxIndex - tabIndex - 1 : 0,
          flexGrow: disabled ? 1 : '',
          maxWidth: 'none',
          '&.Mui-selected': {
            color: (theme: Theme) => theme.colors.textColor,
            borderRadius: (theme: Theme) => theme.spacing(1.5, 1.5, 0, 0),
            zIndex: maxIndex,
            borderBottomColor: '#ffffff',
          },
        },
      }}
      {...other}
    />
  );
};
