import * as Yup from 'yup';
import {
  MESSAGE_FULL_NAME_ALLOWED_CHARACTERS,
  MESSAGE_FULL_NAME_REQUIRED,
  MESSAGE_REQUIRED,
  allowedFullNameCharsRegExp,
  fullNameRegExp,
} from '@/utils/validation-utils';

export const fullNameSchema: Yup.StringSchema = Yup.string()
  .required(MESSAGE_REQUIRED)
  .matches(allowedFullNameCharsRegExp, MESSAGE_FULL_NAME_ALLOWED_CHARACTERS)
  .matches(fullNameRegExp, MESSAGE_FULL_NAME_REQUIRED);
