import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SortIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 6 11'} sx={{ width: '6px', height: '11px' }} {...props}>
    <path
      d={
        'M5.917 7.617A.995.995 0 0 0 4.997 7h-.996V1c0-.552-.446-1-.996-1a.998.998 0 0 0-.996 1v6h-.997a1.001 1.001 0 0 0-.704 1.707L2.3 10.706a.992.992 0 0 0 1.41 0l1.991-1.999c.285-.286.371-.716.216-1.09z'
      }
    />
  </SvgIcon>
);
