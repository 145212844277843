import { Button, ButtonProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  active?: boolean;
  label: string;
}

export const BalanceFilterButton: FC<Props> = (props: Props) => {
  const { label, active, ...other } = props;
  return (
    <Button
      variant={'contained'}
      color={active ? 'primary' : 'secondary'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1.25, 2),
      })}
      {...other}>
      <Typography variant={'h5'}>{label}</Typography>
    </Button>
  );
};
