import { CardDTO } from '@/types/card/CardDTO';
import { CardImportRow } from '@/components/card/import/CardImportRow';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { Theme } from '@mui/material/styles';
import { useDismissCardImportMutation } from '@/services/api/balanceApiSlice';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  cards?: CardDTO[];
  isMobile?: boolean;
  onClose: () => void;
}

export const CardImportDialog: FC<Props> = (props: Props) => {
  const { open, cards, isMobile, onClose } = props;
  const letter: string = (cards?.length || 0) > 1 ? 'ы' : 'а';
  const [dismissCardImport, { isLoading }] = useDismissCardImportMutation();
  const handleDismiss = async (): Promise<void> => {
    await dismissCardImport()
      .unwrap()
      .finally(() => {
        if (onClose) {
          onClose();
        }
      });
  };
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={`Найден${letter} Ф-карт${letter}`}
      handleCloseButton={handleDismiss}
      actions={
        isMobile ? (
          <MobileBar>
            <FixedButton disabled={isLoading} color={'primary'} variant={'outlined'} onClick={handleDismiss}>
              {'Пропустить'}
            </FixedButton>
          </MobileBar>
        ) : (
          <Grid container={true} direction={'row'} justifyContent={'end'}>
            <Grid item={true} xs={5}>
              <LoadingButton
                isLoading={isLoading}
                fullWidth={true}
                variant={'outlined'}
                color={'primary'}
                onClick={handleDismiss}>
                {'Пропустить'}
              </LoadingButton>
            </Grid>
          </Grid>
        )
      }
      sxContent={(theme: Theme) => ({ paddingBottom: theme.spacing(5) })}>
      <Grid container={true} direction={'column'} spacing={isMobile ? 3 : 4}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={3}>
            <Grid item={true}>
              <Typography variant={'body1'}>
                {`К вашей электронной почте привязан${letter} Ф-карт${letter}. Перевести деньги на баланс личного кабинета, чтобы оплачивать письма заключенным?`}
              </Typography>
            </Grid>
            {(cards || []).map((card: CardDTO) => (
              <Grid item={true} key={card.id}>
                <CardImportRow card={card} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item={true}>
          <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
            {'Перевести деньги можно будет потом — со страницы профиля в личном кабинете'}
          </Typography>
        </Grid>
      </Grid>
    </ClientDialog>
  );
};
