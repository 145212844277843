import { AuthenticationForm } from '@/components/auth/AuthenticationForm';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Footer } from '@/components/main/footer/Footer';
import { MainNavigation } from '@/components/main/navigation/MainNavigation';
import { MobileHeader } from '@/components/common/MobileHeader';
import {
  ROUTE_AUTHENTICATION,
  ROUTE_REGISTRATION,
  ROUTE_REGISTRATION_CONFIRM_REQUEST,
  ROUTE_REGISTRATION_CONFIRM_SUCCESS,
  ROUTE_REGISTRATION_SUCCESS,
  ROUTE_RESTORE,
  ROUTE_RESTORE_NEW,
  ROUTE_RESTORE_SUCCESS,
} from '@/app/routes';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RegistrationBanner } from '@/components/auth/RegistrationBanner';
import { RegistrationConfirmRequest } from '@/components/auth/RegistrationConfirmRequest';
import { RegistrationConfirmSuccess } from '@/components/auth/RegistrationConfirmSuccess';
import { RegistrationForm } from '@/components/auth/RegistrationForm';
import { RegistrationSuccessForm } from '@/components/auth/RegistrationSuccessForm';
import { RestoreNewPasswordForm } from '@/components/auth/RestoreNewPasswordForm';
import { RestorePasswordForm } from '@/components/auth/RestorePasswordForm';
import { RestoreSuccessForm } from '@/components/auth/RestoreSuccessForm';
import { Theme } from '@mui/material/styles';
import { TopLine } from '@/components/common/TopLine';
import { UserDTO } from '@/types/user/UserDTO';
import { getAuthenticated, getCurrentUser, signOut } from '@/services/authSlice';
import { setNewEmail } from '@/services/profileSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useConfirmChangeEmailMutation, useConfirmRegistrationMutation } from '@/services/api/authApiSlice';
import { useConfirmEmailTokenMutation } from '@/services/api/settingsApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useEffect } from 'react';

export const AuthorizationPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const dispatch = useAppDispatch();
  const [confirmEmail] = useConfirmEmailTokenMutation();
  const [confirmRegistration] = useConfirmRegistrationMutation();
  const [confirmChangeEmail] = useConfirmChangeEmailMutation();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const activateToken: string | null = params.get('activateToken');
    const confirmToken: string | null = params.get('confirmToken');
    const changeEmailToken: string | null = params.get('changeEmailToken');
    if (activateToken) {
      confirmEmail(activateToken)
        .unwrap()
        .then(() => {
          dispatch(signOut());
          dispatch(setNewEmail(true));
          history.replace(ROUTE_RESTORE_SUCCESS);
        });
    }
    if (confirmToken) {
      confirmRegistration(confirmToken)
        .unwrap()
        .then(() => {
          history.replace(ROUTE_REGISTRATION_CONFIRM_SUCCESS);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message, { variant: 'error' });
        });
    }
    if (changeEmailToken) {
      confirmChangeEmail(changeEmailToken)
        .unwrap()
        .then(() => {
          dispatch(signOut());
          dispatch(setNewEmail(true));
          history.replace(ROUTE_REGISTRATION_CONFIRM_SUCCESS);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message, { variant: 'error' });
        });
    }
  }, [confirmEmail, dispatch, history, location.search]);
  return (
    <Fragment>
      {!isMobile && <TopLine />}
      <Container
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-6px',
          minHeight: '100%',
          [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(5),
            backgroundColor: theme.colors.grayBackground,
            height: '100%',
            overflow: 'auto',
          },
        })}>
        <Box display={'flex'} flex={1}>
          <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={isMobile ? 4 : 12}>
            <Grid item={true}>
              {isMobile ? (
                <MobileHeader hideMenu={location.pathname === ROUTE_RESTORE} mainPage={true} />
              ) : (
                <MainNavigation profile={!!authUser} isAuthenticated={isAuthenticated} />
              )}
            </Grid>
            <Grid item={true} xs={true} display={'flex'} justifyContent={'center'}>
              <Grid container={true} direction={'column'} spacing={2}>
                {!isMobile && location.pathname === ROUTE_REGISTRATION && (
                  <Grid item={true} xs={true} display={'flex'} justifyContent={'center'} marginTop={-9}>
                    <RegistrationBanner />
                  </Grid>
                )}
                <Grid item={true} xs={true} display={'flex'} justifyContent={'center'}>
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    position={'relative'}
                    width={'100%'}
                    height={'min-content'}
                    borderRadius={'12px'}
                    maxWidth={(theme: Theme) => theme.spacing(80)}
                    padding={(theme: Theme) => {
                      return isMobile ? theme.spacing(0) : theme.spacing(6, 5, 7);
                    }}
                    sx={{
                      backgroundColor: (theme: Theme) => theme.colors.grayBackground,
                    }}>
                    <Box display={'flex'} width={'100%'} maxWidth={(theme: Theme) => theme.spacing(40)}>
                      <Switch>
                        <Route exact={true} path={ROUTE_AUTHENTICATION}>
                          <AuthenticationForm />
                        </Route>
                        <Route exact={true} path={ROUTE_REGISTRATION}>
                          <RegistrationForm />
                        </Route>
                        <Route exact={true} path={ROUTE_REGISTRATION_SUCCESS}>
                          <RegistrationSuccessForm />
                        </Route>
                        <Route exact={true} path={ROUTE_RESTORE}>
                          <RestorePasswordForm />
                        </Route>
                        <Route exact={true} path={ROUTE_RESTORE_SUCCESS}>
                          <RestoreSuccessForm />
                        </Route>
                        <Route exact={true} path={ROUTE_RESTORE_NEW}>
                          <RestoreNewPasswordForm />
                        </Route>
                        <Route exact={true} path={ROUTE_REGISTRATION_CONFIRM_REQUEST}>
                          <RegistrationConfirmRequest />
                        </Route>
                        <Route exact={true} path={ROUTE_REGISTRATION_CONFIRM_SUCCESS}>
                          <RegistrationConfirmSuccess />
                        </Route>
                        <Redirect to={ROUTE_AUTHENTICATION} />
                      </Switch>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Footer isMobile={isMobile} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};
