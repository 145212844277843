import { Box } from '@mui/material';
import { CheckIcon } from '@/app/icons/CheckIcon';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

export const SuccessIcon: FC = () => {
  return (
    <Box
      sx={(theme: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.colors.success,
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',
        border: `2px solid ${theme.colors.success}`,
      })}>
      <CheckIcon
        sx={(theme: Theme) => ({
          width: theme.spacing(4),
          height: theme.spacing(4),
        })}
      />
    </Box>
  );
};
