import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Grid, Typography } from '@mui/material';
import { MobileBar } from '@/components/home/MobileBar';
import { ROUTE_MAIN, ROUTE_PROFILE } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { getStatus } from '@/services/departmentRedirectFormSlice';
import { useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  isMobile?: boolean;
  setOpen: (open: boolean) => void;
}

export const DepartmentChangeSuccessDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, setOpen } = props;
  const history = useHistory();
  const handleClose = (): void => {
    setOpen(false);
    history.replace(ROUTE_MAIN);
  };
  const status = useAppSelector(getStatus);
  const handleProfileClick = (): void => {
    history.push(ROUTE_PROFILE);
  };
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      handleCloseButton={handleClose}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(-3.5),
      })}
      topLine={true}>
      <Grid item={true} marginBottom={isMobile ? '20px' : '79px'} mt={3}>
        <Typography fontSize={'20px'} fontWeight={'500'} lineHeight={'32px'}>
          {'Письмо № ' + status?.letterNumber}
          <br />
          {'перенаправлено'}
        </Typography>
      </Grid>
      <Grid
        container={true}
        direction={'column'}
        sx={{
          paddingBottom: (theme: Theme) => (isMobile ? theme.spacing(6) : theme.spacing(0)),
        }}>
        <Grid item={true}>
          <Typography variant={'body1'}>
            {status?.message}
            <br />
            {'Следите за доставкой в '}
            <TypographyLink onClick={handleProfileClick}>{'Личном кабинете'}</TypographyLink>
            {' или в рассылке на электронную почту '}
            <br />
            <a href={'mailto:' + status?.email} target={'_blank'} rel={'noopener noreferrer'}>
              <TypographyLink>{status?.email + '.'}</TypographyLink>
            </a>
          </Typography>
        </Grid>
        <Grid item={true}>
          <MobileBar>
            <FixedButton color={'primary'} variant={'contained'} onClick={handleClose}>
              {'Закрыть'}
            </FixedButton>
          </MobileBar>
        </Grid>
      </Grid>
    </ClientDialog>
  );
};
