import { Button, ButtonProps } from '@mui/material';
import React, { FC } from 'react';

type Props = ButtonProps;

export const FixedButton: FC<Props> = (props: Props) => {
  const { ...other } = props;
  return (
    <Button
      fullWidth={true}
      sx={{
        height: '100%',
        border: 'none !important',
        borderRadius: 'unset',
        boxShadow: '0px -2px 14px rgba(0, 0, 0, 0.2) !important',
      }}
      {...other}
    />
  );
};
