import { AptekaIcon } from '@/app/icons/service/AptekaIcon';
import { AtlasIcon } from '@/app/icons/service/AtlasIcon';
import { FooterItem } from '@/components/main/footer/FooterItem';
import { FsinetIcon } from '@/app/icons/service/FsinetIcon';
import { Grid } from '@mui/material';
import { MoneyIcon } from '@/app/icons/service/MoneyIcon';
import { OknoIcon } from '@/app/icons/service/OknoIcon';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const FooterNavigation: FC = () => {
  return (
    <Grid container={true} columnSpacing={2} rowSpacing={'22px'}>
      <Grid item={true}>
        <FooterItem
          icon={<MoneyIcon />}
          href={config.links.money}
          mainText={'Деньги'}
          secondaryText={'Денежные переводы'}
        />
      </Grid>
      <Grid item={true}>
        <FooterItem
          icon={<AtlasIcon />}
          href={config.links.atlas}
          mainText={'Атлас'}
          secondaryText={'Справочник тюрем'}
        />
      </Grid>
      <Grid item={true}>
        <FooterItem
          icon={<OknoIcon />}
          href={config.links.window}
          mainText={'Окно'}
          secondaryText={'Запись в бюро передач'}
        />
      </Grid>
      <Grid item={true}>
        <FooterItem
          icon={<FsinetIcon />}
          href={config.links.fsinet}
          mainText={'ФСИНЭТ'}
          secondaryText={'Связь с осужденными'}
        />
      </Grid>
      <Grid item={true}>
        <FooterItem
          icon={<AptekaIcon />}
          href={config.links.drugstore}
          mainText={'Аптека'}
          secondaryText={'Отправка лекарств'}
        />
      </Grid>
    </Grid>
  );
};
