import { CheckBox, CheckBoxProps } from '@/components/common/CheckBox';
import { FormControlLabel, Typography } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import React, { FC } from 'react';

export interface LabeledCheckBoxProps extends CheckBoxProps {
  label: string | number | React.ReactElement;
  labelProps?: Omit<Omit<FormControlLabelProps, 'control'>, 'label'>;
  fontWeight?: number;
}

export const LabeledCheckBox: FC<LabeledCheckBoxProps> = (props: LabeledCheckBoxProps) => {
  const { label, labelProps, fontWeight, disabled, ...other } = props;
  return (
    <FormControlLabel
      {...labelProps}
      disabled={disabled}
      label={
        <Typography fontWeight={fontWeight} sx={{ opacity: disabled ? 0.5 : 1 }}>
          {label}
        </Typography>
      }
      control={<CheckBox {...other} />}
    />
  );
};
