import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const HomeIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 60 60'} sx={{ width: '40px', height: '40px' }} {...props}>
    <path
      d={
        'M25 47.5011V35.0011H35V47.5011C35 48.8761 36.125 50.0011 37.5 50.0011H45C46.375 50.0011 47.5 48.8761 47.5 47.5011V30.0011H51.75C52.9 30.0011 53.45 28.5761 52.575 27.8261L31.675 9.00108C30.725 8.15108 29.275 8.15108 28.325 9.00108L7.425 27.8261C6.575 28.5761 7.1 30.0011 8.25 30.0011H12.5V47.5011C12.5 48.8761 13.625 50.0011 15 50.0011H22.5C23.875 50.0011 25 48.8761 25 47.5011Z'
      }
      fill={'#ADC3FF'}
    />
  </SvgIcon>
);
