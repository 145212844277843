import { BalancePaymentRadioGroup } from '@/components/profile/modals/BalancePaymentRadioGroup';
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import { CardDTO } from '@/types/card/CardDTO';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { FillBalanceRequestDTO } from '@/types/balance/FillBalanceRequestDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { OnlinePaymentResponseDTO } from '@/types/OnlinePaymentResponseDTO';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { ROUTE_PROFILE } from '@/app/routes';
import { RefillBalanceDTO } from '@/types/balance/RefillBalanceDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearBalanceByLetterPageState,
  clearBalanceSbpState,
  setBalanceByLetterPageFillAmount,
  setBalanceFillAmount,
  setBalanceSbpQR,
  setBalanceSbpQrByLetterPage,
} from '@/services/sbpSlice';
import { formatCurrency, isBlank, isNotBlank, onlyNumberFormat } from '@/utils/string-utils';
import { getCommission } from '@/utils/currency-utils';
import { getCurrentUser } from '@/services/authSlice';
import { updatePaymentMethodAndProvider } from '@/utils/payment-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCreateBalanceBillMutation } from '@/services/api/paymentApiSlice';
import { useFillFromCardMutation } from '@/services/api/balanceApiSlice';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGetSystemSettingsQuery } from '@/services/api/settingsApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUserBalanceRefillMutation } from '@/services/api/sbpPaymentApiSlice';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  currentBalance: number;
  price?: number;
  cards: CardDTO[];
  isMobile?: boolean;
  isLetterPage?: boolean;
  onClose?: () => void;
  openSecondaryView?: () => void;
}

export const BalancePaymentDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, isLetterPage, onClose, currentBalance, cards, openSecondaryView, price } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });

  const { data: systemSettings, refetch: getSystemSettings } = useGetSystemSettingsQuery();
  const [fillFromCard, { isLoading: isCardLoading }] = useFillFromCardMutation();
  const [createBalanceBill, { isLoading: isBillLoading }] = useCreateBalanceBillMutation();
  const [refillBalanceBySBP, { isLoading: isSbpLoading }] = useUserBalanceRefillMutation();

  const [paymentValue, setPaymentValue] = useState<any>({
    method: PaymentMethod.SBP,
    provider: PaymentProvider.ALFA,
  });
  const [paymentAmount, setPaymentAmount] = useState<string>(price ? (price - currentBalance).toString() : '');
  const [cardId, setCardId] = useState<number | undefined>(undefined);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [balance, setBalance] = useState<number | undefined>(undefined);

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setPaymentValue(JSON.parse(e.target.value));
    setCardId(undefined);
    setCode(undefined);
    setBalance(undefined);
  };

  const handleChangeAmount = (value: string): void => {
    if (isNotBlank(value)) {
      setPaymentAmount(onlyNumberFormat(value));
    } else {
      setPaymentAmount('');
    }
  };

  const isCardMethod = (paymentMethod: PaymentMethod): boolean => {
    return paymentMethod !== PaymentMethod.ONLINE && paymentMethod !== PaymentMethod.SBP;
  };

  const handleCardPayment = async (): Promise<void> => {
    const dto: FillBalanceRequestDTO = {
      cardId: cardId,
      code: code,
      amount: parseInt(paymentAmount),
    };
    await fillFromCard(dto)
      .unwrap()
      .then((): void => {
        handleClose();
        enqueueSnackbar('Баланс пополнен', {
          variant: 'success',
        });
      })
      .catch((): void => {
        enqueueSnackbar('Ошибка пополнения баланса', {
          variant: 'error',
        });
      });
  };

  const handleOnlinePayment = async (): Promise<void> => {
    if (!user?.phone) {
      enqueueSnackbar('Для пополнения баланса необходимо указать номер телефона в профиле', {
        variant: 'error',
      });
      history.push(ROUTE_PROFILE);
    } else {
      const dto: RefillBalanceDTO = {
        amount: parseInt(paymentAmount),
        phone: user?.phone,
      };
      const windowReference: Window | null = window.open();
      await createBalanceBill({ dto: dto, provider: paymentValue.provider })
        .unwrap()
        .then((dto: OnlinePaymentResponseDTO): void => {
          // Fix for Safari (window.open doesn't work in async functions)
          if (windowReference) {
            windowReference.location = dto.paymentUrl;
          }
          handleClose();
          enqueueSnackbar('Счёт сформирован', {
            variant: 'success',
          });
        })
        .catch((): void => {
          enqueueSnackbar('Ошибка формирования счёта', {
            variant: 'error',
          });
        });
    }
  };

  const handleSbpPayment = async (): Promise<void> => {
    if (!user?.phone) {
      enqueueSnackbar('Для пополнения баланса необходимо указать номер телефона в профиле', {
        variant: 'error',
      });
      history.push(ROUTE_PROFILE);
    } else {
      await refillBalanceBySBP({ amount: parseInt(paymentAmount) })
        .unwrap()
        .then((dto: CreatedSbpQrDTO): void => {
          if (isLetterPage) {
            dispatch(clearBalanceByLetterPageState());
            dispatch(setBalanceSbpQrByLetterPage(dto));
            dispatch(setBalanceByLetterPageFillAmount(parseInt(paymentAmount)));
          } else {
            dispatch(clearBalanceSbpState());
            dispatch(setBalanceSbpQR(dto));
            dispatch(setBalanceFillAmount(parseInt(paymentAmount)));
          }
          handleOpenSecondaryView();
          handleClose();
          enqueueSnackbar('Счёт сформирован', {
            variant: 'success',
          });
        })
        .catch((): void => {
          enqueueSnackbar('Ошибка формирования счёта', {
            variant: 'error',
          });
        });
    }
  };

  const handleCardSuccess = (cardId: number, code: string, balance: number): void => {
    setCardId(cardId);
    setCode(code);
    setBalance(balance);
  };

  const handleCardError = (cardId: number, code: string): void => {
    setCardId(cardId);
    setCode(code);
    setBalance(undefined);
  };

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const handleOpenSecondaryView = (): void => {
    if (openSecondaryView) {
      openSecondaryView();
    }
  };

  const isValidPaymentAmount = (paymentAmount: string) => {
    return !isNaN(parseInt(paymentAmount)) && parseInt(paymentAmount) > 0;
  };

  useEffect((): void => {
    getSystemSettings();
    if (typeof paymentValue === 'number') {
      setPaymentValue(paymentValue);
    } else {
      updatePaymentMethodAndProvider(
        (paymentMethod: PaymentMethod | undefined, paymentProvider: PaymentProvider | undefined): void =>
          setPaymentValue({
            method: paymentMethod || PaymentMethod.SBP,
            provider: paymentProvider || PaymentProvider.ALFA,
          }),
        paymentValue?.method || PaymentMethod.SBP,
        paymentValue?.provider || PaymentProvider.ALFA,
        systemSettings
      );
    }
    setCardId(undefined);
    setCode(undefined);
    setBalance(undefined);
  }, [getSystemSettings, open, paymentValue, systemSettings]);

  useEffect(() => {
    setPaymentAmount(price ? (price - currentBalance).toString() : '');
  }, [currentBalance, open, price]);

  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={'Пополнить баланс'}
      handleCloseButton={handleClose}
      PaperProps={{ sx: { minWidth: isMobile ? 'auto' : '540px' } }}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(-3.5),
      })}
      actions={
        <Grid
          container={true}
          justifyContent={isCardMethod(paymentValue.method) ? 'flex-end' : 'space-between'}
          alignItems={'center'}
          sx={{
            paddingTop: (theme: Theme): string => (isMobile ? theme.spacing(0) : theme.spacing(2)),
            paddingBottom: (theme: Theme): string => (isMobile ? theme.spacing(6) : theme.spacing(0)),
          }}>
          {!isCardMethod(paymentValue.method) && (
            <Grid item={true} xs={12} sm={true} pr={{ xs: 0, sm: 2 }}>
              <Typography
                align={isMobile ? 'center' : 'left'}
                component={'div'}
                variant={'body2'}
                whiteSpace={'pre-line'}
                color={(theme: Theme) => theme.colors.grayText}>
                {`Взимается сервисный сбор ${
                  parseInt(paymentAmount) > 0
                    ? formatCurrency(getCommission(parseInt(paymentAmount), paymentValue.method, paymentValue.provider))
                    : ''
                }`}
              </Typography>
            </Grid>
          )}
          <Grid item={true} sx={{ minWidth: '176px' }}>
            {!isCardMethod(paymentValue.method) ? (
              isMobile ? (
                <MobileBar>
                  <FixedButton
                    variant={'contained'}
                    disabled={!isValidPaymentAmount(paymentAmount) || isBillLoading || isSbpLoading}
                    onClick={(): Promise<void> =>
                      paymentValue.method === PaymentMethod.ONLINE ? handleOnlinePayment() : handleSbpPayment()
                    }>
                    {'Перейти к оплате'}
                  </FixedButton>
                </MobileBar>
              ) : (
                <LoadingButton
                  isLoading={isBillLoading}
                  withPadding={true}
                  fullWidth={true}
                  variant={'contained'}
                  disabled={!isValidPaymentAmount(paymentAmount) || isBillLoading || isSbpLoading}
                  onClick={(): Promise<void> =>
                    paymentValue.method === PaymentMethod.ONLINE ? handleOnlinePayment() : handleSbpPayment()
                  }>
                  {'Перейти к оплате'}
                </LoadingButton>
              )
            ) : isMobile ? (
              <MobileBar>
                <FixedButton
                  variant={'contained'}
                  disabled={
                    isCardLoading ||
                    !cardId ||
                    isBlank(code) ||
                    !isValidPaymentAmount(paymentAmount) ||
                    !balance ||
                    balance < (paymentAmount || 0)
                  }
                  onClick={() => handleCardPayment()}>
                  {'Оплатить'}
                </FixedButton>
              </MobileBar>
            ) : (
              <LoadingButton
                isLoading={isCardLoading}
                withPadding={true}
                fullWidth={true}
                variant={'contained'}
                disabled={
                  isCardLoading ||
                  !cardId ||
                  isBlank(code) ||
                  !isValidPaymentAmount(paymentAmount) ||
                  !balance ||
                  balance < (paymentAmount || 0)
                }
                onClick={() => handleCardPayment()}>
                {'Оплатить'}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      }>
      <Grid container={true} direction={'column'}>
        <Grid item={true} mb={isMobile ? 6 : 7}>
          <Typography variant={'body2'}>{`Актуальный баланс: ${formatCurrency(currentBalance)}`}</Typography>
        </Grid>
        <Grid item={true} mb={3}>
          <Grid container={true} alignItems={'center'} spacing={2} wrap={isMobile ? 'wrap' : 'nowrap'}>
            <Grid item={true} xs={12} sm={'auto'}>
              <OutlinedField
                fullWidth={true}
                type={'number'}
                label={'Сумма пополнения'}
                value={paymentAmount}
                placeholder={'0'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeAmount(e.target.value)}
                error={balance !== undefined && (balance === 0 || (paymentAmount || 0) > balance)}
                helperText={
                  balance !== undefined && (balance === 0 || (paymentAmount || 0) > balance)
                    ? 'Недостаточный баланс Ф-карты'
                    : ''
                }
                endAdornment={<InputAdornment position={'end'}>{'₽'}</InputAdornment>}
              />
            </Grid>
            <Grid item={true} xs={12} sm={'auto'}>
              <Grid container={true} alignItems={'center'} columnSpacing={1}>
                <Grid item={true}>
                  <Button
                    sx={{ borderRadius: '12px' }}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    onClick={() => handleChangeAmount('550')}>
                    {formatCurrency(550, 0)}
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Button
                    sx={{ borderRadius: '12px' }}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    onClick={() => handleChangeAmount('1100')}>
                    {formatCurrency(1100, 0)}
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Button
                    sx={{ borderRadius: '12px' }}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    onClick={() => handleChangeAmount('1650')}>
                    {formatCurrency(1650, 0)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <BalancePaymentRadioGroup
            value={JSON.stringify(paymentValue)}
            cards={cards}
            settings={systemSettings}
            onChange={handleRadioChange}
            onCardSuccess={handleCardSuccess}
            onCardError={handleCardError}
          />
        </Grid>
      </Grid>
    </ClientDialog>
  );
};
