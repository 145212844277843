import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { ButtonLink } from '@/components/common/ButtonLink';
import { DepartmentChangeDialog } from '@/components/letter/DepartmentChangeDialog';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { LetterHistoryDialog } from '@/components/letter/history/LetterHistoryDialog';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { StatusChip } from '@/components/common/StatusChip';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { formatSmartShortDateTimeString } from '@/utils/date-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  events?: EventLogDTO[];
  isLoading: boolean;
}

export const MobileLetterHistory: FC<Props> = (props: Props) => {
  const { events, isLoading } = props;
  const [historyDialogOpen, setHistoryDialogOpen] = useState<boolean>(false);
  const [departmentDialogOpen, setDepartmentDialogOpen] = useState<boolean>(false);
  const event: EventLogDTO | undefined = events?.at(0);
  return isLoading ? (
    <Skeleton variant={'rectangular'} height={'200px'} sx={(theme: Theme) => ({ margin: theme.spacing(0, -2) })} />
  ) : event ? (
    <Fragment>
      <Box
        sx={(theme: Theme) => ({
          background: theme.colors.tableHover,
          padding: theme.spacing(2),
          margin: theme.spacing(0, -2),
        })}>
        <Grid container={true} direction={'column'} spacing={2}>
          <Grid item={true}>
            <Grid container={true} direction={'column'} spacing={1}>
              <Grid item={true}>
                <Grid container={true} direction={'row'} spacing={0.5}>
                  {event.statuses.map((status: LetterSimpleStatus, index: number) => (
                    <Grid item={true} key={status}>
                      <StatusChip
                        status={status}
                        prefix={index === 0 ? formatSmartShortDateTimeString(event.timestamp) + ' ' : ''}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container={true} direction={'column'} spacing={1.25}>
                  <Grid item={true}>
                    <Typography
                      variant={'body3'}
                      component={'p'}
                      lineHeight={'20px'}
                      dangerouslySetInnerHTML={{ __html: event.readableStatus }}
                    />
                  </Grid>
                  {!!event?.letter?.departmentChangeAllowed && (
                    <Grid item={true}>
                      <Typography variant={'body3'} component={'div'} lineHeight={'20px'}>
                        {'Вы можете бесплатно '}
                        <TypographyLink onClick={() => setDepartmentDialogOpen(true)}>
                          {'переслать письмо в другое учреждение.'}
                        </TypographyLink>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <ButtonLink color={'primary'} noHover={true} onClick={() => setHistoryDialogOpen(true)}>
              <Typography variant={'h6'} lineHeight={'20px'}>
                {'Посмотреть историю'}
              </Typography>
            </ButtonLink>
          </Grid>
        </Grid>
      </Box>
      <LetterHistoryDialog
        open={historyDialogOpen}
        isMobile={true}
        events={events || []}
        onClose={() => {
          setHistoryDialogOpen(false);
        }}
      />
      <DepartmentChangeDialog
        open={departmentDialogOpen}
        email={event?.letter?.email}
        letterNumber={event?.letter?.number}
        sender={event?.letter?.sender}
        onClose={() => {
          setDepartmentDialogOpen(false);
        }}
      />
    </Fragment>
  ) : null;
};
