import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SendLetterMobileIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 48 48'} sx={{ width: '40px', height: '40px' }} {...props}>
    <rect x={'5'} y={'5'} width={'32'} height={'39'} rx={'2'} fill={'#ADC3FF'} />
    <rect x={'11'} y={'12'} width={'20'} height={'3'} rx={'1'} fill={'#F3F2F7'} />
    <rect x={'11'} y={'19'} width={'14'} height={'3'} rx={'1'} fill={'#F3F2F7'} />
    <rect
      x={'28.8758'}
      y={'20.7433'}
      width={'17'}
      height={'15'}
      rx={'2'}
      transform={'rotate(12 28.8758 20.7433)'}
      fill={'#F3F2F7'}
      stroke={'#ADC3FF'}
      strokeWidth={'2'}
    />
    <path
      d={
        'M32.6025 28.0576L28.5131 31.6652C27.8927 32.2125 28.1673 33.2335 28.9785 33.3957L40.1053 35.6211C40.9008 35.7802 41.5447 34.9757 41.2153 34.2343L40.4119 32.4269C40.1854 31.9171 39.5856 31.6913 39.0791 31.925L37.3854 32.7067C36.8928 32.9341 36.3089 32.7272 36.0692 32.2405L34.1612 28.3657C33.87 27.7745 33.0967 27.6216 32.6025 28.0576Z'
      }
      fill={'#ADC3FF'}
    />
  </SvgIcon>
);
