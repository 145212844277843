import { BankIcons } from '@/components/common/payment/BankIcons';
import { Grid } from '@mui/material';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { PaymentRadio } from '@/components/common/payment/PaymentRadio';
import { PaymentSettingsDTO } from '@/types/PaymentSettingsDTO';
import { SbpIcon } from '@/app/icons/payment/SbpIcon';
import React, { FC } from 'react';

interface Props {
  currentValue?: string;
  settings?: PaymentSettingsDTO;
}

export const BankPaymentRadioSet: FC<Props> = (props: Props) => {
  const { currentValue, settings } = props;

  const valueEquals = (method: PaymentMethod, provider?: PaymentProvider): boolean => {
    const value: { method: PaymentMethod; provider: PaymentProvider } = currentValue
      ? JSON.parse(currentValue)
      : { method: PaymentMethod.SBP, provider: PaymentProvider.ALFA };
    return value?.method === method && value?.provider === provider;
  };

  return (
    <Grid container={true} direction={'column'} spacing={1}>
      {settings?.sbpServiceEnabled && (
        <Grid item={true}>
          <PaymentRadio
            value={JSON.stringify({ method: PaymentMethod.SBP, provider: PaymentProvider.ALFA })}
            label={
              <Grid container={true} alignItems={'center'} spacing={1.5} wrap={'nowrap'}>
                <Grid item={true}>{'Система быстрых платежей'}</Grid>
                <Grid item={true}>
                  <SbpIcon />
                </Grid>
              </Grid>
            }
            caption={
              valueEquals(PaymentMethod.SBP, PaymentProvider.ALFA) &&
              'Оплата через сканирование QR-кода в приложении банка'
            }
          />
        </Grid>
      )}
      {settings?.alfaServiceEnabled && (
        <Grid item={true}>
          <PaymentRadio
            value={JSON.stringify({ method: PaymentMethod.ONLINE, provider: PaymentProvider.ALFA })}
            label={'Банковской картой'}
            caption={valueEquals(PaymentMethod.ONLINE, PaymentProvider.ALFA) && <BankIcons />}
          />
        </Grid>
      )}
      {settings?.orcServiceEnabled && (
        <Grid item={true}>
          <PaymentRadio
            value={JSON.stringify({ method: PaymentMethod.ONLINE, provider: PaymentProvider.ORC })}
            label={'Банковской картой'}
            caption={valueEquals(PaymentMethod.ONLINE, PaymentProvider.ORC) && <BankIcons />}
          />
        </Grid>
      )}
    </Grid>
  );
};
