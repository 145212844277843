import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const RedirectIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 60 60'} sx={{ width: '40px', height: '40px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M32 24C32 26.2091 33.7909 28 36 28H50C52.2091 28 54 26.2091 54 24V10C54 7.79086 52.2091 6 50 6H36C33.7909 6 32 7.79086 32 10V24ZM6 50C6 52.2091 7.79086 54 10 54H24C26.2091 54 28 52.2091 28 50V36C28 33.7909 26.2091 32 24 32H10C7.79086 32 6 33.7909 6 36V50ZM22.6585 7.45054L28.1399 12.2468C28.5952 12.6452 28.5952 13.3535 28.1399 13.7519L22.6585 18.5482C22.0119 19.1139 21 18.6547 21 17.7956V15H20C15.5817 15 12 18.5817 12 23V24C12 25.1046 11.1046 26 10 26C8.89543 26 8 25.1046 8 24V23C8 16.3726 13.3726 11 20 11H21V8.20312C21 7.34396 22.0119 6.88478 22.6585 7.45054ZM39.9999 45C44.4182 45 47.9999 41.4183 47.9999 37V36C47.9999 34.8954 48.8954 34 49.9999 34C51.1045 34 51.9999 34.8954 51.9999 36V37C51.9999 43.6274 46.6274 49 39.9999 49H38.9999V51.7969C38.9999 52.656 37.988 53.1152 37.3414 52.5495L31.86 47.7532C31.4047 47.3548 31.4047 46.6465 31.86 46.2481L37.3414 41.4518C37.988 40.8861 38.9999 41.3453 38.9999 42.2044V45H39.9999Z'
      }
      fill={'#ADC3FF'}
    />
  </SvgIcon>
);
