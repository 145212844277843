export enum EmailChangeResponseStatus {
  EMAIL_CREATE = 'EMAIL_CREATE',
  EMAIL_CHANGE = 'EMAIL_CHANGE',
  WRONG_EMAIL = 'WRONG_EMAIL',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
}

export interface EmailChangeResponseDTO {
  status: EmailChangeResponseStatus;
}
