import { CardPaymentSuccessInfo } from '@/components/create/unauthorized/CardPaymentSuccessInfo';
import { CardPaymentWaitingInfo } from '@/components/create/unauthorized/CardPaymentWaitingInfo';
import { CardService } from '@/types/card/CardService';
import React, { FC, Fragment } from 'react';

interface Props {
  cardService: CardService;
  email: string;
  message?: string;
  isPaymentSuccess?: boolean;
}

export const CardPaymentInfo: FC<Props> = (props: Props) => {
  const { cardService, email, message, isPaymentSuccess } = props;

  return (
    <Fragment>
      {isPaymentSuccess ? (
        <CardPaymentSuccessInfo cardService={cardService} email={email} message={message} />
      ) : (
        <CardPaymentWaitingInfo email={email} message={message} />
      )}
    </Fragment>
  );
};
