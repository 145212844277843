import { AnswerPaymentSuccessInfo } from '@/components/create/unauthorized/AnswerPaymentSuccessInfo';
import { AnswerPaymentWaitingInfo } from '@/components/create/unauthorized/AnswerPaymentWaitingInfo';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import React, { FC, Fragment } from 'react';

interface Props {
  letter: CreateLetterDTO;
  message?: string;
  isPaymentSuccess?: boolean;
}

export const AnswerPaymentInfo: FC<Props> = (props: Props) => {
  const { letter, message, isPaymentSuccess } = props;

  return (
    <Fragment>
      {isPaymentSuccess ? (
        <AnswerPaymentSuccessInfo letter={letter} message={message} />
      ) : (
        <AnswerPaymentWaitingInfo email={letter.author?.email} message={message} />
      )}
    </Fragment>
  );
};
