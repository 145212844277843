import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const UpIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M12.3867 7.74707C12.1494 7.74707 11.9297 7.85254 11.7627 8.02832L5.36426 14.5762C5.20605 14.7344 5.11816 14.9365 5.11816 15.165C5.11816 15.6396 5.47852 16 5.95312 16C6.17285 16 6.39258 15.9121 6.55078 15.7539L12.3867 9.77734L18.2314 15.7539C18.3896 15.9121 18.5918 16 18.8291 16C19.2949 16 19.6641 15.6396 19.6641 15.165C19.6641 14.9365 19.5762 14.7344 19.418 14.5762L13.0195 8.02832C12.835 7.84375 12.6328 7.74707 12.3867 7.74707Z'
      }
    />
  </SvgIcon>
);
