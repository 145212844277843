import { Chip, ChipProps } from '@mui/material';
import { ExclamationIcon } from '@/app/icons/ExclamationIcon';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { getStatusLabel } from '@/utils/status-utils';
import React, { FC, ReactElement } from 'react';

interface Props extends ChipProps {
  status: LetterSimpleStatus;
  prefix?: string;
}

export const StatusChip: FC<Props> = (props: Props) => {
  const { prefix, status, ...other } = props;
  const getIcon = (status: LetterSimpleStatus): ReactElement | undefined => {
    switch (status) {
      case LetterSimpleStatus.UNPAID: {
        return <ExclamationIcon />;
      }
      default: {
        return undefined;
      }
    }
  };
  const getVariant = (status: LetterSimpleStatus): 'filled' | 'outlined' => {
    switch (status) {
      case LetterSimpleStatus.UNPAID:
      case LetterSimpleStatus.PAID:
      case LetterSimpleStatus.IN_PROGRESS:
      case LetterSimpleStatus.DELIVERED:
      case LetterSimpleStatus.WAITING_FOR_ANSWER:
      case LetterSimpleStatus.ANSWER_UPLOADED:
      case LetterSimpleStatus.ANSWER_CENSORED:
      case LetterSimpleStatus.LETTER_CENSORED:
      case LetterSimpleStatus.ATTACHMENT_CENSORED:
      case LetterSimpleStatus.ADDRESSEE_NOT_IN_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_LEFT_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_IS_FREE: {
        return 'outlined';
      }
      default: {
        return 'filled';
      }
    }
  };
  const getColor = (status: LetterSimpleStatus): string => {
    switch (status) {
      case LetterSimpleStatus.UNPAID: {
        return '#af7036';
      }
      case LetterSimpleStatus.PAID:
      case LetterSimpleStatus.DELIVERED: {
        return '#349959';
      }
      case LetterSimpleStatus.IN_PROGRESS: {
        return '#936bbb';
      }
      case LetterSimpleStatus.WAITING_FOR_ANSWER:
      case LetterSimpleStatus.ANSWER_UPLOADED: {
        return '#5e77bb';
      }
      case LetterSimpleStatus.ANSWER_CENSORED:
      case LetterSimpleStatus.LETTER_CENSORED:
      case LetterSimpleStatus.ADDRESSEE_NOT_IN_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_LEFT_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_IS_FREE: {
        return '#e57872';
      }
      case LetterSimpleStatus.ATTACHMENT_CENSORED: {
        return '#838b35';
      }
      default: {
        return '#ffffff';
      }
    }
  };
  const getBorderColor = (status: LetterSimpleStatus): string => {
    switch (status) {
      case LetterSimpleStatus.UNPAID: {
        return '#ebcaac';
      }
      case LetterSimpleStatus.PAID:
      case LetterSimpleStatus.DELIVERED: {
        return '#a0d2b3';
      }
      case LetterSimpleStatus.IN_PROGRESS: {
        return '#d2aff4';
      }
      case LetterSimpleStatus.WAITING_FOR_ANSWER:
      case LetterSimpleStatus.ANSWER_UPLOADED: {
        return '#bcccf8';
      }
      case LetterSimpleStatus.ANSWER_CENSORED:
      case LetterSimpleStatus.LETTER_CENSORED:
      case LetterSimpleStatus.ADDRESSEE_NOT_IN_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_LEFT_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_IS_FREE: {
        return '#ffcfcc';
      }
      case LetterSimpleStatus.ATTACHMENT_CENSORED: {
        return '#c8d557';
      }
      default: {
        return '#c6c5c9';
      }
    }
  };
  const getBackgroundColor = (status: LetterSimpleStatus): string => {
    switch (status) {
      case LetterSimpleStatus.UNPAID: {
        return '#ffe3c9';
      }
      case LetterSimpleStatus.PAID:
      case LetterSimpleStatus.WAITING_FOR_ANSWER: {
        return '#ffffff';
      }
      case LetterSimpleStatus.IN_PROGRESS: {
        return '#f2e4ff';
      }
      case LetterSimpleStatus.DELIVERED: {
        return '#daf0e2';
      }
      case LetterSimpleStatus.ANSWER_UPLOADED: {
        return '#dbe5ff';
      }
      case LetterSimpleStatus.ANSWER_CENSORED:
      case LetterSimpleStatus.LETTER_CENSORED:
      case LetterSimpleStatus.ADDRESSEE_NOT_IN_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_LEFT_DEPARTMENT:
      case LetterSimpleStatus.ADDRESSEE_IS_FREE: {
        return '#fbe3e2';
      }
      case LetterSimpleStatus.ATTACHMENT_CENSORED: {
        return '#f0f2c7';
      }
      default: {
        return '#c6c5c9';
      }
    }
  };
  return (
    <Chip
      variant={getVariant(status)}
      label={(prefix ? prefix : '') + getStatusLabel(status)}
      icon={getIcon(status)}
      sx={{
        color: getColor(status),
        borderColor: getBorderColor(status),
        backgroundColor: getBackgroundColor(status),
      }}
      {...other}
    />
  );
};
