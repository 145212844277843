import { CardFormDialog } from '@/components/create/card/dialog/CardFormDialog';
import { CardFormVariant } from '@/types/card/CardFormVariant';
import { CardSuccessDialog } from '@/components/create/card/dialog/CardSuccessDialog';
import { CreateCardAdvantages } from '@/components/create/card/CreateCardAdvantages';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Footer } from '@/components/main/footer/Footer';
import { Grid, Theme, Typography } from '@mui/material';
import { MobileBar } from '@/components/home/MobileBar';
import React, { FC, Fragment, useState } from 'react';

export const CreateCardBlockMobile: FC = () => {
  const [formType, setFormType] = useState<CardFormVariant>(CardFormVariant.CREATED);
  const [openCardFormDialog, setOpenCardFormDialog] = useState<boolean>(false);
  const [openCardSuccessDialog, setOpenCardSuccessDialog] = useState<boolean>(false);

  const handleClickButton = (formType: CardFormVariant) => {
    setFormType(formType);
    setOpenCardFormDialog(true);
  };

  return (
    <Fragment>
      <Grid container={true} direction={'column'} sx={{ height: '100%' }}>
        <Grid item={true} paddingBottom={4}>
          <Typography variant={'h2'} lineHeight={(theme: Theme) => theme.spacing(3)}>
            {'Закажите Ф-карту, чтобы оплачивать письма и услуги сервиса Ф-письмо'}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid container={true} rowSpacing={3} direction={'column'}>
            <Grid item={true} xs={true}>
              <CreateCardAdvantages
                title={'Удобная оплата писем и услуг'}
                description={'Можно пополнить карту на любую сумму, а затем с помощью неё оплачивать письма'}
              />
            </Grid>
            <Grid item={true} xs={true}>
              <CreateCardAdvantages
                title={'Совместное пользование картой'}
                description={'Создайте карту, которой будут оплачивать письма вы и ваши близкие'}
              />
            </Grid>
            <Grid item={true} xs={true}>
              <CreateCardAdvantages
                title={'Удобные способы пополнения'}
                description={'СБП, банковский перевод, платежные карты, QIWI'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={true}>
          <Grid container={true} sx={{ height: '100%' }} alignItems={'flex-end'}>
            <Grid item={true} xs={true} marginBottom={4} marginTop={7}>
              <Footer isMobile={true} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <MobileBar>
            <Grid container={true} direction={'row'} wrap={'nowrap'}>
              <Grid item={true} xs={true}>
                <FixedButton
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => handleClickButton(CardFormVariant.CREATED)}>
                  {'Оформить карту'}
                </FixedButton>
              </Grid>
              <Grid item={true} xs={true}>
                <FixedButton
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => handleClickButton(CardFormVariant.PAYED)}>
                  {'Баланс и пополнение'}
                </FixedButton>
              </Grid>
            </Grid>
          </MobileBar>
        </Grid>
      </Grid>
      <CardFormDialog open={openCardFormDialog} variant={formType} onClose={() => setOpenCardFormDialog(false)} />
      <CardSuccessDialog
        open={openCardSuccessDialog}
        variant={formType}
        onClose={() => setOpenCardSuccessDialog(false)}
      />
    </Fragment>
  );
};
