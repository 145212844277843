import { AnswerStatus } from '@/types/answer/AnswerStatus';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { CreatePage } from '@/components/create/CreatePage';
import { Footer } from '@/components/main/footer/Footer';
import { Header } from '@/components/common/Header';
import { HomePage } from '@/components/home/HomePage';
import { InboxLetterPage } from '@/components/view/InboxLetterPage';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { MainNavigation } from '@/components/main/navigation/MainNavigation';
import { MobileHeader } from '@/components/common/MobileHeader';
import { MobileHomePage } from '@/components/home/MobileHomePage';
import { MobileProfilePage } from '@/components/profile/MobileProfilePage';
import { OutboxLetterPage } from '@/components/view/OutboxLetterPage';
import { PrivateRoute } from '@/components/auth/PrivateRoute';
import { ProfilePage } from '@/components/profile/ProfilePage';
import {
  ROUTE_CREATE,
  ROUTE_DRAFTS,
  ROUTE_HOME,
  ROUTE_INBOX,
  ROUTE_INBOX_LETTER,
  ROUTE_OUTBOX,
  ROUTE_OUTBOX_LETTER,
  ROUTE_PROFILE,
} from '@/app/routes';
import { Redirect, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { TopLine } from '@/components/common/TopLine';
import { UserDTO } from '@/types/user/UserDTO';
import { getAuthenticated, getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useCountLetterDraftsQuery } from '@/services/api/letterDraftApiSlice';
import { useGetLettersCountQuery } from '@/services/api/letterApiSlice';
import { useGetSendersQuery } from '@/services/api/createFormApiSlice';
import React, { FC, Fragment } from 'react';

export const Main: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const { data: inboxCount } = useGetLettersCountQuery(
    {
      status: LetterSimpleStatus.ALL,
      answerStatus: AnswerStatus.PROCESSED,
      userId: authUser?.id,
    },
    { skip: !authUser }
  );
  const { data: outboxCount } = useGetLettersCountQuery(
    {
      status: LetterSimpleStatus.ALL,
      userId: authUser?.id,
    },
    { skip: !authUser }
  );
  const { data: senders, isLoading: isLoadingSenders } = useGetSendersQuery(authUser?.id, { skip: !authUser });
  const { data: letterDraftsCount } = useCountLetterDraftsQuery(authUser?.id, {
    skip: !authUser,
  });

  return (
    <Fragment>
      <TopLine />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-6px',
          minHeight: '100%',
        }}>
        <Box display={'flex'} flex={1}>
          <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={isMobile ? 5 : 7}>
            <Grid item={true} pb={(theme: Theme) => (isMobile ? theme.spacing(1.5) : 'inherit')}>
              {isMobile ? (
                <MobileHeader inboxCount={inboxCount} outboxCount={outboxCount} letterDraftsCount={letterDraftsCount} />
              ) : authUser ? (
                <Header profile={!!authUser} />
              ) : (
                <MainNavigation profile={!!authUser} isAuthenticated={isAuthenticated} inboxCount={inboxCount} />
              )}
            </Grid>
            <Grid item={true} xs={true}>
              <Switch>
                <PrivateRoute exact={true} path={[ROUTE_HOME, ROUTE_INBOX, ROUTE_OUTBOX, ROUTE_DRAFTS]}>
                  {isMobile ? (
                    <MobileHomePage
                      inboxCount={inboxCount}
                      outboxCount={outboxCount}
                      senders={senders}
                      letterDraftsCount={letterDraftsCount}
                      isLoadingSenders={isLoadingSenders}
                    />
                  ) : (
                    <HomePage
                      inboxCount={inboxCount}
                      outboxCount={outboxCount}
                      senders={senders}
                      letterDraftsCount={letterDraftsCount}
                      isLoadingSenders={isLoadingSenders}
                    />
                  )}
                </PrivateRoute>
                <PrivateRoute path={ROUTE_CREATE}>
                  <CreatePage />
                </PrivateRoute>
                <PrivateRoute path={ROUTE_PROFILE}>{isMobile ? <MobileProfilePage /> : <ProfilePage />}</PrivateRoute>
                <PrivateRoute exact={true} path={ROUTE_OUTBOX_LETTER}>
                  <OutboxLetterPage />
                </PrivateRoute>
                <PrivateRoute exact={true} path={ROUTE_INBOX_LETTER}>
                  <InboxLetterPage />
                </PrivateRoute>
                <Redirect to={ROUTE_HOME} />
              </Switch>
            </Grid>
            {isMobile ? null : (
              <Grid item={true}>
                <Footer isMobile={isMobile} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};
