import { Grid } from '@mui/material';
import { PayCardButton } from '@/components/create/card/buttons/PayCardButton';
import React, { FC } from 'react';

interface Props {
  setPayAmount: (payAmount: number) => void;
}

export const PayCardButtonGroup: FC<Props> = (props: Props) => {
  const { setPayAmount } = props;
  return (
    <Grid container={true} spacing={1} alignItems={'center'} sx={{ height: 'calc(100% + 8px)' }}>
      <Grid item={true}>
        <PayCardButton payAmount={500} setPayAmount={setPayAmount} />
      </Grid>
      <Grid item={true}>
        <PayCardButton payAmount={1100} setPayAmount={setPayAmount} />
      </Grid>
      <Grid item={true}>
        <PayCardButton payAmount={1650} setPayAmount={setPayAmount} />
      </Grid>
      <Grid item={true}>
        <PayCardButton payAmount={2750} setPayAmount={setPayAmount} />
      </Grid>
      <Grid item={true}>
        <PayCardButton payAmount={5500} setPayAmount={setPayAmount} />
      </Grid>
    </Grid>
  );
};
