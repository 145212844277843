import { Button, Grid, Typography } from '@mui/material';
import { ClientDialog, ClientDialogProps } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MobileBar } from '@/components/home/MobileBar';
import React, { FC } from 'react';

export const LetterRulesDialog: FC<ClientDialogProps> = (props: ClientDialogProps) => {
  const { open, handleCloseButton, isMobile, ...other } = props;
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      handleCloseButton={handleCloseButton}
      label={'Правила составления писем'}
      {...other}>
      <Grid container={true} direction={'column'} mb={isMobile ? 6 : 0} wrap={'nowrap'}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} rowSpacing={2}>
            <Grid item={true}>
              <Typography variant={'body1'}>
                {'Какие письма не пройдут цензуру в исправительных учреждениях?'}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} rowSpacing={1.5}>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'1.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>
                        {
                          'Содержащие сведения, которые могут помешать установлению истины по уголовному делу или способствовать совершению преступления.'
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'2.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>
                        {'Содержащие государственную или иную охраняемую законом тайну.'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'3.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>
                        {
                          'Выполненные тайнописью или шифром. Имейте в виду, что эмодзи и другие специальные символы могут быть расценены цензором как тайнопись.'
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'4.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'Содержащие ненормативную лексику или жаргон.'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'5.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>
                        {'Содержащие копии литературных произведений и/или изданий периодической печати.'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} columnSpacing={'6px'} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'6.'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'body1'}>{'Написанные не на русском языке.'}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Typography variant={'body1'}>{'Если письмо не пройдет цензуру, деньги возвращены не будут.'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isMobile ? (
          <MobileBar>
            <FixedButton color={'primary'} variant={'contained'} onClick={() => handleCloseButton(open)}>
              {'Закрыть'}
            </FixedButton>
          </MobileBar>
        ) : (
          <Grid item={true} paddingTop={'57px'}>
            <Grid container={true} justifyContent={'flex-end'}>
              <Grid item={true}>
                <Button variant={'contained'} sx={{ minWidth: '176px' }} onClick={() => handleCloseButton(open)}>
                  {'Закрыть'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ClientDialog>
  );
};
