import { EnvelopeIcon } from '@/app/icons/EnvelopeIcon';
import { Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

const EmailSuccess: FC = () => {
  return (
    <Grid container={true} direction={'column'} rowSpacing={2}>
      <Grid item={true}>
        <Grid container={true} justifyContent={'center'}>
          <Grid item={true}>
            <EnvelopeIcon sx={{ width: '56px', height: '56px' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid container={true} justifyContent={'center'}>
          <Grid item={true}>
            <Typography variant={'h1'} sx={{ maxWidth: '292px' }} align={'center'}>
              {'Подтвердите адрес электронной почты'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid container={true} justifyContent={'center'}>
          <Grid item={true}>
            <Typography variant={'body1'} sx={{ maxWidth: '292px' }} align={'center'}>
              {
                'Мы отправили вам письмо со ссылкой для верификации на старый электронный адрес. Пройдите по ссылке, чтобы подтвердить свой новый е-мейл.'
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailSuccess;
