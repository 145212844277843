import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Grid } from '@mui/material';
import { ROUTE_HELP_REGIONS } from '@/app/routes';
import NavigatePreviousStep from '@/components/create/NavigatePreviousStep';
import React, { FC } from 'react';
import RegionsList from '@/components/help/departments/RegionsList';

interface Props {
  regions?: AtlasRegionDTO[];
  char?: string;
  handleChangeRegion: (region: AtlasRegionDTO) => void;
}

const MobileRegionsCharacter: FC<Props> = (props: Props) => {
  const { regions, char, handleChangeRegion } = props;
  const startIndex: number | undefined = regions?.findIndex((region: AtlasRegionDTO, index: number) => {
    return index > 1 && region.name[0] === char;
  });
  const buffer: number | undefined = regions
    ?.slice()
    ?.reverse()
    ?.findIndex((region: AtlasRegionDTO) => region.name[0] === char);
  //regions.length - buffer: correct condition
  const endIndex: number | undefined = regions && buffer ? regions.length - buffer : undefined;
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} marginBottom={'12px'}>
        <NavigatePreviousStep to={ROUTE_HELP_REGIONS} sx={{ position: 'relative', margin: '0px -5px' }}>
          {'Назад'}
        </NavigatePreviousStep>
      </Grid>
      <Grid item={true}>
        <RegionsList
          regions={regions}
          handleChangeRegion={handleChangeRegion}
          indexFrom={startIndex}
          indexTo={endIndex}
        />
      </Grid>
    </Grid>
  );
};

export default MobileRegionsCharacter;
