import { CollapseView } from '@/components/common/CollapseView';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  text?: string;
}

export const LetterTextView: FC<Props> = (props: Props) => {
  const { text } = props;
  return (
    <CollapseView wrapHeight={192}>
      <Typography
        variant={'body1'}
        sx={{
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
        }}>
        {text}
      </Typography>
    </CollapseView>
  );
};
