import { BalanceHistoryDTO } from '@/types/balance/BalanceHistoryDTO';
import { BalanceHistoryDivider } from '@/components/balance/BalanceHistoryDivider';
import { BalanceHistoryTableRow } from '@/components/balance/BalanceHistoryTableRow';
import { MobileBalanceHistoryTableRow } from '@/components/balance/MobileBalanceHistoryTableRow';
import { Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { UserBalanceHistoryType } from '@/types/balance/UserBalanceHistoryType';
import { formatMonthString } from '@/utils/date-utils';
import { formatPrisonerFullNameShort, upperFirstCase } from '@/utils/string-utils';
import { isSameMonth } from 'date-fns';
import React, { FC, Fragment } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  items: BalanceHistoryDTO[];
  isMobile?: boolean;
}

export const BalanceHistoryTable: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, items, isMobile } = props;
  const loading: boolean = isFetching || isLoading;
  const getTitle = (item: BalanceHistoryDTO): string => {
    switch (item.type) {
      case UserBalanceHistoryType.INCOME: {
        return 'Пополнение';
      }
      case UserBalanceHistoryType.EXPENSE: {
        if (item.letterNumber) {
          let title: string = 'Письмо № '.concat(String(item.letterNumber));
          if (item.prisonerLastName) {
            title = title
              .concat(' адресату ')
              .concat(
                formatPrisonerFullNameShort(
                  item.prisonerLastName || '',
                  item.prisonerFirstName || '',
                  item.prisonerMiddleName
                )
              );
          }
          if (item.photoAttached) {
            title = title.concat(', фото');
          }
          return title;
        }
        return 'Неизвестно';
      }
      case UserBalanceHistoryType.TRANSFER: {
        return 'Пополнение';
      }
      default: {
        return 'Неизвестно';
      }
    }
  };
  const getDescription = (item: BalanceHistoryDTO): string => {
    switch (item.type) {
      case UserBalanceHistoryType.INCOME: {
        return 'Банковская карта';
      }
      case UserBalanceHistoryType.EXPENSE: {
        return 'Списание';
      }
      case UserBalanceHistoryType.TRANSFER: {
        if (item.cardNumber) {
          return `Ф-карта № ${item.cardNumber}`;
        }
        return '';
      }
      default: {
        return 'Неизвестно';
      }
    }
  };
  const isDividerAt = (index: number, items: BalanceHistoryDTO[]): boolean => {
    if (items) {
      const current: BalanceHistoryDTO = items[index];
      const next: BalanceHistoryDTO = items[index + 1];
      if (current && next) {
        const currentTimestamp: Date = new Date(current.timestamp);
        const nextTimestamp: Date = new Date(next.timestamp);
        return !isSameMonth(currentTimestamp, nextTimestamp);
      }
    }
    return false;
  };
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {(loading || (items || []).length > 0) && (
            <TableRow>
              <BalanceHistoryDivider
                label={(items || []).length > 0 ? upperFirstCase(formatMonthString(items[0].timestamp)) : ''}
                isLoading={loading}
              />
            </TableRow>
          )}
          {(loading ? Array.from(new Array(6)) : items).map((item: BalanceHistoryDTO, index: number) => {
            return (
              <Fragment key={loading ? index : item.id}>
                {isMobile ? (
                  <MobileBalanceHistoryTableRow
                    index={index}
                    item={item}
                    items={items}
                    isLoading={loading}
                    isDividerAt={isDividerAt}
                    getTitle={getTitle}
                    getDescription={getDescription}
                  />
                ) : (
                  <BalanceHistoryTableRow
                    index={index}
                    item={item}
                    items={items}
                    isLoading={loading}
                    isDividerAt={isDividerAt}
                    getTitle={getTitle}
                    getDescription={getDescription}
                  />
                )}
                {!loading && isDividerAt(index, items) && (
                  <TableRow>
                    <BalanceHistoryDivider
                      label={upperFirstCase(formatMonthString(items[index + 1].timestamp))}
                      isLoading={loading}
                    />
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
