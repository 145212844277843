import { AuthState, authSlice } from '@/services/authSlice';
import { BalanceHistoryState, balanceHistorySlice } from '@/services/balanceHistorySlice';
import { CombinedState, combineReducers, configureStore } from '@reduxjs/toolkit';
import { CreateFormState, createFormSlice } from '@/services/createFormSlice';
import { DepartmentRedirectFormState, departmentRedirectFormSlice } from '@/services/departmentRedirectFormSlice';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import { HelpState, helpSlice } from '@/services/helpSlice';
import { IpLocationState, ipLocationSlice } from '@/services/ipLocationSlice';
import { LetterDraftState, letterDraftSlice } from '@/services/letter/LetterDraftSlice';
import { LetterInboxState, letterInboxSlice } from '@/services/letter/letterInboxSlice';
import { LetterOutboxState, letterOutboxSlice } from '@/services/letter/letterOutboxSlice';
import { PersistConfig } from 'redux-persist/es/types';
import { ProfileState, profileSlice } from '@/services/profileSlice';
import { SbpState, sbpSlice } from '@/services/sbpSlice';
import { apiSlice } from '@/services/api/apiSlice';
import { ipLocationMiddleware } from '@/app/middleware/ipLocationMiddleware';
import { unauthorizedMiddleware } from '@/app/middleware/unauthorizedMiddleware';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';

interface ReducerState {
  auth: AuthState;
  letterInbox: LetterInboxState;
  letterOutbox: LetterOutboxState;
  createForm: CreateFormState;
  help: HelpState;
  profile: ProfileState;
  balanceHistory: BalanceHistoryState;
  departmentRedirectForm: DepartmentRedirectFormState;
  sbp: SbpState;
  ipLocation: IpLocationState;
  letterDraft: LetterDraftState;
  api: CombinedState<any>;
}

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  letterInbox: letterInboxSlice.reducer,
  letterOutbox: letterOutboxSlice.reducer,
  createForm: createFormSlice.reducer,
  help: helpSlice.reducer,
  profile: profileSlice.reducer,
  balanceHistory: balanceHistorySlice.reducer,
  departmentRedirectForm: departmentRedirectFormSlice.reducer,
  sbp: sbpSlice.reducer,
  ipLocation: ipLocationSlice.reducer,
  letterDraft: letterDraftSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const config: PersistConfig<ReducerState> = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'sbp', 'letterInbox', 'letterOutbox', 'letterDraft', 'createForm', 'balanceHistory'],
};

const reducer = persistReducer(config, rootReducer);

const devMode: boolean = process.env.NODE_ENV !== 'production';

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => {
    let defaultMiddleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(ipLocationMiddleware)
      .concat(unauthorizedMiddleware);
    if (devMode) {
      defaultMiddleware = defaultMiddleware.concat(logger);
    }
    return defaultMiddleware;
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
