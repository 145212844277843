import { BankPaymentRadioSet } from '@/components/common/payment/BankPaymentRadioSet';
import { CardDTO } from '@/types/card/CardDTO';
import { CardPaymentRadio } from '@/components/common/payment/CardPaymentRadio';
import { FormControl, Grid, RadioGroup, RadioGroupProps } from '@mui/material';
import { PaymentSettingsDTO } from '@/types/PaymentSettingsDTO';
import React, { FC } from 'react';

interface Props extends RadioGroupProps {
  cards: CardDTO[];
  settings?: PaymentSettingsDTO;
  onCardSuccess: (cardId: number, code: string, balance: number) => void;
  onCardError: (cardId: number, code: string) => void;
}

export const BalancePaymentRadioGroup: FC<Props> = (props: Props) => {
  const { value, cards, settings, onCardSuccess, onCardError, ...other } = props;

  const bankPaymentEnabled: boolean =
    !!settings?.sbpServiceEnabled || !!settings?.alfaServiceEnabled || !!settings?.orcServiceEnabled;

  const handleCardSuccess = (cardId: number, code: string, balance: number): void => {
    if (onCardSuccess) {
      onCardSuccess(cardId, code, balance);
    }
  };

  const handleCardError = (cardId: number, code: string): void => {
    if (onCardError) {
      onCardError(cardId, code);
    }
  };

  return (
    <FormControl component={'fieldset'}>
      <RadioGroup value={value} {...other}>
        <Grid container={true} direction={'column'} spacing={1}>
          {bankPaymentEnabled && (
            <Grid item={true}>
              <BankPaymentRadioSet currentValue={value} settings={settings} />
            </Grid>
          )}
          {cards.map((card: CardDTO) => (
            <Grid item={true} key={card.id}>
              <CardPaymentRadio
                card={card}
                currentValue={value}
                onCodeSuccess={(code: string, balance: number): void => {
                  handleCardSuccess(card.id, code, balance);
                }}
                onCodeError={(code: string): void => {
                  handleCardError(card.id, code);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};
