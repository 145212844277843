import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AptekaIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    sx={{ width: '24px', height: '24px', display: 'flex', fill: 'none' }}
    {...props}>
    <path
      d={
        'M18.3261 21C16.5368 22.2601 14.3548 23 12 23C9.64517 23 7.46318 22.2601 5.67383 21M18.3261 3C16.5368 1.73994 14.3548 1 12 1C9.64517 1 7.46318 1.73994 5.67383 3'
      }
      strokeLinecap={'round'}
    />
    <mask id={'path-2-inside-1_1036_1109'} fill={'white'}>
      <path fillRule={'evenodd'} clipRule={'evenodd'} d={'M15 5H9V9H5V15H9V19H15V15H19V9L15 9V5Z'} />
    </mask>
    <path
      d={
        'M9 5V4H8V5H9ZM15 5H16V4H15V5ZM9 9V10H10V9H9ZM5 9V8H4V9H5ZM5 15H4V16H5V15ZM9 15H10V14H9V15ZM9 19H8V20H9V19ZM15 19V20H16V19H15ZM15 15V14H14V15H15ZM19 15V16H20V15H19ZM19 9H20V8H19V9ZM15 9H14V10H15V9ZM9 6H15V4H9V6ZM10 9V5H8V9H10ZM5 10H9V8H5V10ZM6 15V9H4V15H6ZM9 14H5V16H9V14ZM10 19V15H8V19H10ZM15 18H9V20H15V18ZM14 15V19H16V15H14ZM19 14H15V16H19V14ZM18 9V15H20V9H18ZM15 10L19 10V8L15 8V10ZM14 5V9H16V5H14Z'
      }
      mask={'url(#path-2-inside-1_1036_1109)'}
    />
  </SvgIcon>
);
