import { AnswerStatus } from '@/types/answer/AnswerStatus';
import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';

export interface LetterInboxState {
  request: LetterRequestDTO;
}

const initialState: LetterInboxState = {
  request: {
    searchRequest: {
      status: LetterSimpleStatus.ALL,
      answerStatus: AnswerStatus.PROCESSED,
    },
    pageRequest: {
      pageNumber: 0,
      pageSize: 8,
      sortRequest: {
        properties: ['answer.processedAt', 'answerDate'],
        direction: 'DESC',
      },
    },
  },
};

export const letterInboxSlice = createSlice({
  name: 'letterInbox',
  initialState: initialState,
  reducers: {
    setLetterInboxRequest: (state: LetterInboxState, action: PayloadAction<LetterRequestDTO>) => {
      state.request = action.payload;
    },
    setLetterInboxRequestPageNumber: (state: LetterInboxState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageNumber: action.payload,
        },
      };
    },
    setLetterInboxRequestPageSize: (state: LetterInboxState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageSize: action.payload,
        },
      };
    },
    setLetterInboxSearchRequestAddressee: (state: LetterInboxState, action: PayloadAction<string>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          addressee: action.payload,
        },
      };
    },
    setLetterInboxSearchRequestAnswerStatus: (state: LetterInboxState, action: PayloadAction<AnswerStatus>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          answerStatus: action.payload,
        },
      };
    },
    setLetterInboxSortRequest: (state: LetterInboxState, action: PayloadAction<SortRequestDTO>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          sortRequest: action.payload,
        },
      };
    },
  },
});

export const {
  setLetterInboxRequestPageNumber,
  setLetterInboxRequestPageSize,
  setLetterInboxSearchRequestAddressee,
  setLetterInboxSearchRequestAnswerStatus,
  setLetterInboxSortRequest,
} = letterInboxSlice.actions;

export const getLetterInboxRequest = (state: RootState): LetterRequestDTO => state.letterInbox.request;

export default letterInboxSlice.reducer;
