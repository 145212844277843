import { AuthorizationPage } from '@/components/auth/AuthorizationPage';
import { Helmet } from 'react-helmet';
import { Main } from '@/components/Main';
import { MainPage } from '@/components/MainPage';
import { NotFoundPage } from '@/components/NotFoundPage';
import {
  ROUTE_ABOUT,
  ROUTE_AUTHENTICATION,
  ROUTE_CARD,
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_CREATE,
  ROUTE_DRAFTS,
  ROUTE_ERROR,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_INBOX,
  ROUTE_MAIN,
  ROUTE_MAIN_CARD,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_OUTBOX,
  ROUTE_PAYMENT_INITIATIVE,
  ROUTE_PROFILE,
  ROUTE_REDIRECT,
  ROUTE_REGISTRATION,
  ROUTE_RESTORE,
  ROUTE_VIEW_INITIATIVE,
} from '@/app/routes';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { config } from '@/app/config';
import { useGetMetaDataQuery } from '@/services/api/metaApiSlice';
import React, { FC, Fragment } from 'react';

export const Root: FC = () => {
  const { data: meta } = useGetMetaDataQuery();
  return (
    <Fragment>
      {meta && (
        <Helmet>
          <title>{meta.title}</title>
          <meta name={'description'} content={meta.description} />
          <meta name={'keywords'} content={meta.keywords} />
        </Helmet>
      )}
      <Router basename={config.basePath}>
        <Switch>
          <Route path={[ROUTE_INBOX, ROUTE_OUTBOX, ROUTE_CREATE, ROUTE_PROFILE, ROUTE_DRAFTS]}>
            <Main />
          </Route>
          <Route path={[ROUTE_AUTHENTICATION, ROUTE_REGISTRATION, ROUTE_RESTORE]}>
            <AuthorizationPage />
          </Route>
          <Route
            path={[
              ROUTE_MAIN,
              ROUTE_CHECK_LETTER_STATUS,
              ROUTE_REDIRECT,
              ROUTE_CARD,
              ROUTE_MAIN_CARD,
              ROUTE_MAIN_CREATE_LETTER,
              ROUTE_HELP,
              ROUTE_ABOUT,
              ROUTE_VIEW_INITIATIVE,
              ROUTE_PAYMENT_INITIATIVE,
              ROUTE_ERROR,
            ]}>
            <MainPage />
          </Route>
          <Route path={ROUTE_HOME} exact={true}>
            <Redirect to={ROUTE_MAIN} />
          </Route>
          <Route path={'*'}>
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </Fragment>
  );
};
