import { BalancePaymentDialog } from '@/components/profile/modals/BalancePaymentDialog';
import { Box } from '@mui/material';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { LetterDTO } from '@/types/letter/LetterDTO';
import { LetterNumberDTO } from '@/types/letter/LetterNumberDTO';
import { OnlinePaymentResponseDTO } from '@/types/OnlinePaymentResponseDTO';
import { PaymentButton } from '@/components/create/unauthorized/PaymentButton';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { PolicyStatementCheckBox } from '@/components/common/PolicyStatementCheckBox';
import { RegistrationSbpDTO } from '@/types/sbp/RegistrationSbpDTO';
import { RootState } from '@/app/store';
import { SbpBalancePaymentDialog } from '@/components/common/sbp/SbpBalancePaymentDialog';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearBalanceSbpState,
  getBalanceFillAmount,
  getSbpQR,
  setBalanceSuccessFill,
  setSbpPaymentMethod,
  setSbpPaymentProvider,
  setSbpQR,
} from '@/services/sbpSlice';
import { formatCurrency, isBlank } from '@/utils/string-utils';
import {
  getAnswersCount,
  refreshIdempotencyKey,
  setLetterNumber,
  setLetterSentForm,
  setLetterUuid,
} from '@/services/createFormSlice';
import { getCostWithCommission } from '@/utils/currency-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  useConfirmLetterPaymentByCardUsingGetMutation,
  useCreateAndPayMutation,
  useCreateLetterMutation,
} from '@/services/api/letterApiSlice';
import { useCreateLetterBillMutation } from '@/services/api/paymentApiSlice';
import { useCreateQrMutation, useRegisterOrderMutation } from '@/services/api/sbpPaymentApiSlice';
import { useGetUserBalanceQuery } from '@/services/api/balanceApiSlice';
import { useGetUserCardsQuery } from '@/services/api/cardApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';

interface Props {
  paymentMethod: PaymentMethod;
  paymentProvider?: PaymentProvider;
  isMobile?: boolean;
  letter: CreateLetterDTO;
  attachFile?: File;
  price: number | undefined;
  setCardInfoMessage: (message: string) => void;
  setErrorMessage: (value: string) => void;
  cardNumber: string;
  cardCode: string;
  sbpMethod: SbpMethod;
  isInitiative?: boolean;
  isInitiativeSubmitAllowed?: boolean;
  idempotencyKey?: string;
}

export const PaymentButtonContainer: FC<Props> = (props: Props) => {
  const {
    paymentMethod,
    paymentProvider,
    isMobile,
    letter,
    attachFile,
    price,
    setCardInfoMessage,
    setErrorMessage,
    cardNumber,
    cardCode,
    sbpMethod,
    isInitiative,
    isInitiativeSubmitAllowed,
    idempotencyKey,
  } = props;

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const answersCount: number = useAppSelector(getAnswersCount);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const balanceSbpFillAmount: number = useAppSelector((state: RootState) => getBalanceFillAmount(state, true));
  const sbpBalanceQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState) =>
    getSbpQR(state, SbpMethod.BALANCE, undefined, true)
  );

  const [createLetter, { isLoading: isLetterLoading }] = useCreateLetterMutation();
  const [createLetterBill, { isLoading: isLetterBillLoading }] = useCreateLetterBillMutation();
  const [createAndPayLetter, { isLoading: isCreateAndPayLoading }] = useCreateAndPayMutation();
  const [createSbpQR, { isLoading: isQrLoading }] = useCreateQrMutation();
  const [confirmPayment] = useConfirmLetterPaymentByCardUsingGetMutation();
  const [createSbpOrder] = useRegisterOrderMutation();
  const { data: balance, isLoading: isBalanceLoading } = useGetUserBalanceQuery(authUser?.id, { skip: !authUser });
  const {
    data: cards,
    isLoading: isCardsLoading,
    isFetching: isCardsFetching,
  } = useGetUserCardsQuery(authUser?.id, { skip: !authUser });

  const [isReCaptchaLoading, setReCaptchaLoading] = useState<boolean>(false);
  const [openBalanceTopUp, setOpenBalanceTopUp] = useState<boolean>(false);
  const [openSbpBalanceSbpQR, setOpenSbpBalanceSbpQR] = useState<boolean>(true);

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const handleLetterSent = (letterNumber: number, letterUuid?: string): void => {
    dispatch(setLetterSentForm(true));
    dispatch(setLetterNumber(letterNumber));
    dispatch(setLetterUuid(letterUuid));
    dispatch(refreshIdempotencyKey());
  };

  const handleSbpQR = (qr: CreatedSbpQrDTO): void => {
    setSbpQR(dispatch, sbpMethod, qr, undefined, false, isInitiative);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    setReCaptchaLoading(true);
    const result = await executeRecaptcha('createLetterUnauthorizedAction');
    setReCaptchaLoading(false);
    return result;
  }, [executeRecaptcha]);

  const handleClickCardPay = async (): Promise<void> => {
    setErrorMessage('');
    letter.card = {
      balance: 0,
      code: cardCode || '',
      email: '',
      id: 0,
      ourNumber: parseInt(cardNumber.replace(/\s+/g, '')) || 0,
      ownerName: '',
      phone: '',
      satelliteId: 0,
      uuid: '',
    };
    handleReCaptchaVerify().then(async (token: string | undefined): Promise<void> => {
      await createLetter({
        letter: letter,
        image: attachFile,
        reCaptchaToken: token,
        idempotencyKey: idempotencyKey,
      })
        .unwrap()
        .then((dto: LetterDTO): void => {
          if (price && dto.card.balance >= price) {
            // Для того чтобы статусы шли по порядку и не было одинакового timestamp
            setTimeout(() => confirmPayment(dto.uuid), 1000);
            handleLetterSent(dto.number, dto.uuid);
            setCardInfoMessage(
              'С карты № ' +
                letter.card?.ourNumber +
                ' было списано ' +
                formatCurrency(price, 0) +
                '\n' +
                'Остаток: ' +
                formatCurrency(dto.card.balance - price, 0)
            );
          }
        })
        .catch((e: { status: number; data: ErrorDTO }): void => {
          setErrorMessage(e.data.details);
        });
    });
  };

  const handleClickOnlinePay = async (provider: PaymentProvider): Promise<void> => {
    const windowReference: Window | null = window.open();
    let letterNumber: number;
    let letterUuid: string;
    handleReCaptchaVerify().then(async (token: string | undefined): Promise<void> => {
      if (isInitiative && letter.uuid) {
        createLetterBill({ uuid: letter.uuid, provider: provider })
          .unwrap()
          .then((billDTO: OnlinePaymentResponseDTO): void => {
            if (windowReference) {
              windowReference.location = billDTO.paymentUrl;
            }
            handleLetterSent(letterNumber, letterUuid);
          })
          .catch((): void => {
            enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
          });
      } else {
        await createLetter({
          letter: letter,
          image: attachFile,
          reCaptchaToken: token,
          idempotencyKey: idempotencyKey,
        })
          .unwrap()
          .then((dto: LetterDTO) => {
            letterNumber = dto.number;
            letterUuid = dto.uuid;
            return createLetterBill({ uuid: dto.uuid, provider: provider }).unwrap();
          })
          .then((billDTO: OnlinePaymentResponseDTO): void => {
            // Fix for Safari (window.open doesn't work in async functions)
            if (windowReference) {
              windowReference.location = billDTO.paymentUrl;
            }
            handleLetterSent(letterNumber, letterUuid);
          })
          .catch((): void => {
            enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
          });
      }
    });
  };

  const handleClickSbpQrPay = async (): Promise<void> => {
    handleReCaptchaVerify().then(async (token: string | undefined): Promise<void> => {
      if (isInitiative) {
        const dto: LetterNumberDTO = {
          number: letter.number,
        };
        const sbpDTO: void | RegistrationSbpDTO = await createSbpOrder(dto)
          .unwrap()
          .catch((): void => {
            enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
          });
        if (sbpDTO && letter.number) {
          const number: number = letter.number;
          const uuid: string | undefined = letter.uuid;
          await createSbpQR({
            sbpOrderId: sbpDTO.orderId,
          })
            .unwrap()
            .then((dto: CreatedSbpQrDTO): void => {
              handleSbpQR(dto);
              handleLetterSent(number, uuid);
            })
            .catch((): void => {
              enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
            });
        }
      } else {
        const letterDTO: void | LetterDTO = await createLetter({
          letter: { ...letter, isPaymentSBP: true },
          image: attachFile,
          reCaptchaToken: token,
          idempotencyKey: idempotencyKey,
        })
          .unwrap()
          .catch((): void => {
            enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
          });
        if (letterDTO && letterDTO.sbpOrderId) {
          await createSbpQR({
            sbpOrderId: letterDTO.sbpOrderId,
          })
            .unwrap()
            .then((dto: CreatedSbpQrDTO): void => {
              handleSbpQR(dto);
              handleLetterSent(letterDTO.number, letterDTO.uuid);
            })
            .catch((): void => {
              enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
            });
        }
      }
    });
  };

  const handleClickBalancePay = async (): Promise<void> => {
    if (isBlank(letter.author?.phone)) {
      enqueueSnackbar('Добавьте номер телефона в настройках профиля.', {
        variant: 'error',
      });
    } else {
      await createAndPayLetter({
        letter: letter,
        image: attachFile,
        idempotencyKey: idempotencyKey,
      })
        .unwrap()
        .then((dto: LetterDTO): void => {
          handleLetterSent(dto.number, dto.uuid);
        })
        .catch(() => {
          enqueueSnackbar('Ошибка оплаты письма', { variant: 'error' });
        });
    }
  };

  const handleClickTopUp = (): void => {
    setOpenBalanceTopUp(true);
  };

  const handleSuccessBalanceTopUp = async (): Promise<void> => {
    await handleClickBalancePay().then((): void => {
      setOpenSbpBalanceSbpQR(false);
      dispatch(setBalanceSuccessFill(false));
      dispatch(clearBalanceSbpState());
    });
  };

  const handlePay = async (method: PaymentMethod, provider?: PaymentProvider): Promise<void> => {
    setSbpPaymentMethod(dispatch, sbpMethod, method, undefined, true, isInitiative);
    setSbpPaymentProvider(dispatch, sbpMethod, provider || PaymentProvider.ALFA, undefined, true, isInitiative);
    switch (method) {
      case PaymentMethod.ONLINE:
        await handleClickOnlinePay(provider || PaymentProvider.ALFA);
        break;
      case PaymentMethod.SBP:
        await handleClickSbpQrPay();
        break;
      case PaymentMethod.CARD:
        await handleClickCardPay();
        break;
      case PaymentMethod.BALANCE:
        await handleClickBalancePay();
        break;
      default:
        enqueueSnackbar('Ошибка выбора способа оплаты', { variant: 'error' });
    }
  };

  const handleLoadingButton = (paymentMethod: PaymentMethod): boolean => {
    switch (paymentMethod) {
      case PaymentMethod.SBP:
        return isQrLoading;
      case PaymentMethod.BALANCE:
        return isBalanceLoading;
      default:
        return false;
    }
  };

  const handleDisabledButton = (paymentMethod: PaymentMethod): boolean => {
    switch (paymentMethod) {
      case PaymentMethod.ONLINE:
        return (
          isLetterLoading ||
          !answersCount ||
          answersCount > 10 ||
          answersCount < 1 ||
          isLetterBillLoading ||
          isReCaptchaLoading
        );
      case PaymentMethod.SBP:
        return isLetterLoading || !answersCount || answersCount > 10 || answersCount < 1 || isReCaptchaLoading;
      case PaymentMethod.CARD:
        return (
          !cardCode ||
          !cardNumber ||
          isLetterLoading ||
          !answersCount ||
          answersCount > 10 ||
          answersCount < 1 ||
          isReCaptchaLoading
        );
      case PaymentMethod.BALANCE:
        return (
          isLetterLoading ||
          isBalanceLoading ||
          isCreateAndPayLoading ||
          !answersCount ||
          answersCount > 10 ||
          answersCount < 1 ||
          !balance?.amount ||
          !price
        );
      default:
        return false;
    }
  };

  return (
    <Fragment>
      {authUser &&
      price &&
      balance &&
      (balance.amount < price || balance.amount === 0) &&
      paymentMethod === PaymentMethod.BALANCE ? (
        <PaymentButton
          buttonText={'Пополнить баланс и оплатить'}
          onClick={handleClickTopUp}
          disabled={
            isLetterLoading ||
            isBalanceLoading ||
            isCreateAndPayLoading ||
            !answersCount ||
            answersCount > 10 ||
            answersCount < 1 ||
            isCardsLoading ||
            isCardsFetching ||
            !balance ||
            !price ||
            !policyChecked
          }
          isMobile={isMobile}
          policyChecked={policyChecked}
          onPolicyChange={setPolicyChecked}
        />
      ) : (
        <PaymentButton
          buttonText={paymentMethod === PaymentMethod.ONLINE ? 'Перейти к оплате' : 'Оплатить'}
          onClick={() => handlePay(paymentMethod, paymentProvider)}
          disabled={
            handleDisabledButton(paymentMethod) || (!!isInitiative && !isInitiativeSubmitAllowed) || !policyChecked
          }
          isLoading={handleLoadingButton(paymentMethod) || isReCaptchaLoading}
          isMobile={isMobile}
          isInitiative={isInitiative}
          isInitiativeSubmitAllowed={isInitiativeSubmitAllowed}
          policyChecked={policyChecked}
          onPolicyChange={setPolicyChecked}
        />
      )}
      {isMobile && (
        <Box mt={2} mb={2}>
          <PolicyStatementCheckBox
            checked={policyChecked}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPolicyChecked(e.target.checked)}
          />
        </Box>
      )}
      {authUser && (
        <BalancePaymentDialog
          open={openBalanceTopUp}
          isLetterPage={true}
          currentBalance={balance?.amount || 0}
          price={price}
          cards={cards || []}
          isMobile={isMobile}
          onClose={() => setOpenBalanceTopUp(false)}
        />
      )}
      {authUser && sbpBalanceQR && (
        <SbpBalancePaymentDialog
          sbpQR={sbpBalanceQR}
          isLetterPage={true}
          isBalanceFillSuccess={false}
          open={openSbpBalanceSbpQR}
          amount={getCostWithCommission(balanceSbpFillAmount, PaymentMethod.SBP)}
          email={authUser?.email || ''}
          onClose={() => setOpenSbpBalanceSbpQR(false)}
          onSuccess={handleSuccessBalanceTopUp}
          isMobile={isMobile}
        />
      )}
    </Fragment>
  );
};
