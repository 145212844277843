import { AnswerBlock } from '@/components/create/answer/AnswerBlock';
import { LetterBlock } from '@/components/create/letter/LetterBlock';
import { ROUTE_CREATE_ANSWER, ROUTE_CREATE_LETTER, ROUTE_CREATE_REPLY } from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ReplyBlock } from '@/components/create/reply/ReplyBlock';
import React, { FC } from 'react';

export const CreatePage: FC = () => {
  return (
    <Switch>
      <Route path={ROUTE_CREATE_LETTER}>
        <LetterBlock />
      </Route>
      <Route path={ROUTE_CREATE_ANSWER}>
        <AnswerBlock />
      </Route>
      <Route path={ROUTE_CREATE_REPLY}>
        <ReplyBlock />
      </Route>
      <Redirect to={ROUTE_CREATE_LETTER} />
    </Switch>
  );
};
