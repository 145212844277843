import { InputBaseComponentProps } from '@mui/material';
import PhoneInput from 'react-phone-number-input/input';
import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import 'react-phone-number-input/style.css';

type Props = InputBaseComponentProps;

export const PhoneNumberInput = forwardRef<HTMLInputElement, Props>(
  (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const { onChange, ...other } = props;
    const handleChange = (value?: string): void => {
      if (onChange) {
        const event: ChangeEvent<HTMLInputElement> = {
          target: { name: props.name, value: value || '' },
        } as ChangeEvent<HTMLInputElement>;
        onChange(event);
      }
    };
    return <PhoneInput {...other} ref={ref} defaultCountry={'RU'} onChange={handleChange} />;
  }
);

PhoneNumberInput.displayName = 'PhoneNumberInput';
