import { BalanceHistoryDTO } from '@/types/balance/BalanceHistoryDTO';
import { Grid, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { formatCurrency } from '@/utils/string-utils';
import { formatShortDateString, formatTimeString } from '@/utils/date-utils';
import React, { FC } from 'react';

interface Props {
  index: number;
  item: BalanceHistoryDTO;
  items: BalanceHistoryDTO[];
  isLoading: boolean;
  isDividerAt: (index: number, items: BalanceHistoryDTO[]) => boolean;
  getTitle: (item: BalanceHistoryDTO) => string;
  getDescription: (item: BalanceHistoryDTO) => string;
}

export const MobileBalanceHistoryTableRow: FC<Props> = (props: Props) => {
  const { index, item, items, isLoading, isDividerAt, getTitle, getDescription } = props;
  const amount: number = item ? item?.newAmount - item?.oldAmount : 0;
  return (
    <TableRow hover={true}>
      <TableCell
        size={'small'}
        sx={(theme: Theme) => ({
          borderBottom: isDividerAt(index, items) ? 'unset' : '1px solid ' + theme.colors.tableBorder,
          padding: theme.spacing(1, 1, 1.5, 0),
        })}>
        <Grid container={true} direction={'column'}>
          <Grid item={true}>
            <Grid container={true} direction={'row'} alignItems={'center'} spacing={1}>
              <Grid item={true}>
                <Typography variant={'body1'} noWrap={true}>
                  {isLoading ? <Skeleton height={'24px'} width={'80px'} /> : formatShortDateString(item.timestamp)}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'body2'} noWrap={true} color={(theme: Theme) => theme.colors.grayText}>
                  {isLoading ? <Skeleton height={'20px'} width={'40px'} /> : formatTimeString(item.timestamp)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Typography variant={'body1'}>{isLoading ? <Skeleton height={'24px'} /> : getTitle(item)}</Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
              {isLoading ? <Skeleton height={'20px'} /> : getDescription(item)}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell
        size={'small'}
        align={'right'}
        sx={(theme: Theme) => ({
          width: theme.spacing(10),
          borderBottom: isDividerAt(index, items) ? 'unset' : '1px solid ' + theme.colors.tableBorder,
          verticalAlign: 'top',
          padding: theme.spacing(1, 0, 1.5, 1),
        })}>
        <Typography
          variant={'body1'}
          noWrap={true}
          color={(theme: Theme) => (amount > 0 ? theme.colors.green : 'inherit')}
          paddingTop={(theme: Theme) => theme.spacing(3)}>
          {isLoading ? <Skeleton height={'24px'} /> : (amount > 0 ? '+' : '') + formatCurrency(amount)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
