import { BalanceState } from '@/types/BalanceState';
import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { SuccessIcon } from '@/components/common/SuccessIcon';
import { Theme } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import React, { FC } from 'react';

export const RegistrationConfirmSuccess: FC = () => {
  const history = useHistory();
  const { state } = useLocation<BalanceState>();
  return (
    <Grid container={true} direction={'column'} alignItems={'center'} spacing={4} mt={14} mb={17}>
      <Grid item={true}>
        <Grid container={true} direction={'column'} alignItems={'center'} spacing={2}>
          <Grid item={true}>
            <SuccessIcon />
          </Grid>
          <Grid item={true}>
            <Typography variant={'h2'} textAlign={'center'}>
              {'Вы подтвердили адрес электронной почты'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => history.replace(ROUTE_AUTHENTICATION, state)}
          sx={{ minWidth: (theme: Theme) => theme.spacing(33) }}>
          {'Перейти в личный кабинет'}
        </Button>
      </Grid>
    </Grid>
  );
};
