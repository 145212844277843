import * as Yup from 'yup';
import { ChangeDepartmentDTO } from '@/types/letter/ChangeDepartmentDTO';
import { ClientDialogTitle } from '@/components/common/modal/ClientDialogTitle';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Form, Formik, FormikErrors, FormikProps, getIn } from 'formik';
import { LetterStatusDTO } from '@/types/letter/LetterStatusDTO';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { SenderDTO } from '@/types/SenderDTO';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';
import { formatPrisonerFullName, isNotBlank } from '@/utils/string-utils';
import { formatTimeDateStringWithoutOffset } from '@/utils/date-utils';
import { regionDTOSchema } from '@/validation/regionDTOSchema';
import { useChangeDepartmentMutation } from '@/services/api/letterApiSlice';
import { useGetRegionsQuery } from '@/services/api/createFormApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback } from 'react';

interface FormValues {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
}

interface Props {
  open: boolean;
  email?: string;
  letterNumber?: number;
  sender?: SenderDTO;
  onClose: (success?: boolean) => void;
}

export const DepartmentChangeDialog: FC<Props> = (props: Props) => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const { open, email, letterNumber, sender, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { data: regions, isLoading, isFetching } = useGetRegionsQuery(null, { refetchOnMountOrArgChange: true });
  const [changeDepartment, { isLoading: isDepartmentChanging }] = useChangeDepartmentMutation();
  const regionsLoading: boolean = isLoading || isFetching;
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    region: regionDTOSchema.required(MESSAGE_REQUIRED).nullable(),
    department: departmentDTOSchema.required(MESSAGE_REQUIRED).nullable(),
  });
  const initialValues: FormValues = {
    region: null,
    department: null,
  };
  const initialErrors: FormikErrors<FormValues> = {
    region: !initialValues.region ? MESSAGE_REQUIRED : undefined,
    department: !initialValues.department ? MESSAGE_REQUIRED : undefined,
  };
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);
  const handleSubmit = async (values: FormValues): Promise<void> => {
    handleReCaptchaVerify().then(async (token) => {
      const dto: ChangeDepartmentDTO = {
        letterNumber: letterNumber,
        email: email,
        departmentId: values.department?.id,
      };
      await changeDepartment({ dto: dto, reCaptchaToken: token })
        .unwrap()
        .then((dto: LetterStatusDTO) => {
          if (dto.isSameDepartment) {
            enqueueSnackbar(
              <div
                dangerouslySetInnerHTML={{
                  __html: `${dto.message} ${formatTimeDateStringWithoutOffset(dto.letterUpdatedAtDateTime)}`,
                }}
              />,
              {
                variant: 'warning',
              }
            );
          } else {
            enqueueSnackbar(<div dangerouslySetInnerHTML={{ __html: dto.message }} />, {
              variant: dto.error ? 'error' : 'success',
            });
          }
          if (!dto.error) {
            onClose(true);
          }
        })
        .catch((e) => {
          console.error(e);
          enqueueSnackbar('Внутренняя ошибка сервера', { variant: 'error' });
        });
    });
  };
  return (
    <Dialog open={open} fullScreen={isMobile} PaperProps={{ sx: { width: isMobile ? 'auto' : '500px' } }}>
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}>
        {(formikProps: FormikProps<FormValues>) => {
          const { values, errors, touched, isValid, submitForm, setFieldValue, setFieldTouched } = formikProps;
          const handleSetFieldValue = (field: string, value: unknown) => {
            setFieldTouched(field, true, false);
            setFieldValue(field, value, true);
          };
          return (
            <Form>
              <ClientDialogTitle label={'Переслать письмо в другое учреждение'} onClose={onClose} />
              <DialogContent
                sx={(theme: Theme) => ({
                  marginTop: theme.spacing(-1),
                  paddingBottom: theme.spacing(isMobile ? 7 : 4),
                })}>
                <Grid container={true} direction={'column'} spacing={6}>
                  <Grid item={true}>
                    <Grid container={true} direction={'column'} spacing={0.5}>
                      <Grid item={true}>
                        <Typography variant={'body1'}>
                          {'Адресат: '}
                          {formatPrisonerFullName(sender?.lastName, sender?.firstName, sender?.middleName)}
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'body1'}>
                          {'Выбранное учреждение: '}
                          {[values?.department?.name || '', values?.region?.name || '']
                            .filter((s: string) => isNotBlank(s))
                            .join(', ')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true}>
                    <RegionSelectField
                      regions={regions || []}
                      region={values.region}
                      isLoading={regionsLoading}
                      disabled={isDepartmentChanging}
                      error={touched.region && Boolean(errors.region)}
                      helperText={
                        touched.region && Boolean(errors.region)
                          ? getIn(errors.region, 'departments')
                            ? getIn(errors.region, 'departments')
                            : errors.region
                          : ''
                      }
                      onChange={(value: RegionDTO | null) => {
                        handleSetFieldValue('region', value);
                        if (value === null) {
                          setFieldValue('department', null);
                          setFieldTouched('department', false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item={true}>
                    <OutlinedSelect
                      size={'medium'}
                      displayEmpty={true}
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        const department: DepartmentDTO | undefined = values.region?.departments.find(
                          (department: DepartmentDTO) => department.id === Number(event.target.value)
                        );
                        handleSetFieldValue('department', department);
                      }}
                      value={values.department ? values.department.id : ''}
                      disabled={Boolean(errors.region) || !values.region || isDepartmentChanging}
                      label={'Выберите учреждение'}
                      MenuProps={{ PaperProps: { sx: { marginTop: '3px' } } }}
                      helperText={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                          ? errors.department
                          : ''
                      }
                      error={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                      }>
                      {values.region?.departments &&
                        values.region.departments.map((value: DepartmentDTO) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                    </OutlinedSelect>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {isMobile ? (
                  <MobileBar>
                    <FixedButton
                      variant={'contained'}
                      color={'primary'}
                      onClick={submitForm}
                      disabled={isDepartmentChanging || !isValid}>
                      {'Переслать'}
                    </FixedButton>
                  </MobileBar>
                ) : (
                  <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={submitForm}
                    isLoading={isDepartmentChanging}
                    disabled={isDepartmentChanging || !isValid}
                    sx={(theme: Theme) => ({
                      minWidth: theme.spacing(23),
                    })}>
                    {'Переслать'}
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
