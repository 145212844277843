import { AttachmentRow } from '@/components/home/outbox/AttachmentRow';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Dot } from '@/components/common/Dot';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { StatusChip } from '@/components/common/StatusChip';
import { Theme } from '@mui/material/styles';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartDateTimeString } from '@/utils/date-utils';
import React, { FC, Fragment } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  isAttachFileLoading?: boolean;
  letterId?: number;
  letters: LetterTableDTO[];
  onLetterClick: (letter: LetterTableDTO) => void;
  onAttachClick: (letter: LetterTableDTO) => void;
}

export const OutboxList: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, isAttachFileLoading, letterId, letters, onAttachClick } = props;

  const loading: boolean = isFetching || isLoading;

  const handleRowClick = (letter: LetterTableDTO) => (): void => {
    const { onLetterClick } = props;
    if (letter) {
      onLetterClick(letter);
    }
  };

  return (
    <Grid container={true} direction={'column'}>
      {(loading ? Array.from(new Array(5)) : letters).map((letter: LetterTableDTO, index: number) => (
        <Grid key={loading ? index : letter.id} item={true}>
          <Box
            position={'relative'}
            borderBottom={'1px solid'}
            borderColor={(theme: Theme) => theme.colors.borderColor}
            paddingY={(theme: Theme) => theme.spacing(1.5)}
            onClick={handleRowClick(letter)}>
            <Grid container={true} direction={'column'} spacing={1} wrap={'nowrap'}>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'14px'} />
                ) : (
                  <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
                    {letter?.unread && <Dot />}
                    <Typography variant={'body2'} lineHeight={1} whiteSpace={'nowrap'}>
                      {formatSmartDateTimeString(letter.sentDate)}
                    </Typography>
                    <Typography variant={'body2'} lineHeight={1} whiteSpace={'nowrap'}>
                      {'#' + letter.number}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Grid container={true} direction={'row'} alignItems={'center'} spacing={1} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography
                        variant={'body2'}
                        color={(theme: Theme) => theme.colors.grayText}
                        whiteSpace={'nowrap'}>
                        {formatPrisonerShortName(letter.prisonerLastName, letter.prisonerFirstName)}
                      </Typography>
                    </Grid>
                    {letter.messageText && (
                      <Fragment>
                        <Grid item={true}>
                          <Dot
                            sxOverride={{
                              width: (theme: Theme) => theme.spacing(0.375),
                              height: (theme: Theme) => theme.spacing(0.375),
                              backgroundColor: (theme: Theme) => theme.colors.grayText,
                            }}
                          />
                        </Grid>
                        <Grid item={true} xs={true}>
                          <Box position={'relative'} width={'100%'} minHeight={'20px'}>
                            <Typography
                              variant={'body2'}
                              color={(theme: Theme) => theme.colors.grayText}
                              textOverflow={'ellipsis'}
                              overflow={'hidden'}
                              whiteSpace={'nowrap'}
                              position={'absolute'}
                              width={'100%'}>
                              {letter.messageText}
                            </Typography>
                          </Box>
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'24px'} />
                ) : (
                  <Stack direction={'row'} spacing={3} alignItems={'center'}>
                    {letter.statuses.map((status: LetterSimpleStatus, index: number) => (
                      <StatusChip key={index} status={status} />
                    ))}
                  </Stack>
                )}
              </Grid>
            </Grid>
            <Box position={'absolute'} top={'5px'} right={0}>
              <AttachmentRow
                letter={letter}
                letterId={letterId}
                isAttachFileLoading={isAttachFileLoading}
                onAttachClick={onAttachClick}
              />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
