import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';

export const getStatusLabel = (status: LetterSimpleStatus): string => {
  switch (status) {
    case LetterSimpleStatus.ALL: {
      return 'Все статусы';
    }
    case LetterSimpleStatus.UNPAID: {
      return 'Ожидает оплаты';
    }
    case LetterSimpleStatus.PAID: {
      return 'Оплачено';
    }
    case LetterSimpleStatus.IN_PROGRESS: {
      return 'В обработке';
    }
    case LetterSimpleStatus.DELIVERED: {
      return 'Доставлено';
    }
    case LetterSimpleStatus.WAITING_FOR_ANSWER: {
      return 'Ожидает ответа';
    }
    case LetterSimpleStatus.ANSWER_UPLOADED: {
      return 'Получен ответ';
    }
    case LetterSimpleStatus.ANSWER_CENSORED: {
      return 'Ответ не прошёл цензуру';
    }
    case LetterSimpleStatus.LETTER_CENSORED: {
      return 'Не прошло цензуру';
    }
    case LetterSimpleStatus.ATTACHMENT_CENSORED: {
      return 'Доставлено без фото';
    }
    case LetterSimpleStatus.ADDRESSEE_NOT_IN_DEPARTMENT: {
      return 'Адресат не числится';
    }
    case LetterSimpleStatus.ADDRESSEE_LEFT_DEPARTMENT: {
      return 'Адресат убыл';
    }
    case LetterSimpleStatus.ADDRESSEE_IS_FREE: {
      return 'Адресат освобождён';
    }
    case LetterSimpleStatus.CITY_COURT: {
      return 'Задержано';
    }
    default: {
      return 'Неизвестно';
    }
  }
};
