import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { Box, Button, ButtonProps, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { DateRangePicker } from '@/components/common/date/DateRangePicker';
import { Theme } from '@mui/material/styles';
import { formatShortDateString } from '@/utils/date-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props extends ButtonProps {
  filterType?: BalanceFilterType;
  startDate?: Date;
  endDate?: Date;
  onFilter: (startDate: Date, endDate: Date) => void;
  onClear: () => void;
}

export const BalanceFilterDateButton: FC<Props> = (props: Props) => {
  const { filterType, startDate, endDate, onFilter, onClear, ...other } = props;
  const active: boolean = !startDate && !endDate;
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = (): void => {
    setOpen(true);
  };
  const handleRange = (startDate: Date | null, endDate: Date | null): void => {
    if (!!startDate && !!endDate) {
      onFilter(startDate, endDate);
    }
    setOpen(false);
  };
  const getLabel = (type: BalanceFilterType): string => {
    switch (type) {
      case BalanceFilterType.ALL: {
        return 'Все операции';
      }
      case BalanceFilterType.INCOME: {
        return 'Пополнения';
      }
      case BalanceFilterType.EXPENSE: {
        return 'Списания';
      }
    }
  };
  return (
    <Fragment>
      {active ? (
        <Button
          variant={'contained'}
          color={'secondary'}
          sx={(theme: Theme) => ({
            borderRadius: theme.spacing(1.5),
            padding: theme.spacing(1.25, 2),
          })}
          onClick={handleClick}
          {...other}>
          <Typography variant={'h5'}>{'Выбрать даты'}</Typography>
        </Button>
      ) : (
        <Box
          sx={(theme: Theme) => ({
            background: theme.colors.grayBackground,
            borderRadius: theme.spacing(1.5),
            padding: theme.spacing(1.25, 2),
          })}>
          <Grid container={true} direction={'row'} alignItems={'center'} spacing={1.5}>
            <Grid item={true}>
              <Typography variant={'h5'}>
                {filterType && getLabel(filterType) + ' за '}
                {formatShortDateString(startDate || new Date()) + ' — ' + formatShortDateString(endDate || new Date())}
              </Typography>
            </Grid>
            <Grid item={true}>
              <IconButton color={'primary'} size={'dense'} sx={{ display: 'flex' }} onClick={onClear}>
                <CloseIcon sx={{ width: '12px', height: '12px' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      )}
      <DateRangePicker open={open} onRange={handleRange} />
    </Fragment>
  );
};
