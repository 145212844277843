import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import { Path } from 'history';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  to: Path;
  label: string;
  value?: number;
  total?: number;
  size?: 'small' | 'medium';
  activeOnlyWhenExact?: boolean;
  onBeforeRedirect?: () => void;
  isRedirect?: boolean;
}

export const MenuLink: FC<Props> = (props: Props) => {
  const { label, value, total, to, activeOnlyWhenExact, onBeforeRedirect, isRedirect, ...other } = props;
  const history = useHistory();
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  const handleClick = (): void => {
    if (isRedirect) {
      location.href = to;
    } else {
      if (onBeforeRedirect) {
        onBeforeRedirect();
      }
      history.push(to);
    }
  };
  return (
    <Button
      color={'inherit'}
      fullWidth={true}
      sx={{
        textTransform: 'none',
        padding: (theme: Theme) => {
          if (props.size === 'small') {
            return theme.spacing(0.75, 0.875);
          }
          return theme.spacing(1.25, 1);
        },
        backgroundColor: (theme: Theme) => (match ? theme.colors.alert + '!important' : 'inherit'),
      }}
      onClick={handleClick}
      {...other}>
      <Grid container={true} direction={'row'} justifyContent={'space-between'} alignItems={'baseline'} spacing={1}>
        <Grid item={true}>
          <RouteLink to={to}>
            <Typography
              whiteSpace={'pre-line'}
              sx={{
                fontSize: (theme: Theme) => {
                  if (props.size === 'small') {
                    return theme.spacing(1.75);
                  }
                  return theme.spacing(2);
                },
                fontWeight: 500,
                lineHeight: (theme: Theme) => theme.spacing(2.5),
              }}>
              {label}
            </Typography>
          </RouteLink>
        </Grid>
        <Grid item={true}>
          <Typography
            sx={{
              fontSize: (theme: Theme) => theme.spacing(1.75),
              lineHeight: 1,
            }}>
            <span style={{ fontWeight: 500 }}>{value && value > 0 ? value : ''}</span>
            <span style={{ fontWeight: 500 }}>{value && total && value > 0 ? '/' : ''}</span>
            <span style={{ opacity: 0.5 }}>{total}</span>
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};
