import { AnswerSuccess } from '@/components/create/answer/AnswerSuccess';
import { AppDispatch } from '@/app/store';
import { MainCreateFormPayment } from '@/components/create/main/letter/MainCreateFormPayment';
import { MainCreateFormPaymentMobile } from '@/components/create/main/letter/MainCreateFormPaymentMobile';
import {
  ROUTE_CREATE_ANSWER,
  ROUTE_CREATE_ANSWER_DEPARTMENT,
  ROUTE_CREATE_ANSWER_FEEDBACK,
  ROUTE_CREATE_ANSWER_PAYMENT,
  ROUTE_CREATE_ANSWER_SENDER,
  ROUTE_CREATE_FEEDBACK_SUCCESS,
  ROUTE_INBOX,
  ROUTE_MAIN_CREATE_ANSWER_AUTHOR,
  ROUTE_MAIN_CREATE_ANSWER_CLIENT,
  ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT,
  ROUTE_MAIN_CREATE_ANSWER_FEEDBACK,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { clearLetterDraftId } from '@/services/createFormSlice';
import { useAppDispatch } from '@/app/hooks';
import { useMediaQuery } from '@mui/material';
import AnswerFeedbackSent from '@/components/create/answer/AnswerFeedbackSent';
import CreateFormDepartment from '@/components/create/letter/CreateFormDepartment';
import CreateFormSender from '@/components/create/letter/CreateFormSender';
import React, { FC, useEffect } from 'react';

export const AnswerBlock: FC = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  useEffect(() => {
    return (): void => {
      dispatch(clearLetterDraftId());
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path={ROUTE_CREATE_ANSWER_DEPARTMENT}>
        <CreateFormDepartment
          tittle={'Шаг 1 из 3: Выберите учреждение'}
          mobileTittle={'1/3: Выберите учреждение'}
          previousFormPath={ROUTE_INBOX}
          previousFormLabel={'Входящие'}
          nextFormPath={ROUTE_CREATE_ANSWER_SENDER}
          isMobile={isMobile}
        />
      </Route>
      <Route path={ROUTE_CREATE_ANSWER_SENDER}>
        <CreateFormSender
          tittle={'Шаг 2 из 3: Введите данные отправителя'}
          mobileTittle={'2/3: Введите данные отправителя'}
          nextFormPath={ROUTE_CREATE_ANSWER_PAYMENT}
          previousFormPath={ROUTE_CREATE_ANSWER_DEPARTMENT}
          previousFormLabel={'Шаг 1'}
          errorRedirectPath={ROUTE_CREATE_ANSWER}
          isMobile={isMobile}
          fromCreateAnswer={true}
        />
      </Route>
      <Route path={ROUTE_CREATE_ANSWER_PAYMENT}>
        {isMobile ? (
          <MainCreateFormPaymentMobile
            mobileTittle={'3/3: Оплатите ответ'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_CLIENT}
            previousFormLabel={'Шаг 2'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            isMobile={isMobile}
            fromCreateAnswer={true}
          />
        ) : (
          <MainCreateFormPayment
            tittle={'Шаг 3 из 3: Оплатите ответ'}
            nextFormPath={ROUTE_MAIN_CREATE_ANSWER_FEEDBACK}
            previousFormPath={ROUTE_MAIN_CREATE_ANSWER_AUTHOR}
            previousFormLabel={'Шаг 2'}
            errorRedirectPath={ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT}
            fromCreateAnswer={true}
          />
        )}
      </Route>
      <Route path={ROUTE_CREATE_ANSWER_FEEDBACK}>
        <AnswerSuccess isMobile={isMobile} />
      </Route>
      <Route path={ROUTE_CREATE_FEEDBACK_SUCCESS}>
        <AnswerFeedbackSent />
      </Route>
      <Redirect to={ROUTE_CREATE_ANSWER_DEPARTMENT} />
    </Switch>
  );
};
