import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AttachIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M16.5 6.75002V17.33C16.5 19.42 14.97 21.28 12.89 21.48C10.5 21.71 8.5 19.84 8.5 17.5V5.14002C8.5 3.83002 9.44 2.64002 10.74 2.51002C12.24 2.36002 13.5 3.53002 13.5 5.00002V15.5C13.5 16.05 13.05 16.5 12.5 16.5C11.95 16.5 11.5 16.05 11.5 15.5V6.75002C11.5 6.34002 11.16 6.00002 10.75 6.00002C10.34 6.00002 10 6.34002 10 6.75002V15.36C10 16.67 10.94 17.86 12.24 17.99C13.74 18.14 15 16.97 15 15.5V5.17002C15 3.08002 13.47 1.22002 11.39 1.02002C9.01 0.790024 7 2.66002 7 5.00002V17.27C7 20.14 9.1 22.71 11.96 22.98C15.25 23.28 18 20.72 18 17.5V6.75002C18 6.34002 17.66 6.00002 17.25 6.00002C16.84 6.00002 16.5 6.34002 16.5 6.75002Z'
      }
    />
  </SvgIcon>
);
