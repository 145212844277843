import * as Yup from 'yup';
import { CardContractCondition } from '@/components/create/card/CardContractCondition';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { MESSAGE_INVALID_AMOUNT, MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PayCardButtonGroup } from '@/components/create/card/buttons/PayCardButtonGroup';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import { regExOnlyNumberKey } from '@/validation/regExOnlyPhoneNumberKey';
import React, { ChangeEvent, FC, useState } from 'react';

interface PayFormValues {
  cardNumber: string;
  cardCode: string;
  payAmount: string;
}

const validationSchema = Yup.object({
  cardNumber: Yup.string().required(MESSAGE_REQUIRED),
  cardCode: Yup.number().required(MESSAGE_REQUIRED),
  payAmount: Yup.number().min(1, MESSAGE_INVALID_AMOUNT).required(MESSAGE_REQUIRED),
});

interface Props {
  cardNumber: string;
  cardCode: string;
  isMobile?: boolean;
  isPayLoading: boolean;
  handleSubmitPayCardForm: (values: PayFormValues) => void;
}

export const PayCardForm: FC<Props> = (props: Props) => {
  const { cardNumber, cardCode, handleSubmitPayCardForm, isPayLoading, isMobile } = props;

  const initialValues: PayFormValues = {
    cardNumber: cardNumber,
    cardCode: cardCode,
    payAmount: '500',
  };

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmitPayCardForm}>
      {(formikProps: FormikProps<PayFormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={isMobile ? 6 : 1}>
            <Grid item={true}>
              <Grid container={true} columnSpacing={3} wrap={'nowrap'}>
                <Grid item={true} xs={isMobile ? 12 : 8}>
                  <Grid
                    container={true}
                    direction={isMobile ? 'column' : 'row'}
                    columns={16}
                    columnSpacing={1}
                    rowSpacing={isMobile ? 2 : 0}
                    wrap={'nowrap'}>
                    <Grid item={true} xs={6}>
                      <OutlinedField
                        fullWidth={true}
                        label={'Сумма пополнения'}
                        value={values.payAmount ? String(values.payAmount) : ''}
                        error={touched.payAmount && Boolean(errors.payAmount)}
                        helperText={touched.payAmount ? errors.payAmount : ''}
                        onKeyPress={(event) => {
                          if (!regExOnlyNumberKey.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue('payAmount', e.target.value);
                        }}
                        disabled={isPayLoading}
                      />
                    </Grid>
                    <Grid item={true}>
                      <PayCardButtonGroup
                        setPayAmount={(amount) => {
                          handleSetFieldValue('payAmount', amount);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isMobile ? (
                  <MobileBar>
                    <FixedButton
                      color={'primary'}
                      variant={'contained'}
                      disabled={isPayLoading || !policyChecked}
                      onClick={submitForm}>
                      {'Пополнить'}
                    </FixedButton>
                  </MobileBar>
                ) : (
                  <Grid item={true} xs={4}>
                    <PolicyLoadingButton
                      variant={'contained'}
                      type={'submit'}
                      fullWidth={true}
                      disabled={isPayLoading || !policyChecked}
                      isLoading={isPayLoading}
                      policyChecked={policyChecked}
                      onPolicyChange={setPolicyChecked}
                      onClick={submitForm}>
                      {'Пополнить'}
                    </PolicyLoadingButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isMobile && (
              <Grid item={true}>
                <CardContractCondition policyChecked={policyChecked} onPolicyChange={setPolicyChecked} />
              </Grid>
            )}
          </Grid>
        );
      }}
    </Formik>
  );
};
