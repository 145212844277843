import { Chip, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { UnauthorizedPaymentBalanceTooltip } from '@/components/create/unauthorized/UnauthorizedPaymentBalanceTooltip';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  authorizedTotalCost: number | undefined;
}

export const UnauthorizedPaymentBalanceChip2: FC<Props> = (props: Props) => {
  const { authorizedTotalCost } = props;
  return (
    <Grid container={true} direction={'row'} alignItems={'center'} wrap={'nowrap'} spacing={1}>
      <Grid item={true}>
        <Chip
          label={
            <Grid container={true} columnSpacing={1.25} rowSpacing={0.5}>
              <Grid item={true}>
                <Typography variant={'body1'}>{'Личный кабинет'}</Typography>
              </Grid>
              {authorizedTotalCost && (
                <Grid item={true}>
                  <Typography variant={'body1'}>{formatCurrency(authorizedTotalCost, 0)}</Typography>
                </Grid>
              )}
            </Grid>
          }
          variant={'filled'}
          sx={(theme: Theme) => ({
            borderRadius: '5px',
            color: '#ffffff',
            backgroundColor: theme.colors.green,
            height: 'auto',
            padding: '4px 8px',
          })}
        />
      </Grid>
      <Grid item={true}>
        <UnauthorizedPaymentBalanceTooltip />
      </Grid>
    </Grid>
  );
};
