import { AnswerCountSelectorField } from '@/components/common/field/AnswerCountSelectorField';
import { AppDispatch, RootState } from '@/app/store';
import { AuthorDTO } from '@/types/AuthorDTO';
import { Button, Grid, Typography } from '@mui/material';
import { CreateFormState, setAnswersCountForm } from '@/services/createFormSlice';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { LetterTextView } from '@/components/letter/LetterTextView';
import { MESSAGE_INVALID_ANSWERS_COUNT } from '@/utils/validation-utils';
import { RegionDTO } from '@/types/RegionDTO';
import { SenderDTO } from '@/types/SenderDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { createStyles, makeStyles } from '@mui/styles';
import { formatPhoneNumber } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import React, { ChangeEvent, FC, Fragment, useEffect } from 'react';
import theme from '@/app/theme';

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      color: theme.colors.grayText,
      paddingBottom: '4px',
    },
  })
);

interface Props {
  department: DepartmentDTO | null;
  region: RegionDTO | null;
  sender: SenderDTO | null;
  author: AuthorDTO | null;
  letterText?: string;
  isMobile?: boolean;
  fromCreateAnswer?: boolean;
  isAnswerReply?: boolean;
  changeAnswerReply?: () => void;
  answersCount?: number;
  setAnswersCount?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const LetterInfo: FC<Props> = (props: Props) => {
  const {
    department,
    region,
    sender,
    author,
    letterText,
    isMobile,
    fromCreateAnswer,
    isAnswerReply,
    changeAnswerReply,
    answersCount,
  } = props;

  const classes = useStyles();

  const dispatch: AppDispatch = useAppDispatch();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const createFormReducer: CreateFormState = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const isLetterSent: boolean = createFormReducer.isLetterSent;

  const handleAnswersCountChange = (answerCount: number): void => {
    dispatch(setAnswersCountForm(answerCount));
  };

  useEffect(() => {
    return (): void => {
      if (isAnswerReply) {
        dispatch(setAnswersCountForm(1));
      }
    };
  }, [dispatch, isAnswerReply]);

  return (
    <Grid container={true} direction={'column'} rowSpacing={2} wrap={'nowrap'}>
      {fromCreateAnswer && isMobile && (
        <Grid item={true} mt={1.5}>
          <Typography variant={'h4'}>{'Информация об ответе'}</Typography>
        </Grid>
      )}
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {'Учреждение'}
        </Typography>
        <Typography variant={'body1'}>{`${department?.name}, ${region?.name}`}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {fromCreateAnswer ? 'Отправитель' : 'Адресат'}
        </Typography>
        <Typography variant={'body1'}>
          {`${sender?.lastName} ${sender?.firstName} ${sender?.middleName}, ${sender?.age}`}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {fromCreateAnswer ? 'Адресат' : 'Отправитель'}
        </Typography>
        <Typography variant={'body1'}>{`${author?.name}`}</Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={isMobile ? 12 : 'auto'}>
            <Typography variant={'body2'} className={classes.label}>
              {'Электронная почта'}
            </Typography>
            <Typography variant={'body1'}>{`${author?.email}`}</Typography>
          </Grid>
          <Grid item={true} xs={isMobile ? 12 : 'auto'}>
            <Typography variant={'body2'} className={classes.label}>
              {'Телефон'}
            </Typography>
            <Typography variant={'body1'}>{`${formatPhoneNumber(author?.phone)}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {authUser && authUser.email !== author?.email && (
        <Grid item={true} mt={1}>
          <LetterAlert>
            <Typography variant={'body1'} fontSize={'14px'}>
              {'Уведомления об изменениях статуса письма будут приходить на указанную электронную почту.'}
            </Typography>
          </LetterAlert>
        </Grid>
      )}
      {fromCreateAnswer && (
        <Fragment>
          {isAnswerReply && !isLetterSent ? (
            <Grid item={true}>
              <AnswerCountSelectorField
                value={answersCount}
                minValue={1}
                maxValue={10}
                step={1}
                error={!answersCount || answersCount <= 0 || answersCount > 10}
                helperText={
                  !answersCount || answersCount <= 0 || answersCount > 10 ? MESSAGE_INVALID_ANSWERS_COUNT : ''
                }
                onValueChange={handleAnswersCountChange}
              />
            </Grid>
          ) : (
            <Grid item={true}>
              <Typography variant={'body2'} className={classes.label}>
                {'Количество бланков ответа'}
              </Typography>
              <Typography variant={'body1'}>{`${answersCount}`}</Typography>
            </Grid>
          )}
        </Fragment>
      )}
      {letterText && letterText?.length > 0 && (
        <Grid item={true} mt={isMobile ? 1 : 5}>
          <Typography variant={'body2'} className={classes.label}>
            {'Текст письма'}
          </Typography>
          <LetterTextView text={letterText} />
        </Grid>
      )}
      {isAnswerReply && fromCreateAnswer && !isLetterSent && (
        <Grid item={true}>
          <Button onClick={changeAnswerReply} size={'small'} sx={{ marginLeft: '-5px', marginTop: '-2px' }}>
            {'Изменить'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
