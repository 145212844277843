import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const TurnIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}
    sx={{ width: '24px', height: '24px' }}
    {...props}>
    <path
      d={'M21 10V8C21 5.23858 18.7614 3 16 3H10'}
      fill={'none'}
      stroke={'white'}
      strokeWidth={'2'}
      strokeLinecap={'round'}
    />
    <path
      d={
        'M8.97609 2.21913L11.3753 0.299756C12.0301 -0.224055 13 0.242119 13 1.08062V4.91938C13 5.75788 12.0301 6.22405 11.3753 5.70024L8.97609 3.78087C8.47568 3.38054 8.47568 2.61946 8.97609 2.21913Z'
      }
      fill={'white'}
    />
    <rect x={'3'} y={'9'} width={'13'} height={'13'} rx={'2'} stroke={'white'} fill={'none'} strokeWidth={'2'} />
  </SvgIcon>
);
