import { FormControl, FormHelperText, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import LetterTextFieldBottomPanel from '@/components/create/letter/LetterTextFieldBottomPanel';
import React, { FC, MouseEvent, useRef, useState } from 'react';

interface Props extends OutlinedInputProps {
  rows: number;
  charactersPerPage: number;
  costPage: number;
  countCharacters: number;
  size?: 'small' | 'medium';
  helperText?: string;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  additionalCost: number;
}

const useBottomPanelStyles = makeStyles(() =>
  createStyles({
    border: {
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderRadius: '6px 6px 0px 0px',
        borderBottom: 'none',
      },
    },
  })
);

export const LetterTextField: FC<Props> = (props: Props) => {
  const { size, label, error, helperText, charactersPerPage, countCharacters, costPage, additionalCost, ...other } =
    props;
  const [hover, setHover] = useState<boolean>(false);
  const refInput = useRef<HTMLInputElement>(null);
  const classes = useBottomPanelStyles();

  return (
    <FormControl variant={'outlined'} size={size} fullWidth={true}>
      {label && (
        <InputLabel error={error} id={props.name + '-input-label'}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        inputRef={refInput}
        className={classes.border}
        sx={{
          padding: '16px 16px 0px 16px',
        }}
        inputProps={{ style: { padding: 0 } }}
        error={error}
        multiline={true}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...other}
      />
      <LetterTextFieldBottomPanel
        onHover={hover}
        charactersPerPage={charactersPerPage}
        costPage={costPage}
        countCharacters={countCharacters}
        error={error}
        additionalCost={additionalCost}
        onClick={() => refInput?.current?.focus()}
      />
      {helperText && (
        <FormHelperText error={error} id={props.name + '-input-helperText'}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
