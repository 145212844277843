import { Box, Grid, Typography } from '@mui/material';
import { CardService } from '@/types/card/CardService';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { InfoIcon } from '@/app/icons/InfoIcon';
import { QrOutdatedIcon } from '@/app/icons/payment/QrOutdatedIcon';
import { QrStatus } from '@/types/sbp/QrStatus';
import { RootState } from '@/app/store';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { Theme } from '@mui/material/styles';
import { TimeIcon } from '@/app/icons/TimeIcon';
import {
  clearSbpQr,
  getSbpPaymentRedirect,
  getSbpQrOutdated,
  setBalanceSuccessFill,
  setCardCreateFillSuccess,
  setCardRefillSuccess,
  setSbpPaymentRedirect,
  setSbpQrOutdated,
} from '@/services/sbpSlice';
import { config } from '@/app/config';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import SockJsClient from 'react-stomp';

interface Props {
  sbpQR: CreatedSbpQrDTO;
  sbpMethod: SbpMethod;
  cardService?: CardService;
  isMobile?: boolean;
  isLetterPage?: boolean;
  isInitiative?: boolean;
  email: string;
  onSuccess?: () => void;
}

export const SpbPaymentContainer: FC<Props> = (props: Props) => {
  const { sbpQR, isMobile, email, sbpMethod, cardService, isLetterPage, isInitiative, onSuccess } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const paymentRedirect: boolean = useAppSelector((state: RootState) =>
    getSbpPaymentRedirect(state, sbpMethod, cardService, isLetterPage, isInitiative)
  );
  const qrOutdated: boolean = useAppSelector((state: RootState) =>
    getSbpQrOutdated(state, sbpMethod, cardService, isLetterPage, isInitiative)
  );

  const [sbpQrStatus, setSbpQrStatus] = useState<QrStatus>(QrStatus.STARTED);

  useEffect((): void => {
    if (sbpQrStatus === QrStatus.ACCEPTED) {
      if (sbpMethod === SbpMethod.BALANCE) {
        dispatch(setBalanceSuccessFill(true));
      }
      if (sbpMethod === SbpMethod.CARD) {
        if (cardService === CardService.CREATE) {
          dispatch(setCardCreateFillSuccess(true));
        }
        if (cardService === CardService.REFILL) {
          dispatch(setCardRefillSuccess(true));
        }
      }
      if (onSuccess && sbpQR) {
        onSuccess();
      }
      clearSbpQr(dispatch, sbpMethod, cardService, isLetterPage, isInitiative);
    } else if (
      sbpQrStatus === QrStatus.OUTDATED ||
      sbpQrStatus === QrStatus.REJECTED ||
      sbpQrStatus === QrStatus.REJECTED_BY_USER
    ) {
      enqueueSnackbar('Платёж отклонён', { variant: 'error' });
      setSbpQrOutdated(dispatch, sbpMethod, true, cardService, isLetterPage, isInitiative);
    }
  }, [cardService, dispatch, enqueueSnackbar, onSuccess, isInitiative, isLetterPage, sbpMethod, sbpQR, sbpQrStatus]);

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (!qrOutdated && new Date() > new Date(sbpQR.dateLimit)) {
        setSbpQrOutdated(dispatch, sbpMethod, true, cardService, isLetterPage, isInitiative);
        clearInterval(timerId);
      }
    }, 30000);
  }, [cardService, dispatch, isInitiative, isLetterPage, qrOutdated, sbpMethod, sbpQR.dateLimit]);

  const handleOpenPaymentLink = useCallback((): void => {
    window.location.href = sbpQR.payload;
  }, [sbpQR.payload]);

  useEffect((): void => {
    if (isMobile && !paymentRedirect) {
      setSbpPaymentRedirect(dispatch, sbpMethod, true, cardService, isLetterPage, isInitiative);
      handleOpenPaymentLink();
    }
  }, [cardService, dispatch, handleOpenPaymentLink, isInitiative, isLetterPage, isMobile, paymentRedirect, sbpMethod]);

  return (
    <Fragment>
      <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
        <Grid item={true}>
          {qrOutdated ? (
            <InfoIcon
              sx={{
                color: (theme: Theme): string => (qrOutdated ? theme.colors.red : theme.colors.primary),
              }}
            />
          ) : (
            <TimeIcon
              sx={{
                color: (theme: Theme): string => (qrOutdated ? theme.colors.red : theme.colors.primary),
              }}
            />
          )}
        </Grid>
        <Grid item={true} xs={true}>
          <Grid container={true} direction={'column'} spacing={2}>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={1}>
                <Grid item={true}>
                  <Typography
                    variant={'body1'}
                    fontWeight={500}
                    sx={{
                      color: (theme: Theme): string => (qrOutdated ? theme.colors.red : theme.colors.primary),
                    }}>
                    {qrOutdated ? 'Срок действия QR-кода истёк' : 'Ожидается оплата'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  {qrOutdated ? (
                    <Typography variant={'body1'}>
                      <Typography component={'span'}>{'На почту '}</Typography>
                      <Typography component={'span'} fontWeight={500}>
                        {email}
                      </Typography>
                      <Typography component={'span'}>{' придёт ссылка для оплаты '}</Typography>
                      {sbpMethod === SbpMethod.LETTER
                        ? 'письма'
                        : sbpMethod === SbpMethod.BALANCE
                        ? 'счёта'
                        : sbpMethod === SbpMethod.CARD
                        ? 'карты'
                        : ''}
                    </Typography>
                  ) : (
                    <Grid container={true} direction={'column'} spacing={1}>
                      <Grid item={true}>
                        <Typography variant={'body1'}>
                          {isMobile ? (
                            <Typography component={'span'}>{'Для оплаты нажмите на QR-код ниже. '}</Typography>
                          ) : (
                            <Typography component={'span'}>
                              {'Для оплаты войдите в мобильное приложение банка и отсканируйте QR-код ниже. '}
                            </Typography>
                          )}
                          <Typography component={'span'}>{'Уведомление об оплате вы получите на '}</Typography>
                          <Typography component={'span'} fontWeight={500}>
                            {email}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'inherit'}>
                          {'Вы можете воспользоваться этим QR в течение 20 минут'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} alignItems={'center'}>
                <Grid item={true} onClick={() => isMobile && !qrOutdated && handleOpenPaymentLink()}>
                  <Box sx={{ position: 'relative', zIndex: 99 }}>
                    <img
                      src={`data:image/jpeg;base64,${sbpQR.renderedQr}`}
                      alt={'QR-code'}
                      style={{
                        margin: 'auto',
                        width: '160px',
                        height: '160px',
                        opacity: qrOutdated ? 0.2 : 1,
                      }}
                    />
                    {qrOutdated && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 'calc(50% - 55px)',
                          left: 'calc(50% - 55px)',
                          zIndex: 100,
                        }}>
                        <QrOutdatedIcon />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SockJsClient
        url={config.baseUrl + '/ws'}
        topics={['/topic/sbp/qr/' + sbpQR.qrId]}
        onMessage={(status: QrStatus): void => {
          setSbpQrStatus(status);
        }}
        onConnect={(): void => {
          console.log(sbpQR.qrId + ' connected!');
        }}
        onError={(): void => {
          enqueueSnackbar('Ошибка статуса QR', { variant: 'error' });
        }}
      />
    </Fragment>
  );
};
