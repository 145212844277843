import { AtlasDepartmentDTO } from '@/types/atlas/AtlasDepartmentDTO';
import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Autocomplete, CircularProgress, Grid, Paper, TextField, Typography, useMediaQuery } from '@mui/material';
import { CancelIcon } from '@/app/icons/CancelIcon';
import { Departments } from '@/components/help/departments/Departments';
import {
  ROUTE_HELP_REGIONS,
  ROUTE_HELP_REGIONS_CHAR,
  ROUTE_HELP_REGIONS_ID,
  getHelpDepartmentsRoute,
  getHelpRegionRoute,
} from '@/app/routes';
import { RootState } from '@/app/store';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { SearchIcon } from '@/app/icons/SearchIcon';
import { SnapReady } from '@/components/SnapReady';
import { Theme } from '@mui/material/styles';
import { setAtlasRegion } from '@/services/helpSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetAtlasRegionsQuery } from '@/services/api/helpApiSlice';
import MobileRegionsCharacter from '@/components/help/departments/MobileRegionsCharacter';
import MobileRegionsList from '@/components/help/departments/MobileRegionsList';
import React, { FC, Fragment, useEffect, useState } from 'react';
import RegionsList from '@/components/help/departments/RegionsList';

const RegionsBlock: FC = () => {
  const { data: regions, isFetching } = useGetAtlasRegionsQuery();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const region: AtlasRegionDTO | null = useAppSelector((state: RootState) => {
    return state.help.region;
  });
  const dispatch = useAppDispatch();
  const setRegion = (region: AtlasRegionDTO | null): void => {
    dispatch(setAtlasRegion(region));
  };
  const handleChangeRegion = (region: AtlasRegionDTO | null): void => {
    if (region) {
      if (region.isPointerOnDepartment) {
        const currentRegion: AtlasRegionDTO | undefined = regions?.find((regionOriginal: AtlasRegionDTO) => {
          return regionOriginal.id === region.id;
        });
        if (currentRegion) {
          setRegion(currentRegion);
          history.push(getHelpDepartmentsRoute(region.id, region.departments[0].id));
        }
      } else {
        setRegion(region);
        history.push(getHelpRegionRoute(region.id));
      }
    } else {
      setRegion(region);
      history.push(ROUTE_HELP_REGIONS);
    }
  };

  const extendedRegionsList = regions && regions.slice();
  if (extendedRegionsList && regions) {
    regions.map((region: AtlasRegionDTO) => {
      region.departments.map((department: AtlasDepartmentDTO) => {
        extendedRegionsList.push({
          ...region,
          departments: [department],
          isPointerOnDepartment: true,
        });
      });
    });
  }

  const isEqualRegions = (regionOne: AtlasRegionDTO, regionTwo: AtlasRegionDTO) => {
    if (regionOne.id != regionTwo.id) {
      return false;
    }
    if (regionOne.departments.length != regionTwo.departments.length) {
      return false;
    }
    for (let i = 0; i < regionOne.departments.length; i++) {
      if (regionOne.departments[i].id != regionTwo.departments[i].id) {
        return false;
      }
    }
    return true;
  };

  const isPad: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const [char, setChar] = useState<string>('');

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === ROUTE_HELP_REGIONS) {
      dispatch(setAtlasRegion(null));
    }
  }, [dispatch, location]);

  return (
    <Grid container={true} direction={'column'} rowSpacing={isMobile ? 3 : 4.5}>
      {isMobile && (
        <Grid item={true}>
          <Typography variant={'h1'}>{'Справочник учреждений'}</Typography>
        </Grid>
      )}
      <Grid item={true}>
        <Autocomplete
          id={'select-region'}
          open={open}
          loading={isFetching}
          options={extendedRegionsList || []}
          value={region}
          getOptionLabel={(option: AtlasRegionDTO) =>
            `${option.name} ${option.isPointerOnDepartment ? option.departments[0].name : ''}`
          }
          isOptionEqualToValue={(option: AtlasRegionDTO, value: AtlasRegionDTO) => isEqualRegions(option, value)}
          onInputChange={(event, newInputValue, reason) => {
            if (newInputValue.length > 1 && reason === 'input') {
              setOpen(true);
            } else {
              setOpen(false);
            }
          }}
          onClose={() => setOpen(false)}
          onChange={(event, value) => handleChangeRegion(value)}
          fullWidth={true}
          noOptionsText={'Не найдено'}
          loadingText={'Загрузка ...'}
          clearText={''}
          clearIcon={<CancelIcon sx={{ width: '32px', height: '32px' }} />}
          popupIcon={null}
          autoHighlight={true}
          handleHomeEndKeys={false}
          PaperComponent={({ children }) => <Paper sx={{ marginTop: '3px' }}>{children}</Paper>}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={'Введите название...'}
              InputProps={{
                ...params.InputProps,
                style: { paddingRight: '8xp' },
                endAdornment: (
                  <Fragment>
                    {isFetching ? (
                      <CircularProgress color={'inherit'} size={20} sx={{ marginRight: '-31px' }} />
                    ) : region ? null : (
                      <SearchIcon
                        sx={{
                          width: (theme: Theme) => theme.spacing(4),
                          height: (theme: Theme) => theme.spacing(4),
                          color: (theme: Theme) => theme.colors.primary,
                          marginRight: '-31px',
                        }}
                      />
                    )}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
          renderOption={(props, option: AtlasRegionDTO) => {
            const isDepartment = option.isPointerOnDepartment;
            return (
              <li
                {...props}
                key={isDepartment ? option.id.toString() + option.departments[0].id.toString() : option.id}>
                <Grid container={true} direction={'column'} sx={{ padding: '8px 0px' }}>
                  {isDepartment && (
                    <Grid item={true}>
                      <Typography component={'div'} variant={'body1'}>
                        {option.departments[0].name}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item={true}>
                    <Typography
                      component={'div'}
                      variant={'body1'}
                      sx={{
                        color: (theme: Theme) => (isDepartment ? theme.colors.grayText : theme.colors.textColor),
                      }}>
                      {option.name}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Grid>
      <Grid item={true}>
        <Grid item={true} xs={true}>
          <Switch>
            <Route exact={true} path={ROUTE_HELP_REGIONS}>
              {isMobile || isPad ? (
                isMobile ? (
                  <MobileRegionsList regions={regions} handleSetChar={setChar} />
                ) : (
                  <Grid container={true} columnSpacing={3}>
                    <Grid item={true} xs={6}>
                      <RegionsList
                        handleChangeRegion={handleChangeRegion}
                        regions={regions}
                        indexTo={regions && regions.length / 2}
                      />
                    </Grid>
                    <Grid item={true} xs={6}>
                      <RegionsList
                        handleChangeRegion={handleChangeRegion}
                        regions={regions}
                        indexFrom={regions && regions.length / 2}
                      />
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid container={true} columnSpacing={3}>
                  <Grid item={true} xs={4}>
                    <RegionsList
                      handleChangeRegion={handleChangeRegion}
                      regions={regions}
                      indexTo={regions && regions.length / 3}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <RegionsList
                      handleChangeRegion={handleChangeRegion}
                      regions={regions}
                      indexFrom={regions && regions.length / 3}
                      indexTo={regions && (2 * regions.length) / 3}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <RegionsList
                      handleChangeRegion={handleChangeRegion}
                      regions={regions}
                      indexFrom={regions && (2 * regions.length) / 3}
                    />
                  </Grid>
                </Grid>
              )}
            </Route>
            <Route path={ROUTE_HELP_REGIONS_CHAR}>
              <MobileRegionsCharacter regions={regions} handleChangeRegion={handleChangeRegion} char={char} />
            </Route>
            <Route path={ROUTE_HELP_REGIONS_ID}>
              <Departments regions={regions} />
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <SnapReady readyIndicator={regions} />
    </Grid>
  );
};

export default RegionsBlock;
