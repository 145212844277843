import * as Yup from 'yup';
import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import {
  MESSAGE_MIN_PASSWORD_LENGTH,
  MESSAGE_PASSWORD_CAPITAL_LETTER,
  MESSAGE_PASSWORD_NOT_EQUALS,
  MESSAGE_PASSWORD_NUMBER,
  MESSAGE_REQUIRED,
  MESSAGE_WRONG_OLD_PASSWORD,
  passwordCapitalLetterRegExp,
  passwordNumberRegExp,
} from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PasswordChangStatus, PasswordChangeResponseDTO } from '@/types/user/PasswordChangeResponseDTO';
import { PasswordChangeRequestDTO } from '@/types/user/PasswordChangeRequestDTO';
import { Theme } from '@mui/material/styles';
import { useSetNewPasswordMutation } from '@/services/api/settingsApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, MouseEvent, RefObject, useState } from 'react';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  newPassword: Yup.string()
    .required(MESSAGE_REQUIRED)
    .matches(passwordNumberRegExp, MESSAGE_PASSWORD_NUMBER)
    .matches(passwordCapitalLetterRegExp, MESSAGE_PASSWORD_CAPITAL_LETTER)
    .min(8, MESSAGE_MIN_PASSWORD_LENGTH),
});

interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  submitButtonRef: RefObject<HTMLButtonElement>;
  handleSubmitNew: (isChangeToNew: boolean) => void;
}

export const NewPasswordForm: FC<Props> = (props: Props) => {
  const { submitButtonRef, handleSubmitNew } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleShowOldPasswordClick = (): void => {
    setShowOldPassword(!showOldPassword);
  };
  const handleShowNewPasswordClick = (): void => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPasswordClick = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [setNewPassword, { isLoading: isLoading }] = useSetNewPasswordMutation();

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handleSubmitFormik = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (values.confirmPassword !== values.newPassword) {
      helpers.setFieldError('newPassword', MESSAGE_PASSWORD_NOT_EQUALS);
    } else {
      const dto: PasswordChangeRequestDTO = {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      };
      setNewPassword(dto)
        .unwrap()
        .then((response: PasswordChangeResponseDTO) => {
          switch (response.status) {
            case PasswordChangStatus.WRONG_OLD_PASSWORD:
              helpers.setFieldError('oldPassword', MESSAGE_WRONG_OLD_PASSWORD);
              break;
            case PasswordChangStatus.MIN_LENGTH:
              helpers.setFieldError('newPassword', MESSAGE_MIN_PASSWORD_LENGTH);
              break;
            case PasswordChangStatus.WITHOUT_NUMBERS:
              helpers.setFieldError('newPassword', MESSAGE_PASSWORD_NUMBER);
              break;
            case PasswordChangStatus.WITHOUT_CAPITAL_LETTER:
              helpers.setFieldError('newPassword', MESSAGE_PASSWORD_CAPITAL_LETTER);
              break;
            case PasswordChangStatus.CHANGED:
              enqueueSnackbar('Пароль изменён', { variant: 'success' });
              handleSubmitNew(true);
              break;
          }
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmitFormik}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, touched, errors, submitForm, setFieldValue, setFieldTouched } = formikProps;

        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Fragment>
            <Grid container={true} direction={'column'}>
              <Grid item={true} mb={2} mt={-4}>
                <Typography fontSize={'16px'} lineHeight={'20px'} whiteSpace={'pre-line'}>
                  {
                    'Пароль должен содержать минимум 8 символов, в нем\nдолжны присутствовать хотя бы одна цифра и одна\nзаглавная буква.'
                  }
                </Typography>
              </Grid>
              <Grid item={true} mt={5} mb={5}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Текущий пароль'}
                      name={'oldPassword'}
                      value={values.oldPassword ? String(values.oldPassword) : ''}
                      error={touched.oldPassword && Boolean(errors.oldPassword)}
                      helperText={touched.oldPassword ? errors.oldPassword : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue('oldPassword', e.target.value);
                      }}
                      type={showOldPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            size={'dense'}
                            edge={'end'}
                            sx={{ padding: 0, margin: 0 }}
                            onClick={handleShowOldPasswordClick}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                              e.preventDefault();
                            }}>
                            {showOldPassword ? (
                              <EyeIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            ) : (
                              <EyeCrossedIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item={true} mt={7}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Новый пароль'}
                      name={'newPassword'}
                      value={values.newPassword ? String(values.newPassword) : ''}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue('newPassword', e.target.value);
                      }}
                      type={showNewPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            size={'dense'}
                            edge={'end'}
                            sx={{ padding: 0, margin: 0 }}
                            onClick={handleShowNewPasswordClick}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                              e.preventDefault();
                            }}>
                            {showNewPassword ? (
                              <EyeIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            ) : (
                              <EyeCrossedIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item={true} mt={5}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Подтвердите новый пароль'}
                      name={'confirmPassword'}
                      value={values.confirmPassword ? String(values.confirmPassword) : ''}
                      error={touched.newPassword && Boolean(errors.newPassword)}
                      helperText={touched.newPassword ? errors.newPassword : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue('confirmPassword', e.target.value);
                      }}
                      type={showConfirmPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            size={'dense'}
                            edge={'end'}
                            sx={{ padding: 0, margin: 0 }}
                            onClick={handleShowConfirmPasswordClick}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                              e.preventDefault();
                            }}>
                            {showConfirmPassword ? (
                              <EyeIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            ) : (
                              <EyeCrossedIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <button type={'submit'} onClick={submitForm} disabled={isLoading} ref={submitButtonRef} hidden={true}>
              {'submit'}
            </button>
          </Fragment>
        );
      }}
    </Formik>
  );
};
