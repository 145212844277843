import { Box } from '@mui/material';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { Logo } from '@/app/icons/Logo';
import { NavigationDrawer } from '@/components/common/NavigationDrawer';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  inboxCount?: LetterCountDTO;
  outboxCount?: LetterCountDTO;
  letterDraftsCount?: number;
  mainPage?: boolean;
  hideMenu?: boolean;
}

export const MobileHeader: FC<Props> = (props: Props) => {
  const { inboxCount, outboxCount, mainPage, hideMenu, letterDraftsCount } = props;
  return (
    <Box display={'flex'} position={'relative'} alignItems={'center'} justifyContent={'center'}>
      {!hideMenu && (
        <NavigationDrawer
          inboxCount={inboxCount}
          outboxCount={outboxCount}
          mainPage={mainPage}
          letterDraftsCount={letterDraftsCount}
        />
      )}
      <Logo
        color={'primary'}
        sx={{
          width: (theme: Theme) => theme.spacing(7.646484375),
          height: (theme: Theme) => theme.spacing(5.625),
        }}
      />
    </Box>
  );
};
