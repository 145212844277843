import { ArrowIcon } from '@/app/icons/about/ArrowIcon';
import { BellIcon } from '@/app/icons/about/BellIcon';
import { ClockIcon } from '@/app/icons/about/ClockIcon';
import { CreditCardIcon } from '@/app/icons/about/CreditCardIcon';
import { Footer } from '@/components/main/footer/Footer';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ImageIcon } from '@/app/icons/about/ImageIcon';
import { LockIcon } from '@/app/icons/about/LockIcon';
import { ShieldIcon } from '@/app/icons/about/ShieldIcon';
import { SupportIcon } from '@/app/icons/about/SupportIcon';
import { Theme } from '@mui/material/styles';
import { TimerIcon } from '@/app/icons/about/TimerIcon';
import AdvantageItem from '@/components/about/AdvantageItem';
import React, { FC } from 'react';

interface Props {
  isMainPage?: boolean;
}

const AboutPage: FC<Props> = (props: Props) => {
  const { isMainPage } = props;

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Grid container={true} direction={'column'} sx={{ minHeight: '100%' }} wrap={'nowrap'}>
      {!isMainPage && (
        <Grid item={true} mb={isMobile ? 2 : 5}>
          <Typography variant={'h1'}>{'О сервисе'}</Typography>
        </Grid>
      )}
      <Grid item={true} mb={5}>
        <Typography variant={'body1'}>
          {'Служба отправки сообщений работает с 1 декабря 2008 года на базе исправительных учреждений России,' +
            ' в соответствии с действующими законами и инструкциями РФ, регламентирующими данный вид деятельности.' +
            ' Сервис предназначен для отправки электронных сообщений с уведомлением о вручении лицам,' +
            ' находящимся в следственных изоляторах или отбывающим наказание в местах лишения свободы.'}
        </Typography>
      </Grid>
      <Grid item={true} mb={isMobile ? 4 : 5}>
        {isMobile ? (
          <Typography fontStyle={'normal'} fontWeight={'500'} fontSize={'16px'} lineHeight={'24px'}>
            {'Чем это лучше обычного письма?'}
          </Typography>
        ) : (
          <Typography variant={'h2'}>{'Чем это лучше обычного письма?'}</Typography>
        )}
      </Grid>
      <Grid item={true} xs={true}>
        <Grid container={true} columnSpacing={7} rowSpacing={isMobile ? 4 : 5}>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<ClockIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Круглосуточный приём сообщений'}
              description={'Служба поддержки наших клиентов 24/7;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<TimerIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Быстрая доставка сообщений'}
              description={'Время доставки писем и бланков ответов в самые кратчайшие сроки;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<ImageIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Возможность отправить фотографию'}
              description={'Делитесь самыми важными моментами с близкими и родственниками;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<CreditCardIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={isMobile ? 'Удобные формы оплаты' : 'Удобные формы\nоплаты'}
              description={'Банковский перевод, платежные карты, СБП, QIWI;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<BellIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Уведомления о каждом этапе обработки письма'}
              description={'Получение платежа, отправка цензору, доставка адресату, ответ на ваше письмо;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<ShieldIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={isMobile ? '100% гарантия доставки' : '100% гарантия\nдоставки'}
              description={'В случае наличия адресата в данном учреждении и прохождения цензуры;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<ArrowIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Возможность получить ответ на письмо'}
              description={'В виде сканированной копии рукописного письма адресата;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<SupportIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Оперативная служба поддержки'}
              description={'Быстро отвечаем на ваши обращения и вопросы;'}
            />
          </Grid>
          <Grid item={true} md={3} sm={4} xs={12}>
            <AdvantageItem
              isMobile={isMobile}
              icon={<LockIcon sx={{ width: '40px', height: '40px' }} />}
              tittle={'Защита персональных данных'}
              description={'Криптографический метод, имеющий сертификат ФСТЭК.'}
            />
          </Grid>
        </Grid>
      </Grid>
      {isMobile && !isMainPage && (
        <Grid item={true} mt={5}>
          <Footer isMobile={true} />
        </Grid>
      )}
    </Grid>
  );
};

export default AboutPage;
