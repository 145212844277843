import { AuthorDTO } from '@/types/AuthorDTO';
import { CreateFormAnswerProps } from '@/types/create/CreateFormAnswerProps';
import {
  CreateFormState,
  clearLetterTextAndFile,
  getAnswersCount,
  getAttachFile,
  getAuthor,
  getCrosswordAttached,
  getLetterNumber,
  getLetterSent,
  getLetterUuid,
  getNewsAttached,
  getSender,
  getSudokuAttached,
  getSudokuCount,
} from '@/services/createFormSlice';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { Grid } from '@mui/material';
import { LetterBalance } from '@/components/create/unauthorized/LetterBalance';
import { LetterInfo } from '@/components/create/LetterInfo';
import { LetterStatus } from '@/types/letter/LetterStatus';
import { PageHeader } from '@/components/common/PageHeader';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { SenderDTO } from '@/types/SenderDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { calculatePageCount, calculateTotalCost } from '@/utils/price-utils';
import { formatNumberOfLetters } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { getLetterCost, getPhotoCost } from '@/utils/currency-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetClientInfoQuery } from '@/services/api/createFormApiSlice';
import { useGetLetterByUuidQuery } from '@/services/api/letterApiSlice';
import { useHistory } from 'react-router-dom';
import AttachFilePanel from '@/components/create/AttachFilePanel';
import React, { FC, useEffect } from 'react';

interface Props {
  isAnswerReply?: boolean;
}

export const MainCreateFormPayment: FC<CreateFormAnswerProps & Props> = (props: CreateFormAnswerProps & Props) => {
  const {
    tittle,
    previousFormLabel,
    previousFormPath,
    errorRedirectPath,
    nextFormPath,
    isMobile,
    mobileTittle,
    fromCreateAnswer,
    isAnswerReply,
  } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const createFormReducer: CreateFormState = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department: DepartmentDTO | null = createFormReducer.department;
  const region: RegionDTO | null = createFormReducer.region;
  const letterText: string | undefined = createFormReducer.letterText;
  const isAnswerOrdered: boolean = createFormReducer.isAnswerOrdered;
  const idempotencyKey: string | undefined = createFormReducer.idempotencyKey;
  const sender: SenderDTO | null = useAppSelector(getSender);
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const answersCount: number = useAppSelector(getAnswersCount);
  const attachFile: File | undefined = useAppSelector(getAttachFile);
  const sudokuAttached: boolean = useAppSelector(getSudokuAttached);
  const sudokuCount: number = useAppSelector(getSudokuCount);
  const crosswordAttached: boolean = useAppSelector(getCrosswordAttached);
  const newsAttached: boolean = useAppSelector(getNewsAttached);
  const isLetterSent: boolean = useAppSelector(getLetterSent);
  const letterNumber: number | undefined = useAppSelector(getLetterNumber);
  const letterUuid: string | undefined = useAppSelector(getLetterUuid);

  if (!(department && region && sender && author)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  const photoCost: number = getPhotoCost(!!authUser, department);
  const letterCost: number = getLetterCost(!!authUser, department);
  const authorizedLetterCost: number = getLetterCost(true, department);
  const authorizedPhotoCost: number = getPhotoCost(true, department);
  const countCharacters: number | undefined = letterText?.length;

  const { data: letter, refetch: getLetterByUuid } = useGetLetterByUuidQuery(letterUuid, {
    skip: !letterUuid,
    refetchOnMountOrArgChange: true,
  });

  const { data: clientInfo } = useGetClientInfoQuery();
  const charactersPerPage: number | undefined = clientInfo?.charactersPerPage;

  const dto: CreateLetterDTO = {
    department: department?.id,
    author: author,
    sender: sender,
    letter: letterText,
    isAnswerOrdered: isAnswerOrdered,
    answersCount: answersCount,
    sudokuAttached: sudokuAttached,
    sudokuCount: sudokuCount,
    crosswordAttached: crosswordAttached,
    newsAttached: newsAttached,
  };

  const pageCount: number | undefined = calculatePageCount(countCharacters || 0, charactersPerPage || 0);

  const totalCost: number = calculateTotalCost(
    pageCount,
    isAnswerOrdered,
    answersCount,
    !!attachFile,
    crosswordAttached,
    sudokuAttached,
    sudokuCount,
    newsAttached,
    letterCost,
    letterCost,
    photoCost
  );

  const authorizedTotalCost: number = calculateTotalCost(
    pageCount,
    isAnswerOrdered,
    answersCount,
    !!attachFile,
    crosswordAttached,
    sudokuAttached,
    sudokuCount,
    newsAttached,
    authorizedLetterCost,
    authorizedLetterCost,
    authorizedPhotoCost
  );

  const toChangeAnswerReply = (): void => {
    if (previousFormPath) {
      history.push(previousFormPath);
    }
  };

  useEffect((): void => {
    if (fromCreateAnswer) {
      dispatch(clearLetterTextAndFile());
    }
  }, [dispatch, fromCreateAnswer]);

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (!(!!letter && letter?.status !== LetterStatus.UNPAID)) {
        getLetterByUuid();
        clearInterval(timerId);
      }
    }, 30000);
  }, [getLetterByUuid, letter]);

  const isPaymentSuccess: boolean = !!letter && letter?.status !== LetterStatus.UNPAID;

  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={2}>
      <Grid item={true}>
        {!isLetterSent ? (
          <PageHeader
            tittle={tittle}
            previousFormPath={previousFormPath}
            previousFormLabel={previousFormLabel}
            isMobile={isMobile}
            mobileTittle={mobileTittle}
          />
        ) : fromCreateAnswer ? (
          <PageHeader
            tittle={
              isPaymentSuccess
                ? `Бланки ответа ${letterNumber && '№ '}${
                    formatNumberOfLetters(letterNumber)?.toString() + ' ' || ''
                  }оплачены`
                : `Ожидается оплата бланков ответа ${letterNumber && '№ '}${
                    formatNumberOfLetters(letterNumber)?.toString() || ''
                  }`
            }
            isMobile={isMobile}
            mobileTittle={mobileTittle}
          />
        ) : (
          <PageHeader
            tittle={
              isPaymentSuccess
                ? `Письмо ${letterNumber && '№ '}${
                    formatNumberOfLetters(letterNumber)?.toString() + ' ' || ''
                  }принято к доставке`
                : `Ожидается оплата письма ${letterNumber && '№ '}${
                    formatNumberOfLetters(letterNumber)?.toString() || ''
                  }`
            }
            isMobile={isMobile}
            mobileTittle={mobileTittle}
          />
        )}
      </Grid>
      <Grid item={true}>
        <Grid container={true} columnSpacing={'55px'}>
          <Grid item={true} sm={7}>
            <Grid container={true} direction={'column'} spacing={4}>
              <Grid item={true}>
                <LetterInfo
                  department={department}
                  region={region}
                  author={author}
                  sender={sender}
                  letterText={letterText}
                  fromCreateAnswer={fromCreateAnswer}
                  isAnswerReply={isAnswerReply}
                  changeAnswerReply={toChangeAnswerReply}
                  answersCount={answersCount}
                />
              </Grid>
              {photoCost && attachFile && (
                <Grid item={true}>
                  <Grid container={true} direction={'row'}>
                    <Grid item={true}>
                      <AttachFilePanel attachedFile={attachFile} photoCost={photoCost} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item={true} sm={5}>
            <LetterBalance
              attachFile={attachFile}
              sudokuAttached={sudokuAttached}
              crosswordAttached={crosswordAttached}
              newsAttached={newsAttached}
              sudokuCount={sudokuCount}
              isAnswerOrdered={isAnswerOrdered}
              countCharacters={letterText?.length}
              letterCost={letterCost}
              authorizedLetterCost={authorizedLetterCost}
              photoCost={photoCost}
              authorizedPhotoCost={authorizedPhotoCost}
              totalCost={totalCost}
              authorizedTotalCost={authorizedTotalCost}
              afterSuccessPath={nextFormPath}
              letter={dto}
              isLetterSent={isLetterSent}
              fromCreateAnswer={fromCreateAnswer}
              pages={pageCount}
              answersCount={answersCount}
              idempotencyKey={idempotencyKey}
              isPaymentSuccess={isPaymentSuccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
