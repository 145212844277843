import { EventLogDTO } from '@/types/event/EventLogDTO';

export interface LetterStatusHistoryDTO {
  letterNumber: number;
  email: string;
  responseStatus: LetterStatusHistoryResponse;
  events?: EventLogDTO[];
}

export enum LetterStatusHistoryResponse {
  LETTER_INITIATIVE = 'LETTER_INITIATIVE',
  LETTER_NOT_FOUND = 'LETTER_NOT_FOUND',
  SUCCESS = 'SUCCESS',
}
