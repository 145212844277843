import { CardCodeField } from '@/components/card/CardCodeField';
import { CardDTO } from '@/types/card/CardDTO';
import { Grid, Theme, Typography } from '@mui/material';
import { PaymentRadio, PaymentRadioProps } from '@/components/common/payment/PaymentRadio';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props extends PaymentRadioProps {
  currentValue?: number | string;
  card: CardDTO;
  onCodeSuccess: (code: string, balance: number) => void;
  onCodeError: (code: string) => void;
}

export const CardPaymentRadio: FC<Props> = (props: Props) => {
  const { card, currentValue, onCodeSuccess, onCodeError, ...other } = props;
  return (
    <PaymentRadio
      {...other}
      value={String(card.ourNumber)}
      label={`Ф-карта № ${card.ourNumber}`}
      caption={
        <Grid container={true} direction={'column'} spacing={1}>
          <Grid item={true}>
            <Typography variant={'body2'} sx={{ color: (theme: Theme) => theme.colors.grayText }}>
              {`Баланс ${formatCurrency(card.balance || 0)}`}
            </Typography>
          </Grid>
          {String(currentValue) === String(card.ourNumber) && (
            <Grid item={true}>
              <Grid container={true}>
                <Grid item={true} xs={10} pb={2}>
                  <CardCodeField
                    placeholder={'Введите пин-код'}
                    cardNumber={card.ourNumber}
                    onSuccess={onCodeSuccess}
                    onError={onCodeError}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};
