import { AmountDTO } from '@/types/AmountDTO';
import { CardCheckDTO } from '@/types/card/CardCheckDTO';
import { CardDTO } from '@/types/card/CardDTO';
import { CardDeleteRequestDTO } from '@/types/card/CardDeleteRequestDTO';
import { CreateCardDTO } from '@/types/card/CreateCardDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { apiSlice } from '@/services/api/apiSlice';

export const cardApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCheckBalance: build.mutation<number, CardCheckDTO>({
      query: (dto: CardCheckDTO) => ({
        url: '/card/checkBalance',
        method: HttpMethod.POST,
        body: dto,
      }),
    }),
    getUserCards: build.query<CardDTO[], number | undefined>({
      query: (userId: number | undefined) => ({
        url: '/card/user',
        method: HttpMethod.GET,
        params: {
          userId: userId,
        },
      }),
      providesTags: ['Cards'],
    }),
    deleteCard: build.mutation<CardDTO, CardDeleteRequestDTO>({
      query: (dto: CardDeleteRequestDTO) => ({
        url: '/card/delete',
        method: HttpMethod.POST,
        body: dto,
      }),
      invalidatesTags: ['Cards', 'Balance', 'BalanceHistory'],
    }),
    createCard: build.mutation<CardDTO, CreateCardDTO>({
      query: (dto: CreateCardDTO) => ({
        url: `/card`,
        method: HttpMethod.POST,
        body: { ...dto, satelliteId: 888 },
      }),
    }),
    getCard: build.mutation<CardDTO, CardCheckDTO>({
      query: (dto: CardCheckDTO) => ({
        url: '/card/get-card',
        method: HttpMethod.POST,
        body: dto,
      }),
    }),
    getAmounts: build.query<AmountDTO[], void>({
      query: () => ({
        url: '/card/amounts',
        method: HttpMethod.GET,
      }),
    }),
  }),
});

export const {
  useGetCheckBalanceMutation,
  useGetUserCardsQuery,
  useDeleteCardMutation,
  useCreateCardMutation,
  useGetCardMutation,
  useGetAmountsQuery,
} = cardApiSlice;
