import { Button, ButtonProps, CircularProgress, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { ForwardedRef, forwardRef } from 'react';

export interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
  withPadding?: boolean;
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (props: LoadingButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { isLoading, withPadding, disabled, children, ...other } = props;

    return (
      <Button disabled={disabled || isLoading} ref={ref} {...other}>
        <Grid
          container={true}
          justifyContent={'center'}
          position={'relative'}
          padding={(theme: Theme): string => (withPadding ? theme.spacing(0, 4) : 'initial')}>
          {isLoading && (
            <Grid item={true} sx={{ position: 'absolute', left: 0 }}>
              <CircularProgress color={'inherit'} size={20} />
            </Grid>
          )}
          <Grid item={true}>{children}</Grid>
        </Grid>
      </Button>
    );
  }
);

LoadingButton.displayName = 'LoadButton';
