import { Grid, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface Props {
  label: ReactNode;
  price: ReactNode;
}

export const LetterReceiptRow: FC<Props> = (props: Props) => {
  const { label, price } = props;
  return (
    <Grid container={true} justifyContent={'space-between'} columnSpacing={1.5}>
      <Grid item={true}>
        <Typography variant={'body1'}>{label}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body1'} fontWeight={500}>
          {price}
        </Typography>
      </Grid>
    </Grid>
  );
};
