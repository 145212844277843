import { Grid } from '@mui/material';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { Logo } from '@/app/icons/Logo';
import { MainNavigationCountItem } from '@/components/main/navigation/MainNavigationCountItem';
import { MainNavigationItem } from '@/components/main/navigation/MainNavigationItem';
import {
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_INBOX,
  ROUTE_MAIN,
  ROUTE_MAIN_CARD,
  ROUTE_MAIN_CREATE_ANSWER,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_REDIRECT_LETTER,
  ROUTE_REGISTRATION,
} from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import HeaderProfile from '@/components/common/HeaderProfile';
import React, { FC } from 'react';
import UnauthorizedHeaderProfile from '@/components/common/UnauthorizedHeaderProfile';

interface Props {
  profile: boolean;
  isAuthenticated: boolean;
  inboxCount?: LetterCountDTO;
}

export const MainNavigation: FC<Props> = (props: Props) => {
  const { profile, isAuthenticated, inboxCount } = props;
  return (
    <Grid container={true} direction={'row'} alignItems={'center'} spacing={4}>
      <Grid item={true}>
        <RouteLink to={ROUTE_MAIN}>
          <Logo
            color={'primary'}
            sx={{
              width: (theme: Theme) => theme.spacing(10.875),
              height: (theme: Theme) => theme.spacing(8),
            }}
          />
        </RouteLink>
      </Grid>
      <Grid item={true} xs={true}>
        <Grid container={true} direction={'row'} justifyContent={'space-around'} alignItems={'center'} spacing={2}>
          <Grid item={true}>
            <MainNavigationItem to={ROUTE_MAIN_CREATE_LETTER} label={'Написать'} />
          </Grid>
          <Grid item={true}>
            <MainNavigationItem to={ROUTE_CHECK_LETTER_STATUS} label={'Узнать статус'} />
          </Grid>
          <Grid item={true}>
            <MainNavigationItem to={ROUTE_REDIRECT_LETTER} label={'Перенаправить'} />
          </Grid>
          <Grid item={true}>
            <MainNavigationItem to={ROUTE_MAIN_CREATE_ANSWER} label={'Заказать ответ'} />
          </Grid>
          <Grid item={true}>
            <MainNavigationItem to={ROUTE_MAIN_CARD} label={'Ф-карты'} />
          </Grid>
          <Grid item={true}>
            <MainNavigationCountItem
              to={isAuthenticated ? ROUTE_INBOX : ROUTE_REGISTRATION}
              label={'Мои письма'}
              count={inboxCount?.unreadCount}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>{profile ? <HeaderProfile /> : <UnauthorizedHeaderProfile />}</Grid>
    </Grid>
  );
};
