import { Grid, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { ProfileExpandIcon } from '@/app/icons/profile/ProfileExpandIcon';
import { ProfileIcon } from '@/app/icons/profile/ProfileIcon';
import { ROUTE_INBOX, ROUTE_MAIN, ROUTE_PROFILE } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { formatCurrency } from '@/utils/string-utils';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetUserBalanceQuery } from '@/services/api/balanceApiSlice';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, useState } from 'react';

const HeaderProfile: FC = () => {
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data: balance, isFetching, isLoading } = useGetUserBalanceQuery(authUser?.id, { skip: !authUser });
  const loading: boolean = isFetching || isLoading;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleClickOpenInbox = () => {
    history.push(ROUTE_INBOX);
    handleClose();
  };
  const handleClickOpenProfile = () => {
    history.push(ROUTE_PROFILE);
    handleClose();
  };
  const handleClickLogout = () => {
    history.push(ROUTE_MAIN);
    dispatch(signOut());
    handleClose();
  };
  return (
    <Fragment>
      <Grid container={true} direction={'row'} alignItems={'center'} spacing={1.25}>
        <Grid item={true}>
          <Typography
            fontSize={'10px'}
            lineHeight={'16px'}
            letterSpacing={'0.03em'}
            textAlign={'right'}
            color={(theme: Theme) => theme.colors.grayText}
            pb={(theme: Theme) => theme.spacing(0.25)}
            sx={{ textTransform: 'uppercase' }}>
            {'Баланс'}
          </Typography>
          <Typography
            fontSize={'14px'}
            lineHeight={'14px'}
            fontWeight={500}
            textAlign={'right'}
            color={(theme: Theme) => theme.colors.primary}>
            {loading ? <Skeleton height={'14px'} width={'56px'} /> : formatCurrency(balance?.amount || 0)}
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid
            container={true}
            alignItems={'center'}
            columnSpacing={0.75}
            onClick={handleClickOpenMenu}
            sx={{
              color: (theme: Theme) => theme.colors.primary,
              cursor: 'pointer',
              '&:hover': { color: (theme: Theme) => theme.colors.primaryDark },
            }}>
            <Grid item={true} display={'flex'}>
              <ProfileIcon
                sx={{
                  width: (theme: Theme) => theme.spacing(4),
                  height: (theme: Theme) => theme.spacing(4),
                }}
              />
            </Grid>
            <Grid item={true} display={'flex'}>
              <ProfileExpandIcon
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s',
                  width: (theme: Theme) => theme.spacing(1.5),
                  height: (theme: Theme) => theme.spacing(0.75),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        autoFocus={false}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-list': {
            padding: '0px',
          },
          '& .MuiMenuItem-root': {
            padding: '0px',
            color: (theme: Theme) => theme.colors.primary,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            '&:active': {
              color: (theme: Theme) => theme.colors.primaryDark,
              background: (theme: Theme) => theme.colors.grayBackground,
            },
            '&:hover': {
              color: (theme: Theme) => theme.colors.primaryDark,
              background: (theme: Theme) => theme.colors.grayBackground,
            },
          },
        }}
        PaperProps={{
          sx: {
            padding: '16px 14px',
            boxShadow: 'none',
            borderRadius: (theme: Theme) => theme.spacing(1.5),
            marginTop: (theme: Theme) => theme.spacing(1),
            background: (theme: Theme) => theme.colors.grayBackground,
          },
        }}>
        <MenuItem
          onClick={handleClickOpenProfile}
          disableRipple={true}
          sx={{ marginBottom: (theme: Theme) => theme.spacing(2) }}>
          {'Открыть профиль'}
        </MenuItem>
        <MenuItem
          onClick={handleClickOpenInbox}
          disableRipple={true}
          sx={{ marginBottom: (theme: Theme) => theme.spacing(2) }}>
          {'Мои письма'}
        </MenuItem>
        <MenuItem onClick={handleClickLogout} disableRipple={true}>
          {'Выйти'}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default HeaderProfile;
