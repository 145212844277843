import { PaymentStatusInfo } from '@/components/create/unauthorized/PaymentStatusInfo';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  email?: string;
  message?: string;
}

export const CardPaymentWaitingInfo: FC<Props> = (props: Props) => {
  const { email, message } = props;

  return (
    <PaymentStatusInfo
      variant={'waiting'}
      title={'Ожидается оплата'}
      message={message}
      content={
        <Typography variant={'body1'}>
          {'Уведомление об оплате вы получите на '}
          <Typography component={'span'} variant={'inherit'} fontWeight={500}>
            {email}
          </Typography>
        </Typography>
      }
    />
  );
};
