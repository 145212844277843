import { Button, Grid, Theme, Typography } from '@mui/material';
import { ErrorPage } from '@/components/main/error/ErrorPage';
import { ROUTE_MAIN_CARD, ROUTE_PROFILE } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { TypographyLink } from '@/components/common/TypographyLink';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

export const CardRefillErrorPage: FC = () => {
  const history = useHistory();
  const handleClick = (): void => {
    history.push(ROUTE_MAIN_CARD);
  };

  return (
    <ErrorPage label={'Ошибка пополнения баланса карты'}>
      <Grid container={true} direction={'column'} wrap={'nowrap'}>
        <Grid item={true} sx={{ maxWidth: '660px !important' }} mb={7}>
          <Typography variant={'body1'}>
            {'Кажется, вы пытаетесь повторно пополнить карту по счёту, который уже оплачен.'}
          </Typography>
          <Typography variant={'body1'} mb={3}>
            {'Пожалуйста, проверьте баланс карты и историю пополнений в '}
            <RouteLink style={{ display: 'inline' }} to={ROUTE_PROFILE}>
              <TypographyLink>{'Личном кабинете'}</TypographyLink>
            </RouteLink>
            {'.'}
          </Typography>
          <Typography variant={'body1'}>
            {
              'Если вы пытаетесь оплатить новое пополнение на баланс карты, то просим вас убедиться, что с момента формирования счёта прошло менее 30 дней. Если прошло больше, то произвести пополнение по высталвенному счёту не удастся.'
            }
          </Typography>
        </Grid>
        <Grid item={true}>
          <Button
            variant={'contained'}
            onClick={handleClick}
            sx={(theme: Theme) => ({
              width: '280px',
              [theme.breakpoints.down('sm')]: {
                width: '180px',
              },
            })}>
            {'Пополнить заново'}
          </Button>
        </Grid>
      </Grid>
    </ErrorPage>
  );
};
