import * as Yup from 'yup';
import { CardCheckDTO } from '@/types/card/CardCheckDTO';
import { CardDTO } from '@/types/card/CardDTO';
import { CardPaymentDialog } from '@/components/card/CardPaymentDialog';
import { CardService } from '@/types/card/CardService';
import { CheckOrderStatusRequestDTO } from '@/types/sbp/CheckOrderStatusRequestDTO';
import { CheckQrStatusRequestDTO } from '@/types/sbp/CheckQrStatusRequestDTO';
import { CheckedStatusOrderDTO } from '@/types/sbp/CheckedStatusOrderDTO';
import { CheckedStatusQrDTO } from '@/types/sbp/CheckedStatusQrDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Grid, Theme, Typography } from '@mui/material';
import { MESSAGE_FETCH_ERROR, MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { MaskedOutlinedField } from '@/components/common/field/MaskedOutlinedField';
import { OnlinePaymentResponseDTO } from '@/types/OnlinePaymentResponseDTO';
import { PayCardForm } from '@/components/create/card/PayCardForm';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { QrStatus } from '@/types/sbp/QrStatus';
import { RefillCardDTO } from '@/types/balance/RefillCardDTO';
import { RootState } from '@/app/store';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SbpOrderStatus } from '@/types/sbp/SbpOrderStatus';
import { cardCodeSchema } from '@/validation/cardCodeSchema';
import {
  clearCardRefillSbpState,
  getCardPaymentMethod,
  getCardPaymentProvider,
  getSbpOrderId,
  getSbpQR,
  setCardEmail,
  setCardPaymentMethod,
  setCardPaymentProvider,
  setCardRefillAmount,
  setCardRefillSbpQR,
  setCardRefillSuccess,
  setSbpOrderId,
} from '@/services/sbpSlice';
import { formatCurrency, isBlank, onlyNumberFormat } from '@/utils/string-utils';
import { regExOnlyNumberKey } from '@/validation/regExOnlyPhoneNumberKey';
import { setCardAuthorEmail } from '@/services/createFormSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useCardBalanceRefillMutation } from '@/services/api/sbpPaymentApiSlice';
import {
  useCreateBillRefillCardMutation,
  useGetOrderStatusMutation,
  useGetQrStatusMutation,
} from '@/services/api/paymentApiSlice';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetCardMutation } from '@/services/api/cardApiSlice';
import { useSnackbar } from 'notistack';
import LoadingOutlinedField from '@/components/common/field/LoadingOutlinedField';
import React, { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect, useState } from 'react';

interface Props {
  isMobile?: boolean;
}

interface PayFormValues {
  cardNumber: string;
  cardCode: string;
  payAmount: string;
}

interface FormValues {
  cardNumber: string;
  code: string;
}

export const CardCodeForm: FC<Props> = (props: Props) => {
  const { isMobile } = props;

  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const sbpOrderId: string | undefined = useAppSelector((state: RootState) => {
    return getSbpOrderId(state, SbpMethod.CARD, CardService.REFILL);
  });
  const sbpQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState) => {
    return getSbpQR(state, SbpMethod.CARD, CardService.REFILL);
  });
  const cardPaymentMethod: PaymentMethod = useAppSelector(getCardPaymentMethod);
  const cardPaymentProvider: PaymentProvider | undefined = useAppSelector(getCardPaymentProvider);

  const [checkCard, { isLoading }] = useGetCardMutation();
  const [createBill, { isLoading: isPayLoading }] = useCreateBillRefillCardMutation();
  const [getQrStatus, { isLoading: isQrStatusChecking }] = useGetQrStatusMutation();
  const [getOrderStatus] = useGetOrderStatusMutation();
  const [refillCard, { isLoading: isCardRefilling }] = useCardBalanceRefillMutation();

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [cardDTO, setCardDTO] = useState<CardDTO>();
  const [cardAmount, setCardAmount] = useState<number>(0);
  const [cardPhone, setCardPhone] = useState<string>('');
  const [cardEmailOwner, setCardEmailOwner] = useState<string>('');
  const [openPaymentDialog, setOpenPaymentDialog] = useState<boolean>(typeof sbpQR !== 'undefined');
  const [createBillDTO, setCreateBillDTO] = useState<RefillCardDTO>();

  const handleChange = useDebounce<() => Promise<void>>(async (submitForm: () => Promise<void>): Promise<void> => {
    await submitForm();
  }, 500);

  const initialValues: FormValues = {
    cardNumber: '',
    code: '',
  };

  const initialErrors: FormikErrors<FormValues> = {
    code: isBlank(initialValues.code) ? MESSAGE_REQUIRED : undefined,
  };

  const validationSchema = Yup.object({
    cardNumber: Yup.string().required(MESSAGE_REQUIRED),
    code: cardCodeSchema.required(MESSAGE_REQUIRED).nullable(),
  });

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const dto: CardCheckDTO = {
      cardNumber: parseInt(values.cardNumber.replace(/\s+/g, '')) || -1,
      code: values.code || '',
    };
    await checkCard(dto)
      .unwrap()
      .then((dto: CardDTO): void => {
        setSuccess(true);
        setCardAmount(dto.balance);
        setCardPhone(dto.phone);
        setCardEmailOwner(dto.email);
        setCardDTO(dto);
      })
      .catch((e: { status: number; data: ErrorDTO }): void => {
        const { setFieldError } = helpers;
        setSuccess(false);
        if (Number.isInteger(e.status)) {
          setFieldError('code', e.data?.message);
        } else {
          enqueueSnackbar(MESSAGE_FETCH_ERROR, { variant: 'error' });
        }
      });
  };

  const handleSubmitPayCardForm = async (values: PayFormValues): Promise<void> => {
    const createBillDTO: RefillCardDTO = {
      amount: parseInt(values.payAmount),
      number: parseInt(onlyNumberFormat(values.cardNumber)),
      code: values.cardCode,
      phone: cardPhone.startsWith('+') ? cardPhone : '+' + cardPhone,
    };
    setCreateBillDTO(createBillDTO);
    setOpenPaymentDialog(true);
    dispatch(setCardRefillSuccess(false));
  };

  const handleSbpOrderId = (orderId?: string, cardService?: CardService): void => {
    setSbpOrderId(dispatch, SbpMethod.CARD, orderId, cardService, false, false);
  };

  const handleCardPayment = async (provider: PaymentProvider): Promise<void> => {
    if (createBillDTO) {
      if (cardPaymentMethod === PaymentMethod.ONLINE) {
        const windowReference: Window | null = window.open();
        await createBill({ dto: createBillDTO, provider: provider })
          .unwrap()
          .then((dto: OnlinePaymentResponseDTO): void => {
            dispatch(setCardAuthorEmail(cardEmailOwner));
            // Fix for Safari (window.open doesn't work in async functions)
            if (windowReference) {
              windowReference.location = dto.paymentUrl;
            }
            handleCardRefillState(cardEmailOwner, createBillDTO.amount || 0);
            handleSbpOrderId(dto.orderId, CardService.REFILL);
          })
          .catch((e: { status: number; data: ErrorDTO }): void => {
            enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
          });
      }
      if (cardPaymentMethod === PaymentMethod.SBP && cardDTO) {
        await handlePayBySBP(cardDTO?.uuid, cardEmailOwner, createBillDTO.amount || 0);
      }
    }
  };

  const handleCardRefillState = (cardEmail: string, amount: number): void => {
    dispatch(clearCardRefillSbpState());
    dispatch(setCardRefillSuccess(false));
    dispatch(setCardRefillAmount(amount));
    dispatch(
      setCardEmail({
        email: cardEmail,
        cardService: CardService.REFILL,
      })
    );
  };

  const handlePayBySBP = async (cardUUID: string, cardEmail: string, amount: number): Promise<void> => {
    await refillCard({
      cardUUID: cardUUID,
      amount: amount,
    })
      .unwrap()
      .then((dto: CreatedSbpQrDTO): void => {
        handleCardRefillState(cardEmail, amount);
        dispatch(setCardRefillSbpQR(dto));
      })
      .catch((e: { status: number; data: ErrorDTO }): void => {
        enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
      });
  };

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (sbpOrderId) {
        const dto: CheckOrderStatusRequestDTO = {
          orderId: sbpOrderId,
        };
        getOrderStatus(dto)
          .unwrap()
          .then((response: CheckedStatusOrderDTO): void => {
            if (response?.orderStatus === SbpOrderStatus.ACCEPTED) {
              dispatch(setCardRefillSuccess(true));
              clearInterval(timerId);
            } else if (
              response?.orderStatus === SbpOrderStatus.CANCELED ||
              response?.orderStatus === SbpOrderStatus.REJECTED ||
              response?.orderStatus === SbpOrderStatus.ERROR
            ) {
              enqueueSnackbar('Платёж отклонён', { variant: 'error' });
              dispatch(clearCardRefillSbpState());
              setOpenPaymentDialog(false);
              clearInterval(timerId);
            }
          })
          .catch((): void => {
            dispatch(clearCardRefillSbpState());
            setOpenPaymentDialog(false);
          });
      }
    }, 30000);
  }, [dispatch, enqueueSnackbar, getOrderStatus, sbpOrderId]);

  useEffect((): void => {
    if (sbpQR) {
      const dto: CheckQrStatusRequestDTO = {
        qrId: sbpQR.qrId,
      };
      getQrStatus(dto)
        .unwrap()
        .then((response: CheckedStatusQrDTO): void => {
          if (response.qrStatus !== QrStatus.STARTED) {
            dispatch(clearCardRefillSbpState());
            setOpenPaymentDialog(false);
          }
        })
        .catch((): void => {
          dispatch(clearCardRefillSbpState());
          setOpenPaymentDialog(false);
        });
    }
  }, [dispatch, getQrStatus, sbpQR]);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}>
        {(formikProps: FormikProps<FormValues>) => {
          const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
          const handleSetFieldCodeValue = (field: string, value: string): void => {
            setFieldTouched(field, true, false);
            if (value.length < 4 && !isSuccess) {
              setFieldValue(field, onlyNumberFormat(value), true);
            } else if (value.length === 4) {
              setFieldValue(field, onlyNumberFormat(value), true);
              handleChange(submitForm);
            }
          };
          const handleSetFieldValue = (field: string, value: string): void => {
            setFieldTouched(field, true, false);
            setFieldValue(field, value, true);
            if (isSuccess) {
              setSuccess(false);
              setFieldTouched('code', false, false);
              setFieldValue('code', '', true);
            }
          };
          return (
            <Grid container={true} direction={'column'} wrap={'nowrap'}>
              <Grid item={true} paddingBottom={isMobile ? 6 : 7}>
                <Grid container={true} direction={isMobile ? 'column' : 'row'} columnSpacing={3} wrap={'nowrap'}>
                  <Grid item={true} xs={true} pb={isMobile ? 6 : 0} mt={isMobile ? 4 : 0}>
                    <MaskedOutlinedField
                      type={'string'}
                      label={'Номер карты'}
                      placeholder={'25 XXXX XX'}
                      value={values.cardNumber ? String(values.cardNumber) : ''}
                      error={touched.code && Boolean(errors.code)}
                      helperText={touched.cardNumber ? errors.cardNumber : ''}
                      onKeyPress={(e: KeyboardEvent<HTMLDivElement>): void => {
                        if (!regExOnlyNumberKey.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleSetFieldValue('cardNumber', e.target.value);
                      }}
                      mask={'99 999 99'}
                      maskPlaceholder={'X'}
                      disabled={isLoading || isPayLoading}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <LoadingOutlinedField
                      fullWidth={true}
                      name={'code'}
                      type={'password'}
                      required={true}
                      placeholder={'****'}
                      label={'Пин-код'}
                      value={values.code}
                      isLoading={isLoading}
                      isSuccess={isSuccess}
                      error={touched.code && Boolean(errors.code)}
                      helperText={touched.code ? errors.code : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                        handleSetFieldCodeValue(e.target.name, e.target.value);
                      }}
                      disabled={isBlank(values.cardNumber) || isPayLoading}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    {isSuccess && !isLoading && (
                      <Grid container={true} direction={'column'} spacing={1} marginTop={isMobile ? '16px' : '-34px'}>
                        <Grid item={true}>
                          <Typography
                            variant={'label1'}
                            sx={{
                              color: (theme: Theme) => theme.colors.grayText,
                            }}>
                            {'Баланс'}
                          </Typography>
                        </Grid>
                        <Grid item={true}>
                          <Typography variant={'h1'}>{formatCurrency(cardAmount, 0)}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {isSuccess && !isLoading && (
                <PayCardForm
                  cardNumber={values.cardNumber}
                  cardCode={values.code}
                  handleSubmitPayCardForm={handleSubmitPayCardForm}
                  isPayLoading={isPayLoading}
                  isMobile={isMobile}
                />
              )}
            </Grid>
          );
        }}
      </Formik>
      <CardPaymentDialog
        open={openPaymentDialog && !isQrStatusChecking}
        paymentAmount={createBillDTO?.amount || 0}
        cardService={CardService.REFILL}
        paymentMethod={cardPaymentMethod}
        onPaymentMethodChange={(method: PaymentMethod) => dispatch(setCardPaymentMethod(method))}
        paymentProvider={cardPaymentProvider}
        onPaymentProviderChange={(provider?: PaymentProvider) => dispatch(setCardPaymentProvider(provider))}
        handlePayment={() => handleCardPayment(cardPaymentProvider || PaymentProvider.ALFA)}
        sbpOrderId={sbpOrderId}
        sbpQR={sbpQR}
        isMobile={isMobile}
        isLoading={isPayLoading || isCardRefilling}
        onClose={(): void => setOpenPaymentDialog(false)}
      />
    </Fragment>
  );
};
