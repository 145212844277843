import { ButtonsBlock } from '@/components/main/header/ButtonsBlock';
import { Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import { MobileButtonsBlock } from '@/components/main/header/MobileButtonsBlock';
import { ServiceNavigation } from '@/components/main/service/ServiceNavigation';
import { Theme } from '@mui/material/styles';
import { formatNumberOfLetters, getNumEnding } from '@/utils/string-utils';
import { useGetAllLettersCountQuery } from '@/services/api/helpApiSlice';
import React, { FC } from 'react';

export const MainHeader: FC = () => {
  const { data: lettersCount } = useGetAllLettersCountQuery();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true}>
        <Typography
          variant={'h1'}
          fontSize={isMobile ? '24px' : '48px'}
          lineHeight={isMobile ? '32px' : '56px'}
          align={isMobile ? 'center' : 'inherit'}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down('sm')]: {
              fontSize: '24px',
              lineHeight: '32px',
            },
          })}>
          {'Ф-письмо: письма '} <br /> {'в исправительные учреждения России'}
        </Typography>
      </Grid>
      <Grid
        item={true}
        sx={(theme: Theme) => ({
          marginTop: theme.spacing(isMobile ? 2 : 1.5),
        })}>
        <Grid container={true} direction={'column'} spacing={5}>
          <Grid item={true}>
            <Typography align={isMobile ? 'center' : 'inherit'}>
              {`Мы доставили уже ${lettersCount ? formatNumberOfLetters(lettersCount) : 0} ${getNumEnding(
                lettersCount || 0,
                ['письмо', 'письма', 'писем']
              )}`}
            </Typography>
          </Grid>
          <Grid item={true}>{isMobile ? <MobileButtonsBlock /> : <ButtonsBlock />}</Grid>
          <Grid item={true}>
            <Divider />
          </Grid>
          <Grid item={true}>
            <ServiceNavigation />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
