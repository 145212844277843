import { FormHelperText, FormHelperTextProps, Grid, Typography, useFormControl } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { formatCurrency, getNumEnding } from '@/utils/string-utils';
import React, { FC } from 'react';
import theme from '@/app/theme';

interface Props extends FormHelperTextProps {
  onHover: boolean;
  countCharacters: number;
  charactersPerPage: number;
  costPage: number;
  additionalCost: number;
}

const LetterTextFieldBottomPanel: FC<Props> = (props: Props) => {
  const { focused } = useFormControl() || {};
  const { onHover, countCharacters, charactersPerPage, costPage, additionalCost, error, ...other } = props;
  const pages = countCharacters > 0 ? Math.ceil(countCharacters / charactersPerPage) : 0;
  const price = countCharacters > 0 ? pages * costPage : 0;
  const allCost = price + additionalCost;
  return (
    <FormHelperText
      component={'div'}
      sx={{
        margin: '0',
        border: '1px solid ' + theme.colors.borderColor,
        borderTop: 'none',
        borderRadius: '0px 0px 6px 6px',
        borderColor: (theme: Theme) => {
          if (focused && !error) {
            return theme.colors.primary;
          }
          if (error) {
            return theme.colors.errorBorder;
          }
          if (onHover && !error) {
            return theme.colors.textColor;
          }
          return theme.colors.inputBorder;
        },
        borderWidth: () => {
          if (focused) {
            return '2px';
          }
          return '1px';
        },
      }}
      {...other}>
      <Grid
        container={true}
        justifyContent={'space-between'}
        sx={{
          background: theme.colors.grayBackground,
          borderRadius: '0px 0px 6px 6px',
          padding: () => {
            if (focused) {
              return '8px 15px 7px 15px';
            }
            return '8px 16px';
          },
        }}>
        <Grid item={true}>
          <Typography variant={'label1'}>{`${countCharacters} ${getNumEnding(countCharacters || 0, [
            'символ',
            'символа',
            'символов',
          ])}, ${pages} ${getNumEnding(pages || 0, ['страница', 'страницы', 'страниц'])}`}</Typography>
        </Grid>
        <Grid item={true}>
          <Typography variant={'label1'}>{`Стоимость: ${formatCurrency(allCost)}`}</Typography>
        </Grid>
      </Grid>
    </FormHelperText>
  );
};

export default LetterTextFieldBottomPanel;
