import { Skeleton, TableCell, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  label: string;
  isLoading?: boolean;
}

export const BalanceHistoryDivider: FC<Props> = (props: Props) => {
  const { label, isLoading } = props;
  return isLoading ? (
    <TableCell
      colSpan={3}
      sx={{
        padding: 0,
        height: 'auto',
        borderBottom: 'unset',
      }}>
      <Skeleton
        variant={'rectangular'}
        height={'32px'}
        sx={(theme: Theme) => ({
          borderRadius: theme.spacing(0.75),
        })}
      />
    </TableCell>
  ) : (
    <TableCell
      colSpan={3}
      sx={(theme: Theme) => ({
        background: theme.colors.grayBackground,
        borderRadius: theme.spacing(0.75),
        padding: theme.spacing(0.5, 1.5),
        height: 'auto',
        borderBottom: 'unset',
      })}>
      <Typography variant={'h4'} color={(theme: Theme) => theme.colors.grayText}>
        {label}
      </Typography>
    </TableCell>
  );
};
