import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

interface Props extends ButtonProps {
  icon: ReactNode;
  title: string;
  content: string;
}

export const ServiceNavigationItem: FC<Props> = (props: Props) => {
  const { icon, href, title, content, ...other } = props;

  const handleNavigation = (): void => {
    if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      onClick={handleNavigation}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1.5, 2),
        width: '100%',
        height: '100%',
        textAlign: 'left',
        stroke: theme.colors.primary,
      })}
      {...other}>
      <Grid container={true} direction={'row'} spacing={1}>
        <Grid item={true} display={'flex'} md={12}>
          {icon}
        </Grid>
        <Grid item={true} md={12}>
          <Grid container={true} direction={'column'}>
            <Grid item={true}>
              <Typography variant={'h5'}>{title}</Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'body2'}>{content}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};
