import { FaqInfoDTO } from '@/types/help/FaqInfoDTO';
import { Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useGetClientFaqQuery } from '@/services/api/helpApiSlice';
import CollapseHelpText from '@/components/help/CollapseHelpText';
import React, { FC, useEffect, useRef } from 'react';

const FaqBlock: FC = () => {
  const { data: faqContent, isFetching } = useGetClientFaqQuery();
  const sendLetterBlockRef = useRef<HTMLDivElement>(null);
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const scroll = new URLSearchParams(window.location.search).get('scroll');

  useEffect(() => {
    if (sendLetterBlockRef?.current && scroll) {
      sendLetterBlockRef.current.scrollIntoView({ block: 'end' });
    }
  }, [sendLetterBlockRef, scroll, isFetching]);

  return (
    <Grid container={true} direction={'column'} sx={{ maxWidth: '750px' }}>
      {isMobile && (
        <Grid item={true} mb={3}>
          <Typography variant={'h1'}>{'Часто задаваемые вопросы'}</Typography>
        </Grid>
      )}
      <Grid
        item={true}
        sx={{
          background: (theme: Theme) => theme.colors.grayBackground,
          borderRadius: '6px',
          padding: '4px 8px',
          marginBottom: '12px',
        }}>
        <Typography variant={'h1'} fontSize={20}>
          {'Отправка письма'}
        </Typography>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        <div ref={sendLetterBlockRef}>
          {faqContent &&
            faqContent.sendLetter.map((question: FaqInfoDTO, index: number) => {
              return (
                <Grid item={true} key={index}>
                  <CollapseHelpText tittle={question.tittle} hide={!(scroll && index === Number(scroll))}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.body,
                      }}
                    />
                  </CollapseHelpText>
                </Grid>
              );
            })}
        </div>
      )}
      <Grid
        item={true}
        sx={{
          background: (theme: Theme) => theme.colors.grayBackground,
          borderRadius: '6px',
          padding: '4px 8px',
          marginTop: '24px',
          marginBottom: '12px',
        }}>
        <Typography variant={'h1'} fontSize={20}>
          {'Личный кабинет'}
        </Typography>
      </Grid>
      {isFetching ? (
        <Grid container={true} direction={'column'} rowSpacing={2}>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
          <Grid item={true}>
            <Skeleton variant={'rectangular'} width={750} height={40} />
          </Grid>
        </Grid>
      ) : (
        faqContent &&
        faqContent.account.map((question: FaqInfoDTO, index: number) => {
          return (
            <Grid item={true} key={index}>
              <CollapseHelpText tittle={question.tittle}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.body,
                  }}
                />
              </CollapseHelpText>
            </Grid>
          );
        })
      )}
    </Grid>
  );
};

export default FaqBlock;
