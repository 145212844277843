import { Grid, Theme, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  tittle: string;
  description: string;
  isMobile?: boolean;
}
const AdvantageItem: FC<Props> = (prop: Props) => {
  const { icon, tittle, description, isMobile } = prop;
  return isMobile ? (
    <Grid container={true} wrap={'nowrap'}>
      <Grid item={true} mr={2}>
        <Grid item={true} color={(theme: Theme) => theme.colors.primary}>
          {icon}
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction={'column'}>
          <Grid item={true} mb={1}>
            <Typography
              fontStyle={'normal'}
              fontWeight={'500'}
              fontSize={'14px'}
              lineHeight={'20px'}
              whiteSpace={'pre-line'}>
              {tittle}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              fontStyle={'normal'}
              fontWeight={'400'}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={(theme: Theme) => theme.colors.grayText}>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container={true} direction={'column'} sx={{ maxWidth: '206px' }}>
      <Grid item={true} color={(theme: Theme) => theme.colors.primary} mb={2}>
        {icon}
      </Grid>
      <Grid item={true} mb={1}>
        <Typography variant={'h4'} whiteSpace={'pre-line'}>
          {tittle}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography
          fontStyle={'normal'}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={(theme: Theme) => theme.colors.grayText}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AdvantageItem;
