import { Button, ButtonProps, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  label: string;
  smallLabel: string;
  route: string;
  active?: boolean;
}

export const HeaderButton: FC<Props> = (props: Props) => {
  const { label, route, smallLabel, active, children, ...other } = props;
  const history = useHistory();
  return (
    <Button
      variant={'contained'}
      color={active ? 'primary' : 'secondary'}
      sx={(theme: Theme) => ({
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(3),
        height: '100%',
        width: '100%',
        textAlign: 'left',
        '&:hover, &:focus': {
          color: 'primary',
        },
      })}
      onClick={() => history.push(route)}
      {...other}>
      <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
        <Grid item={true}>
          <Grid item={true}>
            <Typography variant={'h2'}>{label}</Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'h4'}>{smallLabel}</Typography>
          </Grid>
        </Grid>
        <Grid item={true}>
          <Grid container={true}>{children}</Grid>
        </Grid>
      </Grid>
    </Button>
  );
};
