import * as Yup from 'yup';
import { EmailChangeRequestDTO } from '@/types/user/EmailChangeRequestDTO';
import { EmailChangeResponseDTO, EmailChangeResponseStatus } from '@/types/user/EmailChangeResponseDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { MESSAGE_EMAIL_NOT_EQUALS, MESSAGE_WRONG_EMAIL, MESSAGE_WRONG_PASSWORD } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { Theme } from '@mui/material/styles';
import { emailSchema } from '@/validation/emailSchema';
import { useSendEmailTokenMutation } from '@/services/api/settingsApiSlice';
import React, { ChangeEvent, FC, Fragment, MouseEvent, RefObject, useState } from 'react';

const validationSchema = Yup.object({
  email: emailSchema,
  confirmEmail: emailSchema,
});

interface FormValues {
  password: string;
  email: string;
  confirmEmail: string;
}

interface Props {
  handleSubmitNew: (isChangeToNew: boolean) => void;
  submitButtonRef: RefObject<HTMLButtonElement>;
}

const EmailForm: FC<Props> = (props: Props) => {
  const { handleSubmitNew, submitButtonRef } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const initialValues: FormValues = {
    password: '',
    email: '',
    confirmEmail: '',
  };
  const [sendEmail, { isLoading }] = useSendEmailTokenMutation();
  const handleShowPasswordClick = (): void => {
    setShowPassword(!showPassword);
  };
  const handleSubmitFormik = async (value: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const { setFieldError } = helpers;
    if (value.email !== value.confirmEmail) {
      setFieldError('confirmEmail', MESSAGE_EMAIL_NOT_EQUALS);
    } else {
      const dto: EmailChangeRequestDTO = {
        newEmail: value.email,
        password: value.password,
      };
      await sendEmail(dto)
        .unwrap()
        .then((responseStatus: EmailChangeResponseDTO) => {
          switch (responseStatus.status) {
            case EmailChangeResponseStatus.WRONG_PASSWORD:
              setFieldError('password', MESSAGE_WRONG_PASSWORD);
              break;
            case EmailChangeResponseStatus.WRONG_EMAIL:
              setFieldError('confirmEmail', MESSAGE_WRONG_EMAIL);
              break;
            case EmailChangeResponseStatus.EMAIL_CHANGE:
              handleSubmitNew(true);
              break;
          }
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          setFieldError('email', e.data.message);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmitFormik}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, touched, errors, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>): void => {
          setFieldTouched(e.target.name, true, false);
          setFieldValue(e.target.name, e.target.value, true);
        };
        return (
          <Fragment>
            <Grid container={true} direction={'column'} spacing={3}>
              <Grid item={true}>
                <OutlinedField
                  type={showPassword ? 'text' : 'password'}
                  name={'password'}
                  label={'Введите пароль'}
                  withLabel={true}
                  required={true}
                  fullWidth={true}
                  value={values.password ? String(values.password) : ''}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password ? errors.password : ''}
                  onChange={handleSetFieldValue}
                  endAdornment={
                    <InputAdornment position={'end'}>
                      <IconButton
                        aria-label={'toggle password visibility'}
                        size={'dense'}
                        edge={'end'}
                        sx={{ padding: 0, margin: 0 }}
                        onClick={handleShowPasswordClick}
                        onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                        }}>
                        {showPassword ? (
                          <EyeIcon
                            sx={{
                              width: (theme: Theme) => theme.spacing(4),
                              height: (theme: Theme) => theme.spacing(4),
                            }}
                          />
                        ) : (
                          <EyeCrossedIcon
                            sx={{
                              width: (theme: Theme) => theme.spacing(4),
                              height: (theme: Theme) => theme.spacing(4),
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item={true}>
                <OutlinedField
                  name={'email'}
                  label={'Новый е-мейл'}
                  withLabel={true}
                  required={true}
                  fullWidth={true}
                  value={values.email ? String(values.email) : ''}
                  error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                  onChange={handleSetFieldValue}
                />
              </Grid>
              <Grid item={true}>
                <OutlinedField
                  name={'confirmEmail'}
                  label={'Подтвердите новый е-мейл'}
                  withLabel={true}
                  required={true}
                  fullWidth={true}
                  value={values.confirmEmail ? String(values.confirmEmail) : ''}
                  error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                  helperText={touched.confirmEmail ? errors.confirmEmail : ''}
                  onChange={handleSetFieldValue}
                />
              </Grid>
            </Grid>
            <button type={'submit'} onClick={submitForm} disabled={isLoading} ref={submitButtonRef} hidden={true}>
              {'submit'}
            </button>
          </Fragment>
        );
      }}
    </Formik>
  );
};

export default EmailForm;
