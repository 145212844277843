import { ClientDialogTitle } from '@/components/common/modal/ClientDialogTitle';
import { Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { TopLine } from '@/components/common/TopLine';
import React, { FC, ReactNode } from 'react';

export interface ClientDialogProps extends DialogProps {
  label?: string;
  actions?: ReactNode;
  isMobile?: boolean;
  sxContent?: SxProps<Theme>;
  handleCloseButton: (open: boolean) => void;
  topLine?: boolean;
}

export const ClientDialog: FC<ClientDialogProps> = (props: ClientDialogProps) => {
  const { children, actions, label, handleCloseButton, open, isMobile, sxContent, topLine, ...other } = props;
  return (
    <Dialog open={open} fullScreen={isMobile} {...other}>
      {topLine && <TopLine />}
      <ClientDialogTitle label={label} onClose={() => handleCloseButton(open)} />
      <DialogContent sx={sxContent}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
