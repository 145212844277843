import { Button, ButtonProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

export interface NumberSelectorButtonProps extends ButtonProps {
  noHover?: boolean;
}

export const NumberSelectorButton: FC<NumberSelectorButtonProps> = (props: NumberSelectorButtonProps) => {
  const { noHover, ...other } = props;
  const noHoverSx: SxProps<Theme> = noHover
    ? {
        '&:hover': {
          background: 'none',
        },
      }
    : {};
  return (
    <Button
      disableRipple={true}
      sx={(theme: Theme) => ({
        minWidth: 'auto',
        padding: theme.spacing(1.5),
        ...noHoverSx,
      })}
      {...other}
    />
  );
};
