import { App } from './components/App';
import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/proposals/relative-indexing-method';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const appElement: HTMLElement | null = document.getElementById('app');

if (appElement?.hasChildNodes()) {
  ReactDOM.hydrate(<App />, appElement);
} else {
  ReactDOM.render(<App />, appElement);
}
