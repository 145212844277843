import { CreateCardTab } from '@/components/create/card/menu/CreateCardTab';
import { Tabs } from '@mui/material';
import React, { FC, SyntheticEvent } from 'react';

interface Props {
  value: number;
  handleChange: (event: SyntheticEvent, newValue: number) => void;
}

export const CreateCardTabs: FC<Props> = (props: Props) => {
  const { value, handleChange } = props;

  return (
    <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
      <CreateCardTab label={'Оформить Ф-карту'} tabIndex={1} currentTabIndex={value} />
      <CreateCardTab label={'Баланс и пополнение'} tabIndex={2} currentTabIndex={value} />
      <CreateCardTab disabled={true} currentTabIndex={value} />
    </Tabs>
  );
};
