import { AddPrimaryIcon } from '@/app/icons/AddPrimaryIcon';
import { AuthorDTO } from '@/types/AuthorDTO';
import { Button, Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { ForeignStateAlert } from '@/components/create/ForeignStateAlert';
import { Path } from 'history';
import {
  ROUTE_CREATE_ANSWER,
  ROUTE_CREATE_LETTER,
  ROUTE_CREATE_LETTER_AUTHOR,
  ROUTE_CREATE_LETTER_CLIENT,
  ROUTE_CREATE_REPLY_ANSWER,
  ROUTE_CREATE_REPLY_LETTER,
  ROUTE_MAIN_CREATE_ANSWER_AUTHOR,
  ROUTE_MAIN_CREATE_ANSWER_CLIENT,
} from '@/app/routes';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearFormState,
  setAnswerOrderedForm,
  setAuthor,
  setCreateInfoFromDTO,
  setPhoneNumber,
} from '@/services/createFormSlice';
import { clearLetterSbpState } from '@/services/sbpSlice';
import { formatUserFullName } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { isForeignState } from '@/services/ipLocationSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGetSendersQuery } from '@/services/api/createFormApiSlice';
import { useHistory } from 'react-router-dom';
import { validateAuthor, validateSender } from '@/utils/validation-utils';
import React, { FC, Fragment } from 'react';

interface Props {
  type: 'answer' | 'letter';
}

export const CreateFormReceiver: FC<Props> = (props: Props) => {
  const { type } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const foreignState = useAppSelector(isForeignState);
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const tittle: string = type === 'answer' ? 'От кого вы хотите получить ответ?' : 'Кому вы хотите написать?';
  const label: string = type === 'answer' ? 'Выберите отправителя' : 'Выберите адресата';
  const buttonText: string = type === 'answer' ? 'Новый отправитель' : 'Новый адресат';
  const senderPath: Path = type === 'answer' ? ROUTE_MAIN_CREATE_ANSWER_CLIENT : ROUTE_CREATE_LETTER_CLIENT;
  const authorPath: Path = type === 'answer' ? ROUTE_MAIN_CREATE_ANSWER_AUTHOR : ROUTE_CREATE_LETTER_AUTHOR;
  const submitPath: Path = type === 'answer' ? ROUTE_CREATE_REPLY_ANSWER : ROUTE_CREATE_REPLY_LETTER;
  const newSenderPath: Path = type === 'answer' ? ROUTE_CREATE_ANSWER : ROUTE_CREATE_LETTER;

  const { data: senders, isLoading: isLoadingSenders } = useGetSendersQuery(authUser?.id, {
    skip: !authUser,
  });

  const handleClickSender = (dto: SenderInfoDTO): void => {
    let author: AuthorDTO | null = null;
    dispatch(setCreateInfoFromDTO(dto));
    if (user) {
      author = {
        name: formatUserFullName(user),
        phone: user.phone || '',
        email: user.email,
      };
      dispatch(setAuthor(author));
      if (!user?.phone) {
        dispatch(setPhoneNumber(dto.authorPhone));
      }
    }
    if (type === 'answer') {
      dispatch(setAnswerOrderedForm(true));
    }
    if (!validateSender(dto.sender)) {
      history.push(senderPath);
    } else if (!validateAuthor(author)) {
      history.push(authorPath);
    } else {
      history.push(submitPath);
    }
  };
  const handleClickNewSender = (): void => {
    dispatch(clearFormState());
    dispatch(clearLetterSbpState());
    history.push(newSenderPath);
  };
  return (
    <Grid container={true} direction={'column'} alignItems={isMobile ? 'start' : 'center'}>
      <Grid item={true}>
        <Typography variant={'h1'}>{label}</Typography>
      </Grid>
      <Grid item={true} mt={2}>
        <Typography fontSize={'14px'} lineHeight={'20px'}>
          {tittle}
        </Typography>
      </Grid>
      {foreignState && (
        <Grid item={true} mt={isMobile ? 1 : 2}>
          <ForeignStateAlert />
        </Grid>
      )}
      <Grid item={true} mt={2}>
        {isLoadingSenders ? (
          <Grid container={true} direction={'column'}>
            <Grid item={true}>
              <Skeleton
                sx={{
                  minWidth: isMobile ? '280px' : '450px',
                  minHeight: '40px',
                }}
              />
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Grid item={true}>
              <Button
                size={'small'}
                fullWidth={true}
                onClick={() => handleClickNewSender()}
                variant={'contained'}
                color={'secondary'}
                sx={{
                  minWidth: isMobile ? '0px' : '450px',
                  justifyContent: 'start',
                  padding: '10px 16px',
                }}>
                <Grid container={true} spacing={'6px'}>
                  <Grid item={true} mt={'1px'}>
                    <AddPrimaryIcon />
                  </Grid>
                  <Grid item={true}>
                    <Typography
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      fontWeight={500}
                      color={(theme: Theme) => theme.colors.primary}>
                      {buttonText}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {(senders || []).map((dto: SenderInfoDTO, index: number) => (
              <Grid item={true} key={index} mt={1}>
                <Button
                  size={'small'}
                  fullWidth={true}
                  onClick={() => handleClickSender(dto)}
                  variant={'contained'}
                  color={'secondary'}
                  sx={{
                    minWidth: isMobile ? '0px' : '450px',
                    justifyContent: 'start',
                    textAlign: 'start',
                    padding: '10px 16px',
                  }}>
                  <Grid container={true} direction={'column'}>
                    <Grid item={true}>
                      <Typography fontSize={'14px'} lineHeight={'20px'} fontWeight={500}>
                        {`${dto.sender.firstName} ${dto.sender.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography fontSize={'14px'} lineHeight={'20px'} color={(theme: Theme) => theme.colors.grayText}>
                        {`${dto.region.name}, ${dto.department.name}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
