import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { Divider, Grid, Typography } from '@mui/material';
import { LetterPaymentSuccessInfo } from '@/components/create/unauthorized/LetterPaymentSuccessInfo';
import { LetterPaymentWaitingInfo } from '@/components/create/unauthorized/LetterPaymentWaitingInfo';
import { StarRounded } from '@mui/icons-material';
import { TypographyLink } from '@/components/common/TypographyLink';
import React, { FC } from 'react';

interface Props {
  letter: CreateLetterDTO;
  cardInfoMessage: string;
  isProfileCreated: boolean;
  onRegistrationClick: () => void;
  isInitiative?: boolean;
  isPaymentSuccess?: boolean;
}

export const LetterPaymentInfo: FC<Props> = (props: Props) => {
  const { letter, cardInfoMessage, isProfileCreated, onRegistrationClick, isInitiative, isPaymentSuccess } = props;
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        {isPaymentSuccess ? (
          <LetterPaymentSuccessInfo
            email={isInitiative ? letter.email : letter.author?.email}
            message={cardInfoMessage}
            isInitiative={isInitiative}
          />
        ) : (
          <LetterPaymentWaitingInfo
            email={isInitiative ? letter.email : letter.author?.email}
            message={cardInfoMessage}
          />
        )}
      </Grid>
      {!isInitiative && (
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={2}>
            <Grid item={true} ml={-3} mr={-3}>
              <Divider />
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'row'} spacing={1}>
                <Grid item={true}>
                  <StarRounded />
                </Grid>
                <Grid item={true} xs={true}>
                  <Grid container={true} direction={'column'} spacing={1}>
                    {isProfileCreated ? (
                      <Grid item={true} xs={true}>
                        <Grid container={true} direction={'column'} spacing={1}>
                          <Grid item={true}>
                            <Typography variant={'body1'} fontWeight={500}>
                              {'Создан личный кабинет'}
                            </Typography>
                          </Grid>
                          <Grid item={true}>
                            <Typography variant={'body1'}>
                              {
                                'Следите за отправленными письмами и ответами адресата. Инструкция для входа в кабинет отправлена на почту.'
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item={true} xs={true}>
                        <Typography variant={'body1'} maxWidth={'325px'}>
                          {'Следите за доставкой в '}
                          <TypographyLink onClick={onRegistrationClick}>{'Личном кабинете'}</TypographyLink>
                          {' или в рассылке на электронную почту.'}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
