import { CheckBox, CheckBoxProps } from '@/components/common/CheckBox';
import { FormControlLabel, Typography } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import { GET_OFFER_URL, GET_POLICY_URL } from '@/app/config';
import { TypographyLink } from '@/components/common/TypographyLink';
import { createStyles, makeStyles } from '@mui/styles';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'start !important',
    },
    label: {
      paddingTop: '10px',
    },
  })
);

interface Props extends CheckBoxProps {
  labelProps?: Omit<Omit<FormControlLabelProps, 'control'>, 'label'>;
}

export const PolicyStatementCheckBox: FC<Props> = (props: Props) => {
  const { labelProps, ...other } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      label={
        <Typography variant={'body3'} classes={{ root: classes.label }}>
          {'Настоящим я подтверждаю свое согласие c '}
          <a href={GET_OFFER_URL} target={'_blank'} rel={'noopener noreferrer'}>
            <TypographyLink>{'условиями работы сервиса'}</TypographyLink>
          </a>
          {' и с '}
          <a href={GET_POLICY_URL} target={'_blank'} rel={'noopener noreferrer'}>
            <TypographyLink>{'политикой обработки моих персональных данных'}</TypographyLink>
          </a>
          {
            ', а также выражаю свое согласие на обработку предоставляемых мной персональных данных в соответствии с целями вышеуказанных условий и политики.'
          }
        </Typography>
      }
      control={<CheckBox size={'small'} {...other} />}
      classes={{ root: classes.root }}
      {...labelProps}
    />
  );
};
