import * as Yup from 'yup';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Grid, Typography } from '@mui/material';
import { PhoneNumberField } from '@/components/common/field/PhoneNumberField';
import { phoneFieldSchema } from '@/validation/phoneSchema';
import { useAddNewPhoneMutation, useSetNewPhoneMutation } from '@/services/api/settingsApiSlice';
import React, { ChangeEvent, FC, Fragment, RefObject } from 'react';

const validationSchema = Yup.object({
  phoneNumber: phoneFieldSchema,
});

interface FormValues {
  phoneNumber: string;
}

interface Props {
  initialPhone: string;
  isAddPhone?: boolean;
  handleSubmit: (newPhoneNumber: string) => void;
  submitButtonRef: RefObject<HTMLButtonElement>;
  onLoading?: (isLoading: boolean) => void;
}

export const PhoneFormOne: FC<Props> = (props: Props) => {
  const { submitButtonRef, initialPhone, isAddPhone, handleSubmit, onLoading } = props;
  const initialValues: FormValues = {
    phoneNumber: initialPhone,
  };
  const [setNewPhone, { isLoading: isPhoneChanging }] = useSetNewPhoneMutation();
  const [addNewPhone, { isLoading: isPhoneAdding }] = useAddNewPhoneMutation();
  const handleSubmitFormik = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (onLoading) {
      onLoading(true);
    }
    if (isAddPhone) {
      await addNewPhone({ phone: values.phoneNumber })
        .unwrap()
        .then(() => {
          handleSubmit(values.phoneNumber);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          const { setFieldError } = helpers;
          setFieldError('phoneNumber', e.data.message);
        })
        .finally(() => {
          if (onLoading) {
            onLoading(false);
          }
        });
    } else {
      await setNewPhone({ phone: values.phoneNumber })
        .unwrap()
        .then(() => {
          handleSubmit(values.phoneNumber);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          const { setFieldError } = helpers;
          setFieldError('phoneNumber', e.data.message);
        })
        .finally(() => {
          if (onLoading) {
            onLoading(false);
          }
        });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmitFormik}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, touched, errors, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown): void => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Fragment>
            <Grid container={true} direction={'column'}>
              <Grid item={true} mb={2}>
                <Typography variant={'body1'} lineHeight={'20px'}>
                  {'Введите новый номер телефона:'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <Grid item={true} xs={12} sm={9}>
                    <PhoneNumberField
                      fullWidth={true}
                      name={'phoneNumber'}
                      value={values.phoneNumber ? String(values.phoneNumber) : ''}
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber ? errors.phoneNumber : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                      disabled={isPhoneAdding || isPhoneChanging}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <button onClick={submitForm} hidden={true} ref={submitButtonRef}>
              {'submit'}
            </button>
          </Fragment>
        );
      }}
    </Formik>
  );
};
