import { EnclosureType } from '@/types/enclosure/EnclosureType';
import { FullName } from '@/types/FullName';
import { UserDTO } from '@/types/user/UserDTO';
import { formatPhoneNumber as formatPhoneNumberLib } from 'react-phone-number-input';
import { fullNameRegExp } from '@/utils/validation-utils';

export const formatCurrency = (
  value: number | string,
  maximumFractionDigits?: number,
  minimumFractionDigits?: number
): string => {
  if (isNaN(Number(value))) {
    return 'NaN';
  }
  const format = new Intl.NumberFormat('ru-Ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : maximumFractionDigits,
  });
  return format.format(Number(value));
};

const isWhitespace = (char: string): boolean => {
  return /\s/.test(char);
};

export const isBlank = (value: string | undefined | null): boolean => {
  let length;
  if (value == undefined || (length = value.length) === 0) {
    return true;
  }
  for (let i = 0; i < length; i++) {
    if (!isWhitespace(value.charAt(i))) {
      return false;
    }
  }
  return true;
};

export const isNotBlank = (value: string | undefined | null): boolean => {
  return !isBlank(value);
};

/**
 * Функция возвращает окончание для множественного числа слова на основании числа и массива окончаний.
 * @param  iNumber Integer Число на основе которого нужно сформировать окончание
 * @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
 *         например ['яблоко', 'яблока', 'яблок']
 * @return String
 */
export const getNumEnding = (iNumber: number, aEndings: string[]): string => {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case 1:
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return sEnding;
};

export const formatPrisonerShortName = (lastName: string, firstName: string, middleName?: string): string => {
  return [
    upperFirstCaseInSentence(firstName).substring(0, 1) + '.',
    middleName ? upperFirstCaseInSentence(middleName).substring(0, 1) + '.' : '',
    upperFirstCaseInSentence(lastName),
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatPrisonerFullName = (lastName?: string, firstName?: string, middleName?: string): string => {
  return [
    lastName ? upperFirstCaseInSentence(lastName) : '',
    firstName ? upperFirstCaseInSentence(firstName) : '',
    middleName ? upperFirstCaseInSentence(middleName) : '',
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatPrisonerFullNameShort = (lastName: string, firstName: string, middleName?: string): string => {
  return [
    upperFirstCaseInSentence(lastName),
    upperFirstCaseInSentence(firstName).substring(0, 1) + '.',
    middleName ? upperFirstCaseInSentence(middleName).substring(0, 1) + '.' : '',
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatFullName = (lastName: string, firstName: string, middleName?: string): string => {
  return [
    upperFirstCaseInSentence(lastName),
    upperFirstCaseInSentence(firstName),
    upperFirstCaseInSentence(middleName || ''),
  ]
    .filter((s: string) => isNotBlank(s))
    .join(' ');
};

export const formatUserFullName = (user: UserDTO | undefined | null): string => {
  if (user) {
    return formatFullName(user.lastName, user.firstName, user.middleName);
  }
  return '';
};

export const formatUserFullNameShort = (user: UserDTO | undefined | null): string => {
  if (user) {
    return formatFullName(user.lastName, user.firstName);
  }
  return '';
};

export const formatPhoneNumber = (phone?: string): string => {
  if (phone) {
    return formatPhoneNumberLib(phone);
  }
  return '';
};

export const parseFullName = (fullName: string | undefined | null): FullName | null => {
  if (isNotBlank(fullName)) {
    const match: RegExpExecArray | null = fullNameRegExp.exec(fullName || '');
    if (match?.groups) {
      return {
        lastName: upperFirstCaseInSentence(match?.groups?.lastName),
        firstName: upperFirstCaseInSentence(match?.groups?.firstName),
        middleName: upperFirstCaseInSentence(match?.groups?.middleName),
      };
    }
  }
  return null;
};

export const upperFirstCase = (value: string): string => {
  if (isNotBlank(value)) {
    return removeExtraSpaces(value.charAt(0).toUpperCase() + value.slice(1));
  }
  return removeExtraSpaces(value);
};

export const upperFirstCaseInSentence = (value: string): string => {
  if (isNotBlank(value)) {
    const words: string[] = removeExtraSpaces(value).split(' ');
    return words.map((word: string) => upperFirstCase(word)).join(' ');
  }
  return removeExtraSpaces(value);
};

export const removeExtraSpaces = (value: string): string => {
  if (isNotBlank(value)) {
    return value
      .replace(/\s+/g, ' ')
      .replace(/^\s+|\s+$/g, '')
      .trim();
  }
  return '';
};

export const onlyNumberFormat = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

export const formatNumberOfLetters = (value?: number): string | undefined => {
  if (value) {
    return value.toString().replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1' + ' ');
  } else {
    return undefined;
  }
};

/**
 * Get the name of the enclosure based on its type.
 *
 * @param type The type of the enclosure.
 * @returns The name of the enclosure.
 */
export const getEnclosureName = (type: EnclosureType): string => {
  switch (type) {
    case EnclosureType.CROSSWORD:
      return 'Кроссворд';
    case EnclosureType.SUDOKU:
      return 'Судоку';
    case EnclosureType.NEWS:
      return 'Главные новости';
    default:
      return '';
  }
};
