import { CardFormVariant } from '@/types/card/CardFormVariant';
import { CardSuccess } from '@/components/create/card/CardSuccess';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  variant: CardFormVariant;
  onClose?: () => void;
}

export const CardSuccessDialog: FC<Props> = (props: Props) => {
  const { open, variant, onClose } = props;
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <ClientDialog
      open={open}
      isMobile={true}
      handleCloseButton={handleClose}
      sxContent={(theme: Theme) => ({
        paddingBottom: theme.spacing(6 + 3),
      })}>
      <CardSuccess variant={variant} isMobile={true} />
    </ClientDialog>
  );
};
