import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { LetterHistoryContent } from '@/components/letter/history/LetterHistoryContent';
import { MobileBar } from '@/components/home/MobileBar';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  isMobile?: boolean;
  events: EventLogDTO[];
  onClose?: () => void;
}

export const LetterHistoryDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, events, onClose } = props;
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={'История'}
      handleCloseButton={handleClose}
      sxContent={(theme: Theme) => ({
        paddingBottom: isMobile ? theme.spacing(6 + 3) : 0,
      })}>
      <LetterHistoryContent events={events} />
      {isMobile && (
        <MobileBar>
          <FixedButton color={'primary'} variant={'contained'} onClick={handleClose}>
            {'Закрыть'}
          </FixedButton>
        </MobileBar>
      )}
    </ClientDialog>
  );
};
