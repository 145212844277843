import * as Yup from 'yup';
import { Button, Grid, MenuItem, Theme } from '@mui/material';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { CreateFormState, setRegionAndDepartment } from '@/services/createFormSlice';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { ForeignStateAlert } from '@/components/create/ForeignStateAlert';
import { Form, Formik, FormikProps, getIn } from 'formik';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { PageHeader } from '@/components/common/PageHeader';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { RootState } from '@/app/store';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';
import { isForeignState } from '@/services/ipLocationSlice';
import { regionDTOSchema } from '@/validation/regionDTOSchema';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetRegionsQuery } from '@/services/api/createFormApiSlice';
import { useHistory, useLocation } from 'react-router-dom';
import React, { FC, useCallback, useEffect } from 'react';

interface FormValues {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
}

const validationSchema = Yup.object({
  region: regionDTOSchema,
  department: departmentDTOSchema,
});

const CreateFormDepartment: FC<CreateFormProps> = (props: CreateFormProps) => {
  const { tittle, nextFormPath, previousFormPath, previousFormLabel, isMobile, mobileTittle } = props;
  const createFormReducer: CreateFormState = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const { data: regions, isFetching } = useGetRegionsQuery(null, { refetchOnMountOrArgChange: true });
  const initialValues: FormValues = {
    region: createFormReducer.region,
    department: createFormReducer.department,
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = (value: FormValues): void => {
    dispatch(
      setRegionAndDepartment({
        region: value.region,
        department: value.department,
      })
    );
    if (nextFormPath) {
      history.push(nextFormPath);
    }
  };

  const foreignState: boolean = useAppSelector(isForeignState);

  let handleSetFieldValue: ((field: string, value: unknown) => void) | undefined = undefined;

  const actualizeRegionAndDepartment = useCallback(
    (departmentId, regions): void => {
      if (regions && departmentId) {
        const region: RegionDTO | undefined = regions?.find((region: RegionDTO) => {
          return region?.departments?.some((department: DepartmentDTO): boolean => {
            return department.id === parseInt(departmentId);
          });
        });
        const department: DepartmentDTO | undefined = region?.departments?.find(
          (department: DepartmentDTO): boolean => {
            return department.id === parseInt(departmentId);
          }
        );
        if (region && department) {
          dispatch(
            setRegionAndDepartment({
              region: region,
              department: department,
            })
          );
          if (handleSetFieldValue) {
            handleSetFieldValue('region', region);
            handleSetFieldValue('department', department);
          }
        }
      }
    },
    [dispatch, handleSetFieldValue]
  );

  useEffect((): void => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const departmentId: string | null = params.get('departmentId');
    actualizeRegionAndDepartment(departmentId, regions);
  }, [actualizeRegionAndDepartment, dispatch, history, isFetching, location.search, regions]);

  useEffect((): void => {
    const departmentId: number | undefined = createFormReducer?.department?.id;
    actualizeRegionAndDepartment(departmentId, regions);
  }, [actualizeRegionAndDepartment, createFormReducer?.department?.id, regions]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
        handleSetFieldValue = (field: string, value: unknown): void => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Form>
            <Grid
              container={true}
              direction={'column'}
              sx={{
                paddingBottom: (theme: Theme): string => (isMobile ? theme.spacing(6) : theme.spacing(0)),
              }}>
              <Grid item={true} marginBottom={isMobile ? '48px' : '79px'}>
                <PageHeader
                  tittle={tittle}
                  previousFormLabel={previousFormLabel}
                  previousFormPath={previousFormPath}
                  isMobile={isMobile}
                  mobileTittle={mobileTittle}
                />
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={isMobile ? 6 : 3}>
                  <Grid item={true} xs={isMobile ? 12 : 4}>
                    <RegionSelectField
                      regions={regions || []}
                      region={values.region}
                      isLoading={isFetching}
                      error={touched.region && Boolean(errors.region)}
                      helperText={
                        touched.region && Boolean(errors.region)
                          ? getIn(errors.region, 'departments')
                            ? getIn(errors.region, 'departments')
                            : errors.region
                          : ''
                      }
                      onChange={(value: RegionDTO | null): void => {
                        if (handleSetFieldValue) {
                          handleSetFieldValue('region', value);
                        }
                        if (value === null) {
                          setFieldValue('department', null);
                          setFieldTouched('department', false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <OutlinedSelect
                      size={'medium'}
                      displayEmpty={true}
                      onChange={(event) => {
                        const selectDepartment = values.region?.departments.find(
                          (department) => department.id === (event.target.value as number)
                        );
                        if (handleSetFieldValue) {
                          handleSetFieldValue('department', selectDepartment);
                        }
                      }}
                      value={values.department ? values.department.id : ''}
                      disabled={Boolean(errors.region) || !values.region}
                      label={'Выберите учреждение'}
                      MenuProps={{ PaperProps: { sx: { marginTop: '3px' } } }}
                      helperText={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                          ? errors.department
                          : ''
                      }
                      error={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                      }>
                      {values.region?.departments &&
                        values.region.departments.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                    </OutlinedSelect>
                  </Grid>
                  {isMobile ? (
                    <MobileBar>
                      <FixedButton
                        disabled={!(values.department && values.department.allowSending)}
                        color={'primary'}
                        variant={'contained'}
                        onClick={submitForm}>
                        {'Продолжить'}
                      </FixedButton>
                    </MobileBar>
                  ) : (
                    <Grid item={true} xs={true}>
                      <Button
                        disabled={!(values.department && values.department.allowSending)}
                        onClick={submitForm}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        size={'medium'}>
                        {'Продолжить'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid item={true} mt={isMobile ? 3 : 4}>
                  {values.department?.message && (
                    <LetterAlert error={!values.department.allowSending} isWarn={values.department.isWarnMessage}>
                      {values.department.message}
                    </LetterAlert>
                  )}
                </Grid>
                {foreignState && (
                  <Grid item={true} mt={isMobile ? 1 : 2}>
                    <ForeignStateAlert />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateFormDepartment;
