import { BalanceFilterButton } from '@/components/balance/BalanceFilterButton';
import { BalanceFilterDateButton } from '@/components/balance/BalanceFilterDateButton';
import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { Divider, Grid, Typography } from '@mui/material';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MobileBar } from '@/components/home/MobileBar';
import { Theme } from '@mui/material/styles';
import {
  clearBalanceHistoryRequestDates,
  setBalanceHistoryRequestEndDate,
  setBalanceHistoryRequestPageNumber,
  setBalanceHistoryRequestStartDate,
  setBalanceHistoryRequestType,
} from '@/services/balanceHistorySlice';
import { useAppDispatch } from '@/app/hooks';
import React, { FC } from 'react';

interface Props {
  open: boolean;
  type: BalanceFilterType;
  startDate?: Date;
  endDate?: Date;
  onClose: () => void;
}

export const BalanceFilterDialog: FC<Props> = (props: Props) => {
  const { open, type, startDate, endDate, onClose } = props;
  const dispatch = useAppDispatch();
  const handleType = (type: BalanceFilterType): void => {
    dispatch(setBalanceHistoryRequestType(type));
    dispatch(setBalanceHistoryRequestPageNumber(0));
  };
  const handleFilter = (startDate: Date, endDate: Date): void => {
    dispatch(setBalanceHistoryRequestStartDate(new Date(startDate)));
    dispatch(setBalanceHistoryRequestEndDate(new Date(endDate)));
    dispatch(setBalanceHistoryRequestPageNumber(0));
  };
  const handleClear = (): void => {
    dispatch(clearBalanceHistoryRequestDates());
  };
  return (
    <ClientDialog
      open={open}
      isMobile={true}
      label={'Фильтры'}
      handleCloseButton={onClose}
      actions={
        <MobileBar>
          <FixedButton color={'primary'} variant={'contained'} onClick={onClose}>
            {'Показать'}
          </FixedButton>
        </MobileBar>
      }
      sxContent={(theme: Theme) => ({
        paddingBottom: theme.spacing(5),
      })}>
      <Grid container={true} direction={'column'} spacing={3}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h4'}>{'Типы операций'}</Typography>
            </Grid>
            <Grid item={true}>
              <BalanceFilterButton
                label={'Все операции'}
                active={type === BalanceFilterType.ALL}
                onClick={() => handleType(BalanceFilterType.ALL)}
              />
            </Grid>
            <Grid item={true}>
              <BalanceFilterButton
                label={'Пополнения'}
                active={type === BalanceFilterType.INCOME}
                onClick={() => handleType(BalanceFilterType.INCOME)}
              />
            </Grid>
            <Grid item={true}>
              <BalanceFilterButton
                label={'Списания'}
                active={type === BalanceFilterType.EXPENSE}
                onClick={() => handleType(BalanceFilterType.EXPENSE)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={2}>
            <Grid item={true}>
              <Divider />
            </Grid>
            <Grid item={true}>
              <Typography variant={'h4'}>{'Даты'}</Typography>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'row'} spacing={2}>
                <Grid item={true}>
                  <BalanceFilterDateButton
                    filterType={type}
                    startDate={startDate}
                    endDate={endDate}
                    onFilter={handleFilter}
                    onClear={handleClear}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ClientDialog>
  );
};
