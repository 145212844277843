import React, { CSSProperties, FC, PropsWithChildren } from 'react';

interface Props {
  style?: CSSProperties;
  href: string;
}

export const MainNavigationRedirectLink: FC<PropsWithChildren<Props>> = (props: PropsWithChildren<Props>) => {
  const { style, children, href, ...other } = props;
  return (
    <a
      href={href}
      style={{
        display: 'flex',
        textDecoration: 'none',
        color: 'inherit',
        ...style,
      }}
      {...other}>
      {children}
    </a>
  );
};
