import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ProfileIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 31 31'} sx={{ width: '31px', height: '31px' }} {...props}>
    <circle cx={'15.5'} cy={'15.5'} r={'15.5'} fill={'#C5D5FF'} />
    <path
      d={
        'M15.5 14.8125C13.3594 14.7969 11.6094 12.9844 11.6094 10.5625C11.6094 8.29688 13.3594 6.42188 15.5 6.42188C17.6406 6.42188 19.3906 8.29688 19.3906 10.5625C19.3906 12.9844 17.6406 14.8125 15.5 14.8125ZM9.04688 23.0312C8.34375 23.0312 8 22.5312 8 21.9062C8 20.25 10.1875 16 15.5 16C20.8281 16 23 20.25 23 21.9062C23 22.5312 22.6562 23.0312 21.9375 23.0312H9.04688Z'
      }
    />
  </SvgIcon>
);
