import { Box, Grid, Typography } from '@mui/material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { LetterHistoryContent } from '@/components/letter/history/LetterHistoryContent';
import { LetterStatusHistoryResponse } from '@/types/letter/LetterStatusHistoryDTO';
import { MobileBar } from '@/components/home/MobileBar';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

interface Props {
  open: boolean;
  events?: EventLogDTO[];
  onClose?: () => void;
  letterNumber: number;
  email: string;
  responseStatus: LetterStatusHistoryResponse;
  handleSupportClick: () => void;
}

export const CheckLetterStatusDialog: FC<Props> = (props: Props) => {
  const { open, events, onClose, letterNumber, email, responseStatus } = props;
  const handleClose = (): void => {
    onClose && onClose();
  };
  const renderContent = (): ReactNode => {
    switch (responseStatus) {
      case LetterStatusHistoryResponse.SUCCESS:
        return events && <LetterHistoryContent events={events} />;
      case LetterStatusHistoryResponse.LETTER_INITIATIVE:
        return (
          <Box
            sx={{
              padding: (theme: Theme) => theme.spacing(1.5, 2),
              background: (theme: Theme) => theme.colors.grayBackground,
              borderRadius: (theme: Theme) => theme.spacing(1.5),
            }}>
            <Typography whiteSpace={'pre-line'} variant={'body2'}>
              {'Проверка статуса недоступна для инициативных писем.'}
            </Typography>
          </Box>
        );
      case LetterStatusHistoryResponse.LETTER_NOT_FOUND:
        return (
          <Box
            sx={{
              padding: (theme: Theme) => theme.spacing(1.5, 2),
              background: (theme: Theme) => theme.colors.grayBackground,
              borderRadius: (theme: Theme) => theme.spacing(1.5),
            }}>
            <Typography whiteSpace={'pre-line'} variant={'body2'}>
              {`Письмо № ${letterNumber} отправителя\n${email} не найдено.`}
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <ClientDialog
      open={open}
      isMobile={true}
      label={`Письмо № ${letterNumber}`}
      handleCloseButton={handleClose}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(-4),
        paddingBottom: theme.spacing(6 + 3),
      })}>
      <Grid container={true} direction={'column'}>
        <Grid item={true} paddingBottom={3}>
          <Typography
            component={'div'}
            variant={'label1'}
            lineHeight={'20px'}
            letterSpacing={'0.03em'}
            color={(theme: Theme) => theme.colors.grayText}>{`Отправитель: ${email}`}</Typography>
        </Grid>
        <Grid item={true} paddingBottom={4}>
          {renderContent()}
        </Grid>
      </Grid>
      <MobileBar>
        <FixedButton color={'primary'} variant={'contained'} onClick={handleClose}>
          {'Закрыть'}
        </FixedButton>
      </MobileBar>
    </ClientDialog>
  );
};
