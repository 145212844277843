import { Grid, Tooltip } from '@mui/material';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MenuLink } from '@/components/common/MenuLink';
import { ROUTE_DRAFTS, ROUTE_INBOX, ROUTE_OUTBOX } from '@/app/routes';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import React, { FC, useState } from 'react';
import SenderChooseDialog from '@/components/create/SenderChooseDialog';

interface Props {
  inboxCount?: LetterCountDTO;
  outboxCount?: LetterCountDTO;
  senders?: SenderInfoDTO[];
  letterDraftsCount?: number;
  isLoadingSenders?: boolean;
}

export const NavigationBlock: FC<Props> = (props: Props) => {
  const { inboxCount, outboxCount, senders, isLoadingSenders, letterDraftsCount } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<'letter' | 'answer'>('letter');
  const handleClick = async (type: 'letter' | 'answer'): Promise<void> => {
    setType(type);
    setOpen(true);
  };
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <Grid container={true} direction={'column'} spacing={1}>
          <Grid item={true}>
            <MenuLink
              label={'Входящие'}
              size={'small'}
              value={inboxCount?.unreadCount}
              total={inboxCount?.totalCount}
              to={ROUTE_INBOX}
            />
          </Grid>
          <Grid item={true}>
            <MenuLink
              label={'Исходящие'}
              size={'small'}
              value={outboxCount?.unreadCount}
              total={outboxCount?.totalCount}
              to={ROUTE_OUTBOX}
            />
          </Grid>
          <Grid item={true}>
            <MenuLink label={'Черновики'} size={'small'} total={letterDraftsCount} to={ROUTE_DRAFTS} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction={'column'} spacing={1}>
          <Grid item={true}>
            <Tooltip
              title={'Написать письмо лицу, находящемуся в\u00A0исправительном учреждении.'}
              disableInteractive={true}>
              <div>
                <LoadingButton
                  disabled={isLoadingSenders}
                  isLoading={type === 'letter' && isLoadingSenders}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth={true}
                  size={'small'}
                  onClick={() => handleClick('letter')}>
                  {'Написать письмо'}
                </LoadingButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid item={true}>
            <Tooltip
              title={'При заказе ответа заключенный получит бланк для написания письма.'}
              disableInteractive={true}>
              <div>
                <LoadingButton
                  disabled={isLoadingSenders}
                  isLoading={type === 'answer' && isLoadingSenders}
                  variant={'outlined'}
                  color={'primary'}
                  fullWidth={true}
                  size={'small'}
                  onClick={() => handleClick('answer')}>
                  {'Заказать ответ'}
                </LoadingButton>
              </div>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <SenderChooseDialog
        open={open}
        type={type}
        isMobile={false}
        senders={senders}
        handleCloseButton={() => setOpen(false)}
        onClose={() => setOpen(false)}
      />
    </Grid>
  );
};
