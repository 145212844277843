import { BankPaymentRadioSet } from '@/components/common/payment/BankPaymentRadioSet';
import { FormControl, RadioGroup, RadioGroupProps } from '@mui/material';
import { PaymentSettingsDTO } from '@/types/PaymentSettingsDTO';
import React, { FC } from 'react';

interface Props extends RadioGroupProps {
  settings?: PaymentSettingsDTO;
}

export const CardPaymentRadioGroup: FC<Props> = (props: Props) => {
  const { value, settings, ...other } = props;
  return (
    <FormControl component={'fieldset'}>
      <RadioGroup value={value} {...other}>
        <BankPaymentRadioSet currentValue={value} settings={settings} />
      </RadioGroup>
    </FormControl>
  );
};
