import { Theme } from '@mui/material/styles';
import { Tooltip, Typography } from '@mui/material';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import React, { FC } from 'react';

export const UnauthorizedPaymentBalanceTooltip: FC = () => {
  return (
    <Tooltip
      sx={{
        maxWidth: '260px',
        '& .MuiTooltip-tooltip': { padding: '8px', whiteSpace: 'pre-line' },
      }}
      title={
        <Typography variant={'body3'}>
          {'Преимущества использования личного кабинета:'}
          <ul
            style={{
              padding: '0',
              margin: '0',
              paddingLeft: '20px',
              marginTop: '8px',
            }}>
            <li>{'Можно отслеживать статусы всех писем в одном месте;'}</li>
            <li>{'Несколько способов оплаты письма.'}</li>
          </ul>
        </Typography>
      }>
      <QuestionMarkOutlinedIcon
        sx={(theme: Theme) => ({
          display: 'block',
          cursor: 'pointer',
          color: theme.colors.blueBackgroundQuestion,
          fontSize: '16px',
          border: '1.5px solid ' + theme.colors.blueBackgroundQuestion,
          borderRadius: '50%',
        })}
      />
    </Tooltip>
  );
};
