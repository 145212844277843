import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ArrowIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M18.8602 3.86003L21.6502 6.65003C21.8402 6.84003 21.8402 7.16003 21.6402 7.35003L18.8502 10.14C18.5402 10.46 18.0002 10.24 18.0002 9.79003V8.00003H4.00024C3.45024 8.00003 3.00024 7.55003 3.00024 7.00003C3.00024 6.45003 3.45024 6.00003 4.00024 6.00003H18.0002V4.21003C18.0002 3.76003 18.5402 3.54003 18.8602 3.86003ZM6.00026 16H20.0003C20.5503 16 21.0003 16.45 21.0003 17C21.0003 17.55 20.5503 18 20.0003 18H6.00026V19.79C6.00026 20.24 5.46026 20.46 5.14026 20.14L2.35026 17.35C2.16026 17.16 2.16026 16.84 2.36026 16.65L5.15026 13.86C5.46026 13.54 6.00026 13.76 6.00026 14.21V16Z'
      }
    />
  </SvgIcon>
);
