import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const GreenInfoIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM9 10C9 9.44772 9.44771 9 10 9C10.5523 9 11 9.44772 11 10V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V10ZM9.5 5C9.22386 5 9 5.22386 9 5.5V6.5C9 6.77614 9.22386 7 9.5 7H10.5C10.7761 7 11 6.77614 11 6.5V5.5C11 5.22386 10.7761 5 10.5 5H9.5Z'
      }
      fill={'#009F53'}
    />
  </SvgIcon>
);
