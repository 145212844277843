import { Box, Skeleton } from '@mui/material';
import { EventDTO } from '@/types/event/EventDTO';
import { EventItem } from '@/components/home/event/EventItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';
import 'swiper/css';

interface Props {
  events: EventDTO[];
  isLoading?: boolean;
}

export const EventCarousel: FC<Props> = (props: Props) => {
  const { events, isLoading } = props;
  return (
    <Swiper pagination={false}>
      {(isLoading ? Array.from(new Array(3)) : events).map((event: EventDTO, index: number) => (
        <SwiperSlide key={index}>
          <Box display={'flex'} height={'100%'} px={(theme: Theme) => theme.spacing(2)}>
            {isLoading ? (
              <Skeleton variant={'rectangular'} height={'100px'} width={'100%'} sx={{ borderRadius: '12px' }} />
            ) : (
              <EventItem event={event} index={index} total={props.events.length} />
            )}
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
