import * as Yup from 'yup';
import { Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import {
  MESSAGE_MIN_PASSWORD_LENGTH,
  MESSAGE_PASSWORD_CAPITAL_LETTER,
  MESSAGE_PASSWORD_NOT_EQUALS,
  MESSAGE_PASSWORD_NUMBER,
  MESSAGE_REQUIRED,
  MESSAGE_WRONG_OLD_PASSWORD,
  passwordCapitalLetterRegExp,
  passwordNumberRegExp,
} from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PasswordChangStatus, PasswordChangeResponseDTO } from '@/types/user/PasswordChangeResponseDTO';
import { PasswordRestoreDTO } from '@/types/user/PasswordRestoreDTO';
import { ROUTE_AUTHENTICATION, ROUTE_MAIN } from '@/app/routes';
import { SuccessIcon } from '@/components/common/SuccessIcon';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useRestorePasswordMutation } from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, MouseEvent, useEffect, useState } from 'react';

const validationSchema = Yup.object({
  newPassword: Yup.string()
    .required(MESSAGE_REQUIRED)
    .matches(passwordNumberRegExp, MESSAGE_PASSWORD_NUMBER)
    .matches(passwordCapitalLetterRegExp, MESSAGE_PASSWORD_CAPITAL_LETTER)
    .min(8, MESSAGE_MIN_PASSWORD_LENGTH),
});

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

export const RestoreNewPasswordForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const initialValues: FormValues = {
    newPassword: '',
    confirmPassword: '',
  };
  const [passwordRestore, { isLoading: isLoading }] = useRestorePasswordMutation();
  const [uuid, setUuid] = useState<string>('');
  const [isRestore, setIsRestore] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const handleShowNewPasswordClick = (): void => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPasswordClick = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const uuid = new URLSearchParams(window.location.search).get('uuid');
    const restore = new URLSearchParams(window.location.search).get('isRestore');
    if (uuid && uuid?.length == 36) {
      setUuid(uuid);
    } else {
      history.replace(ROUTE_MAIN);
    }
    if (restore) {
      setIsRestore(true);
    }
  }, []);

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (values.confirmPassword !== values.newPassword) {
      helpers.setFieldError('newPassword', MESSAGE_PASSWORD_NOT_EQUALS);
    } else {
      const dto: PasswordRestoreDTO = {
        newPassword: values.newPassword,
        uuid: uuid,
      };
      await passwordRestore(dto)
        .unwrap()
        .then((response: PasswordChangeResponseDTO) => {
          switch (response.status) {
            case PasswordChangStatus.WRONG_OLD_PASSWORD:
              helpers.setFieldError('oldPassword', MESSAGE_WRONG_OLD_PASSWORD);
              break;
            case PasswordChangStatus.MIN_LENGTH:
              helpers.setFieldError('newPassword', MESSAGE_MIN_PASSWORD_LENGTH);
              break;
            case PasswordChangStatus.WITHOUT_NUMBERS:
              helpers.setFieldError('newPassword', MESSAGE_PASSWORD_NUMBER);
              break;
            case PasswordChangStatus.WITHOUT_CAPITAL_LETTER:
              helpers.setFieldError('newPassword', MESSAGE_PASSWORD_CAPITAL_LETTER);
              break;
            case PasswordChangStatus.CHANGED:
              setIsChanged(true);
          }
        })
        .catch(() => {
          enqueueSnackbar('Код восстановления не найден', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Fragment>
      {isChanged ? (
        <Grid container={true} direction={'column'} alignItems={'center'} spacing={4} mt={5} mb={8}>
          <Grid item={true}>
            <Grid container={true} direction={'column'} alignItems={'center'} spacing={2}>
              <Grid item={true}>
                <SuccessIcon />
              </Grid>
              <Grid item={true}>
                <Typography variant={'h2'} textAlign={'center'}>
                  {isRestore ? 'Вы успешно восстановили пароль' : 'Вы успешно изменили пароль'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => history.replace(ROUTE_AUTHENTICATION)}
              sx={{ minWidth: (theme: Theme) => theme.spacing(33) }}>
              {'Перейти в личный кабинет'}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={handleSubmit}>
          {(formikProps: FormikProps<FormValues>) => {
            const { values, touched, errors, setFieldValue, setFieldTouched, submitForm } = formikProps;
            const handleSetFieldValue = (field: string, value: unknown) => {
              setFieldTouched(field, true, false);
              setFieldValue(field, value, true);
            };
            return (
              <Grid container={true} direction={'column'} alignContent={'center'}>
                <Grid item={true} textAlign={'center'} mb={2}>
                  <Typography variant={'h1'}>{isRestore ? 'Восстановление пароля' : 'Изменение пароля'}</Typography>
                </Grid>
                <Grid item={true} textAlign={'center'}>
                  <Typography fontSize={'14px'} lineHeight={'20px'} whiteSpace={'pre-line'}>
                    {
                      'Пароль должен содержать минимум 8 символов, в нем должны присутствовать\nхотя бы одна цифра и одна заглавная буква.'
                    }
                  </Typography>
                </Grid>
                <Grid item={true} mt={7} mb={7}>
                  <Grid container={true} direction={'column'}>
                    <Grid item={true}>
                      <OutlinedField
                        label={'Новый пароль'}
                        name={'newPassword'}
                        disabled={isLoading}
                        value={values.newPassword ? String(values.newPassword) : ''}
                        error={touched.newPassword && Boolean(errors.newPassword)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue('newPassword', e.target.value);
                        }}
                        fullWidth={true}
                        type={showNewPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position={'end'}>
                            <IconButton
                              aria-label={'toggle password visibility'}
                              size={'dense'}
                              edge={'end'}
                              sx={{ padding: 0, margin: 0 }}
                              onClick={handleShowNewPasswordClick}
                              onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                              }}>
                              {showNewPassword ? (
                                <EyeIcon
                                  sx={{
                                    width: (theme: Theme) => theme.spacing(4),
                                    height: (theme: Theme) => theme.spacing(4),
                                  }}
                                />
                              ) : (
                                <EyeCrossedIcon
                                  sx={{
                                    width: (theme: Theme) => theme.spacing(4),
                                    height: (theme: Theme) => theme.spacing(4),
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item={true} mt={6}>
                      <OutlinedField
                        fullWidth={true}
                        label={'Подтвердите новый пароль'}
                        name={'confirmPassword'}
                        disabled={isLoading}
                        value={values.confirmPassword ? String(values.confirmPassword) : ''}
                        error={touched.newPassword && Boolean(errors.newPassword)}
                        helperText={touched.newPassword ? errors.newPassword : ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleSetFieldValue('confirmPassword', e.target.value);
                        }}
                        type={showConfirmPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position={'end'}>
                            <IconButton
                              aria-label={'toggle password visibility'}
                              size={'dense'}
                              edge={'end'}
                              sx={{ padding: 0, margin: 0 }}
                              onClick={handleShowConfirmPasswordClick}
                              onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault();
                              }}>
                              {showConfirmPassword ? (
                                <EyeIcon
                                  sx={{
                                    width: (theme: Theme) => theme.spacing(4),
                                    height: (theme: Theme) => theme.spacing(4),
                                  }}
                                />
                              ) : (
                                <EyeCrossedIcon
                                  sx={{
                                    width: (theme: Theme) => theme.spacing(4),
                                    height: (theme: Theme) => theme.spacing(4),
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>
                  <Button variant={'contained'} fullWidth={true} disabled={isLoading} onClick={submitForm}>
                    {'Сохранить'}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
};
