import { Grid } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@/components/common/button/LoadingButton';
import { PolicyStatementCheckBox } from '@/components/common/PolicyStatementCheckBox';
import React, { ChangeEvent, FC } from 'react';

export interface PolicyLoadingButtonProps extends LoadingButtonProps {
  policyChecked: boolean;
  onPolicyChange: (policyChecked: boolean) => void;
}

export const PolicyLoadingButton: FC<PolicyLoadingButtonProps> = (props: PolicyLoadingButtonProps) => {
  const { policyChecked, onPolicyChange, disabled, ...other } = props;

  return (
    <Grid container={true} direction={'column'} spacing={1}>
      <Grid item={true}>
        <LoadingButton disabled={disabled || !policyChecked} {...other} />
      </Grid>
      <Grid item={true}>
        <PolicyStatementCheckBox
          checked={policyChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onPolicyChange(e.target.checked)}
        />
      </Grid>
    </Grid>
  );
};
