import { Box, Dialog, Grid, IconButton, useMediaQuery } from '@mui/material';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { ImageViewControls } from '@/components/view/image/ImageViewControls';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode, useState } from 'react';

interface Props {
  open: boolean;
  title?: ReactNode;
  content: string;
  onClose?: () => void;
}

const zoomStep = 0.25;
const maxScale = 10;
const minScale = 0.25;
const defaultScale = 1;
const defaultRotate = 0;

export const ImageViewDialog: FC<Props> = (props: Props) => {
  const { open, title, content, onClose } = props;
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const [scale, setScale] = useState<number>(defaultScale);
  const [rotate, setRotate] = useState<number>(defaultRotate);
  const handleTurnRight = (): void => {
    setRotate(rotate === 270 ? 0 : rotate + 90);
  };
  const handleTurnLeft = (): void => {
    setRotate(rotate === -270 ? 0 : rotate - 90);
  };
  const handleZoomIn = (): void => {
    const newScale: number = scale + zoomStep;
    setScale(newScale <= maxScale ? newScale : maxScale);
  };
  const handleZoomOut = (): void => {
    const newScale: number = scale - zoomStep;
    setScale(newScale >= minScale ? newScale : minScale);
  };
  const getMatrix = (scale: number, rotate: number): string => {
    switch (rotate) {
      case -270: {
        return `matrix(0, ${scale}, -${scale}, 0, 0, 0) translate(0, -100%)`;
      }
      case -180: {
        return `matrix(-${scale}, 0, 0, -${scale}, 0, 0) translate(-100%, -100%)`;
      }
      case -90: {
        return `matrix(0, -${scale}, ${scale}, 0, 0, 0) translate(-100%, 0)`;
      }
      case 0: {
        return `matrix(${scale}, 0, 0, ${scale}, 0, 0) translate(0, 0)`;
      }
      case 90: {
        return `matrix(0, ${scale}, -${scale}, 0, 0, 0) translate(0, -100%)`;
      }
      case 180: {
        return `matrix(-${scale}, 0, 0, -${scale}, 0, 0) translate(-100%, -100%)`;
      }
      case 270: {
        return `matrix(0, -${scale}, ${scale}, 0, 0, 0) translate(-100%, 0)`;
      }
      default: {
        return `matrix(${scale}, 0, 0, ${scale}, 0, 0) translate(0, 0)`;
      }
    }
  };
  return (
    <Dialog
      open={open}
      fullScreen={isMobile}
      PaperProps={{
        sx: (theme: Theme) => ({
          overflow: 'hidden',
          [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(5),
            width: '100%',
            maxWidth: `calc(100% - ${theme.spacing(10)})`,
            height: '100%',
            maxHeight: `calc(100% - ${theme.spacing(10)})`,
          },
        }),
      }}>
      <Grid container={true} direction={'column'} height={'100%'} flexWrap={'nowrap'}>
        <Grid item={true}>
          <Box padding={(theme: Theme) => theme.spacing(2)}>
            <Grid
              container={true}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}
              wrap={'nowrap'}
              spacing={2}>
              <Grid item={true}>{title}</Grid>
              <Grid item={true}>
                {onClose && (
                  <IconButton
                    disableRipple={true}
                    sx={(theme: Theme) => ({
                      display: 'flex',
                      padding: theme.spacing(0.375),
                    })}
                    onClick={onClose}>
                    <CloseIcon
                      sx={{
                        color: (theme: Theme) => theme.colors.textColor,
                        width: (theme: Theme) => theme.spacing(2.25),
                        height: (theme: Theme) => theme.spacing(2.25),
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item={true} xs={true} position={'relative'}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              overflow: 'auto',
            }}>
            <img
              src={`data:image/jpeg;base64,${content}`}
              alt={''}
              style={{
                objectFit: 'contain',
                transformOrigin: 'top left',
                transform: getMatrix(scale, rotate),
                transition: 'all 500ms ease-in-out',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          left: (theme: Theme) => theme.spacing(4),
          right: (theme: Theme) => theme.spacing(4),
          bottom: (theme: Theme) => theme.spacing(4),
        }}>
        <ImageViewControls
          canZoomIn={scale < maxScale}
          canZoomOut={scale > minScale}
          onTurnRight={handleTurnRight}
          onTurnLeft={handleTurnLeft}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
        />
      </Box>
    </Dialog>
  );
};
