import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const OknoIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    sx={{ width: '24px', height: '24px', display: 'flex', fill: 'none' }}
    {...props}>
    <path
      d={
        'M18.3261 21C16.5368 22.2601 14.3548 23 12 23C9.64517 23 7.46318 22.2601 5.67383 21M18.3261 3C16.5368 1.73994 14.3548 1 12 1C9.64517 1 7.46318 1.73994 5.67383 3'
      }
      strokeLinecap={'round'}
    />
    <path d={'M11.5 11.2812L4 8V17L11.5 20.2812L19 17V8L11.5 11.2812Z'} />
    <path d={'M11.5 11.5V20.5'} />
    <path d={'M3.70776 7.69331L11.4999 4L19.2821 7.68411'} />
    <path d={'M8 5.5L15.5 9.5'} />
  </SvgIcon>
);
