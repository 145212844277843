import { Box, Button, ButtonProps, Grid, Typography } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { Theme } from '@mui/material/styles';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  label: string;
  smallLabel: string;
  route?: string;
  labelVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  smallLabelVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  active?: boolean;
  orange?: boolean;
}

export const SmallHeaderButton: FC<Props> = (props: Props) => {
  const { route, href, label, smallLabel, labelVariant, smallLabelVariant, active, orange, children, ...other } = props;
  const history = useHistory();

  const handleNavigation = (): void => {
    if (route) {
      history.push(route);
    } else if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <Button
      variant={'contained'}
      color={active ? 'primary' : 'secondary'}
      onClick={handleNavigation}
      sx={(theme: Theme) => ({
        border: orange ? `2px solid ${theme.colors.orange2}` : 'none',
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1, 1.5, 1.5),
        width: '100%',
        height: '100%',
        textAlign: 'left',
        '& .children': {
          color: orange ? theme.colors.orange2 : 'primary',
        },
        '&:hover': {
          color: orange ? 'white' : 'primary',
          backgroundColor: orange ? theme.colors.orange2 : theme.colors.darkGrayBackground,
          '& .children': {
            color: 'white',
          },
        },
        '@media (hover: none)': {
          color: `${active ? 'white' : theme.colors.textColor} !important`,
          backgroundColor: `${active ? 'primary' : theme.colors.grayBackground} !important`,
          '& .children': {
            color: `${orange ? theme.colors.orange2 : 'primary'} !important`,
          },
        },
      })}
      {...other}>
      <Box position={'relative'} width={'100%'} height={'100%'}>
        <Grid container={true} direction={'column'} height={'100%'}>
          <Grid item={true}>
            <Typography variant={labelVariant || 'h5'}>{label}</Typography>
          </Grid>
          <Grid item={true} xs={true}>
            <Grid
              container={true}
              direction={'row'}
              justifyContent={'space-between'}
              spacing={1}
              wrap={'nowrap'}
              height={'calc(100% + 8px)'}>
              <Grid item={true}>
                <Typography variant={smallLabelVariant || 'h6'}>{smallLabel}</Typography>
              </Grid>
              <Grid item={true} display={'flex'} alignSelf={'end'} className={'children'}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Button>
  );
};
