import { CreateFormValues } from '@/types/create/CreateFormValues';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PhoneNumberField } from '@/components/common/field/PhoneNumberField';
import { RootState } from '@/app/store';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  formikProps: FormikProps<CreateFormValues>;
  handleSetFieldValue: (field: string, value: unknown, isErrorField: boolean) => void;
  handleValidEmail: (valid: boolean) => void;
  isMobile?: boolean;
  disabled?: boolean;
}

export const AuthorForm: FC<Props> = (props: Props) => {
  const { formikProps, handleSetFieldValue, handleValidEmail, isMobile, disabled } = props;
  const { values, errors, setFieldError } = formikProps;
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const userReducer = useAppSelector((state: RootState) => {
    return state.auth;
  });

  const validEmail = () => {
    if (
      authUser &&
      (values.authorEmail != userReducer.user?.email || values.authorConfirmEmail != userReducer.user?.email)
    ) {
      setFieldError('authorEmail', 'Почта не привязана');
      setFieldError('authorConfirmEmail', 'Почта не привязана');
      handleValidEmail(true);
    } else {
      handleValidEmail(false);
    }
  };

  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} marginBottom={'52px'}>
        <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 6 : 3}>
          <Grid item={true} xs={isMobile ? 12 : 9}>
            <OutlinedField
              fullWidth={true}
              label={'ФИО'}
              name={'fullName'}
              disabled={disabled}
              value={values.authorFullName ? String(values.authorFullName) : ''}
              error={Boolean(errors.authorFullName)}
              helperText={errors.authorFullName}
              onKeyPress={(event) => {
                if (values.authorFullName.length === 0 && event.key === ' ') {
                  event.preventDefault();
                }
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('authorFullName', e.target.value, Boolean(errors.authorFullName));
              }}
            />
          </Grid>
          <Grid item={true} xs={isMobile ? 12 : 3}>
            <PhoneNumberField
              fullWidth={true}
              name={'authorPhoneNumber'}
              label={'Телефон'}
              disabled={disabled}
              value={values.authorPhoneNumber ? String(values.authorPhoneNumber) : ''}
              error={Boolean(errors.authorPhoneNumber)}
              helperText={errors.authorPhoneNumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue(e.target.name, e.target.value, Boolean(errors.authorPhoneNumber));
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 6 : 3}>
          <Grid item={true} xs={true}>
            <OutlinedField
              fullWidth={true}
              label={'Электронная почта'}
              name={'authorEmail'}
              disabled={disabled}
              value={values.authorEmail ? String(values.authorEmail) : ''}
              error={Boolean(errors.authorEmail)}
              helperText={errors.authorEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('authorEmail', e.target.value, Boolean(errors.authorEmail));
              }}
              onBlur={() => {
                if (!errors.authorEmail && !errors.authorConfirmEmail) {
                  validEmail();
                }
              }}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <OutlinedField
              fullWidth={true}
              label={'Подтвердите электронную почту'}
              name={'authorConfirmEmail'}
              disabled={disabled}
              value={values.authorConfirmEmail ? String(values.authorConfirmEmail) : ''}
              error={Boolean(errors.authorConfirmEmail)}
              helperText={errors.authorConfirmEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('authorConfirmEmail', e.target.value, Boolean(errors.authorConfirmEmail));
              }}
              onBlur={() => {
                if (!errors.authorEmail && !errors.authorConfirmEmail) {
                  validEmail();
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
