import { CardCodeField } from '@/components/card/CardCodeField';
import { CardDTO } from '@/types/card/CardDTO';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { formatCurrency } from '@/utils/string-utils';
import { useDeleteCardMutation } from '@/services/api/cardApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';

interface Props {
  open: boolean;
  card: CardDTO;
  isMobile?: boolean;
  onClose?: () => void;
}

export const CardDeleteDialog: FC<Props> = (props: Props) => {
  const { open, card, isMobile, onClose } = props;
  const [deleteCard, { isLoading }] = useDeleteCardMutation();
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [code, setCode] = useState<string | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = (): void => {
    if (onClose) {
      onClose();
      setSuccess(false);
    }
  };
  const handleDeleteCard = async (): Promise<void> => {
    await deleteCard({ cardId: card.id, code: code || '' })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Ф-карта удалена', { variant: 'success' });
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar('Ошибка удаления Ф-карты', { variant: 'error' });
      });
  };
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={`Удаление карты № ${card.ourNumber}`}
      PaperProps={{ sx: { width: isMobile ? 'auto' : '500px' } }}
      handleCloseButton={handleClose}>
      <Grid container={true} direction={'column'}>
        <Grid item={true} mb={2}>
          <Typography variant={'body1'}>{'Введите пин-код, чтобы удалить Ф-карту:'}</Typography>
        </Grid>
        <Grid item={true} mb={4}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12} sm={6}>
              <CardCodeField
                cardNumber={card.ourNumber}
                placeholder={'Введите пин-код'}
                onSuccess={(code: string) => {
                  setSuccess(true);
                  setCode(code);
                }}
                onError={(code: string) => {
                  setSuccess(false);
                  setCode(code);
                }}
              />
            </Grid>
            {isSuccess && (
              <Grid item={true} xs={12} sm={5}>
                <LoadingButton
                  isLoading={isLoading}
                  fullWidth={true}
                  variant={'outlined'}
                  color={'error'}
                  onClick={() => handleDeleteCard()}>
                  {'Удалить'}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item={true}>
          <Typography variant={'body1'}>
            <Typography fontWeight={600} display={'inline'} component={'span'}>
              {formatCurrency(card.balance)}
            </Typography>
            {' будут переведены с карты на баланс личного кабинета.'}
          </Typography>
        </Grid>
      </Grid>
    </ClientDialog>
  );
};
