import { AppDispatch } from '@/app/store';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Pagination, useMediaQuery } from '@mui/material';
import { InboxList } from '@/components/home/inbox/InboxList';
import { InboxTable } from '@/components/home/inbox/InboxTable';
import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { getCreateLetterReplyRoute, getInboxLetterRoute } from '@/app/routes';
import { getCurrentUser } from '@/services/authSlice';
import {
  getLetterInboxRequest,
  setLetterInboxRequestPageNumber,
  setLetterInboxRequestPageSize,
  setLetterInboxSearchRequestAddressee,
  setLetterInboxSortRequest,
} from '@/services/letter/letterInboxSlice';
import { setCreateInfoFromDTO } from '@/services/createFormSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetLettersQuery, useSetLetterReadMutation } from '@/services/api/letterApiSlice';
import { useGetSenderToReplyMutation } from '@/services/api/createFormApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

export const InboxBlock: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const request: LetterRequestDTO = useAppSelector(getLetterInboxRequest);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [search, setSearch] = useState<string>('');
  const { data, isFetching, isLoading } = useGetLettersQuery({ ...request, userId: authUser?.id }, { skip: !authUser });
  const [getSender] = useGetSenderToReplyMutation();
  useEffect(() => {
    dispatch(setLetterInboxRequestPageSize(isMobile ? 5 : 8));
  }, [dispatch, isMobile]);
  useEffect(() => {
    setSearch(request.searchRequest.addressee || '');
  }, [request.searchRequest.addressee]);
  const handlePage = (page: number): void => {
    dispatch(setLetterInboxRequestPageNumber(page - 1));
  };
  const handleAddressee = useDebounce<string>((addressee: string): void => {
    dispatch(setLetterInboxSearchRequestAddressee(addressee));
    dispatch(setLetterInboxRequestPageNumber(0));
  }, 500);
  const handleSearch = (addressee: string): void => {
    setSearch(addressee);
    handleAddressee(addressee);
  };
  const handleSort = (sortRequest: SortRequestDTO): void => {
    dispatch(setLetterInboxSortRequest(sortRequest));
  };
  const [readLetter] = useSetLetterReadMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleLetterClick = async (letter: LetterTableDTO): Promise<void> => {
    history.push(getInboxLetterRoute(letter.id));
    if (letter.unread) {
      await readLetter(letter.id)
        .unwrap()
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data.message, { variant: 'error' });
        });
    }
  };
  const handleReplyClick = (letter: LetterTableDTO): void => {
    getSender(letter.id)
      .unwrap()
      .then((dto: SenderInfoDTO) => {
        dispatch(setCreateInfoFromDTO(dto));
      })
      .then(() => history.push(getCreateLetterReplyRoute(letter.id)));
  };
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <OutlinedField
          fullWidth={true}
          size={'small'}
          placeholder={'Поиск по адресату'}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
          onClear={() => {
            handleSearch('');
          }}
        />
      </Grid>
      <Grid item={true}>
        {isMobile ? (
          <InboxList
            isFetching={isFetching}
            isLoading={isLoading}
            letters={data?.content || []}
            onLetterClick={handleLetterClick}
          />
        ) : (
          <InboxTable
            isFetching={isFetching}
            isLoading={isLoading}
            letters={data?.content || []}
            sortRequest={
              request.pageRequest.sortRequest || {
                properties: ['answer.processedAt', 'answerDate'],
                direction: 'DESC',
              }
            }
            onSort={handleSort}
            onLetterClick={handleLetterClick}
            onReplyClick={handleReplyClick}
          />
        )}
      </Grid>
      <Grid item={true} display={'flex'} justifyContent={isMobile ? 'center' : 'right'}>
        <Pagination
          count={data?.totalPages || 1}
          page={(request.pageRequest?.pageNumber || 0) + 1}
          onChange={(e: ChangeEvent<unknown>, page: number) => {
            handlePage(page);
          }}
        />
      </Grid>
    </Grid>
  );
};
