import { Box, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { LetterDraftIdDTO } from '@/types/letterDraft/LetterDraftIdDTO';
import { LetterDraftListItemDTO } from '@/types/letterDraft/LetterDraftListItemDTO';
import { Theme } from '@mui/material/styles';
import { TrashIcon } from '@/app/icons/profile/TrashIcon';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartDateTimeString } from '@/utils/date-utils';
import { useDeleteLetterDraftMutation } from '@/services/api/letterDraftApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  drafts: LetterDraftListItemDTO[];
  onDraftClick: (draft: LetterDraftListItemDTO) => void;
}

export const DraftsList: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, drafts, onDraftClick } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDraft, { isLoading: isLetterDraftDeleting }] = useDeleteLetterDraftMutation();
  const loading: boolean = isFetching || isLoading || isLetterDraftDeleting;
  const handleRowClick = (draft: LetterDraftListItemDTO) => (): void => {
    if (draft) {
      onDraftClick(draft);
    }
  };
  const handleClickDelete = async (id: number) => {
    const dto: LetterDraftIdDTO = {
      id: id,
    };
    await deleteDraft(dto)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Черновик удален', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ошибка удаления черновика', { variant: 'error' });
      });
  };
  return (
    <Grid container={true} direction={'column'}>
      {(loading ? Array.from(new Array(5)) : drafts).map((draft: LetterDraftListItemDTO, index: number) => (
        <Grid key={loading ? index : draft.id} item={true}>
          <Box
            position={'relative'}
            borderBottom={'1px solid'}
            borderColor={(theme: Theme) => theme.colors.borderColor}
            paddingY={(theme: Theme) => theme.spacing(1.5)}>
            <Grid container={true} direction={'column'} spacing={1} wrap={'nowrap'} onClick={handleRowClick(draft)}>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'14px'} />
                ) : (
                  <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
                    <Typography variant={'body2'} lineHeight={1} whiteSpace={'nowrap'} fontWeight={500}>
                      {formatSmartDateTimeString(draft.createdAt)}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography
                    variant={'body2'}
                    color={(theme: Theme) => theme.colors.grayText}
                    whiteSpace={'nowrap'}
                    fontWeight={400}>
                    {formatPrisonerShortName(draft.prisonerLastName, draft.prisonerFirstName)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {!loading && (
              <IconButton
                size={'dense'}
                sx={(theme: Theme) => ({
                  position: 'absolute',
                  right: 0,
                  top: 'calc(50% - 12px)',
                  color: theme.colors.inputBorder,
                })}
                onClick={() => handleClickDelete(draft.id)}>
                <TrashIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
