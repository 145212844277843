import { Button, Divider, Grid, Theme, Typography } from '@mui/material';
import { ErrorPage } from '@/components/main/error/ErrorPage';
import { ROUTE_CHECK_LETTER_STATUS, ROUTE_MAIN_CREATE_ANSWER, ROUTE_MAIN_CREATE_LETTER } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { TypographyLink } from '@/components/common/TypographyLink';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

export const LetterPayedErrorPage: FC = () => {
  const history = useHistory();

  const handleStatusCheckClick = (): void => {
    history.push(ROUTE_CHECK_LETTER_STATUS);
  };

  return (
    <ErrorPage label={'Ошибка оплаты письма'}>
      <Grid container={true} direction={'column'} wrap={'nowrap'}>
        <Grid item={true} sx={{ maxWidth: '660px !important' }} mb={7}>
          <Typography variant={'body1'} mb={3}>
            {'Кажется, вы пытаетесь оплатить письмо, которое уже оплачено.'}
          </Typography>
          <Typography variant={'body1'}>
            {
              'Если вы пытаетесь оплатить неоплаченное письмо, то просим вас убедиться, что с момента формирования письма прошло менее 30 дней. Если прошло больше, то письмо уже невозможно оплатить, и оно было удалено из базы.'
            }
          </Typography>
        </Grid>
        <Grid item={true}>
          <Grid container={true} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Button
                variant={'contained'}
                onClick={handleStatusCheckClick}
                sx={(theme: Theme) => ({
                  width: '280px',
                  [theme.breakpoints.down('sm')]: {
                    width: '180px',
                  },
                })}>
                {'Проверить статус'}
              </Button>
            </Grid>
            <Grid item={true}>
              <Grid container={true} columnSpacing={2}>
                <Grid item={true}>
                  <RouteLink to={ROUTE_MAIN_CREATE_LETTER}>
                    <TypographyLink>{'Написать другое письмо'}</TypographyLink>
                  </RouteLink>
                </Grid>
                <Grid item={true}>
                  <Divider orientation={'vertical'} />
                </Grid>
                <Grid item={true}>
                  <RouteLink to={ROUTE_MAIN_CREATE_ANSWER}>
                    <TypographyLink>{'Заказать бланк ответа'}</TypographyLink>
                  </RouteLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ErrorPage>
  );
};
