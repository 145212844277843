import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';

export interface LetterOutboxState {
  request: LetterRequestDTO;
}

const initialState: LetterOutboxState = {
  request: {
    searchRequest: {
      status: LetterSimpleStatus.ALL,
    },
    pageRequest: {
      pageNumber: 0,
      pageSize: 8,
      sortRequest: {
        properties: ['sentDate'],
        direction: 'DESC',
      },
    },
  },
};

export const letterOutboxSlice = createSlice({
  name: 'letterOutbox',
  initialState: initialState,
  reducers: {
    setLetterOutboxRequest: (state: LetterOutboxState, action: PayloadAction<LetterRequestDTO>) => {
      state.request = action.payload;
    },
    setLetterOutboxRequestPageNumber: (state: LetterOutboxState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageNumber: action.payload,
        },
      };
    },
    setLetterOutboxRequestPageSize: (state: LetterOutboxState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageSize: action.payload,
        },
      };
    },
    setLetterOutboxSearchRequestAddressee: (state: LetterOutboxState, action: PayloadAction<string>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          addressee: action.payload,
        },
      };
    },
    setLetterOutboxSearchRequestStatus: (state: LetterOutboxState, action: PayloadAction<LetterSimpleStatus>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          status: action.payload,
        },
      };
    },
    setLetterOutboxSortRequest: (state: LetterOutboxState, action: PayloadAction<SortRequestDTO>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          sortRequest: action.payload,
        },
      };
    },
  },
});

export const {
  setLetterOutboxRequestPageNumber,
  setLetterOutboxRequestPageSize,
  setLetterOutboxSearchRequestAddressee,
  setLetterOutboxSearchRequestStatus,
  setLetterOutboxSortRequest,
} = letterOutboxSlice.actions;

export const getLetterOutboxRequest = (state: RootState): LetterRequestDTO => state.letterOutbox.request;

export default letterOutboxSlice.reducer;
