import { Box, Grid, Theme, Typography } from '@mui/material';
import { CreateFeedbackDTO } from '@/types/help/CreateFeedbackDTO';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { ROUTE_CREATE_FEEDBACK_SUCCESS } from '@/app/routes';
import { UserDTO } from '@/types/user/UserDTO';
import { formatUserFullName, isBlank } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { useSendFeedbackMutation } from '@/services/api/helpApiSlice';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';

export const AnswerFeedback: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const {
    data: user,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
  } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const userLoading: boolean = isUserFetching || isUserLoading;
  const [sendFeedback, { isLoading }] = useSendFeedbackMutation();
  const [text, setText] = useState<string>('');
  const userEmail: string | undefined = user?.email;
  const topic = 2;
  const author: string = formatUserFullName(user);
  const question: string = text;
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);
  const handleSubmit = async (): Promise<void> => {
    // TODO: temporary version
    handleReCaptchaVerify().then(async (token) => {
      if (userEmail) {
        const dto: CreateFeedbackDTO = {
          topic: topic,
          email: userEmail,
          author: author,
          question: question,
        };
        await sendFeedback({ dto: dto, reCaptchaToken: token })
          .unwrap()
          .then(() => {
            setText('');
            history.push(ROUTE_CREATE_FEEDBACK_SUCCESS);
          })
          .catch(() =>
            enqueueSnackbar('Ошибка отправки отзыва', {
              variant: 'error',
            })
          );
      }
    });
  };
  return (
    <Box
      sx={(theme: Theme) => ({
        padding: theme.spacing(5, 10.75),
        background: theme.colors.grayBackground,
        borderRadius: '12px',
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3, 2),
        },
      })}>
      <Grid container={true} direction={'column'} spacing={2}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} spacing={1}>
            <Grid item={true}>
              <Typography variant={'h4'} textAlign={'center'}>
                {'Оцените работу сервиса Ф‑письмо'}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                textAlign={'center'}
                whiteSpace={'pre-line'}
                variant={'body1'}
                sx={(theme: Theme) => ({
                  [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(0, 4),
                  },
                })}>
                {
                  'Команда Ф‑письма сделала личный кабинет для вашего удобства. Если у вас есть пожелания по улучшению сервиса, вы можете отправить их нам:'
                }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <OutlinedField
            fullWidth={true}
            disabled={isLoading}
            value={text}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
            multiline={true}
            rows={6}
          />
        </Grid>
        <Grid item={true} display={'flex'} justifyContent={'end'}>
          <LoadingButton
            isLoading={userLoading || isLoading}
            fullWidth={true}
            disabled={isBlank(text) || userLoading || isLoading}
            variant={'contained'}
            onClick={() => handleSubmit()}
            sx={(theme: Theme) => ({
              [theme.breakpoints.up('sm')]: {
                maxWidth: theme.spacing(22.75),
              },
            })}>
            {'Отправить'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
