import { FooterInfo } from '@/components/main/footer/FooterInfo';
import { FooterNavigation } from '@/components/main/footer/FooterNavigation';
import { FooterNavigationMobile } from '@/components/main/footer/FooterNavigationMobile';
import { Grid, Theme, Typography } from '@mui/material';
import React, { FC, Fragment } from 'react';

interface Props {
  isMobile?: boolean;
  isMainPage?: boolean;
}
export const Footer: FC<Props> = (props: Props) => {
  const { isMobile, isMainPage } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      {!isMainPage && (
        <Fragment>
          <Grid item={true} pb={isMobile ? 2 : '6px'}>
            <Typography
              variant={'body2'}
              fontWeight={500}
              align={isMobile ? 'center' : 'left'}
              fontSize={isMobile ? '14px' : '10px'}
              lineHeight={isMobile ? '20px' : '16px'}
              color={(theme: Theme) => theme.colors.secondaryGrayText}>
              {'Другие сервисы'}
            </Typography>
          </Grid>
          <Grid item={true} pb={isMobile ? 4 : 3}>
            {isMobile ? <FooterNavigationMobile /> : <FooterNavigation />}
          </Grid>
        </Fragment>
      )}
      <Grid item={true}>
        <FooterInfo isMobile={isMobile} />
      </Grid>
    </Grid>
  );
};
