import { AmountCardIcon } from '@/app/icons/card/AmountCardIcon';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC, useState } from 'react';

interface Props {
  amount: number;
  currentValue: number;
  countLetters: number;
  amountId: number;
  setAmount: (amount: number) => void;
}

export const CardAmountButton: FC<Props> = (props: Props) => {
  const { amount, countLetters, currentValue, setAmount, amountId } = props;
  const active = currentValue === amountId;
  const [hover, setHover] = useState(false);
  const handleClick = () => {
    setAmount(amountId);
    setHover(false);
  };
  return (
    <Button
      onClick={handleClick}
      color={active ? 'primary' : 'secondary'}
      sx={{ padding: 0, borderRadius: (theme: Theme) => theme.spacing(1.5) }}
      variant={'contained'}>
      <Grid container={true} direction={'column'}>
        <Grid
          item={true}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{
            zIndex: 2,
            textAlign: 'left',
            padding: (theme: Theme) => theme.spacing(2.75, 2),
            marginBottom: (theme: Theme) => theme.spacing(-12),
          }}>
          <Grid container={true} direction={'column'}>
            <Grid item={true}>
              <Typography variant={'h1'}>{formatCurrency(amount, 0)}</Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant={'body2'}>{`${countLetters} писем`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <AmountCardIcon
            isHoverColor={hover}
            primary={active}
            sx={{
              display: 'flex',
              width: (theme: Theme) => theme.spacing(20),
              height: (theme: Theme) => theme.spacing(12),
              color: (theme: Theme) =>
                active
                  ? hover
                    ? theme.colors.primaryDark
                    : theme.colors.primary
                  : hover
                  ? theme.colors.darkGrayBackground
                  : theme.colors.grayBackground,
            }}
          />
        </Grid>
      </Grid>
    </Button>
  );
};
