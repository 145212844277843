import * as Yup from 'yup';
import { ChangeDepartmentDTO } from '@/types/letter/ChangeDepartmentDTO';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { DepartmentChangeSuccessDialog } from '@/components/letter/department/DepartmentChangeSuccessDialog';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Form, Formik, FormikProps, getIn } from 'formik';
import { Grid, MenuItem } from '@mui/material';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { LetterStatusDTO } from '@/types/letter/LetterStatusDTO';
import { MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { PageHeader } from '@/components/common/PageHeader';
import { PolicyStatementCheckBox } from '@/components/common/PolicyStatementCheckBox';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { RootState } from '@/app/store';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';
import { getEmail, getLetterNumber, setStatus } from '@/services/departmentRedirectFormSlice';
import { regionDTOSchema } from '@/validation/regionDTOSchema';
import { setRegionAndDepartment } from '@/services/createFormSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useChangeDepartmentMutation } from '@/services/api/letterApiSlice';
import { useGetRegionsQuery } from '@/services/api/createFormApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from 'react';

interface FormValues {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
}

const validationSchema = Yup.object({
  region: regionDTOSchema,
  department: departmentDTOSchema,
});

export const DepartmentChangeFormDepartment: FC<CreateFormProps> = (props: CreateFormProps) => {
  const { tittle, nextFormPath, previousFormPath, previousFormLabel, isMobile, mobileTittle } = props;
  const departmentRedirectFormReducer = useAppSelector((state: RootState) => {
    return state.departmentRedirectForm;
  });
  const { data: regions, isFetching } = useGetRegionsQuery(null, { refetchOnMountOrArgChange: true });
  const initialValues: FormValues = {
    region: departmentRedirectFormReducer.region || null,
    department: departmentRedirectFormReducer.department || null,
  };
  const [changeDepartment, { isLoading: isDepartmentChanging }] = useChangeDepartmentMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const letterNumber = useAppSelector(getLetterNumber);
  const email = useAppSelector(getEmail);
  const resultDTO = departmentRedirectFormReducer.status;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    handleReCaptchaVerify().then(async (token) => {
      dispatch(setStatus({ status: null }));
      const dto: ChangeDepartmentDTO = {
        letterNumber: letterNumber,
        email: email,
        departmentId: values.department?.id,
      };
      dispatch(
        setRegionAndDepartment({
          region: values.region,
          department: values.department,
        })
      );
      await changeDepartment({ dto: dto, reCaptchaToken: token })
        .unwrap()
        .then((dto: LetterStatusDTO) => {
          dispatch(
            setStatus({
              status: dto,
            })
          );
          if (nextFormPath && !dto.error) {
            setDialogOpen(true);
          }
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    });
  };
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const departmentId: string | null = params.get('departmentId');
    if (regions && departmentId) {
      const region: RegionDTO | undefined = regions?.find((region: RegionDTO) => {
        return region?.departments?.some((department: DepartmentDTO) => {
          return department.id === parseInt(departmentId);
        });
      });
      const department: DepartmentDTO | undefined = region?.departments?.find((department: DepartmentDTO) => {
        return department.id === parseInt(departmentId);
      });
      if (region && department) {
        dispatch(
          setRegionAndDepartment({
            region: region,
            department: department,
          })
        );
      }
    }
    dispatch(
      setStatus({
        status: null,
      })
    );
  }, [dispatch, history, isFetching, location.search, regions]);
  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}>
        {(formikProps: FormikProps<FormValues>) => {
          const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
          const handleSetFieldValue = (field: string, value: unknown) => {
            setFieldTouched(field, true, false);
            setFieldValue(field, value, true);
          };
          return (
            <Form>
              <Grid container={true} sx={{ height: '100%' }} alignItems={'flex-end'}>
                <Grid item={true} marginBottom={isMobile ? '48px' : '79px'} marginTop={'20px'}>
                  <PageHeader
                    tittle={tittle}
                    previousFormLabel={previousFormLabel}
                    previousFormPath={previousFormPath}
                    isMobile={isMobile}
                    mobileTittle={mobileTittle}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={isMobile ? 6 : 3}>
                    <Grid item={true} xs={isMobile ? 12 : 4}>
                      <RegionSelectField
                        regions={regions || []}
                        region={values.region}
                        isLoading={isFetching}
                        error={touched.region && Boolean(errors.region)}
                        helperText={
                          touched.region && Boolean(errors.region)
                            ? getIn(errors.region, 'departments')
                              ? getIn(errors.region, 'departments')
                              : errors.region
                            : ''
                        }
                        onChange={(value: RegionDTO | null) => {
                          handleSetFieldValue('region', value);
                          if (value === null) {
                            setFieldValue('department', null);
                            setFieldTouched('department', false);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={true}>
                      <OutlinedSelect
                        size={'medium'}
                        displayEmpty={true}
                        onChange={(event) => {
                          const selectDepartment = values.region?.departments.find(
                            (department) => department.id === (event.target.value as number)
                          );
                          handleSetFieldValue('department', selectDepartment);
                        }}
                        value={values.department ? values.department.id : ''}
                        disabled={Boolean(errors.region) || !values.region}
                        label={'Выберите учреждение'}
                        MenuProps={{
                          PaperProps: { sx: { marginTop: '3px' } },
                        }}
                        helperText={
                          touched.department &&
                          Boolean(errors.department) &&
                          !(Boolean(errors.region) || !values.region)
                            ? errors.department
                            : ''
                        }
                        error={
                          touched.department &&
                          Boolean(errors.department) &&
                          !(Boolean(errors.region) || !values.region)
                        }>
                        {values.region?.departments &&
                          values.region.departments.map((value) => (
                            <MenuItem key={value.id} value={value.id}>
                              {value.name}
                            </MenuItem>
                          ))}
                      </OutlinedSelect>
                    </Grid>
                  </Grid>
                  <Grid item={true} mt={isMobile ? 3 : 4}>
                    {resultDTO && resultDTO.error && (
                      <Grid item={true} mt={3}>
                        <LetterAlert error={true}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: resultDTO.message,
                            }}
                          />
                        </LetterAlert>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid container={true} sx={{ height: '100%' }} direction={'row'} alignItems={'flex-end'}>
                  <Grid item={true} marginBottom={3} xs={12}>
                    <PolicyStatementCheckBox
                      checked={policyChecked}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPolicyChecked(e.target.checked)}
                    />
                  </Grid>
                </Grid>
                <MobileBar>
                  <FixedButton
                    variant={'contained'}
                    color={'primary'}
                    disabled={!(values.department && email && letterNumber && !isDepartmentChanging) || !policyChecked}
                    onClick={submitForm}>
                    {'Перенаправить'}
                  </FixedButton>
                </MobileBar>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <DepartmentChangeSuccessDialog open={dialogOpen} isMobile={isMobile} setOpen={setDialogOpen} />
    </Fragment>
  );
};
