import { MetaDTO } from '@/types/MetaDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const metaApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMetaData: build.query<MetaDTO, void>({
      query: () => ({
        url: '/meta/data',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetMetaDataQuery } = metaApiSlice;
