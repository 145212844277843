import { LetterDraftRequestDTO } from '@/types/letterDraft/LetterDraftRequestDTO';
import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';

export interface LetterDraftState {
  request: LetterRequestDTO;
}

const initialState: LetterDraftState = {
  request: {
    searchRequest: {},
    pageRequest: {
      pageNumber: 0,
      pageSize: 8,
      sortRequest: {
        properties: ['createdAt'],
        direction: 'DESC',
      },
    },
  },
};

export const letterDraftSlice = createSlice({
  name: 'letterDraft',
  initialState: initialState,
  reducers: {
    setLetterDraftRequest: (state: LetterDraftState, action: PayloadAction<LetterDraftRequestDTO>) => {
      state.request = action.payload;
    },
    setLetterDraftRequestPageNumber: (state: LetterDraftState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageNumber: action.payload,
        },
      };
    },
    setLetterDraftRequestPageSize: (state: LetterDraftState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageSize: action.payload,
        },
      };
    },
    setLetterDraftSearchRequestAddressee: (state: LetterDraftState, action: PayloadAction<string>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          addressee: action.payload,
        },
      };
    },
    setLetterDraftSortRequest: (state: LetterDraftState, action: PayloadAction<SortRequestDTO>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          sortRequest: action.payload,
        },
      };
    },
  },
});

export const {
  setLetterDraftSortRequest,
  setLetterDraftRequestPageSize,
  setLetterDraftRequestPageNumber,
  setLetterDraftSearchRequestAddressee,
  setLetterDraftRequest,
} = letterDraftSlice.actions;

export const getLetterDraftRequest = (state: RootState): LetterRequestDTO => state.letterDraft.request;

export default letterDraftSlice.reducer;
