import { Box, Divider, Drawer, Grid, IconButton, Typography } from '@mui/material';
import { BurgerIcon } from '@/app/icons/BurgerIcon';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { Logo } from '@/app/icons/Logo';
import { MenuLink } from '@/components/common/MenuLink';
import {
  ROUTE_ABOUT,
  ROUTE_AUTHENTICATION,
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_DRAFTS,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_REGIONS,
  ROUTE_HELP_WORK,
  ROUTE_INBOX,
  ROUTE_MAIN,
  ROUTE_MAIN_CARD,
  ROUTE_MAIN_CREATE_ANSWER,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_OUTBOX,
  ROUTE_PROFILE,
  ROUTE_REDIRECT_LETTER,
} from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { VkIcon } from '@/app/icons/social/VkIcon';
import { config } from '@/app/config';
import { getCurrentUser, signOut } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  inboxCount?: LetterCountDTO;
  outboxCount?: LetterCountDTO;
  letterDraftsCount?: number;
  mainPage?: boolean;
}

export const NavigationDrawer: FC<Props> = (props: Props) => {
  const { inboxCount, outboxCount, mainPage, letterDraftsCount } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [open, setOpen] = useState<boolean>(false);
  const handleBeforeRedirect = (): void => {
    setOpen(false);
  };
  const handleClickLogout = (): void => {
    history.push(ROUTE_MAIN);
    dispatch(signOut());
  };
  return (
    <Fragment>
      <IconButton
        color={'primary'}
        onClick={() => {
          setOpen(true);
        }}
        sx={{
          zIndex: 100,
          position: 'absolute',
          left: 0,
          m: (theme: Theme) => theme.spacing(-1),
        }}>
        <BurgerIcon />
      </IconButton>
      <Drawer open={open} anchor={'left'}>
        <Box p={(theme: Theme) => theme.spacing(2.5, 3, 3, 2)}>
          <Grid container={true} direction={'column'}>
            <Grid item={true} pb={(theme: Theme) => theme.spacing(2)}>
              <Grid container={true} alignItems={'center'} justifyContent={'center'} position={'relative'}>
                <IconButton
                  color={'primary'}
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    position: 'absolute',
                    left: 0,
                    m: (theme: Theme) => theme.spacing(-1),
                  }}>
                  <CloseIcon />
                </IconButton>
                <Grid item={true} display={'flex'}>
                  <Logo
                    color={'primary'}
                    sx={{
                      width: (theme: Theme) => theme.spacing(7.646484375),
                      height: (theme: Theme) => theme.spacing(5.625),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={1}>
                {!!mainPage && (
                  <Fragment>
                    <Grid item={true}>
                      <MenuLink
                        label={'Главная'}
                        to={ROUTE_MAIN}
                        onBeforeRedirect={handleBeforeRedirect}
                        activeOnlyWhenExact={true}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Написать'}
                        to={ROUTE_MAIN_CREATE_LETTER}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Проверить статус'}
                        to={ROUTE_CHECK_LETTER_STATUS}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Перенаправить'}
                        to={ROUTE_REDIRECT_LETTER}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Заказать ответ'}
                        to={ROUTE_MAIN_CREATE_ANSWER}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink label={'Ф-карты'} to={ROUTE_MAIN_CARD} onBeforeRedirect={handleBeforeRedirect} />
                    </Grid>
                    <Grid item={true}>
                      {mainPage ? (
                        <MenuLink label={'Личный кабинет'} to={ROUTE_PROFILE} onBeforeRedirect={handleBeforeRedirect} />
                      ) : (
                        <MenuLink label={'Войти'} to={ROUTE_AUTHENTICATION} onBeforeRedirect={handleBeforeRedirect} />
                      )}
                    </Grid>
                  </Fragment>
                )}
                {!!authUser && !mainPage && (
                  <Fragment>
                    <Grid item={true}>
                      <MenuLink
                        label={'Главная'}
                        to={ROUTE_MAIN}
                        onBeforeRedirect={handleBeforeRedirect}
                        activeOnlyWhenExact={true}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink label={'Профиль'} to={ROUTE_PROFILE} onBeforeRedirect={handleBeforeRedirect} />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Входящие'}
                        value={inboxCount?.unreadCount}
                        total={inboxCount?.totalCount}
                        to={ROUTE_INBOX}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Исходящие'}
                        value={outboxCount?.unreadCount}
                        total={outboxCount?.totalCount}
                        to={ROUTE_OUTBOX}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Черновики'}
                        total={letterDraftsCount}
                        to={ROUTE_DRAFTS}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink
                        label={'Перенаправить'}
                        to={ROUTE_REDIRECT_LETTER}
                        onBeforeRedirect={handleBeforeRedirect}
                      />
                    </Grid>
                    <Grid item={true}>
                      <MenuLink label={'Ф-карты'} to={ROUTE_MAIN_CARD} onBeforeRedirect={handleBeforeRedirect} />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <Grid item={true} pt={(theme: Theme) => theme.spacing(2.75)} pb={(theme: Theme) => theme.spacing(1.5)}>
              <Divider textAlign={'left'}>
                <Typography variant={'label1'}>{'Помощь'}</Typography>
              </Divider>
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={1}>
                <Grid item={true}>
                  <MenuLink label={'О сервисе'} to={ROUTE_ABOUT} onBeforeRedirect={handleBeforeRedirect} />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Как работает Ф-письмо'}
                    to={ROUTE_HELP_WORK}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Часто задаваемые вопросы'}
                    to={ROUTE_HELP_FAQ}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink
                    label={'Справочник учреждений'}
                    to={ROUTE_HELP_REGIONS}
                    onBeforeRedirect={handleBeforeRedirect}
                  />
                </Grid>
                <Grid item={true}>
                  <MenuLink label={'Обратная связь'} to={ROUTE_HELP_FEEDBACK} onBeforeRedirect={handleBeforeRedirect} />
                </Grid>
                {!!authUser && (
                  <Grid item={true}>
                    <MenuLink label={'Выйти'} to={ROUTE_AUTHENTICATION} onClick={handleClickLogout} />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item={true} pt={(theme: Theme) => theme.spacing(2.75)} pl={(theme: Theme) => theme.spacing(1)}>
              <Divider />
            </Grid>
            <Grid item={true} py={(theme: Theme) => theme.spacing(4)}>
              <Grid container={true} direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Grid item={true}>
                  <IconButton size={'dense'} href={config.links.social.vk || ''} target={'_blank'}>
                    <VkIcon
                      sx={{
                        width: (theme: Theme) => theme.spacing(4),
                        height: (theme: Theme) => theme.spacing(4),
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </Fragment>
  );
};
