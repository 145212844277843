import {
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { LetterDraftIdDTO } from '@/types/letterDraft/LetterDraftIdDTO';
import { LetterDraftListItemDTO } from '@/types/letterDraft/LetterDraftListItemDTO';
import { SortIcon } from '@/app/icons/SortIcon';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { Theme } from '@mui/material/styles';
import { TrashIcon } from '@/app/icons/profile/TrashIcon';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartZonedDateTimeString } from '@/utils/date-utils';
import { useDeleteLetterDraftMutation } from '@/services/api/letterDraftApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, useState } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  drafts: LetterDraftListItemDTO[];
  sortRequest: SortRequestDTO;
  onSort: (sortRequest: SortRequestDTO) => void;
  onDraftClick: (draft: LetterDraftListItemDTO) => void;
}

export const DraftsTable: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, drafts, sortRequest, onSort, onDraftClick } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDraft, { isLoading: isLetterDraftDeleting }] = useDeleteLetterDraftMutation();
  const loading: boolean = isFetching || isLoading || isLetterDraftDeleting;
  const [hoveredId, setHoveredId] = useState<number>(-1);
  const handleRowHover = (letterId: number) => (): void => {
    setHoveredId(letterId);
  };
  const handleRowClick = (draft: LetterDraftListItemDTO) => (): void => {
    if (draft) {
      onDraftClick(draft);
    }
  };
  const handleClickDelete = async (id: number) => {
    const dto: LetterDraftIdDTO = {
      id: id,
    };
    await deleteDraft(dto)
      .unwrap()
      .then(() => {
        enqueueSnackbar('Черновик удален', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ошибка удаления черновика', { variant: 'error' });
      });
  };

  console.log(drafts);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'createdAt';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['createdAt'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Дата'}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                color={'primary'}
                active={
                  sortRequest.properties.find((name: string) => {
                    return name == 'prisonerLastName' || name == 'prisonerFirstName' || name == 'prisonerMiddleName';
                  }) != null
                }
                direction={sortRequest.direction === 'ASC' ? 'asc' : 'desc'}
                IconComponent={SortIcon}
                onClick={() =>
                  onSort({
                    properties: ['prisonerLastName', 'prisonerFirstName', 'prisonerMiddleName'],
                    direction: sortRequest.direction === 'ASC' ? 'DESC' : 'ASC',
                  })
                }>
                {'Адресат'}
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(loading ? Array.from(new Array(8)) : drafts).map((draft: LetterDraftListItemDTO, index: number) => (
            <TableRow
              key={loading ? index : draft.id}
              hover={true}
              onMouseEnter={handleRowHover(loading ? index : draft.id)}
              onMouseLeave={handleRowHover(-1)}
              sx={{ cursor: 'pointer' }}>
              <TableCell sx={{ width: (theme: Theme) => theme.spacing(8) }} onClick={handleRowClick(draft)}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography variant={'body2'} whiteSpace={'nowrap'} fontWeight={500}>
                    {formatSmartZonedDateTimeString(draft.createdAt)}
                  </Typography>
                )}
              </TableCell>
              <TableCell sx={{ width: '100%' }} onClick={handleRowClick(draft)}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography
                    variant={'body2'}
                    fontWeight={400}
                    color={(theme: Theme) => theme.colors.grayText}
                    whiteSpace={'nowrap'}>
                    {formatPrisonerShortName(draft.prisonerLastName, draft.prisonerFirstName)}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                align={'right'}
                sx={{
                  padding: 0,
                  width: (theme: Theme) => theme.spacing(3),
                  verticalAlign: 'middle',
                  zIndex: '100',
                }}>
                {!loading && (
                  <IconButton
                    size={'dense'}
                    sx={{ display: 'flex', zIndex: '100' }}
                    onClick={() => handleClickDelete(draft.id)}>
                    <TrashIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
