import { Grid } from '@mui/material';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { PolicyStatementCheckBox } from '@/components/common/PolicyStatementCheckBox';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  policyChecked: boolean;
  onPolicyChange: (policyChecked: boolean) => void;
}

export const CardContractCondition: FC<Props> = (props: Props) => {
  const { policyChecked, onPolicyChange } = props;

  return (
    <Grid
      container={true}
      direction={'column'}
      spacing={3}
      sx={{ height: '100%' }}
      justifyContent={'flex-end'}
      wrap={'nowrap'}>
      <Grid item={true}>
        <Grid container={true} spacing={3} alignItems={'center'}>
          <Grid item={true}>
            <VisaIcon />
          </Grid>
          <Grid item={true}>
            <MastercardIcon />
          </Grid>
          <Grid item={true}>
            <MirIcon />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <PolicyStatementCheckBox
          checked={policyChecked}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onPolicyChange(e.target.checked)}
        />
      </Grid>
    </Grid>
  );
};
