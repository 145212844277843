import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends SelectProps {
  size?: 'small' | 'medium';
  helperText?: string;
  disabled?: boolean;
}

export const OutlinedSelect: FC<Props> = (props: Props) => {
  const { size, label, error, helperText, children, disabled, ...other } = props;
  return (
    <FormControl variant={'outlined'} size={size} fullWidth={true}>
      {label && (
        <InputLabel error={error} id={props.name + '-input-label'}>
          {label}
        </InputLabel>
      )}
      {disabled ? (
        <Select
          id={'props.name + -select'}
          error={error}
          sx={{
            backgroundColor: (theme: Theme) => theme.colors.grayBackground,
          }}
          disabled={disabled}
          {...other}>
          {children}
        </Select>
      ) : (
        <Select id={'props.name + -select'} error={error} {...other}>
          {children}
        </Select>
      )}
      {helperText && (
        <FormHelperText error={error} id={props.name + '-input-helperText'}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
