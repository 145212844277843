import { AppDispatch } from '@/app/store';
import { BalanceFilter } from '@/components/balance/BalanceFilter';
import { BalanceHistoryRequestDTO } from '@/types/balance/BalanceHistoryRequestDTO';
import { BalanceHistoryTable } from '@/components/balance/BalanceHistoryTable';
import { Grid, Pagination } from '@mui/material';
import { UserDTO } from '@/types/user/UserDTO';
import { getBalanceHistoryRequest, setBalanceHistoryRequestPageNumber } from '@/services/balanceHistorySlice';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetHistoryQuery } from '@/services/api/balanceApiSlice';
import React, { ChangeEvent, FC } from 'react';

export const BalanceHistory: FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const request: BalanceHistoryRequestDTO = useAppSelector(getBalanceHistoryRequest);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data, isFetching, isLoading } = useGetHistoryQuery({ ...request, userId: authUser?.id }, { skip: !authUser });
  const handlePage = (page: number): void => {
    dispatch(setBalanceHistoryRequestPageNumber(page - 1));
  };
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <BalanceFilter
          type={request.searchRequest.type}
          startDate={request.searchRequest.startDate ? new Date(request.searchRequest.startDate) : undefined}
          endDate={request.searchRequest.endDate ? new Date(request.searchRequest.endDate) : undefined}
        />
      </Grid>
      <Grid item={true}>
        <BalanceHistoryTable isFetching={isFetching} isLoading={isLoading} items={data?.content || []} />
      </Grid>
      <Grid item={true} display={'flex'}>
        <Pagination
          count={data?.totalPages || 1}
          page={(request.pageRequest?.pageNumber || 0) + 1}
          onChange={(e: ChangeEvent<unknown>, page: number) => {
            handlePage(page);
          }}
        />
      </Grid>
    </Grid>
  );
};
