import { LetterDraftDTO } from '@/types/letterDraft/LetterDraftDTO';
import { LetterDraftIdDTO } from '@/types/letterDraft/LetterDraftIdDTO';
import { LetterDraftListItemDTO } from '@/types/letterDraft/LetterDraftListItemDTO';
import { LetterDraftRequestDTO } from '@/types/letterDraft/LetterDraftRequestDTO';
import { Page } from '@/types/dto';
import { SaveLetterDraftDTO } from '@/types/letterDraft/SaveLetterDraftDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const letterDraftApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    saveLetterDraft: build.mutation<LetterDraftIdDTO, { letterDraft: SaveLetterDraftDTO; image?: File }>({
      query: ({ letterDraft, image }) => ({
        url: '/letter-draft/save',
        method: 'POST',
        body: createLetterDraft(letterDraft, image),
        headers: {
          'X-Access-Token': 'fcf5ca14-bb76-44ee-b737-179c2da942c7',
        },
      }),
      invalidatesTags: ['LetterDraftsCount', 'LetterDrafts'],
    }),
    getLetterDraft: build.mutation<LetterDraftDTO, LetterDraftIdDTO>({
      query: (dto: LetterDraftIdDTO) => ({
        url: '/letter-draft',
        method: 'POST',
        body: dto,
      }),
    }),
    getLetterDrafts: build.query<Page<LetterDraftListItemDTO>, LetterDraftRequestDTO>({
      query: (dto: LetterDraftRequestDTO) => ({
        url: '/letter-draft/search',
        method: 'POST',
        body: dto,
      }),
      providesTags: ['LetterDrafts'],
    }),
    countLetterDrafts: build.query<number, number | undefined>({
      query: (id: number | undefined) => ({
        url: '/letter-draft/count',
        method: 'GET',
      }),
      providesTags: ['LetterDraftsCount'],
    }),
    deleteLetterDraft: build.mutation<void, LetterDraftIdDTO>({
      query: (dto: LetterDraftIdDTO) => ({
        url: 'letter-draft/delete',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['LetterDraftsCount', 'LetterDrafts'],
    }),
  }),
});

const createLetterDraft = (letter: SaveLetterDraftDTO, image?: File): FormData => {
  const formData: FormData = new FormData();
  formData.append(
    'letterDraft',
    new Blob([JSON.stringify({ ...letter })], {
      type: 'application/json',
    })
  );
  if (image) {
    formData.append('image', image);
  }
  return formData;
};

export const {
  useSaveLetterDraftMutation,
  useGetLetterDraftMutation,
  useGetLetterDraftsQuery,
  useCountLetterDraftsQuery,
  useDeleteLetterDraftMutation,
} = letterDraftApiSlice;
