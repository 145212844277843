import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PasswordRequestDTO } from '@/types/auth/PasswordRequestDTO';
import { ROUTE_AUTHENTICATION, ROUTE_RESTORE_SUCCESS } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { emailSchema } from '@/validation/emailSchema';
import { isBlank } from '@/utils/string-utils';
import { useHistory } from 'react-router-dom';
import { useRestorePasswordRequestMutation } from '@/services/api/authApiSlice';
import NavigatePreviousStep from '@/components/create/NavigatePreviousStep';
import React, { ChangeEvent, FC, Fragment } from 'react';

interface FormValues {
  email?: string;
}

export const RestorePasswordForm: FC = () => {
  const history = useHistory();
  const [restorePasswordRequest, { isLoading }] = useRestorePasswordRequestMutation();
  const initialValues: FormValues = {
    email: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
  });
  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const dto: PasswordRequestDTO = {
      email: values.email || '',
    };
    await restorePasswordRequest(dto)
      .unwrap()
      .then(() => {
        history.replace(ROUTE_RESTORE_SUCCESS);
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        const { setFieldError } = helpers;
        setFieldError('email', e.data.message);
      });
  };
  return (
    <Fragment>
      <Box
        sx={(theme: Theme) => ({
          position: 'absolute',
          top: theme.spacing(8.75),
          right: 0,
          left: theme.spacing(4),
          [theme.breakpoints.down('sm')]: {
            top: theme.spacing(-3),
            left: 0,
          },
        })}>
        <NavigatePreviousStep to={ROUTE_AUTHENTICATION}>{'Назад'}</NavigatePreviousStep>
      </Box>
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handleSubmit}>
        {({
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          submitForm,
          isValid,
        }: FormikProps<FormValues>) => {
          const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
            setFieldTouched(e.target.name, true, false);
            setFieldValue(e.target.name, e.target.value, true);
          };
          return (
            <Grid container={true} direction={'column'} alignItems={'center'} spacing={5} mb={10}>
              <Grid item={true}>
                <Typography
                  variant={'h1'}
                  sx={(theme: Theme) => ({
                    lineHeight: '20px',
                    [theme.breakpoints.down('sm')]: {
                      lineHeight: '32px',
                    },
                  })}>
                  {'Восстановить пароль'}
                </Typography>
              </Grid>
              <Grid item={true} width={'100%'}>
                <OutlinedField
                  fullWidth={true}
                  name={'email'}
                  label={'Введите электронную почту'}
                  withLabel={true}
                  required={true}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email ? errors.email : ''}
                  onChange={handleSetFieldValue}
                />
              </Grid>
              <Grid item={true} width={'100%'}>
                <LoadingButton
                  variant={'contained'}
                  color={'primary'}
                  fullWidth={true}
                  type={'submit'}
                  onClick={submitForm}
                  isLoading={isLoading}
                  disabled={isLoading || !isValid}>
                  {'Восстановить'}
                </LoadingButton>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Fragment>
  );
};
