import { Collapse, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

interface Props {
  wrapHeight: number;
}

export const CollapseView: FC<Props> = (props: PropsWithChildren<Props>) => {
  const { wrapHeight, children } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const wrapRef = useRef<HTMLDivElement>(null);
  const [isWrap, setIsWrap] = useState(false);
  useEffect(() => {
    if (wrapRef && wrapRef.current && wrapRef.current.parentElement) {
      setIsWrap(wrapRef.current.getBoundingClientRect().height > wrapHeight);
    }
  }, [isWrap, wrapHeight]);
  return (
    <Grid container={true} direction={'column'}>
      {isWrap ? (
        <Grid item={true}>
          <Collapse
            in={visible}
            collapsedSize={wrapHeight}
            sx={{
              position: 'relative',
              '&:after': !visible
                ? {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    left: 0,
                    height: '100px',
                    background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)',
                    pointerEvents: 'none',
                  }
                : {},
            }}>
            {children}
          </Collapse>
          <Typography
            onClick={() => setVisible(!visible)}
            variant={'body1'}
            sx={(theme: Theme) => ({
              color: theme.colors.primary,
              cursor: 'pointer',
              marginTop: visible ? theme.spacing(3) : 0,
            })}>
            {visible ? 'Свернуть' : 'Развернуть'}
          </Typography>
        </Grid>
      ) : (
        <Grid item={true} ref={wrapRef}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};
