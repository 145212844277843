import { AnswerFeedback } from '@/components/create/answer/AnswerFeedback';
import { AuthorDTO } from '@/types/AuthorDTO';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { EnvelopeIcon } from '@/app/icons/EnvelopeIcon';
import { ROUTE_INBOX } from '@/app/routes';
import { SenderDTO } from '@/types/SenderDTO';
import { TypographyLink } from '@/components/common/TypographyLink';
import { getAuthor, getSender } from '@/services/createFormSlice';
import { useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}

export const AnswerSuccess: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const history = useHistory();
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const sender: SenderDTO | null = useAppSelector(getSender);
  return (
    <Grid
      container={true}
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ minHeight: '68vh' }}>
      <Grid item={true} pb={1.5}>
        <EnvelopeIcon sx={{ width: '68px', height: '68px', display: 'block' }} />
      </Grid>
      <Grid item={true} pb={1}>
        <Typography variant={'h1'}>{'Ответ оплачен!'}</Typography>
      </Grid>
      <Grid item={true} mb={2}>
        <Typography variant={'body1'} textAlign={'center'}>
          {`Теперь ${sender?.firstName} ${sender?.lastName} сможет отправить вам письмо.`}
        </Typography>
        <Typography variant={'body1'} textAlign={'center'} whiteSpace={'pre-line'}>
          {isMobile
            ? 'Следите за обновлениями в личном кабинете или в рассылке на адрес\n'
            : 'Следите за обновлениями в личном кабинете\nили в рассылке на адрес '}
          <TypographyLink>{author?.email}</TypographyLink>
        </Typography>
      </Grid>
      <Grid item={true} display={'flex'} justifyContent={'center'} width={'100%'} mb={4}>
        <Button
          fullWidth={true}
          color={'primary'}
          variant={'outlined'}
          sx={(theme: Theme) => ({
            maxWidth: isMobile ? 'unset' : theme.spacing(40),
          })}
          onClick={() => history.push(ROUTE_INBOX)}>
          {'Вернуться на главную'}
        </Button>
      </Grid>
      <Grid item={true} style={{ maxWidth: '664px' }}>
        <AnswerFeedback />
      </Grid>
    </Grid>
  );
};
