import { Box, Grid, Stack, Typography } from '@mui/material';
import { Dot } from '@/components/common/Dot';
import { EventDTO } from '@/types/event/EventDTO';
import { Theme } from '@mui/material/styles';
import { formatDateTimeString } from '@/utils/date-utils';
import { isNotBlank } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  event: EventDTO;
  index?: number;
  total?: number;
}

export const EventItem: FC<Props> = (props: Props) => {
  const { event } = props;
  return (
    <Box
      position={'relative'}
      width={'100%'}
      padding={(theme: Theme) => theme.spacing(1, 2, 1.5)}
      borderRadius={'12px'}
      sx={{
        backgroundColor: (theme: Theme) => {
          switch (event.type) {
            case 'yellow': {
              return theme.colors.alert;
            }
            default: {
              return theme.colors.grayBackground;
            }
          }
        },
      }}>
      <Grid container={true} direction={'column'} flexWrap={'nowrap'} spacing={0.375}>
        <Grid item={true} minHeight={props.total ? '20px' : 'auto'}>
          <Grid container={true} direction={'row'} justifyContent={'space-between'} spacing={2}>
            <Grid item={true}>
              {event.date && (
                <Typography fontSize={'12px'} lineHeight={'20px'} color={(theme: Theme) => theme.colors.grayText}>
                  {event.date ? formatDateTimeString(event.date) : ''}
                </Typography>
              )}
            </Grid>
            {props.total && (
              <Grid item={true}>
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  position={'absolute'}
                  top={(theme: Theme) => theme.spacing(1.5)}
                  right={(theme: Theme) => theme.spacing(1.5)}>
                  {Array.from(Array(props.total)).map((value: unknown, index: number) => (
                    <Dot
                      key={index}
                      sxOverride={{
                        opacity: index === props.index ? 1 : 0.3,
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item={true}>
          <Stack direction={'column'}>
            {isNotBlank(event?.title) && (
              <Typography variant={'body2'} fontWeight={500}>
                {event?.title}
              </Typography>
            )}
            <Typography variant={'body2'}>{event.description}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
