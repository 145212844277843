import { CardFormVariant } from '@/types/card/CardFormVariant';
import { CardSuccess } from '@/components/create/card/CardSuccess';
import { CreateCardBlockMobile } from '@/components/create/card/CreateCardBlockMobile';
import { CreateCardsBlock } from '@/components/create/card/CreateCardsBlock';
import {
  ROUTE_MAIN_CARD,
  ROUTE_MAIN_CARD_CREATED,
  ROUTE_MAIN_CARD_PAYED,
  ROUTE_MAIN_CARD_REFILLED,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import React, { FC } from 'react';

export const MainCardPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <Switch>
      <Route exact={true} path={ROUTE_MAIN_CARD}>
        {isMobile ? <CreateCardBlockMobile /> : <CreateCardsBlock />}
      </Route>
      <Route path={ROUTE_MAIN_CARD_CREATED}>
        <CardSuccess variant={CardFormVariant.CREATED} isMobile={isMobile} />
      </Route>
      <Route path={ROUTE_MAIN_CARD_PAYED}>
        <CardSuccess variant={CardFormVariant.PAYED} isMobile={isMobile} />
      </Route>
      <Route path={ROUTE_MAIN_CARD_REFILLED}>
        <CardSuccess variant={CardFormVariant.REFILLED} isMobile={isMobile} />
      </Route>
      <Redirect to={ROUTE_MAIN_CARD} />
    </Switch>
  );
};
