import { BalanceFilterButton } from '@/components/balance/BalanceFilterButton';
import { BalanceFilterDateButton } from '@/components/balance/BalanceFilterDateButton';
import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { Grid } from '@mui/material';
import {
  clearBalanceHistoryRequestDates,
  setBalanceHistoryRequestEndDate,
  setBalanceHistoryRequestPageNumber,
  setBalanceHistoryRequestStartDate,
  setBalanceHistoryRequestType,
} from '@/services/balanceHistorySlice';
import { useAppDispatch } from '@/app/hooks';
import React, { FC } from 'react';

interface Props {
  type: BalanceFilterType;
  startDate?: Date;
  endDate?: Date;
}

export const BalanceFilter: FC<Props> = (props: Props) => {
  const { type, startDate, endDate } = props;
  const dispatch = useAppDispatch();
  const handleType = (type: BalanceFilterType): void => {
    dispatch(setBalanceHistoryRequestType(type));
    dispatch(setBalanceHistoryRequestPageNumber(0));
  };
  const handleFilter = (startDate: Date, endDate: Date): void => {
    dispatch(setBalanceHistoryRequestStartDate(new Date(startDate)));
    dispatch(setBalanceHistoryRequestEndDate(new Date(endDate)));
    dispatch(setBalanceHistoryRequestPageNumber(0));
  };
  const handleClear = (): void => {
    dispatch(clearBalanceHistoryRequestDates());
  };
  return (
    <Grid container={true} direction={'row'} spacing={1}>
      <Grid item={true}>
        <BalanceFilterButton
          label={'Вся история'}
          active={type === BalanceFilterType.ALL}
          onClick={() => handleType(BalanceFilterType.ALL)}
        />
      </Grid>
      <Grid item={true}>
        <BalanceFilterButton
          label={'Пополнения'}
          active={type === BalanceFilterType.INCOME}
          onClick={() => handleType(BalanceFilterType.INCOME)}
        />
      </Grid>
      <Grid item={true}>
        <BalanceFilterButton
          label={'Списания'}
          active={type === BalanceFilterType.EXPENSE}
          onClick={() => handleType(BalanceFilterType.EXPENSE)}
        />
      </Grid>
      <Grid item={true}>
        <BalanceFilterDateButton
          startDate={startDate}
          endDate={endDate}
          onFilter={handleFilter}
          onClear={handleClear}
        />
      </Grid>
    </Grid>
  );
};
