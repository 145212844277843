import { AppDispatch } from '@/app/store';
import { DraftsList } from '@/components/home/drafts/DraftsList';
import { DraftsTable } from '@/components/home/drafts/DraftsTable';
import { Grid, Pagination, useMediaQuery } from '@mui/material';
import { LetterDraftDTO } from '@/types/letterDraft/LetterDraftDTO';
import { LetterDraftIdDTO } from '@/types/letterDraft/LetterDraftIdDTO';
import { LetterDraftListItemDTO } from '@/types/letterDraft/LetterDraftListItemDTO';
import { LetterDraftRequestDTO } from '@/types/letterDraft/LetterDraftRequestDTO';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { ROUTE_CREATE_LETTER_AUTHOR, ROUTE_CREATE_LETTER_CLIENT, ROUTE_CREATE_REPLY_LETTER } from '@/app/routes';
import { SortRequestDTO } from '@/types/page/SortRequestDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearFormState,
  clearLetterDraftId,
  deleteAttachFile,
  setAnswerOrderedForm,
  setAnswersCountForm,
  setAuthor,
  setBufferFile,
  setLetterDraftId,
  setLetterTextForm,
  setRegionAndDepartment,
  setSender,
  setTypeFile,
} from '@/services/createFormSlice';
import { clearLetterSbpState } from '@/services/sbpSlice';
import { getCurrentUser } from '@/services/authSlice';
import {
  getLetterDraftRequest,
  setLetterDraftRequestPageNumber,
  setLetterDraftRequestPageSize,
  setLetterDraftSearchRequestAddressee,
  setLetterDraftSortRequest,
} from '@/services/letter/LetterDraftSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetLetterDraftMutation, useGetLetterDraftsQuery } from '@/services/api/letterDraftApiSlice';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { validateAuthor, validateSender } from '@/utils/validation-utils';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

export const DraftsBlock: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const request: LetterDraftRequestDTO = useAppSelector(getLetterDraftRequest);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const [search, setSearch] = useState<string>('');
  const { data, isFetching, isLoading } = useGetLetterDraftsQuery({ ...request }, { skip: !authUser });
  const [getLetterDraft] = useGetLetterDraftMutation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    dispatch(setLetterDraftRequestPageSize(isMobile ? 5 : 8));
  }, [dispatch, isMobile]);
  useEffect(() => {
    setSearch(request.searchRequest.addressee || '');
  }, [request.searchRequest.addressee]);
  const handlePage = (page: number): void => {
    dispatch(setLetterDraftRequestPageNumber(page - 1));
  };
  const handleAddressee = useDebounce<string>((addressee: string): void => {
    dispatch(setLetterDraftSearchRequestAddressee(addressee));
    dispatch(setLetterDraftRequestPageNumber(0));
  }, 500);
  const handleSearch = (addressee: string): void => {
    setSearch(addressee);
    handleAddressee(addressee);
  };
  const handleSort = (sortRequest: SortRequestDTO): void => {
    dispatch(setLetterDraftSortRequest(sortRequest));
  };
  const handleDraftClick = async (draft: LetterDraftListItemDTO) => {
    const dto: LetterDraftIdDTO = {
      id: draft.id,
    };
    try {
      const response: LetterDraftDTO = await getLetterDraft(dto).unwrap();
      handleDraft(response);
      if (!validateSender(response.sender)) {
        history.push(ROUTE_CREATE_LETTER_CLIENT);
      } else if (!validateAuthor(response.author)) {
        history.push(ROUTE_CREATE_LETTER_AUTHOR);
      } else {
        history.push(ROUTE_CREATE_REPLY_LETTER);
      }
    } catch (error) {
      enqueueSnackbar('Ошибка получения черновика', { variant: 'error' });
    }
  };
  const handleDraft = (draft: LetterDraftDTO): void => {
    const { region, department, author, sender, letter, isAnswerOrdered, answersCount, image } = draft;
    dispatch(clearFormState);
    dispatch(clearLetterSbpState());
    dispatch(clearLetterDraftId());
    dispatch(setRegionAndDepartment({ region, department }));
    if (author) {
      dispatch(setAuthor(author));
    }
    if (sender) {
      dispatch(setSender(sender));
    }
    if (letter) {
      dispatch(setLetterTextForm(letter));
    }
    dispatch(setAnswerOrderedForm(isAnswerOrdered));
    dispatch(setAnswersCountForm(answersCount || 1));
    if (image && image.content) {
      dispatch(setBufferFile(image.content));
      dispatch(setTypeFile('image/png'));
    } else {
      dispatch(deleteAttachFile());
    }
    dispatch(setLetterDraftId(draft.id));
  };
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <OutlinedField
          fullWidth={true}
          size={'small'}
          placeholder={'Поиск по адресату'}
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleSearch(e.target.value);
          }}
          onClear={() => {
            handleSearch('');
          }}
        />
      </Grid>
      <Grid item={true}>
        {isMobile ? (
          <DraftsList
            isFetching={isFetching}
            isLoading={isLoading}
            drafts={data?.content || []}
            onDraftClick={handleDraftClick}
          />
        ) : (
          <DraftsTable
            isFetching={isFetching}
            isLoading={isLoading}
            drafts={data?.content || []}
            sortRequest={
              request.pageRequest.sortRequest || {
                properties: ['answer.processedAt', 'answerDate'],
                direction: 'DESC',
              }
            }
            onSort={handleSort}
            onDraftClick={handleDraftClick}
          />
        )}
      </Grid>
      <Grid item={true} display={'flex'} justifyContent={isMobile ? 'center' : 'right'}>
        <Pagination
          count={data?.totalPages || 1}
          page={(request.pageRequest?.pageNumber || 0) + 1}
          onChange={(e: ChangeEvent<unknown>, page: number) => {
            handlePage(page);
          }}
        />
      </Grid>
    </Grid>
  );
};
