import { Grid, Typography } from '@mui/material';
import { ROUTE_MAIN } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { TypographyLink } from '@/components/common/TypographyLink';
import React, { FC } from 'react';

export const NotFoundPage: FC = () => {
  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ height: '100%', padding: '16px' }}
      rowSpacing={3}>
      <Grid item={true}>
        <Typography variant={'h1'}>{'404 Страница не найдена'}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body1'}>{'Извините, но запрашиваемая страница не существует'}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body1'}>
          <TypographyLink>
            <RouteLink to={ROUTE_MAIN}>
              <TypographyLink>{'Вернуться к главной странице'}</TypographyLink>
            </RouteLink>
          </TypographyLink>
        </Typography>
      </Grid>
    </Grid>
  );
};
