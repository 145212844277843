import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ClockIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M11.0007 8.74975V12.4298C11.0007 12.7798 11.1907 13.1098 11.4907 13.2898L14.6107 15.1398C14.9707 15.3498 15.4307 15.2298 15.6407 14.8798C15.8507 14.5198 15.7407 14.0598 15.3807 13.8498L12.5107 12.1398V8.73975C12.5007 8.33975 12.1607 7.99975 11.7507 7.99975C11.3407 7.99975 11.0007 8.33975 11.0007 8.74975ZM21.0007 9.49975V4.20975C21.0007 3.75975 20.4607 3.53975 20.1507 3.85975L18.3707 5.63975C16.5607 3.82975 13.9807 2.78975 11.1607 3.03975C6.97074 3.41975 3.52074 6.78975 3.06074 10.9798C2.46074 16.3998 6.69074 20.9998 12.0007 20.9998C16.5907 20.9998 20.3807 17.5598 20.9307 13.1198C21.0007 12.5198 20.5307 11.9998 19.9307 11.9998C19.4307 11.9998 19.0107 12.3698 18.9507 12.8598C18.5207 16.3498 15.5107 19.0498 11.9007 18.9998C8.19074 18.9498 5.06074 15.8198 5.00074 12.0998C4.94074 8.19976 8.11074 4.99975 12.0007 4.99975C13.9307 4.99975 15.6807 5.78975 16.9507 7.04975L14.8607 9.13975C14.5407 9.45975 14.7607 9.99975 15.2107 9.99975H20.5007C20.7807 9.99975 21.0007 9.77975 21.0007 9.49975Z'
      }
    />
  </SvgIcon>
);
