import { DepartmentDTO } from '@/types/DepartmentDTO';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';

/**
 * Calculates the total cost with the commission based on payment method and provider.
 *
 * @param {number} baseCost - The base cost of the item.
 * @param {PaymentMethod} method - The payment method being used.
 * @param {PaymentProvider} [provider] - The optional payment provider for ONLINE payments.
 * @return {number} - Total cost including the applicable commission.
 */
export const getCostWithCommission = (baseCost: number, method: PaymentMethod, provider?: PaymentProvider): number => {
  return baseCost + getCommission(baseCost, method, provider);
};

/**
 * Determines and calculates the commission based on payment method and provider.
 *
 * @param {number} baseCost - The base cost before the commission.
 * @param {PaymentMethod} method - The payment method being used.
 * @param {PaymentProvider} [provider] - The optional payment provider for ONLINE payments.
 * @return {number} - The calculated commission.
 */
export const getCommission = (baseCost: number, method: PaymentMethod, provider?: PaymentProvider): number => {
  if (method === PaymentMethod.ONLINE) {
    if (provider === PaymentProvider.ORC) {
      return getOrcCommission(baseCost);
    }
    return getAlfaCommission(baseCost);
  }
  if (method === PaymentMethod.SBP) {
    return getSbpCommission(baseCost);
  }
  return 0;
};

/**
 * Calculates the SBP commission.
 *
 * @param {number} baseCost - The base cost before the commission.
 * @return {number} - The calculated SBP commission, rounded to two decimal places.
 */
export const getSbpCommission = (baseCost: number): number => {
  const minimumCommission = 7.22;
  const variableRate = 0.011;
  const additionalFeeRate = 0.007;
  const divisor: number = 1 - additionalFeeRate;

  const receiptCost: number = Math.max(baseCost * variableRate, minimumCommission);

  const finalCommission: number = (receiptCost + additionalFeeRate * baseCost) / divisor;

  return roundToTwoDecimals(finalCommission);
};

/**
 * Calculates the ALFA commission.
 *
 * @param {number} baseCost - The base cost before the commission.
 * @return {number} - The calculated ALFA commission, rounded to two decimal places.
 */
export const getAlfaCommission = (baseCost: number): number => {
  const minimumCommission = 7.22;
  const variableRate = 0.011;
  const additionalFeeRate = 0.023;
  const divisor: number = 1 - additionalFeeRate;
  const minimumAdditionalFee = 3.5;

  const receiptCost: number = Math.max(baseCost * variableRate, minimumCommission);
  const calculatedFee: number = ((receiptCost + baseCost) * additionalFeeRate) / divisor;

  const finalCommission: number = receiptCost + Math.max(calculatedFee, minimumAdditionalFee);

  return roundToTwoDecimals(finalCommission);
};

/**
 * Calculates the ORC commission.
 *
 * @param {number} baseCost - The base cost before the commission.
 * @return {number} - The calculated ORC commission, rounded to two decimal places.
 */
export const getOrcCommission = (baseCost: number): number => {
  const minimumCommission = 7.22;
  const lowerRate = 0.02565;
  const upperRate = 0.03077;

  const finalCommission: number = baseCost < 1000 ? minimumCommission + baseCost * lowerRate : baseCost * upperRate;

  return roundToTwoDecimals(finalCommission);
};

/**
 * Retrieves the letter cost based on authentication status and department information.
 *
 * @param {boolean} isAuthenticated - The user's authentication status.
 * @param {DepartmentDTO | null} department - The department data object.
 * @return {number} - The cost of the letter, applying default values if necessary.
 */
export const getLetterCost = (isAuthenticated: boolean, department: DepartmentDTO | null): number => {
  if (isAuthenticated) {
    return department?.letterCost ?? 55;
  }
  return department?.unauthorizedLetterCost ?? 65;
};

/**
 * Retrieves the photo cost based on authentication status and department information.
 *
 * @param {boolean} isAuthenticated - The user's authentication status.
 * @param {DepartmentDTO | null} department - The department data object.
 * @return {number} - The cost of the photo, applying default values if necessary.
 */
export const getPhotoCost = (isAuthenticated: boolean, department: DepartmentDTO | null): number => {
  if (isAuthenticated) {
    return department?.photoCost ?? 30;
  }
  return department?.unauthorizedPhotoCost ?? 40;
};

export const LETTER_COST = 55;

/**
 * Utility function to round a number to two decimal places.
 *
 * @param {number} value - The number to round.
 * @return {number} - The rounded number.
 */
const roundToTwoDecimals = (value: number): number => Math.round(value * 100) / 100;
