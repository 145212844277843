import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { PaymentSettingsDTO } from '@/types/PaymentSettingsDTO';

export const updatePaymentMethodAndProvider = (
  onChange: (paymentMethod?: PaymentMethod, paymentProvider?: PaymentProvider) => void,
  paymentMethod?: PaymentMethod,
  paymentProvider?: PaymentProvider,
  paymentSettings?: PaymentSettingsDTO
): void => {
  if (paymentMethod === PaymentMethod.ONLINE) {
    if (paymentProvider === PaymentProvider.ALFA && !paymentSettings?.alfaServiceEnabled) {
      if (paymentSettings?.orcServiceEnabled) {
        onChange(PaymentMethod.ONLINE, PaymentProvider.ORC);
      } else if (paymentSettings?.sbpServiceEnabled) {
        onChange(PaymentMethod.SBP, PaymentProvider.ALFA);
      } else {
        onChange(PaymentMethod.CARD, PaymentProvider.ALFA);
      }
    } else if (paymentProvider === PaymentProvider.ORC && !paymentSettings?.orcServiceEnabled) {
      if (paymentSettings?.alfaServiceEnabled) {
        onChange(PaymentMethod.ONLINE, PaymentProvider.ALFA);
      } else if (paymentSettings?.sbpServiceEnabled) {
        onChange(PaymentMethod.SBP, PaymentProvider.ALFA);
      } else {
        onChange(PaymentMethod.CARD, PaymentProvider.ALFA);
      }
    }
  } else if (paymentMethod === PaymentMethod.SBP) {
    if (paymentProvider === PaymentProvider.ALFA && !paymentSettings?.sbpServiceEnabled) {
      if (paymentSettings?.alfaServiceEnabled) {
        onChange(PaymentMethod.ONLINE, PaymentProvider.ALFA);
      } else if (paymentSettings?.orcServiceEnabled) {
        onChange(PaymentMethod.ONLINE, PaymentProvider.ORC);
      } else {
        onChange(PaymentMethod.CARD, PaymentProvider.ALFA);
      }
    }
  }
};
