import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const VkIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M15.0729 2H8.9375C3.33333 2 2 3.33333 2 8.92708V15.0625C2 20.6667 3.32292 22 8.92708 22H15.0625C20.6667 22 22 20.6771 22 15.0729V8.9375C22 3.33333 20.6771 2 15.0729 2ZM18.2181 8C18.6777 8 18.7761 8.22105 18.6777 8.53684C18.5133 9.29935 17.034 11.3597 16.6912 11.8372C16.646 11.9002 16.6205 11.9356 16.6205 11.9368C16.4564 12.1789 16.3908 12.3053 16.6205 12.5895C16.6988 12.7024 16.877 12.8702 17.0807 13.0622C17.2758 13.246 17.4943 13.4518 17.671 13.6526C18.3275 14.3684 18.8199 14.9684 18.9622 15.3789C19.0935 15.7895 18.8746 16 18.4369 16H16.905C16.4986 16 16.291 15.7777 15.8454 15.3005C15.6552 15.0968 15.4215 14.8466 15.1105 14.5474C14.2023 13.7053 13.8194 13.6 13.5896 13.6C13.2722 13.6 13.1847 13.6842 13.1847 14.1053V15.4316C13.1847 15.7895 13.0643 16 12.0905 16C10.4711 16 8.6875 15.0526 7.41821 13.3053C5.51428 10.7474 5 8.81053 5 8.42105C5 8.2 5.08754 8 5.52522 8H7.05712C7.45104 8 7.59328 8.16842 7.74647 8.5579C8.49054 10.6632 9.75983 12.4947 10.2741 12.4947C10.4711 12.4947 10.5586 12.4105 10.5586 11.9368V9.76842C10.5182 9.1602 10.296 8.89403 10.1307 8.69609C10.0271 8.57201 9.94584 8.47474 9.94584 8.33684C9.94584 8.17895 10.099 8 10.3398 8H12.747C13.0753 8 13.1847 8.15789 13.1847 8.53684V11.4632C13.1847 11.7789 13.327 11.8842 13.4254 11.8842C13.6224 11.8842 13.7756 11.7789 14.1367 11.4316C15.2418 10.2526 16.0187 8.42105 16.0187 8.42105C16.1172 8.2 16.2923 8 16.6862 8H18.2181Z'
      }
    />
  </SvgIcon>
);
