import { AttachButton } from '@/components/common/button/AttachButton';
import { AttachFileTableDTO } from '@/types/attachment/AttachFileTableDTO';
import { EnclosureDTO } from '@/types/enclosure/EnclosureDTO';
import { EnclosureIcon } from '@/app/icons/enclosure/EnclosureIcon';
import { EnclosureType } from '@/types/enclosure/EnclosureType';
import { Grid } from '@mui/material';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { Theme } from '@mui/material/styles';
import React, { FC, MouseEvent } from 'react';

interface Props {
  letter: LetterTableDTO;
  letterId?: number;
  isAttachFileLoading?: boolean;
  onAttachClick: (letter: LetterTableDTO) => void;
}

export const AttachmentRow: FC<Props> = (props: Props) => {
  const { letter, letterId, isAttachFileLoading } = props;

  const handleAttachClick = (letter: LetterTableDTO): void => {
    const { onAttachClick } = props;
    if (letter) {
      onAttachClick(letter);
    }
  };

  const sudokuEnclosures: EnclosureDTO[] = (letter?.enclosures || []).filter((enclosure: EnclosureDTO): boolean => {
    return enclosure.type === EnclosureType.SUDOKU;
  });

  const crosswordEnclosures: EnclosureDTO[] = (letter?.enclosures || []).filter((enclosure: EnclosureDTO): boolean => {
    return enclosure.type === EnclosureType.CROSSWORD;
  });

  const newsEnclosures: EnclosureDTO[] = (letter?.enclosures || []).filter((enclosure: EnclosureDTO): boolean => {
    return enclosure.type === EnclosureType.NEWS;
  });

  return (
    <Grid container={true} direction={'row'} alignItems={'center'} wrap={'nowrap'} spacing={1}>
      {(letter?.attachFiles || []).map((attachFile: AttachFileTableDTO) => (
        <Grid item={true} key={`attachFile-${attachFile.id}`}>
          <AttachButton
            isLoading={isAttachFileLoading && Number(letterId) === Number(letter.id)}
            disabled={isAttachFileLoading}
            onClick={(e: MouseEvent<HTMLButtonElement>): void => {
              e.stopPropagation();
              handleAttachClick(letter);
            }}
          />
        </Grid>
      ))}
      {(sudokuEnclosures || []).length > 0 && (
        <Grid item={true}>
          <EnclosureIcon
            type={EnclosureType.SUDOKU}
            sx={{
              display: 'flex',
              height: (theme: Theme) => theme.spacing(3),
              color: (theme: Theme) => theme.colors.iconColor,
            }}
          />
        </Grid>
      )}
      {(crosswordEnclosures || []).length > 0 && (
        <Grid item={true}>
          <EnclosureIcon
            type={EnclosureType.SUDOKU}
            sx={{
              display: 'flex',
              height: (theme: Theme) => theme.spacing(3),
              color: (theme: Theme) => theme.colors.iconColor,
            }}
          />
        </Grid>
      )}
      {(newsEnclosures || []).length > 0 && (
        <Grid item={true}>
          <EnclosureIcon
            type={EnclosureType.NEWS}
            sx={{
              display: 'flex',
              height: (theme: Theme) => theme.spacing(3),
              color: (theme: Theme) => theme.colors.iconColor,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};
