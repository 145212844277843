export enum LetterStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  ANSWER_UPLOADED = 'ANSWER_UPLOADED',
  CITY_COURT = 'CITY_COURT',
  DECLINED = 'DECLINED',
  PROCESSED = 'PROCESSED',
  PAID_INITIATIVE = 'PAID_INITIATIVE',
}
