import { Link } from 'react-router-dom';
import { Path } from 'history';
import { Theme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  to: Path;
}

export const FooterLink: FC<Props> = (props) => {
  const { to, children } = props;

  return (
    <Link to={to}>
      <Typography
        variant={'body2'}
        sx={(theme: Theme) => ({
          color: theme.colors.primary,
          fontWeight: 400,
          '&:hover': {
            cursor: 'pointer',
          },
        })}>
        {children}
      </Typography>
    </Link>
  );
};
