import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ZoomOutIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M10.1445 18.418C11.9609 18.418 13.6484 17.8438 15.043 16.8711L20.1641 22.0039C20.4336 22.2617 20.7852 22.3906 21.1602 22.3906C21.9453 22.3906 22.5078 21.793 22.5078 21.0195C22.5078 20.6562 22.3906 20.3047 22.1211 20.0352L17.0352 14.9375C18.1016 13.5078 18.7344 11.7383 18.7344 9.82812C18.7344 5.10547 14.8672 1.23828 10.1445 1.23828C5.42188 1.23828 1.55469 5.09375 1.55469 9.82812C1.55469 14.5508 5.41016 18.418 10.1445 18.418ZM10.1445 16.4141C6.52344 16.4141 3.54688 13.4375 3.54688 9.82812C3.54688 6.21875 6.52344 3.24219 10.1445 3.24219C13.7539 3.24219 16.7305 6.21875 16.7305 9.82812C16.7305 13.4375 13.7539 16.4141 10.1445 16.4141ZM7.28516 10.6602H12.9922C13.4492 10.6602 13.8242 10.2852 13.8242 9.82812C13.8242 9.37109 13.4492 8.99609 12.9922 8.99609H7.28516C6.82812 8.99609 6.45312 9.37109 6.45312 9.82812C6.45312 10.2852 6.82812 10.6602 7.28516 10.6602Z'
      }
    />
  </SvgIcon>
);
