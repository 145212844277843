import { Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends BoxProps {
  sxOverride?: SxProps<Theme>;
}

export const Dot: FC<Props> = (props: Props) => {
  const { sxOverride, ...other } = props;
  return (
    <Box
      sx={{
        width: (theme: Theme) => theme.spacing(1),
        height: (theme: Theme) => theme.spacing(1),
        backgroundColor: (theme: Theme) => theme.colors.primary,
        borderRadius: '50%',
        ...sxOverride,
      }}
      {...other}
    />
  );
};
