import { AuthorDTO } from '@/types/AuthorDTO';
import { Button, Grid, Typography } from '@mui/material';
import { EnvelopeIcon } from '@/app/icons/EnvelopeIcon';
import { ROUTE_INBOX } from '@/app/routes';
import { TypographyLink } from '@/components/common/TypographyLink';
import { getAuthor } from '@/services/createFormSlice';
import { useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}

export const LetterSuccess: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const history = useHistory();
  const author: AuthorDTO | null = useAppSelector(getAuthor);
  const handleReturnMain = (): void => {
    history.push(ROUTE_INBOX);
  };
  return (
    <Grid
      container={true}
      spacing={0}
      direction={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ minHeight: '68vh' }}>
      <Grid item={true} pb={1.5}>
        <EnvelopeIcon sx={{ width: '68px', height: '68px', display: 'block' }} />
      </Grid>
      <Grid item={true} pb={1}>
        <Typography variant={'h1'}>{'Письмо отправлено!'}</Typography>
      </Grid>
      <Grid item={true} pb={3}>
        <Typography component={'div'} variant={'body1'} textAlign={'center'} whiteSpace={'pre-line'}>
          {isMobile
            ? 'Следите за доставкой в личном кабинете или в рассылке на адрес\n'
            : 'Следите за доставкой в личном кабинете\nили в рассылке на адрес '}
          <TypographyLink>{author?.email}</TypographyLink>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Button color={'primary'} variant={'outlined'} sx={{ width: '324px' }} onClick={() => handleReturnMain()}>
          {'Вернуться на главную'}
        </Button>
      </Grid>
    </Grid>
  );
};
