import { Theme, Typography, TypographyProps } from '@mui/material';
import React, { FC } from 'react';

export const TypographyLink: FC<TypographyProps> = (props: TypographyProps) => {
  const { children, ...other } = props;
  return (
    <Typography
      {...other}
      variant={'inherit'}
      component={'span'}
      sx={{
        display: 'inline',
        cursor: 'pointer',
        color: (theme: Theme) => theme.colors.primary,
        ':hover': {
          color: (theme: Theme) => theme.colors.primaryDark,
        },
      }}>
      {children}
    </Typography>
  );
};
