import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { setForeignState } from '@/services/ipLocationSlice';

export const ipLocationMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (action.meta?.baseQueryMeta?.response?.status !== 200) {
    return next(action);
  }
  const headers: Headers | undefined = action.meta?.baseQueryMeta?.response.headers;
  const foreignState = headers?.get('X-Foreign-State');
  if (foreignState !== null) {
    api.dispatch(setForeignState(foreignState));
  }
  return next(action);
};
