import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const MoneyIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    viewBox={'0 0 24 24'}
    sx={{
      width: '24px',
      height: '24px',
      display: 'flex',
      fill: 'none',
    }}
    {...props}>
    <path
      d={
        'M18.3261 21C16.5368 22.2601 14.3548 23 12 23C9.64517 23 7.46318 22.2601 5.67383 21M18.3261 3C16.5368 1.73994 14.3548 1 12 1C9.64517 1 7.46318 1.73994 5.67383 3'
      }
      strokeLinecap={'round'}
    />
    <rect x={'2.5'} y={'5.5'} width={'19'} height={'13'} rx={'1.5'} />
    <path
      d={
        'M10.1719 16V7.43201H12.4279C13.4759 7.43201 14.2519 7.64401 14.7559 8.06801C15.2679 8.49201 15.5239 9.10801 15.5239 9.91601C15.5239 10.46 15.3999 10.928 15.1519 11.32C14.9039 11.704 14.5359 12 14.0479 12.208C13.5679 12.416 12.9719 12.52 12.2599 12.52H11.2519V16H10.1719ZM9.12793 14.368V13.588H13.2319V14.368H9.12793ZM9.12793 12.52V11.608H11.8759V12.52H9.12793ZM12.1039 11.608C12.5919 11.608 13.0039 11.556 13.3399 11.452C13.6839 11.348 13.9479 11.176 14.1319 10.936C14.3159 10.696 14.4079 10.368 14.4079 9.95201C14.4079 9.40801 14.2399 9.00401 13.9039 8.74001C13.5679 8.47601 13.0439 8.34401 12.3319 8.34401H11.2519V11.608H12.1039Z'
      }
    />
  </SvgIcon>
);
