export enum AnswerStatus {
  INITIALIZED = 'INITIALIZED',
  CREATED = 'CREATED',
  NOT_ATTACHED = 'NOT_ATTACHED',
  LETTER_NOT_FOUND = 'LETTER_NOT_FOUND',
  DUPLICATE = 'DUPLICATE',
  QR_NOT_FOUND = 'QR_NOT_FOUND',
  LETTER_NOT_DELIVERED = 'LETTER_NOT_DELIVERED',
  LETTER_DECLINED = 'LETTER_DECLINED',
  ATTACHED = 'ATTACHED',
  PROCESSED = 'PROCESSED',
  LETTER_FROM_OTHER_DEPARTMENT = 'LETTER_FROM_OTHER_DEPARTMENT',
  HRC_NOT_FOUND = 'HRC_NOT_FOUND',
  HRC_NOT_ACTIVE = 'HRC_NOT_ACTIVE',
}
