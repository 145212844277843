import { CheckIcon } from '@/app/icons/CheckIcon';
import { CircularProgress, InputAdornment, Theme } from '@mui/material';
import { OutlinedField, OutlinedFieldProps } from './OutlinedField';
import React, { FC } from 'react';

interface Props extends OutlinedFieldProps {
  isLoading?: boolean;
  isSuccess?: boolean;
}

const LoadingOutlinedField: FC<Props> = (props: Props) => {
  const { isSuccess, isLoading, onChange, ...other } = props;
  return (
    <OutlinedField
      {...other}
      sx={{
        '& .MuiInputAdornment-root': {
          minWidth: '24px',
        },
        '& .MuiOutlinedInput-input': {
          paddingRight: '0px',
        },
      }}
      onChange={isLoading ? () => null : onChange}
      endAdornment={
        <InputAdornment position={'end'}>
          {isLoading ? (
            <CircularProgress color={'primary'} size={24} />
          ) : isSuccess ? (
            <CheckIcon sx={{ color: (theme: Theme) => theme.colors.green }} />
          ) : null}
        </InputAdornment>
      }
    />
  );
};

export default LoadingOutlinedField;
