export interface Config {
  nodeEnv?: string;
  links: {
    social: {
      twitter?: string;
      facebook?: string;
      vk?: string;
    };
    money?: string;
    atlas?: string;
    window?: string;
    drugstore?: string;
    fsinet?: string;
    letter?: string;
    prisonmail?: string;
  };
  baseUrl?: string;
  basePath?: string;
  reCaptcha: {
    siteKey: string;
  };
}

export const config: Config = {
  nodeEnv: process.env.NODE_ENV,
  links: {
    social: {
      twitter: process.env.TWITTER_URL,
      facebook: process.env.FACEBOOK_URL,
      vk: process.env.VK_URL,
    },
    money: process.env.MONEY_URL,
    atlas: process.env.ATLAS_URL,
    window: process.env.WINDOW_URL,
    drugstore: process.env.DRUGSTORE_URL,
    fsinet: process.env.FSINET_URL,
    letter: process.env.LETTER_URL,
    prisonmail: process.env.PRISONMAIL_URL,
  },
  baseUrl: process.env.BASE_URL,
  basePath: process.env.BASE_PATH,
  reCaptcha: {
    siteKey: process.env.SITE_KEY ? process.env.SITE_KEY : '6LfIAS8fAAAAAH0joW2NomRE6lLbouot0yTPXBij',
  },
};

export const GET_OFFER_URL: string = config.baseUrl + '/static/offer';
export const GET_POLICY_URL: string = config.basePath + '/fz152.doc';
