import { FormControlLabel, FormControlLabelProps, Grid, Radio, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

export interface PaymentRadioProps extends Omit<Omit<FormControlLabelProps, 'control'>, 'label'> {
  label?: ReactNode;
  caption?: ReactNode;
}

export const PaymentRadio: FC<PaymentRadioProps> = (props: PaymentRadioProps) => {
  const { label, caption, disabled, ...other } = props;
  return (
    <Grid container={true} direction={'column'} spacing={0.5}>
      <Grid item={true}>
        <FormControlLabel
          {...other}
          disabled={disabled}
          label={
            <Typography variant={'body1'} component={'div'} sx={{ opacity: disabled ? 0.5 : 1 }}>
              {label}
            </Typography>
          }
          control={
            <Radio
              sx={{
                padding: '7px',
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                },
              }}
            />
          }
          sx={{ marginLeft: '-9px' }}
        />
      </Grid>
      {caption && (
        <Grid item={true} marginLeft={3}>
          <Typography variant={'body2'} component={'div'} color={(theme: Theme) => theme.colors.grayText}>
            {caption}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
