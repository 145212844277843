import { AnswerPaymentInfo } from '@/components/create/unauthorized/AnswerPaymentInfo';
import { BalanceInfo } from '@/components/create/unauthorized/BalanceInfo';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { FullName } from '@/types/FullName';
import { LetterPaymentInfo } from '@/components/create/unauthorized/LetterPaymentInfo';
import { ROUTE_OUTBOX } from '@/app/routes';
import { RootState } from '@/app/store';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SignUpDTO } from '@/types/auth/SignUpDTO';
import { SpbPaymentContainer } from '@/components/common/sbp/SpbPaymentContainer';
import { getSbpQR } from '@/services/sbpSlice';
import { parseFullName } from '@/utils/string-utils';
import { useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import { useSignUpMutation } from '@/services/api/authApiSlice';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  attachFile?: File;
  sudokuAttached: boolean;
  sudokuCount?: number;
  crosswordAttached: boolean;
  newsAttached: boolean;
  isAnswerOrdered?: boolean;
  letterCost?: number;
  authorizedLetterCost?: number;
  photoCost?: number;
  authorizedPhotoCost?: number;
  totalCost?: number;
  authorizedTotalCost?: number;
  afterSuccessPath?: string;
  letter: CreateLetterDTO;
  isMobile?: boolean;
  isLetterSent: boolean;
  fromCreateAnswer?: boolean;
  pages?: number;
  countCharacters?: number;
  answersCount?: number;
  isInitiative?: boolean;
  isInitiativeSubmitAllowed?: boolean;
  idempotencyKey?: string;
  onSbpQrPaymentSuccess?: () => void;
  isPaymentSuccess?: boolean;
}

export const LetterBalance: FC<Props> = (props: Props) => {
  const {
    attachFile,
    sudokuAttached,
    sudokuCount,
    crosswordAttached,
    newsAttached,
    isAnswerOrdered,
    letterCost,
    authorizedLetterCost,
    photoCost,
    authorizedPhotoCost,
    totalCost,
    authorizedTotalCost,
    letter,
    isLetterSent,
    isMobile,
    fromCreateAnswer,
    pages,
    countCharacters,
    answersCount,
    isInitiative,
    isInitiativeSubmitAllowed,
    idempotencyKey,
    onSbpQrPaymentSuccess,
    isPaymentSuccess,
  } = props;

  const history = useHistory();

  const sbpQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState) =>
    getSbpQR(state, SbpMethod.LETTER, undefined, false, isInitiative)
  );

  const [signUp] = useSignUpMutation();

  const [isProfileCreated, setProfileCreated] = useState<boolean>(false);
  const [cardInfoMessage, setCardInfoMessage] = useState<string>('');

  const handleRegistrationClick = async (): Promise<void> => {
    const fullName: FullName | null = parseFullName(letter.author?.name);
    const dto: SignUpDTO = {
      username: letter.author?.email || '',
      lastName: fullName?.lastName || '',
      firstName: fullName?.firstName || '',
      middleName: fullName?.middleName,
    };
    await signUp(dto)
      .unwrap()
      .then((): void => {
        setProfileCreated(true);
      })
      .catch((): void => {
        history.replace(ROUTE_OUTBOX);
      });
  };

  return (
    <Fragment>
      {isLetterSent && isMobile ? (
        sbpQR ? (
          <SpbPaymentContainer
            sbpQR={sbpQR}
            isMobile={true}
            sbpMethod={SbpMethod.LETTER}
            isInitiative={isInitiative}
            email={isInitiative ? letter.email || '' : letter.author?.email || ''}
            onSuccess={onSbpQrPaymentSuccess}
          />
        ) : fromCreateAnswer ? (
          <AnswerPaymentInfo letter={letter} message={cardInfoMessage} isPaymentSuccess={isPaymentSuccess} />
        ) : (
          <LetterPaymentInfo
            letter={letter}
            isInitiative={isInitiative}
            cardInfoMessage={cardInfoMessage}
            isProfileCreated={isProfileCreated}
            onRegistrationClick={handleRegistrationClick}
            isPaymentSuccess={isPaymentSuccess}
          />
        )
      ) : (
        <BalanceInfo
          attachFile={attachFile}
          sudokuAttached={sudokuAttached}
          sudokuCount={sudokuCount}
          crosswordAttached={crosswordAttached}
          newsAttached={newsAttached}
          isAnswerOrdered={isAnswerOrdered}
          letterCost={letterCost}
          authorizedLetterCost={authorizedLetterCost}
          photoCost={photoCost}
          authorizedPhotoCost={authorizedPhotoCost}
          totalCost={totalCost}
          authorizedTotalCost={authorizedTotalCost}
          letter={letter}
          isLetterSent={isLetterSent}
          isMobile={isMobile}
          fromCreateAnswer={fromCreateAnswer}
          pages={pages}
          countCharacters={countCharacters}
          answersCount={answersCount}
          isProfileCreated={isProfileCreated}
          handleRegistrationClick={handleRegistrationClick}
          cardInfoMessage={cardInfoMessage}
          setCardInfoMessage={setCardInfoMessage}
          sbpQR={sbpQR}
          sbpMethod={SbpMethod.LETTER}
          isInitiative={isInitiative}
          isInitiativeSubmitAllowed={isInitiativeSubmitAllowed}
          idempotencyKey={idempotencyKey}
          onSbpQrPaymentSuccess={onSbpQrPaymentSuccess}
          isPaymentSuccess={isPaymentSuccess}
        />
      )}
    </Fragment>
  );
};
