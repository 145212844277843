import * as Yup from 'yup';
import { BalanceState } from '@/types/BalanceState';
import { ButtonLink } from '@/components/common/ButtonLink';
import { ErrorDTO } from '@/types/ErrorDTO';
import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { FullName } from '@/types/FullName';
import { Grid, IconButton, InputAdornment, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  MESSAGE_MIN_PASSWORD_LENGTH,
  MESSAGE_PASSWORD_CAPITAL_LETTER,
  MESSAGE_PASSWORD_NOT_EQUALS,
  MESSAGE_PASSWORD_NUMBER,
  MESSAGE_REQUIRED,
  passwordCapitalLetterRegExp,
  passwordNumberRegExp,
} from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import { ROUTE_AUTHENTICATION, ROUTE_REGISTRATION_SUCCESS } from '@/app/routes';
import { SignUpDTO } from '@/types/auth/SignUpDTO';
import { Theme } from '@mui/material/styles';
import { emailSchema } from '@/validation/emailSchema';
import { fullNameSchema } from '@/validation/fullNameSchema';
import { isBlank, parseFullName } from '@/utils/string-utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useSignUpMutation } from '@/services/api/authApiSlice';
import React, { ChangeEvent, FC, MouseEvent, useState } from 'react';

interface FormValues {
  fullName?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export const RegistrationForm: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const { state } = useLocation<BalanceState>();
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const handleShowNewPasswordClick = (): void => {
    setShowNewPassword(!showNewPassword);
  };
  const handleShowConfirmPasswordClick = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const [signUp, { isLoading }] = useSignUpMutation();
  const initialValues: FormValues = {
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    fullName: isBlank(initialValues.fullName) ? MESSAGE_REQUIRED : undefined,
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    fullName: fullNameSchema.required(MESSAGE_REQUIRED).nullable(),
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    password: Yup.string()
      .required(MESSAGE_REQUIRED)
      .matches(passwordNumberRegExp, MESSAGE_PASSWORD_NUMBER)
      .matches(passwordCapitalLetterRegExp, MESSAGE_PASSWORD_CAPITAL_LETTER)
      .min(8, MESSAGE_MIN_PASSWORD_LENGTH),
    confirmPassword: Yup.string().required(MESSAGE_REQUIRED),
  });

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    if (values.confirmPassword !== values.password) {
      helpers.setFieldError('password', MESSAGE_PASSWORD_NOT_EQUALS);
    } else {
      const fullName: FullName | null = parseFullName(values.fullName);
      const dto: SignUpDTO = {
        username: values.email || '',
        lastName: fullName?.lastName || '',
        firstName: fullName?.firstName || '',
        middleName: fullName?.middleName,
        password: values.password,
      };
      await signUp(dto)
        .unwrap()
        .then(() => {
          history.replace(ROUTE_REGISTRATION_SUCCESS, state);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          const { setFieldError } = helpers;
          setFieldError('email', e.data.message);
        });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({ values, touched, errors, setFieldValue, setFieldTouched, submitForm, isValid }: FormikProps<FormValues>) => {
        const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
          setFieldTouched(e.target.name, true, false);
          setFieldValue(e.target.name, e.target.value, true);
        };
        return (
          <Grid container={true} direction={'column'} alignItems={'center'} spacing={5}>
            <Grid item={true}>
              <Grid container={true} direction={'column'} alignItems={'center'} spacing={isMobile ? 0.5 : 2}>
                <Grid item={true}>
                  <Typography
                    variant={'h1'}
                    sx={(theme: Theme) => ({
                      lineHeight: '20px',
                      [theme.breakpoints.down('sm')]: {
                        lineHeight: '32px',
                      },
                    })}>
                    {'Регистрация'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} alignItems={'baseline'} spacing={1}>
                    <Grid item={true}>
                      <Typography variant={'body2'}>{'Уже есть личный кабинет?'}</Typography>
                    </Grid>
                    <Grid item={true}>
                      <ButtonLink color={'primary'} to={ROUTE_AUTHENTICATION} state={state} noHover={true}>
                        <Typography variant={'body2'} fontWeight={500}>
                          {'Войти'}
                        </Typography>
                      </ButtonLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} width={'100%'}>
              <Grid container={true} direction={'column'} spacing={3}>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    fullWidth={true}
                    name={'fullName'}
                    label={'ФИО'}
                    withLabel={true}
                    required={true}
                    value={values.fullName}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName ? errors.fullName : ''}
                    onChange={handleSetFieldValue}
                  />
                </Grid>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    fullWidth={true}
                    name={'email'}
                    label={'Электронная почта'}
                    withLabel={true}
                    required={true}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ''}
                    onChange={handleSetFieldValue}
                    isNotAbsoluteHelperText={true}
                  />
                </Grid>
                <Grid item={true} width={'100%'}>
                  <Stack direction={'column'} spacing={1}>
                    <OutlinedField
                      fullWidth={true}
                      name={'password'}
                      label={'Пароль'}
                      withLabel={true}
                      required={true}
                      value={values.password}
                      error={touched.password && Boolean(errors.password)}
                      onChange={handleSetFieldValue}
                      isNotAbsoluteHelperText={true}
                      type={showNewPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position={'end'}>
                          <IconButton
                            aria-label={'toggle password visibility'}
                            size={'dense'}
                            edge={'end'}
                            sx={{ padding: 0, margin: 0 }}
                            onClick={handleShowNewPasswordClick}
                            onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                              e.preventDefault();
                            }}>
                            {showNewPassword ? (
                              <EyeIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            ) : (
                              <EyeCrossedIcon
                                sx={{
                                  width: (theme: Theme) => theme.spacing(4),
                                  height: (theme: Theme) => theme.spacing(4),
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <Typography variant={'body3'} component={'p'} color={(theme: Theme) => theme.colors.grayText}>
                      {'От 8 символов, должны быть как минимум 1 заглавная буква и 1 цифра'}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    fullWidth={true}
                    name={'confirmPassword'}
                    label={'Подтвердите пароль'}
                    withLabel={true}
                    required={true}
                    value={values.confirmPassword}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ''}
                    onChange={handleSetFieldValue}
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position={'end'}>
                        <IconButton
                          aria-label={'toggle password visibility'}
                          size={'dense'}
                          edge={'end'}
                          sx={{ padding: 0, margin: 0 }}
                          onClick={handleShowConfirmPasswordClick}
                          onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                          }}>
                          {showConfirmPassword ? (
                            <EyeIcon
                              sx={{
                                width: (theme: Theme) => theme.spacing(4),
                                height: (theme: Theme) => theme.spacing(4),
                              }}
                            />
                          ) : (
                            <EyeCrossedIcon
                              sx={{
                                width: (theme: Theme) => theme.spacing(4),
                                height: (theme: Theme) => theme.spacing(4),
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} width={'100%'}>
              <PolicyLoadingButton
                variant={'contained'}
                color={'primary'}
                fullWidth={true}
                type={'submit'}
                onClick={submitForm}
                isLoading={isLoading}
                disabled={isLoading || !isValid || !policyChecked}
                policyChecked={policyChecked}
                onPolicyChange={setPolicyChecked}>
                {'Создать личный кабинет'}
              </PolicyLoadingButton>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
