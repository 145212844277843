import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { PaymentStatusInfo } from '@/components/create/unauthorized/PaymentStatusInfo';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  letter: CreateLetterDTO;
  message?: string;
}

export const AnswerPaymentSuccessInfo: FC<Props> = (props: Props) => {
  const { letter, message } = props;

  return (
    <PaymentStatusInfo
      variant={'success'}
      title={'Бланки ответа оплачены'}
      message={message}
      content={
        <Typography variant={'body1'}>
          {`Теперь ${letter.sender?.lastName} ${letter.sender?.firstName} сможет отправить вам письмо. Ожидайте ответ на `}
          <Typography component={'span'} variant={'inherit'} fontWeight={500}>
            {letter.author?.email}
          </Typography>
        </Typography>
      }
    />
  );
};
