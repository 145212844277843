import { CardDTO } from '@/types/card/CardDTO';
import { ChangeDepartmentDTO } from '@/types/letter/ChangeDepartmentDTO';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { InitiativeLetterDTO } from '@/types/letter/InitiativeLetterDTO';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { LetterDTO } from '@/types/letter/LetterDTO';
import { LetterEmailDTO } from '@/types/letter/LetterEmailDTO';
import { LetterRequestDTO } from '@/types/letter/LetterRequestDTO';
import { LetterSearchRequestDTO } from '@/types/letter/LetterSearchRequestDTO';
import { LetterStatusDTO } from '@/types/letter/LetterStatusDTO';
import { LetterStatusHistoryDTO } from '@/types/letter/LetterStatusHistoryDTO';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { LetterViewDTO } from '@/types/letter/LetterViewDTO';
import { Page } from '@/types/dto';
import { apiSlice } from '@/services/api/apiSlice';

export const letterApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLetters: build.query<Page<LetterTableDTO>, LetterRequestDTO & { userId: number | undefined }>({
      query: (dto: LetterRequestDTO & { userId: number | undefined }) => ({
        url: '/letter/search',
        method: HttpMethod.POST,
        body: dto,
      }),
      providesTags: ['Letters'],
    }),
    getLettersCount: build.query<LetterCountDTO, LetterSearchRequestDTO & { userId: number | undefined }>({
      query: (dto: LetterSearchRequestDTO & { userId: number | undefined }) => ({
        url: '/letter/count',
        method: HttpMethod.POST,
        body: dto,
      }),
      providesTags: ['LettersCount'],
    }),
    getLetter: build.query<LetterViewDTO, number>({
      query: (letterId: number) => ({
        url: '/letter/id',
        method: HttpMethod.GET,
        params: {
          letterId: letterId,
        },
      }),
    }),
    getLetterByUuid: build.query<LetterDTO, string | undefined>({
      query: (uuid: string | undefined) => ({
        url: '/letter',
        method: HttpMethod.GET,
        params: {
          uuid: uuid,
        },
      }),
    }),
    createAndPay: build.mutation<LetterDTO, { letter: CreateLetterDTO; image?: File; idempotencyKey?: string }>({
      query: ({ letter, image, idempotencyKey }) => ({
        url: '/letter/create-and-pay',
        method: HttpMethod.POST,
        body: createLetter(letter, image),
        headers: {
          'X-Access-Token': 'fcf5ca14-bb76-44ee-b737-179c2da942c7',
          'Idempotency-Key': idempotencyKey,
        },
      }),
      invalidatesTags: ['Balance', 'BalanceHistory', 'Letters', 'LettersCount', 'Senders', 'SystemEvents'],
    }),
    setLetterRead: build.mutation<boolean, number>({
      query: (letterId: number) => ({
        url: '/letter/read/id',
        method: HttpMethod.GET,
        params: {
          letterId: letterId,
        },
      }),
      invalidatesTags: ['Letters', 'LettersCount'],
    }),
    changeDepartment: build.mutation<LetterStatusDTO, { dto: ChangeDepartmentDTO; reCaptchaToken?: string }>({
      query: ({ dto, reCaptchaToken }) => ({
        url: '/letter/department/change',
        method: HttpMethod.POST,
        body: dto,
        headers: {
          'Re-Captcha-Token': reCaptchaToken,
        },
      }),
      invalidatesTags: (dto: LetterStatusDTO | undefined) => {
        if (dto && !dto.error) {
          return ['Letters', 'LettersCount', 'LetterHistory', 'Senders', 'SystemEvents'];
        }
        return [];
      },
    }),
    checkLetterStatus: build.mutation<
      LetterStatusHistoryDTO,
      { letterNumber: number; email: string; reCaptchaToken?: string }
    >({
      query: ({ letterNumber, email, reCaptchaToken }) => ({
        url: '/letter/status-history',
        method: HttpMethod.GET,
        params: {
          letterNumber: letterNumber,
          email: email,
        },
        headers: {
          'Re-Captcha-Token': reCaptchaToken,
        },
      }),
    }),
    createLetter: build.mutation<
      LetterDTO,
      {
        letter: CreateLetterDTO;
        image?: File;
        reCaptchaToken?: string;
        idempotencyKey?: string;
      }
    >({
      query: ({ letter, image, reCaptchaToken, idempotencyKey }) => ({
        url: '/letter/create',
        method: HttpMethod.POST,
        body: createLetter(letter, image),
        headers: {
          'X-Access-Token': 'fcf5ca14-bb76-44ee-b737-179c2da942c7',
          'Re-Captcha-Token': reCaptchaToken,
          'Idempotency-Key': idempotencyKey,
        },
      }),
      invalidatesTags: ['Balance', 'BalanceHistory', 'Letters', 'LettersCount', 'Senders', 'SystemEvents'],
    }),
    confirmLetterPaymentByCardUsingGet: build.mutation<CardDTO, string>({
      query: (uuid: string) => ({
        url: `/letter/confirm-card-payment`,
        method: HttpMethod.GET,
        params: { uuid: uuid },
      }),
    }),
    getInitiativeLetter: build.query<InitiativeLetterDTO, string | null>({
      query: (uuid: string | null) => ({
        url: '/letter/initiative',
        method: HttpMethod.GET,
        params: {
          uuid: uuid,
        },
      }),
    }),
    checkImage: build.mutation<void, File>({
      query: (image: File) => ({
        url: '/letter/check-image',
        method: HttpMethod.POST,
        body: checkImageBody(image),
      }),
    }),
    setEmailToLetter: build.mutation<void, LetterEmailDTO>({
      query: (dto: LetterEmailDTO) => ({
        url: '/letter/set-email',
        method: HttpMethod.POST,
        body: dto,
      }),
    }),
  }),
});

const checkImageBody = (image: File): FormData => {
  const formData: FormData = new FormData();
  formData.append('image', image);
  return formData;
};

const createLetter = (letter: CreateLetterDTO, image?: File): FormData => {
  const formData: FormData = new FormData();
  formData.append(
    'letter',
    new Blob([JSON.stringify({ ...letter, satelliteId: 888 })], {
      type: 'application/json',
    })
  );
  if (image) {
    formData.append('image', image);
  }
  return formData;
};

export const {
  useGetLettersQuery,
  useGetLettersCountQuery,
  useGetLetterQuery,
  useGetLetterByUuidQuery,
  useCreateAndPayMutation,
  useSetLetterReadMutation,
  useChangeDepartmentMutation,
  useCheckLetterStatusMutation,
  useCreateLetterMutation,
  useConfirmLetterPaymentByCardUsingGetMutation,
  useGetInitiativeLetterQuery,
  useCheckImageMutation,
  useSetEmailToLetterMutation,
} = letterApiSlice;
