import { EventDTO } from '@/types/event/EventDTO';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { SystemEventDTO } from '@/types/event/system/SystemEventDTO';
import { SystemEventType } from '@/types/event/system/SystemEventType';
import { formatCurrency } from '@/utils/string-utils';
import { formatSmartShortTimeDateString } from '@/utils/date-utils';
import { getStatusLabel } from '@/utils/status-utils';

export const convertSystemEvent = (event: SystemEventDTO): EventDTO => {
  return {
    date: event?.timestamp,
    type: event?.type === SystemEventType.COMMON ? 'yellow' : 'grey',
    title: event?.title,
    description: getDescription(event),
  };
};

const getDescription = (event: SystemEventDTO): string => {
  switch (event?.type) {
    case SystemEventType.COMMON: {
      return event?.description || '';
    }
    case SystemEventType.LETTER_STATUS_CHANGE: {
      let content = 'Письмо';
      if (event?.letter?.number) {
        content = content.concat(' № ', String(event?.letter?.number));
      }
      if (event?.letter?.sentDate) {
        const date: string = formatSmartShortTimeDateString(event?.letter?.sentDate);
        content = content.concat(' от ', date);
      }
      const status: string | undefined = event?.statuses
        ? event?.statuses
            .map((status: LetterSimpleStatus) => {
              return getStatusLabel(status);
            })
            .join(', ')
        : event?.status;
      return content.concat(' переведено в статус «', String(status), '»');
    }
    case SystemEventType.LETTER_DEPARTMENT_CHANGE: {
      let content = 'Письмо';
      if (event?.letter?.number) {
        content = content.concat(' № ', String(event?.letter?.number));
      }
      if (event?.letter?.sentDate) {
        const date: string = formatSmartShortTimeDateString(event?.letter?.sentDate);
        content = content.concat(' от ', date);
      }
      content = content.concat(' переслано');
      if (event?.oldDepartment) {
        const name: string = [event?.oldDepartment?.departmentName, event?.oldDepartment?.regionName].join(', ');
        content = content.concat(' из «', name, '»');
      }
      if (event?.newDepartment) {
        const name: string = [event?.newDepartment?.departmentName, event?.newDepartment?.regionName].join(', ');
        content = content.concat(' в «', name, '»');
      } else {
        content = content.concat(' в другое учреждение');
      }
      return content;
    }
    case SystemEventType.BALANCE_INCOME: {
      let content = 'Пополнение баланса';
      if (event?.balanceHistory) {
        const amount: number = event?.balanceHistory?.newAmount - event?.balanceHistory?.oldAmount;
        content = content.concat(' на ', formatCurrency(amount));
      }
      return content;
    }
    default: {
      return event?.description || '';
    }
  }
};
