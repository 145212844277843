import { AppDispatch, RootState } from '@/app/store';
import { CardService } from '@/types/card/CardService';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SbpQrState } from '@/types/sbp/SbpQrState';

export interface SbpState {
  letterSbpQR: SbpQrState;
  balanceSbpQrByLetterPage: SbpQrState;
  balanceSbpQR: SbpQrState;
  cardCreateSbpQR: SbpQrState;
  cardRefillSbpQR: SbpQrState;
  initiativeSbpQR: SbpQrState;
  cardPaymentMethod: PaymentMethod;
  cardPaymentProvider?: PaymentProvider;
}

const initialSbpQrState: SbpQrState = {
  sbpOrderId: undefined,
  sbpQR: undefined,
  qrOutdated: false,
  paymentRedirect: false,
  paymentMethod: undefined,
  paymentProvider: undefined,
};

const initialState: SbpState = {
  letterSbpQR: initialSbpQrState,
  balanceSbpQrByLetterPage: initialSbpQrState,
  balanceSbpQR: initialSbpQrState,
  cardCreateSbpQR: initialSbpQrState,
  cardRefillSbpQR: initialSbpQrState,
  initiativeSbpQR: initialSbpQrState,
  cardPaymentMethod: PaymentMethod.SBP,
  cardPaymentProvider: PaymentProvider.ALFA,
};

export const sbpSlice = createSlice({
  name: 'sbp',
  initialState: initialState,
  reducers: {
    setLetterSbpOrderId: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.letterSbpQR.sbpOrderId = action.payload;
    },
    clearLetterSbpOrderId: (state: SbpState): void => {
      state.letterSbpQR.sbpOrderId = undefined;
    },
    setLetterSbpQR: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.letterSbpQR.sbpQR = action.payload;
    },
    clearLetterSbpQR: (state: SbpState): void => {
      state.letterSbpQR.sbpQR = undefined;
    },
    setLetterQrOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.letterSbpQR.qrOutdated = action.payload;
    },
    setLetterPaymentRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.letterSbpQR.paymentRedirect = action.payload;
    },
    setLetterPaymentPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.letterSbpQR.paymentMethod = action.payload;
    },
    setLetterPaymentPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.letterSbpQR.paymentProvider = action.payload;
    },
    clearLetterSbpState: (state: SbpState): void => {
      state.letterSbpQR.sbpOrderId = undefined;
      state.letterSbpQR.sbpQR = undefined;
      state.letterSbpQR.qrOutdated = false;
      state.letterSbpQR.paymentRedirect = false;
      state.letterSbpQR.paymentMethod = undefined;
      state.letterSbpQR.paymentProvider = undefined;
    },
    setBalanceSbpOrderId: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.balanceSbpQR.sbpOrderId = action.payload;
    },
    clearBalanceSbpOrderId: (state: SbpState): void => {
      state.balanceSbpQR.sbpOrderId = undefined;
    },
    setBalanceSbpQR: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.balanceSbpQR.sbpQR = action.payload;
    },
    clearBalanceSbpQR: (state: SbpState): void => {
      state.balanceSbpQR.sbpQR = undefined;
    },
    setBalanceQrOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.balanceSbpQR.qrOutdated = action.payload;
    },
    setBalanceFillAmount: (state: SbpState, action: PayloadAction<number>): void => {
      state.balanceSbpQR.amount = action.payload;
    },
    setBalancePaymentRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.balanceSbpQR.paymentRedirect = action.payload;
    },
    setBalancePaymentPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.balanceSbpQR.paymentMethod = action.payload;
    },
    setBalancePaymentPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.balanceSbpQR.paymentProvider = action.payload;
    },
    setBalanceSuccessFill: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.balanceSbpQR.successTransaction = action.payload;
    },
    clearBalanceSbpState: (state: SbpState): void => {
      state.balanceSbpQR.sbpOrderId = undefined;
      state.balanceSbpQR.sbpQR = undefined;
      state.balanceSbpQR.qrOutdated = false;
      state.balanceSbpQR.paymentRedirect = false;
      state.balanceSbpQR.paymentMethod = undefined;
      state.balanceSbpQR.paymentProvider = undefined;
      state.balanceSbpQR.amount = 0;
    },
    setBalanceSbpOrderIdByLetterPage: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.balanceSbpQrByLetterPage.sbpOrderId = action.payload;
    },
    clearBalanceByLetterPageSbpOrderId: (state: SbpState): void => {
      state.balanceSbpQrByLetterPage.sbpOrderId = undefined;
    },
    setBalanceSbpQrByLetterPage: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.balanceSbpQrByLetterPage.sbpQR = action.payload;
    },
    clearBalanceByLetterPageSbpQR: (state: SbpState): void => {
      state.balanceSbpQrByLetterPage.sbpQR = undefined;
    },
    setBalanceByLetterPageOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.balanceSbpQrByLetterPage.qrOutdated = action.payload;
    },
    setBalanceByLetterPageFillAmount: (state: SbpState, action: PayloadAction<number>): void => {
      state.balanceSbpQrByLetterPage.amount = action.payload;
    },
    setBalanceByLetterPageRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.balanceSbpQrByLetterPage.paymentRedirect = action.payload;
    },
    setBalanceByLetterPagePaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.balanceSbpQrByLetterPage.paymentMethod = action.payload;
    },
    setBalanceByLetterPagePaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.balanceSbpQrByLetterPage.paymentProvider = action.payload;
    },
    clearBalanceByLetterPageState: (state: SbpState): void => {
      state.balanceSbpQrByLetterPage.sbpOrderId = undefined;
      state.balanceSbpQrByLetterPage.sbpQR = undefined;
      state.balanceSbpQrByLetterPage.qrOutdated = false;
      state.balanceSbpQrByLetterPage.paymentRedirect = false;
      state.balanceSbpQrByLetterPage.paymentMethod = undefined;
      state.balanceSbpQrByLetterPage.paymentProvider = undefined;
      state.balanceSbpQrByLetterPage.amount = 0;
    },
    setCardCreateSbpOrderId: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.cardCreateSbpQR.sbpOrderId = action.payload;
    },
    clearCardCreateSbpOrderId: (state: SbpState): void => {
      state.cardCreateSbpQR.sbpOrderId = undefined;
    },
    setCardCreateSbpQR: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.cardCreateSbpQR.sbpQR = action.payload;
    },
    clearCardCreateSbpQR: (state: SbpState): void => {
      state.cardCreateSbpQR.sbpQR = undefined;
    },
    setCardCreateQrOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardCreateSbpQR.qrOutdated = action.payload;
    },
    setCardCreateFillAmount: (state: SbpState, action: PayloadAction<number>): void => {
      state.cardCreateSbpQR.amount = action.payload;
    },
    setCardCreatePaymentRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardCreateSbpQR.paymentRedirect = action.payload;
    },
    setCardCreatePaymentPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.cardCreateSbpQR.paymentMethod = action.payload;
    },
    setCardCreatePaymentPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.cardCreateSbpQR.paymentProvider = action.payload;
    },
    setCardCreateFillSuccess: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardCreateSbpQR.successTransaction = action.payload;
    },
    clearCardCreateSbpState: (state: SbpState): void => {
      state.cardCreateSbpQR.sbpOrderId = undefined;
      state.cardCreateSbpQR.sbpQR = undefined;
      state.cardCreateSbpQR.qrOutdated = false;
      state.cardCreateSbpQR.paymentRedirect = false;
      state.cardCreateSbpQR.paymentMethod = undefined;
      state.cardCreateSbpQR.paymentProvider = undefined;
      state.cardCreateSbpQR.amount = 0;
      state.cardCreateSbpQR.email = undefined;
    },
    setCardRefillSbpOrderId: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.cardRefillSbpQR.sbpOrderId = action.payload;
    },
    clearCardRefillSbpOrderId: (state: SbpState): void => {
      state.cardRefillSbpQR.sbpOrderId = undefined;
    },
    setCardRefillSbpQR: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.cardRefillSbpQR.sbpQR = action.payload;
    },
    clearCardRefillSbpQR: (state: SbpState): void => {
      state.cardRefillSbpQR.sbpQR = undefined;
    },
    setCardRefillQrOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardRefillSbpQR.qrOutdated = action.payload;
    },
    setCardRefillAmount: (state: SbpState, action: PayloadAction<number>): void => {
      state.cardRefillSbpQR.amount = action.payload;
    },
    setCardRefillPaymentRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardRefillSbpQR.paymentRedirect = action.payload;
    },
    setCardRefillPaymentPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.cardRefillSbpQR.paymentMethod = action.payload;
    },
    setCardRefillPaymentPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.cardRefillSbpQR.paymentProvider = action.payload;
    },
    setCardRefillSuccess: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.cardRefillSbpQR.successTransaction = action.payload;
    },
    clearCardRefillSbpState: (state: SbpState): void => {
      state.cardRefillSbpQR.sbpOrderId = undefined;
      state.cardRefillSbpQR.sbpQR = undefined;
      state.cardRefillSbpQR.qrOutdated = false;
      state.cardRefillSbpQR.paymentRedirect = false;
      state.cardRefillSbpQR.paymentMethod = undefined;
      state.cardRefillSbpQR.paymentProvider = undefined;
      state.cardRefillSbpQR.amount = 0;
      state.cardRefillSbpQR.email = undefined;
    },
    setInitiativeSbpOrderId: (state: SbpState, action: PayloadAction<string | undefined>): void => {
      state.initiativeSbpQR.sbpOrderId = action.payload;
    },
    clearInitiativeSbpOrderId: (state: SbpState): void => {
      state.initiativeSbpQR.sbpOrderId = undefined;
    },
    setInitiativeSbpQR: (state: SbpState, action: PayloadAction<CreatedSbpQrDTO>): void => {
      state.initiativeSbpQR.sbpQR = action.payload;
    },
    clearInitiativeSbpQR: (state: SbpState): void => {
      state.initiativeSbpQR.sbpQR = undefined;
    },
    setInitiativeQrOutdated: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.initiativeSbpQR.qrOutdated = action.payload;
    },
    setInitiativePaymentRedirect: (state: SbpState, action: PayloadAction<boolean>): void => {
      state.initiativeSbpQR.paymentRedirect = action.payload;
    },
    setInitiativePaymentPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.initiativeSbpQR.paymentMethod = action.payload;
    },
    setInitiativePaymentPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider>): void => {
      state.initiativeSbpQR.paymentProvider = action.payload;
    },
    clearInitiativeSbpState: (state: SbpState): void => {
      state.initiativeSbpQR.sbpOrderId = undefined;
      state.initiativeSbpQR.sbpQR = undefined;
      state.initiativeSbpQR.qrOutdated = false;
      state.initiativeSbpQR.paymentRedirect = false;
      state.initiativeSbpQR.paymentMethod = undefined;
      state.initiativeSbpQR.paymentProvider = undefined;
    },
    setCardEmail: (state: SbpState, action: PayloadAction<{ email: string; cardService: CardService }>): void => {
      if (action.payload.cardService === CardService.CREATE) {
        state.cardCreateSbpQR.email = action.payload.email;
      }
      if (action.payload.cardService === CardService.REFILL) {
        state.cardRefillSbpQR.email = action.payload.email;
      }
    },
    setCardPaymentMethod: (state: SbpState, action: PayloadAction<PaymentMethod>): void => {
      state.cardPaymentMethod = action.payload;
    },
    setCardPaymentProvider: (state: SbpState, action: PayloadAction<PaymentProvider | undefined>): void => {
      state.cardPaymentProvider = action.payload;
    },
  },
});

export const {
  setLetterSbpOrderId,
  clearLetterSbpOrderId,
  setLetterSbpQR,
  clearLetterSbpQR,
  setLetterQrOutdated,
  setLetterPaymentRedirect,
  setLetterPaymentPaymentMethod,
  setLetterPaymentPaymentProvider,
  clearLetterSbpState,
  setInitiativeSbpOrderId,
  clearInitiativeSbpOrderId,
  setInitiativeSbpQR,
  clearInitiativeSbpQR,
  setInitiativeQrOutdated,
  setInitiativePaymentRedirect,
  setInitiativePaymentPaymentMethod,
  setInitiativePaymentPaymentProvider,
  clearInitiativeSbpState,
  setBalanceSbpOrderId,
  clearBalanceSbpOrderId,
  setBalanceSbpQR,
  clearBalanceSbpQR,
  setBalanceQrOutdated,
  setBalancePaymentRedirect,
  setBalancePaymentPaymentMethod,
  setBalancePaymentPaymentProvider,
  setBalanceFillAmount,
  clearBalanceSbpState,
  setBalanceSuccessFill,
  setBalanceSbpOrderIdByLetterPage,
  clearBalanceByLetterPageSbpOrderId,
  setBalanceSbpQrByLetterPage,
  clearBalanceByLetterPageSbpQR,
  setBalanceByLetterPageOutdated,
  setBalanceByLetterPageFillAmount,
  setBalanceByLetterPageRedirect,
  setBalanceByLetterPagePaymentMethod,
  setBalanceByLetterPagePaymentProvider,
  clearBalanceByLetterPageState,
  setCardCreateSbpOrderId,
  clearCardCreateSbpOrderId,
  setCardCreateSbpQR,
  setCardCreateQrOutdated,
  clearCardCreateSbpQR,
  setCardCreateFillAmount,
  setCardCreatePaymentRedirect,
  setCardCreatePaymentPaymentMethod,
  setCardCreatePaymentPaymentProvider,
  setCardCreateFillSuccess,
  clearCardCreateSbpState,
  setCardRefillSbpOrderId,
  clearCardRefillSbpOrderId,
  setCardRefillSbpQR,
  setCardRefillQrOutdated,
  clearCardRefillSbpQR,
  setCardRefillAmount,
  setCardRefillPaymentRedirect,
  setCardRefillPaymentPaymentMethod,
  setCardRefillPaymentPaymentProvider,
  setCardRefillSuccess,
  clearCardRefillSbpState,
  setCardEmail,
  setCardPaymentMethod,
  setCardPaymentProvider,
} = sbpSlice.actions;

export const getSbpQR = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): CreatedSbpQrDTO | undefined => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.sbpQR;
    } else {
      return state.sbp.letterSbpQR.sbpQR;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.sbpQR;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.sbpQR;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.sbpQR;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.sbpQR;
    }
  }
  return undefined;
};

export const setSbpQR = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  qr: CreatedSbpQrDTO,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativeSbpQR(qr));
    } else {
      dispatch(setLetterSbpQR(qr));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceSbpQrByLetterPage(qr));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalanceSbpQR(qr));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreateSbpQR(qr));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillSbpQR(qr));
    }
  }
};

export const getSbpOrderId = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): string | undefined => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.sbpOrderId;
    } else {
      return state.sbp.letterSbpQR.sbpOrderId;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.sbpOrderId;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.sbpOrderId;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.sbpOrderId;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.sbpOrderId;
    }
  }
  return undefined;
};

export const setSbpOrderId = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  orderId?: string,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativeSbpOrderId(orderId));
    } else {
      dispatch(setLetterSbpOrderId(orderId));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceSbpOrderIdByLetterPage(orderId));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalanceSbpOrderId(orderId));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreateSbpOrderId(orderId));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillSbpOrderId(orderId));
    }
  }
};

export const getSbpQrOutdated = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): boolean => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.qrOutdated;
    } else {
      return state.sbp.letterSbpQR.qrOutdated;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.qrOutdated;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.qrOutdated;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.qrOutdated;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.qrOutdated;
    }
  }
  return false;
};

export const setSbpQrOutdated = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  qrOutdated: boolean,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativeQrOutdated(qrOutdated));
    } else {
      dispatch(setLetterQrOutdated(qrOutdated));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceByLetterPageOutdated(qrOutdated));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalanceQrOutdated(qrOutdated));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreateQrOutdated(qrOutdated));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillQrOutdated(qrOutdated));
    }
  }
};

export const getSbpPaymentRedirect = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): boolean => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.paymentRedirect;
    } else {
      return state.sbp.letterSbpQR.paymentRedirect;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.paymentRedirect;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.paymentRedirect;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.paymentRedirect;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.paymentRedirect;
    }
  }
  return false;
};

export const setSbpPaymentRedirect = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  paymentRedirect: boolean,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativePaymentRedirect(paymentRedirect));
    } else {
      dispatch(setLetterPaymentRedirect(paymentRedirect));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceByLetterPageRedirect(paymentRedirect));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalancePaymentRedirect(paymentRedirect));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreatePaymentRedirect(paymentRedirect));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillPaymentRedirect(paymentRedirect));
    }
  }
};

export const getSbpPaymentMethod = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): PaymentMethod | undefined => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.paymentMethod;
    } else {
      return state.sbp.letterSbpQR.paymentMethod;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.paymentMethod;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.paymentMethod;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.paymentMethod;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.paymentMethod;
    }
  }
  return undefined;
};

export const setSbpPaymentMethod = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  paymentMethod: PaymentMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativePaymentPaymentMethod(paymentMethod));
    } else {
      dispatch(setLetterPaymentPaymentMethod(paymentMethod));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceByLetterPagePaymentMethod(paymentMethod));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalancePaymentPaymentMethod(paymentMethod));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreatePaymentPaymentMethod(paymentMethod));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillPaymentPaymentMethod(paymentMethod));
    }
  }
};

export const getSbpPaymentProvider = (
  state: RootState,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): PaymentProvider | undefined => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      return state.sbp.initiativeSbpQR.paymentProvider;
    } else {
      return state.sbp.letterSbpQR.paymentProvider;
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.paymentProvider;
  } else if (sbpMethod === SbpMethod.BALANCE) {
    return state.sbp.balanceSbpQR.paymentProvider;
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      return state.sbp.cardCreateSbpQR.paymentProvider;
    } else if (cardService === CardService.REFILL) {
      return state.sbp.cardRefillSbpQR.paymentProvider;
    }
  }
  return undefined;
};

export const setSbpPaymentProvider = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  paymentProvider: PaymentProvider,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(setInitiativePaymentPaymentProvider(paymentProvider));
    } else {
      dispatch(setLetterPaymentPaymentProvider(paymentProvider));
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(setBalanceByLetterPagePaymentProvider(paymentProvider));
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(setBalancePaymentPaymentProvider(paymentProvider));
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(setCardCreatePaymentPaymentProvider(paymentProvider));
    } else if (cardService === CardService.REFILL) {
      dispatch(setCardRefillPaymentPaymentProvider(paymentProvider));
    }
  }
};

export const clearSbpQr = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(clearInitiativeSbpQR());
    } else {
      dispatch(clearLetterSbpQR());
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(clearBalanceByLetterPageSbpQR());
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(clearBalanceSbpQR());
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(clearCardCreateSbpQR());
    } else if (cardService === CardService.REFILL) {
      dispatch(clearCardRefillSbpQR());
    }
  }
};

export const clearSbpQrState = (
  dispatch: AppDispatch,
  sbpMethod: SbpMethod,
  cardService?: CardService,
  isLetterPage?: boolean,
  isInitiative?: boolean
): void => {
  if (sbpMethod === SbpMethod.LETTER) {
    if (isInitiative) {
      dispatch(clearInitiativeSbpState());
    } else {
      dispatch(clearLetterSbpState());
    }
  } else if (sbpMethod === SbpMethod.BALANCE && isLetterPage) {
    dispatch(clearBalanceByLetterPageState());
  } else if (sbpMethod === SbpMethod.BALANCE) {
    dispatch(clearBalanceSbpState());
  } else if (sbpMethod === SbpMethod.CARD) {
    if (cardService === CardService.CREATE) {
      dispatch(clearCardCreateSbpState());
    } else if (cardService === CardService.REFILL) {
      dispatch(clearCardRefillSbpState());
    }
  }
};

export const getBalanceFillAmount = (state: RootState, isLetterPage?: boolean): number => {
  if (isLetterPage) {
    return state.sbp.balanceSbpQrByLetterPage.amount || 0;
  }
  return state.sbp.balanceSbpQR.amount || 0;
};

export const isBalanceSuccessFill = (state: RootState): boolean => {
  return state.sbp.balanceSbpQR.successTransaction || false;
};

export const getCardAmount = (state: RootState, cardService: CardService): number => {
  return cardService === CardService.CREATE
    ? state.sbp.cardCreateSbpQR.amount || 0
    : cardService === CardService.REFILL
    ? state.sbp.cardRefillSbpQR.amount || 0
    : 0;
};

export const isCardFillSuccess = (state: RootState, cardService: CardService): boolean => {
  return cardService === CardService.CREATE
    ? state.sbp.cardCreateSbpQR.successTransaction || false
    : cardService === CardService.REFILL
    ? state.sbp.cardRefillSbpQR.successTransaction || false
    : false;
};

export const getCardEmail = (state: RootState, cardService: CardService): string | undefined => {
  return cardService === CardService.CREATE
    ? state.sbp.cardCreateSbpQR.email
    : cardService === CardService.REFILL
    ? state.sbp.cardRefillSbpQR.email
    : undefined;
};

export const getCardPaymentMethod = (state: RootState): PaymentMethod => {
  return state.sbp.cardPaymentMethod;
};

export const getCardPaymentProvider = (state: RootState): PaymentProvider | undefined => {
  return state.sbp.cardPaymentProvider;
};

export default sbpSlice.reducer;
