import { ErrorDTO } from '@/types/ErrorDTO';
import { Grid, Typography } from '@mui/material';
import { MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { formatPhoneNumber, onlyNumberFormat } from '@/utils/string-utils';
import { useConfirmNewPhoneMutation } from '@/services/api/settingsApiSlice';
import { useSnackbar } from 'notistack';
import LoadingOutlinedField from '@/components/common/field/LoadingOutlinedField';
import React, { ChangeEvent, ClipboardEvent, FC, useState } from 'react';

interface Props {
  newPhone: string;
  setSuccessChange: (isSuccessChange: boolean) => void;
  isMobile?: boolean;
}

export const PhoneFormTwo: FC<Props> = (props: Props) => {
  const { newPhone, setSuccessChange, isMobile } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [confirmPhone, { isSuccess, isLoading, isError }] = useConfirmNewPhoneMutation();
  const [code, setCode] = useState<string>('');
  const success = isSuccess && code.length === 4;
  const error = isError && code.length === 4;
  const handleCode = async (value: string): Promise<void> => {
    if (value.length < 4) {
      setCode(onlyNumberFormat(value));
    } else if (value.length === 4) {
      setCode(onlyNumberFormat(value));
      await confirmPhone({ phone: newPhone, code: parseInt(value) })
        .unwrap()
        .then(() => {
          setSuccessChange(true);
          enqueueSnackbar('Номер телефона изменён', { variant: 'success' });
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    }
  };
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} mb={2}>
        <Typography variant={'body1'} lineHeight={'20px'} whiteSpace={'pre-line'}>
          {isMobile
            ? `Мы отправили sms с четырёхзначным кодом подтверждения на номер \n${formatPhoneNumber(newPhone)}`
            : `Мы отправили sms с четырёхзначным кодом\nподтверждения на номер ${formatPhoneNumber(newPhone)}`}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true}>
          <Grid item={true} xs={9}>
            <LoadingOutlinedField
              fullWidth={true}
              placeholder={'Укажите код'}
              value={code}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleCode(e.target.value)}
              onPaste={(e: ClipboardEvent<HTMLDivElement>) => e.preventDefault()}
              error={error}
              helperText={error ? 'Неверный код' : ''}
              isLoading={isLoading}
              isSuccess={success}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
