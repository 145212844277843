import { AnswerViewDTO } from '@/types/answer/AnswerViewDTO';
import { AppDispatch } from '@/app/store';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ImagePreview } from '@/components/view/image/ImagePreview';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { Theme } from '@mui/material/styles';
import { formatShortDateTimeString } from '@/utils/date-utils';
import { getCreateLetterReplyRoute } from '@/app/routes';
import { setCreateInfoFromDTO } from '@/services/createFormSlice';
import { useAppDispatch } from '@/app/hooks';
import { useGetSenderToReplyMutation } from '@/services/api/createFormApiSlice';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  answer: AnswerViewDTO;
}

export const AnswerViewBlock: FC<Props> = (props: Props) => {
  const { answer } = props;
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();
  const dispatch: AppDispatch = useAppDispatch();
  const [getSender, { isLoading }] = useGetSenderToReplyMutation();
  const handleReplyClick = (answer: AnswerViewDTO): void => {
    getSender(answer.letterId)
      .unwrap()
      .then((dto: SenderInfoDTO) => {
        dispatch(setCreateInfoFromDTO(dto));
      })
      .then(() => history.push(getCreateLetterReplyRoute(answer.letterId)));
  };
  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <Grid container={true} direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
          <Grid item={true} xs={true}>
            <Typography variant={isMobile ? 'h2' : 'h1'} whiteSpace={'nowrap'}>
              {'Получен ответ'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant={'label1'} color={(theme: Theme) => theme.colors.grayText}>
              {answer ? formatShortDateTimeString(answer.date) : ''}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <ImagePreview
          title={`Ответ на письмо № ${answer.letterNumber}`}
          subtitle={answer ? formatShortDateTimeString(answer.date) : ''}
          content={answer.content}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item={true}>
        <LoadingButton
          fullWidth={isMobile}
          variant={'contained'}
          color={'primary'}
          sx={(theme: Theme) => ({
            minWidth: theme.spacing(23),
          })}
          isLoading={isLoading}
          onClick={() => handleReplyClick(answer)}>
          {'Ответить'}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
