import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const TimerIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M13.9991 1H9.99912C9.44912 1 8.99912 1.45 8.99912 2C8.99912 2.55 9.44912 3 9.99912 3H13.9991C14.5491 3 14.9991 2.55 14.9991 2C14.9991 1.45 14.5491 1 13.9991 1ZM11.9991 14C12.5491 14 12.9991 13.55 12.9991 13V9C12.9991 8.45 12.5491 8 11.9991 8C11.4491 8 10.9991 8.45 10.9991 9V13C10.9991 13.55 11.4491 14 11.9991 14ZM19.0291 7.39L19.7791 6.64C20.1591 6.26 20.1691 5.63 19.7791 5.24L19.7691 5.23C19.3791 4.84 18.7591 4.85 18.3691 5.23L17.6191 5.98C16.0691 4.74 14.1191 4 11.9991 4C7.19912 4 3.11912 7.96 2.99912 12.76C2.86912 17.84 6.93912 22 11.9991 22C16.9791 22 20.9991 17.97 20.9991 13C20.9991 10.88 20.2591 8.93 19.0291 7.39ZM11.9991 20C8.12912 20 4.99912 16.87 4.99912 13C4.99912 9.13 8.12912 6 11.9991 6C15.8691 6 18.9991 9.13 18.9991 13C18.9991 16.87 15.8691 20 11.9991 20Z'
      }
    />
  </SvgIcon>
);
