import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const BinocularsIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 60 60'} sx={{ width: '40px', height: '40px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M48.0042 14.8989L56.6061 29.9521C58.1116 31.9338 59 34.3751 59 37.0156C59 43.643 53.4036 49.0156 46.5 49.0156C39.9433 49.0156 34.5657 44.1694 34.0418 38.0046L33.9999 38.0162L33.9882 37.5166H25.9893C25.7158 43.9117 20.2288 49.0156 13.5 49.0156C6.59644 49.0156 1 43.643 1 37.0156C1 34.4583 1.83327 32.0879 3.25339 30.1408L11.5476 14.9949C13.0332 12.2821 16.002 10.7262 19.0781 11.0483C23.0546 11.4648 26.0452 14.8645 25.9511 18.8616L25.9121 20.5166H33.5879L33.5503 18.9201C33.4554 14.8899 36.4707 11.4621 40.4801 11.0422C43.5308 10.7228 46.4823 12.2356 48.0042 14.8989ZM13.5 45.0156C8.65011 45.0156 5 41.2816 5 37.0156C5 32.7497 8.65011 29.0156 13.5 29.0156C18.3499 29.0156 22 32.7497 22 37.0156C22 41.2816 18.3499 45.0156 13.5 45.0156ZM38 37.0156C38 41.2816 41.6501 45.0156 46.5 45.0156C51.3499 45.0156 55 41.2816 55 37.0156C55 32.7497 51.3499 29.0156 46.5 29.0156C41.6501 29.0156 38 32.7497 38 37.0156Z'
      }
      fill={'#ADC3FF'}
    />
  </SvgIcon>
);
