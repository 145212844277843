import { Grid, Typography } from '@mui/material';
import { SuccessIcon } from '@/components/common/SuccessIcon';
import React, { FC } from 'react';

export const RegistrationSuccessForm: FC = () => {
  return (
    <Grid container={true} direction={'column'} alignItems={'center'} spacing={4} mt={14} mb={17}>
      <Grid item={true}>
        <Grid container={true} direction={'column'} alignItems={'center'} spacing={2}>
          <Grid item={true}>
            <SuccessIcon />
          </Grid>
          <Grid item={true}>
            <Typography variant={'h2'} textAlign={'center'}>
              {'На электронную почту выслана ссылка для подтверждения регистрации'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
