import { EmailChangeRequestDTO } from '@/types/user/EmailChangeRequestDTO';
import { EmailChangeResponseDTO } from '@/types/user/EmailChangeResponseDTO';
import { FullName } from '@/types/FullName';
import { PasswordChangeRequestDTO } from '@/types/user/PasswordChangeRequestDTO';
import { PasswordChangeResponseDTO } from '@/types/user/PasswordChangeResponseDTO';
import { PhoneChangeRequestDTO } from '@/types/user/PhoneChangeRequestDTO';
import { SystemSettingsDTO } from '@/types/SystemSettingsDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    sendEmailToken: build.mutation<EmailChangeResponseDTO, EmailChangeRequestDTO>({
      query: (dto: EmailChangeRequestDTO) => ({
        url: '/users/change-email',
        method: 'POST',
        body: dto,
      }),
    }),
    confirmEmailToken: build.mutation<void, string>({
      query: (activateToken: string) => ({
        url: '/users/confirm-email',
        method: 'GET',
        params: { activateToken: activateToken },
      }),
    }),
    setNewPhone: build.mutation<string, PhoneChangeRequestDTO>({
      query: (dto: PhoneChangeRequestDTO) => ({
        url: '/users/change-phone',
        method: 'POST',
        body: dto,
      }),
    }),
    addNewPhone: build.mutation<string, PhoneChangeRequestDTO>({
      query: (dto: PhoneChangeRequestDTO) => ({
        url: '/users/add-phone',
        method: 'POST',
        body: dto,
      }),
    }),
    confirmNewPhone: build.mutation<void, PhoneChangeRequestDTO>({
      query: (dto: PhoneChangeRequestDTO) => ({
        url: '/users/confirm-phone',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['User'],
    }),
    setNewFullName: build.mutation<void, FullName>({
      query: (dto: FullName) => ({
        url: '/users/change-fullname',
        method: 'POST',
        body: dto,
      }),
    }),
    setUserNotification: build.mutation<void, boolean>({
      query: (isSendNotification: boolean) => ({
        url: '/users/change-notification',
        method: 'GET',
        params: { isSendNotification: isSendNotification },
      }),
      invalidatesTags: ['User'],
    }),
    setNewPassword: build.mutation<PasswordChangeResponseDTO, PasswordChangeRequestDTO>({
      query: (dto: PasswordChangeRequestDTO) => ({
        url: '/users/change-password',
        method: 'POST',
        body: dto,
      }),
    }),
    getSystemSettings: build.query<SystemSettingsDTO, void>({
      query: () => ({
        url: '/settings',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSendEmailTokenMutation,
  useConfirmEmailTokenMutation,
  useSetNewPhoneMutation,
  useAddNewPhoneMutation,
  useConfirmNewPhoneMutation,
  useSetNewFullNameMutation,
  useSetUserNotificationMutation,
  useSetNewPasswordMutation,
  useGetSystemSettingsQuery,
} = settingsApiSlice;
