import { AnswerStatus } from '@/types/answer/AnswerStatus';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { CardPage } from '@/components/create/card/CardPage';
import { CheckLetterStatusBlock } from '@/components/letter/statusCheck/CheckLetterStatusBlock';
import { DepartmentChangeBlock } from '@/components/letter/department/DepartmentChangeBlock';
import { ErrorBlock } from '@/components/main/ErrorBlock';
import { Footer } from '@/components/main/footer/Footer';
import { InitiativeLetterPage } from '@/components/letter/initiative/InitiativeLetterPage';
import { InitiativeLetterPayment } from '@/components/letter/initiative/InitiativeLetterPayment';
import { InitiativeLetterPaymentSuccess } from '@/components/letter/initiative/InitiativeLetterPaymentSuccess';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { MainAnswerBlock } from '@/components/create/main/answer/MainAnswerBlock';
import { MainCardPage } from '@/components/create/card/MainCardPage';
import { MainHeader } from '@/components/main/header/MainHeader';
import { MainLetterBlock } from '@/components/create/main/letter/MainLetterBlock';
import { MainNavigation } from '@/components/main/navigation/MainNavigation';
import { MobileHeader } from '@/components/common/MobileHeader';
import { NotFoundPage } from '@/components/NotFoundPage';
import {
  ROUTE_ABOUT,
  ROUTE_CARD,
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_ERROR,
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_MAIN,
  ROUTE_MAIN_CARD,
  ROUTE_MAIN_CREATE_ANSWER,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_PAYMENT_INITIATIVE,
  ROUTE_PAYMENT_INITIATIVE_SUCCESS,
  ROUTE_REDIRECT,
  ROUTE_VIEW_INITIATIVE,
} from '@/app/routes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { TopLine } from '@/components/common/TopLine';
import { UserDTO } from '@/types/user/UserDTO';
import { getAuthenticated, getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetLettersCountQuery } from '@/services/api/letterApiSlice';
import AboutPage from '@/components/about/AboutPage';
import HelpPage from '@/components/help/HelpPage';
import React, { FC, Fragment } from 'react';

export const MainPage: FC = () => {
  const location = useLocation();

  const isPrerender: boolean = navigator.userAgent === 'ReactSnap';
  const isMobile: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) && !isPrerender;

  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const { data: inboxCount } = useGetLettersCountQuery(
    {
      status: LetterSimpleStatus.ALL,
      answerStatus: AnswerStatus.PROCESSED,
      userId: authUser?.id,
    },
    { skip: !authUser }
  );

  const isMainPage: boolean = location.pathname.startsWith(ROUTE_MAIN);

  return (
    <Fragment>
      <TopLine />
      <Container
        sx={(theme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-6px',
          minHeight: '100%',
          [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(5),
            overflow: 'auto',
          },
        })}>
        <Box display={'flex'} flex={1}>
          <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={isMobile ? 2 : 10}>
            <Grid item={true}>
              {isMobile ? (
                <MobileHeader mainPage={true} />
              ) : (
                <MainNavigation profile={!!authUser} isAuthenticated={isAuthenticated} inboxCount={inboxCount} />
              )}
            </Grid>
            <Grid item={true} xs={true}>
              <Switch>
                <Route exact={true} path={ROUTE_MAIN}>
                  <Grid container={true} direction={'column'} spacing={5}>
                    <Grid item={true}>
                      <MainHeader />
                    </Grid>
                    <Grid item={true}>
                      <AboutPage isMainPage={true} />
                    </Grid>
                  </Grid>
                </Route>
                <Route path={ROUTE_REDIRECT}>
                  <DepartmentChangeBlock />
                </Route>
                <Route path={ROUTE_CHECK_LETTER_STATUS}>
                  <CheckLetterStatusBlock isMobile={isMobile} />
                </Route>
                <Route path={ROUTE_MAIN_CREATE_LETTER}>
                  <MainLetterBlock />
                </Route>
                <Route path={ROUTE_MAIN_CARD}>
                  <MainCardPage />
                </Route>
                <Route path={ROUTE_MAIN_CREATE_ANSWER}>
                  <MainAnswerBlock />
                </Route>
                <Route path={ROUTE_CARD}>
                  <CardPage />
                </Route>
                <Route path={ROUTE_HELP}>
                  <HelpPage />
                </Route>
                <Route path={ROUTE_ABOUT}>
                  <AboutPage />
                </Route>
                <Route path={ROUTE_VIEW_INITIATIVE} exact={true}>
                  <InitiativeLetterPage />
                </Route>
                <Route path={ROUTE_PAYMENT_INITIATIVE_SUCCESS}>
                  <InitiativeLetterPaymentSuccess />
                </Route>
                <Route path={ROUTE_PAYMENT_INITIATIVE}>
                  <InitiativeLetterPayment />
                </Route>
                <Route path={ROUTE_ERROR}>
                  <ErrorBlock />
                </Route>
                <Route path={ROUTE_HOME} exact={true}>
                  <Redirect to={ROUTE_MAIN} />
                </Route>
                <Route path={'*'}>
                  <NotFoundPage />
                </Route>
              </Switch>
            </Grid>
            {location.pathname === ROUTE_MAIN && isMobile && (
              <Grid item={true} mt={'25px'}>
                <Footer isMobile={isMobile} isMainPage={isMainPage} />
              </Grid>
            )}
            {!isMobile && (
              <Grid item={true}>
                <Footer isMobile={isMobile} isMainPage={isMainPage} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};
