import { Divider, Grid, Typography } from '@mui/material';
import { ROUTE_HELP_FEEDBACK } from '@/app/routes';
import { TypographyLink } from '@/components/common/TypographyLink';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  label: string;
  isMobile?: boolean;
}

export const ErrorPage: FC<Props> = (props) => {
  const { label, children, isMobile } = props;
  const history = useHistory();

  const handleSupportClick = (): void => {
    history.push(ROUTE_HELP_FEEDBACK);
  };
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'}>
      <Grid item={true} mb={4}>
        <Typography variant={'h2'}>{label}</Typography>
      </Grid>
      <Grid item={true}>{children}</Grid>
      <Grid item={true} mt={4}>
        <Divider />
      </Grid>
      <Grid item={true} mt={4}>
        <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Grid item={true}>
            <Typography variant={'body1'}>{'Остались вопросы?'}</Typography>
          </Grid>
          <Grid item={true}>
            <TypographyLink onClick={handleSupportClick}>{'Написать в техподдержку'}</TypographyLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
