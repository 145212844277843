import { Button, Grid } from '@mui/material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { MobileBar } from '@/components/home/MobileBar';
import { RightIcon } from '@/app/icons/RightIcon';
import { Theme } from '@mui/material/styles';
import EmailForm from '@/components/profile/modals/email/EmailForm';
import EmailSuccess from '@/components/profile/modals/email/EmailSuccess';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';

interface Props {
  open: boolean;
  isMobile?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

export const EmailDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, onClose, onOpen } = props;
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };

  const [isSuccess, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setSuccess(false);
    if (onOpen && open) {
      onOpen();
    }
  }, [onOpen, open]);

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const handleClickSubmit = () => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  return (
    <Fragment>
      <ClientDialog
        open={open}
        isMobile={isMobile}
        label={isSuccess ? '' : 'Изменить е-мейл'}
        handleCloseButton={handleClose}
        PaperProps={{ sx: { minHeight: isMobile ? '0' : '420px' } }}
        sxContent={(theme: Theme) => ({
          paddingBottom: theme.spacing(5),
          overflowY: 'visible',
        })}
        actions={
          !isSuccess && (
            <Grid container={true} justifyContent={'space-between'} pb={isMobile ? 7 : 0}>
              <Grid item={true} xs={12} sm={4}>
                <Button
                  sx={{ padding: (theme: Theme) => theme.spacing(1.375, 1) }}
                  fullWidth={true}
                  variant={'outlined'}
                  onClick={handleClose}>
                  <Grid container={true}>
                    <Grid item={true} marginRight={'17px'}>
                      <RightIcon
                        sx={{
                          display: 'block',
                          transform: 'rotate(180deg)',
                        }}
                      />
                    </Grid>
                    <Grid item={true}>{'Назад'}</Grid>
                  </Grid>
                </Button>
              </Grid>
              {isMobile ? (
                <MobileBar>
                  <FixedButton color={'primary'} variant={'contained'} onClick={handleClickSubmit}>
                    {'Изменить'}
                  </FixedButton>
                </MobileBar>
              ) : (
                <Grid item={true} xs={4}>
                  <Button fullWidth={true} variant={'contained'} onClick={handleClickSubmit}>
                    {'Изменить'}
                  </Button>
                </Grid>
              )}
            </Grid>
          )
        }>
        {isSuccess ? <EmailSuccess /> : <EmailForm handleSubmitNew={setSuccess} submitButtonRef={submitButtonRef} />}
      </ClientDialog>
    </Fragment>
  );
};

export default EmailDialog;
