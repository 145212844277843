import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ZoomInIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M10.5898 18.1797C12.4062 18.1797 14.0938 17.6055 15.4883 16.6328L20.6094 21.7656C20.8789 22.0234 21.2305 22.1523 21.6055 22.1523C22.3906 22.1523 22.9531 21.5547 22.9531 20.7812C22.9531 20.418 22.8359 20.0664 22.5664 19.7969L17.4805 14.6992C18.5469 13.2695 19.1797 11.5 19.1797 9.58984C19.1797 4.86719 15.3125 1 10.5898 1C5.86719 1 2 4.85547 2 9.58984C2 14.3125 5.85547 18.1797 10.5898 18.1797ZM10.5898 16.1758C6.96875 16.1758 3.99219 13.1992 3.99219 9.58984C3.99219 5.98047 6.96875 3.00391 10.5898 3.00391C14.1992 3.00391 17.1758 5.98047 17.1758 9.58984C17.1758 13.1992 14.1992 16.1758 10.5898 16.1758ZM10.5898 13.3984C11.082 13.3984 11.4688 13.0352 11.4688 12.5664V10.4219H13.4375C13.8828 10.4219 14.2695 10.0352 14.2695 9.58984C14.2695 9.13281 13.8828 8.75781 13.4375 8.75781H11.4688V6.61328C11.4688 6.13281 11.082 5.78125 10.5898 5.78125C10.0859 5.78125 9.71094 6.13281 9.71094 6.61328V8.75781H7.74219C7.29688 8.75781 6.89844 9.14453 6.89844 9.58984C6.89844 10.0352 7.29688 10.4219 7.74219 10.4219H9.71094V12.5664C9.71094 13.0352 10.0977 13.3984 10.5898 13.3984Z'
      }
    />
  </SvgIcon>
);
