import { Button, Grid, Theme, Typography } from '@mui/material';
import { CardFormVariant } from '@/types/card/CardFormVariant';
import { EnvelopeIcon } from '@/app/icons/EnvelopeIcon';
import { Footer } from '@/components/main/footer/Footer';
import { ROUTE_MAIN } from '@/app/routes';
import { getCardAuthorEmail } from '@/services/createFormSlice';
import { useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
  variant: CardFormVariant;
}

export const CardSuccess: FC<Props> = (props: Props) => {
  const { isMobile, variant } = props;

  const history = useHistory();

  const authorEmail: string = useAppSelector(getCardAuthorEmail);

  const handleReturnMain = (): void => {
    history.push(ROUTE_MAIN);
  };

  return (
    <Grid
      container={true}
      direction={'column'}
      wrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'center'}
      minHeight={'100%'}>
      <Grid item={true} xs={true} display={'flex'}>
        <Grid
          container={true}
          direction={'column'}
          wrap={'nowrap'}
          alignItems={'center'}
          justifyContent={'center'}
          maxWidth={'400px'}>
          <Grid item={true} pb={1.5}>
            <EnvelopeIcon
              sx={{
                width: (theme: Theme): string => (isMobile ? theme.spacing(7) : theme.spacing(8.5)),
                height: (theme: Theme): string => (isMobile ? theme.spacing(7) : theme.spacing(8.5)),
                display: 'block',
              }}
            />
          </Grid>
          <Grid item={true} pb={1}>
            <Typography variant={'h1'} align={'center'}>
              {variant === CardFormVariant.CREATED
                ? 'Ф-карта принята к оформлению'
                : variant === CardFormVariant.REFILLED
                ? 'Пополнение Ф-карты принято'
                : variant === CardFormVariant.PAYED
                ? 'Оплата Ф-карты принята'
                : ''}
            </Typography>
          </Grid>
          <Grid item={true} pb={3}>
            <Typography variant={'body1'} textAlign={'center'} whiteSpace={'pre-line'}>
              {variant === CardFormVariant.CREATED
                ? 'После подтверждения об оплате, мы отправим данные карты и код доступа на почту '
                : variant === CardFormVariant.REFILLED
                ? 'Мы отправили подтверждение на почту '
                : variant === CardFormVariant.PAYED
                ? 'Мы отправим данные карты и код доступа на почту '
                : ''}
              <Typography component={'span'} variant={'body1'} fontWeight={500}>
                {authorEmail}
              </Typography>
            </Typography>
          </Grid>
          <Grid item={true} width={'100%'} display={'flex'} justifyContent={'center'}>
            <Grid container={true} maxWidth={'324px'}>
              <Grid item={true} width={'100%'}>
                <Button color={'primary'} variant={'outlined'} fullWidth={true} onClick={handleReturnMain}>
                  {'Вернуться на главную'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item={true} mt={2}>
          <Footer isMobile={true} />
        </Grid>
      )}
    </Grid>
  );
};
