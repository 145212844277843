import { Grid, Typography } from '@mui/material';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { Theme } from '@mui/material/styles';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  amount: number;
  price: number;
}

export const PaymentBalanceInfo: FC<Props> = (props: Props) => {
  const { amount, price } = props;
  return (
    <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={1}>
      <Grid item={true}>
        <Grid container={true} justifyContent={'space-between'} spacing={2}>
          <Grid item={true}>
            <Typography
              fontWeight={500}
              fontSize={'18px'}
              lineHeight={'24px'}
              color={(theme: Theme): string => theme.colors.textColor}>
              {'Ваш баланс'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography
              color={(theme: Theme): string => (amount >= price ? theme.colors.textColor : theme.colors.red)}
              fontWeight={500}
              fontSize={'18px'}
              lineHeight={'24px'}>
              {formatCurrency(amount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {amount < price && (
        <Grid item={true}>
          <LetterAlert error={true} sx={{ padding: '4px 8px', fontSize: '14px', lineHeight: '20px' }}>
            {'На балансе недостаточно средств'}
          </LetterAlert>
        </Grid>
      )}
    </Grid>
  );
};
