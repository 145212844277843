import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const QrOutdatedIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 110 110'} sx={{ width: '110px', height: '110px', display: 'flex', margin: 'auto' }} {...props}>
    <circle cx={'55'} cy={'55'} r={'55'} fill={'white'} />
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M55 102C80.9574 102 102 80.9574 102 55C102 29.0426 80.9574 8 55 8C29.0426 8 8 29.0426 8 55C8 80.9574 29.0426 102 55 102ZM51.0008 22.2308C51.0008 19.8942 52.895 18 55.2316 18C57.5682 18 59.4624 19.8942 59.4624 22.2308V52.6085L77.2151 56.1591C79.5064 56.6173 80.9923 58.8462 80.534 61.1374C80.0758 63.4286 77.8469 64.9145 75.5557 64.4563L54.4788 60.2409C54.1059 60.174 53.75 60.0581 53.4179 59.9003C52.8443 59.6287 52.3495 59.2354 51.9597 58.7593C51.5693 58.2836 51.2805 57.7213 51.1267 57.1056C51.0371 56.749 50.9934 56.3772 51.0008 55.9985V22.2308Z'
      }
      fill={'#DE606C'}
    />
  </SvgIcon>
);
