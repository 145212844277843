import * as Yup from 'yup';
import { CardCheckDTO } from '@/types/card/CardCheckDTO';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { cardCodeSchema } from '@/validation/cardCodeSchema';
import { isBlank, onlyNumberFormat } from '@/utils/string-utils';
import { useDebounce } from '@/app/hooks/useDebounce';
import { useGetCheckBalanceMutation } from '@/services/api/cardApiSlice';
import LoadingOutlinedField from '@/components/common/field/LoadingOutlinedField';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  label?: string;
  placeholder?: string;
  cardNumber: number;
  onSuccess?: (code: string, amount: number) => void;
  onError?: (code: string) => void;
  disabled?: boolean;
}

interface FormValues {
  code: string;
}

export const CardCodeField: FC<Props> = (props: Props) => {
  const { placeholder, label, cardNumber, onSuccess, onError, disabled } = props;
  const [checkCard, { isLoading, isSuccess }] = useGetCheckBalanceMutation();
  const initialValues: FormValues = {
    code: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    code: isBlank(initialValues.code) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    code: cardCodeSchema.required(MESSAGE_REQUIRED).nullable(),
  });
  const handleChange = useDebounce<() => Promise<void>>(async (submitForm: () => Promise<void>): Promise<void> => {
    await submitForm();
  }, 500);
  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const dto: CardCheckDTO = {
      cardNumber: cardNumber,
      code: values.code || '',
    };
    await checkCard(dto)
      .unwrap()
      .then((amount: number) => {
        if (onSuccess) {
          onSuccess(dto.code, amount);
        }
      })
      .catch((e: { status: number; data: ErrorDTO }) => {
        const { setFieldError } = helpers;
        setFieldError('code', e.data?.message);
        if (onError) {
          onError(dto.code);
        }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (field: string, value: string) => {
          setFieldTouched(field, true, false);
          if (value.length < 4 && !isSuccess) {
            setFieldValue(field, onlyNumberFormat(value), true);
          } else if (value.length === 4) {
            setFieldValue(field, onlyNumberFormat(value), true);
            handleChange(submitForm);
          }
        };
        return (
          <LoadingOutlinedField
            name={'code'}
            type={'password'}
            required={true}
            placeholder={placeholder || 'Пин-код'}
            label={label}
            value={values.code}
            isLoading={isLoading}
            isSuccess={isSuccess}
            error={touched.code && Boolean(errors.code)}
            helperText={touched.code ? errors.code : ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleSetFieldValue(e.target.name, e.target.value);
            }}
            disabled={disabled}
          />
        );
      }}
    </Formik>
  );
};
