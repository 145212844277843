import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const EyeIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M12.0031 17.0475C16.7526 17.0475 20 13.2143 20 12.0238C20 10.827 16.7402 7 12.0031 7C7.32217 7 4 10.827 4 12.0238C4 13.2143 7.32217 17.0475 12.0031 17.0475ZM12.0031 15.2898C10.1831 15.2898 8.73705 13.8002 8.72458 12.0238C8.71835 10.1975 10.1831 8.76393 12.0031 8.76393C13.8169 8.76393 15.2817 10.2037 15.2817 12.0238C15.2817 13.8002 13.8169 15.2898 12.0031 15.2898ZM12.0093 13.2143C12.6638 13.2143 13.2061 12.672 13.2061 12.0238C13.2061 11.3693 12.6638 10.827 12.0093 10.827C11.3424 10.827 10.8002 11.3693 10.8002 12.0238C10.8002 12.672 11.3424 13.2143 12.0093 13.2143Z'
      }
    />
  </SvgIcon>
);
