import { EventDTO } from '@/types/event/EventDTO';
import { EventItem } from '@/components/home/event/EventItem';
import { Grid, Skeleton } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  events: EventDTO[];
  isLoading?: boolean;
}

export const EventBlock: FC<Props> = (props: Props) => {
  const { events, isLoading } = props;
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      {(isLoading ? Array.from(new Array(3)) : events).map((event: EventDTO, index: number) => (
        <Grid item={true} key={index}>
          {isLoading ? (
            <Skeleton variant={'rectangular'} height={'100px'} sx={{ borderRadius: '12px' }} />
          ) : (
            <EventItem event={event} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
