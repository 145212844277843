import { AmountCardMobileIcon } from '@/app/icons/card/AmountCardMobileIcon';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  amount: number;
  currentValue: number;
  countLetters: number;
  amountId: number;
  setAmount: (amount: number) => void;
}

export const CardAmountMobileButton: FC<Props> = (props: Props) => {
  const { amount, countLetters, currentValue, setAmount, amountId } = props;
  const active = currentValue === amountId;
  const handleClick = () => {
    setAmount(amountId);
  };
  return (
    <Button
      onClick={handleClick}
      disableRipple={true}
      color={active ? 'primary' : 'secondary'}
      sx={{
        padding: 0,
        borderRadius: (theme: Theme) => theme.spacing(1.5),
      }}
      variant={'contained'}>
      <Grid container={true} direction={'column'}>
        <Grid
          item={true}
          sx={{
            zIndex: 2,
            textAlign: 'left',
            padding: (theme: Theme) => theme.spacing(1.25, 1),
            marginBottom: (theme: Theme) => theme.spacing(-7),
          }}>
          <Grid container={true} direction={'column'}>
            <Grid item={true}>
              <Typography variant={'body2'} fontWeight={500}>
                {formatCurrency(amount, 0)}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'body2'}
                sx={{
                  fontSize: (theme: Theme) => theme.spacing(1.5),
                  lineHeight: (theme: Theme) => theme.spacing(2),
                }}>{`${countLetters} писем`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <AmountCardMobileIcon
            primary={active}
            sx={{
              display: 'flex',
              width: (theme: Theme) => theme.spacing(12),
              height: (theme: Theme) => theme.spacing(7),
              color: (theme: Theme) => (active ? theme.colors.primary : theme.colors.grayBackground),
            }}
          />
        </Grid>
      </Grid>
    </Button>
  );
};
