import { Autocomplete, AutocompleteRenderInputParams, CircularProgress, Paper, TextField } from '@mui/material';
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { RegionDTO } from '@/types/RegionDTO';
import React, { FC, Fragment, ReactNode, SyntheticEvent, useState } from 'react';

interface Props {
  regions?: RegionDTO[];
  region?: RegionDTO | null;
  isLoading?: boolean;
  error?: boolean;
  helperText?: ReactNode;
  onChange: (value: RegionDTO | null) => void;
  disabled?: boolean;
}

export const RegionSelectField: FC<Props> = (props: Props) => {
  const { regions, region, isLoading, error, helperText, onChange, disabled } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Autocomplete
      id={'select-region'}
      open={open}
      disabled={disabled}
      loading={isLoading}
      options={regions || []}
      value={region}
      getOptionLabel={(option: RegionDTO) => option.name}
      isOptionEqualToValue={(option: RegionDTO, value: RegionDTO) => option.name === value.name}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e: SyntheticEvent, value: AutocompleteValue<RegionDTO, undefined, undefined, undefined>) =>
        onChange(value)
      }
      fullWidth={true}
      blurOnSelect={true}
      noOptionsText={'Не найдено'}
      loadingText={'Загрузка ...'}
      openText={''}
      clearText={''}
      autoHighlight={true}
      handleHomeEndKeys={false}
      PaperComponent={({ children }) => <Paper sx={{ marginTop: '3px' }}>{children}</Paper>}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
              right: '9px',
            },
          }}
          label={'Выберите регион'}
          disabled={disabled}
          InputLabelProps={{
            shrink: false,
          }}
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? <CircularProgress color={'inherit'} size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};
