import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ImageIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M11.1042 16.1875L8.89583 13.3333L5.77083 17.3333H18.2292L14.2292 12.1875L11.1042 16.1875ZM20 18.2292C20 18.7153 19.8264 19.1319 19.4792 19.4792C19.1319 19.8264 18.7153 20 18.2292 20H5.77083C5.28472 20 4.86806 19.8264 4.52083 19.4792C4.17361 19.1319 4 18.7153 4 18.2292V5.77083C4 5.28472 4.17361 4.86806 4.52083 4.52083C4.86806 4.17361 5.28472 4 5.77083 4H18.2292C18.7153 4 19.1319 4.17361 19.4792 4.52083C19.8264 4.86806 20 5.28472 20 5.77083V18.2292Z'
      }
    />
  </SvgIcon>
);
