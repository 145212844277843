import { Grid, Skeleton, Typography } from '@mui/material';
import { LabeledCheckBox, LabeledCheckBoxProps } from '@/components/common/LabeledCheckBox';
import { formatShortFullDateString } from '@/utils/date-utils';
import React, { FC, ReactNode } from 'react';

export interface AttachmentCheckBoxProps extends LabeledCheckBoxProps {
  lastUpdatedDate?: Date;
  isLoading?: boolean;
  isUpdatable: boolean;
  captionText?: ReactNode;
}

export const AttachmentCheckBox: FC<AttachmentCheckBoxProps> = (props: AttachmentCheckBoxProps) => {
  const { lastUpdatedDate, isLoading, isUpdatable, captionText, disabled, ...other } = props;

  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true}>
        <LabeledCheckBox disabled={disabled || isLoading} {...other} fontWeight={500} />
      </Grid>
      <Grid item={true} display={'flex'}>
        <Typography variant={'caption'} display={'flex'}>
          {isUpdatable ? (
            <Grid container={true} direction={'row'} spacing={0.5}>
              <Grid item={true}>{'Последнее обновление:'}</Grid>
              <Grid item={true} minWidth={'64px'}>
                {isLoading ? (
                  <Skeleton height={'16px'} width={'100%'} />
                ) : lastUpdatedDate ? (
                  formatShortFullDateString(lastUpdatedDate)
                ) : (
                  '-'
                )}
              </Grid>
            </Grid>
          ) : (
            captionText
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};
