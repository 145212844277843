import { Button, Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { ImagePreview } from '@/components/view/image/ImagePreview';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { ROUTE_HOME, ROUTE_INBOX, ROUTE_REGISTRATION, getInitiativeLetterPaymentRoute } from '@/app/routes';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { addDays, format } from 'date-fns';
import { clearFormState } from '@/services/createFormSlice';
import { clearSbpQrState } from '@/services/sbpSlice';
import { ru } from 'date-fns/locale';
import { useAppDispatch } from '@/app/hooks';
import { useGetInitiativeLetterQuery } from '@/services/api/letterApiSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useEffect } from 'react';

export const InitiativeLetterPage: FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const params: URLSearchParams = new URLSearchParams(location.search);
  const uuid: string | null = params.get('uuid');

  const {
    data: letter,
    error,
    isLoading,
  } = useGetInitiativeLetterQuery(uuid, { skip: !uuid, refetchOnMountOrArgChange: true, pollingInterval: 30000 });

  const handlePayment = (): void => {
    if (letter?.uuid) {
      clearState();
      history.push(getInitiativeLetterPaymentRoute(letter.uuid));
    }
  };

  const clearState = useCallback((): void => {
    clearSbpQrState(dispatch, SbpMethod.LETTER, undefined, true, true);
    dispatch(clearFormState());
  }, [dispatch]);

  useEffect((): void => {
    if (uuid) {
      if (error) {
        enqueueSnackbar((error as { status: number; data: ErrorDTO }).data.message, { variant: 'error' });
        history.replace(ROUTE_HOME);
      }
    } else {
      history.replace(ROUTE_HOME);
    }
  }, [enqueueSnackbar, error, history, uuid]);

  return (
    <Fragment>
      <Grid container={true} direction={'column'}>
        {isMobile ? (
          <Fragment>
            <Grid item={true}>
              <Typography variant={'h1'} whiteSpace={'pre-line'}>
                <Grid container={true} direction={'row'} spacing={1}>
                  <Grid item={true}>
                    <Typography variant={'inherit'} component={'span'}>
                      {'Инициативное письмо'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                      <Grid item={true}>
                        <Typography variant={'inherit'} component={'span'}>
                          {'№'}
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography variant={'inherit'} component={'span'}>
                          {isLoading ? <Skeleton width={'136px'} /> : letter?.number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item={true} mt={0.5} mb={2.5}>
              <Typography fontSize={'12px'} lineHeight={'16px'} color={(theme: Theme) => theme.colors.grayText}>
                {isLoading ? (
                  <Skeleton width={'80px'} />
                ) : (
                  letter && letter?.sendDate && format(letter.sendDate, 'dd MMM yyyy', { locale: ru })
                )}
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          <Grid item={true}>
            <Grid container={true} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item={true}>
                <Grid container={true} alignItems={'center'} spacing={3}>
                  <Grid item={true}>
                    <Typography variant={'h1'}>
                      <Grid container={true} direction={'row'} spacing={1}>
                        <Grid item={true}>
                          <Typography variant={'inherit'} component={'span'}>
                            {'Инициативное письмо'}
                          </Typography>
                        </Grid>
                        <Grid item={true}>
                          <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                            <Grid item={true}>
                              <Typography variant={'inherit'} component={'span'}>
                                {'№'}
                              </Typography>
                            </Grid>
                            <Grid item={true}>
                              <Typography variant={'inherit'} component={'span'}>
                                {isLoading ? <Skeleton width={'136px'} /> : letter?.number}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography fontSize={'12px'} lineHeight={'16px'} color={(theme: Theme) => theme.colors.grayText}>
                      {isLoading ? (
                        <Skeleton width={'80px'} />
                      ) : (
                        letter && letter?.sendDate && format(letter.sendDate, 'dd MMM yyyy', { locale: ru })
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true}>
                <Grid container={true}>
                  <Grid item={true}>
                    {letter?.status === LetterSimpleStatus.PAID_INITIATIVE ? (
                      <Button variant={'contained'} onClick={() => history.push(ROUTE_INBOX)}>
                        <Grid container={true} ml={3} mr={3}>
                          <Grid item={true}>{'Ответить'}</Grid>
                        </Grid>
                      </Button>
                    ) : (
                      <Button variant={'contained'} onClick={handlePayment}>
                        <Grid container={true} ml={3} mr={3}>
                          <Grid item={true}>{'Оплатить и прочитать'}</Grid>
                        </Grid>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item={true}>
          <Typography
            fontSize={'16px'}
            lineHeight={'24px'}
            color={(theme: Theme) => theme.colors.grayText}
            whiteSpace={'pre-line'}>
            {isLoading ? (
              <Skeleton width={'160px'} />
            ) : (
              `Заключенный ${letter?.prisonerLastName} ${letter?.prisonerFirstName} ${letter?.prisonerMiddleName} ${
                letter &&
                letter?.prisonerBirthDate &&
                (format(letter?.prisonerBirthDate, 'yyyy') === '1900'
                  ? ''
                  : format(letter?.prisonerBirthDate, 'yyyy') + ' г.р.')
              } \nотправил вам письмо${
                letter?.status !== LetterSimpleStatus.PAID_INITIATIVE
                  ? ''
                  : ' из ' + letter.departmentName + ', ' + letter.regionName + ''
              }.`
            )}
          </Typography>
        </Grid>
        <Grid item={true} mt={3}>
          {letter && letter?.preview && (
            <ImagePreview
              content={letter?.status === LetterSimpleStatus.PAID_INITIATIVE ? letter.image : letter.preview}
              isLoading={isLoading}
              isMobile={isMobile}
              isInitiative={true}
            />
          )}
        </Grid>
        {letter?.status !== LetterSimpleStatus.PAID_INITIATIVE ? (
          <Fragment>
            <Grid item={true} mt={4}>
              <Typography
                fontSize={'16px'}
                lineHeight={'24px'}
                color={(theme: Theme) => theme.colors.grayText}
                whiteSpace={'pre-line'}>
                {
                  'Для того, чтобы прочитать письмо, вам необходимо оплатить его получение. Оплатить можно по нажатию на кнопку "Оплатить и прочитать".'
                }
              </Typography>
            </Grid>
            <Grid item={true} mt={2}>
              <Typography
                fontSize={'16px'}
                lineHeight={'24px'}
                color={(theme: Theme) => theme.colors.grayText}
                whiteSpace={'pre-line'}>
                {`Если письмо не будет оплачено до ${
                  letter && letter?.sendDate
                    ? format(addDays(letter.sendDate, 30), 'dd MMM yyyy', {
                        locale: ru,
                      })
                    : ''
                }, то оно будет удалено`}
              </Typography>
            </Grid>
          </Fragment>
        ) : (
          !isMobile && (
            <Grid item={true} mt={4}>
              <Grid container={true} justifyContent={'space-between'}>
                <Grid item={true}>
                  <Typography
                    whiteSpace={'pre-line'}
                    fontSize={'16px'}
                    lineHeight={'24px'}
                    color={(theme: Theme) => theme.colors.grayText}>
                    {'Для того, чтобы постоянно быть на связи,\nзарегистрируйтесь в личном кабинете'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Button variant={'contained'} onClick={() => history.push(ROUTE_REGISTRATION)}>
                    <Grid container={true} ml={3} mr={3}>
                      <Grid item={true}>{'Зарегистрироваться'}</Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
        {isMobile &&
          (letter?.status !== LetterSimpleStatus.PAID_INITIATIVE ? (
            <Fragment>
              <Grid item={true} mt={3}>
                <Button variant={'contained'} onClick={handlePayment} fullWidth={true}>
                  <Grid container={true} ml={3} mr={3} justifyContent={'center'}>
                    <Grid item={true}>{'Оплатить и прочитать'}</Grid>
                  </Grid>
                </Button>
              </Grid>
            </Fragment>
          ) : (
            <Grid item={true} mt={3}>
              <Button variant={'contained'} onClick={() => history.push(ROUTE_INBOX)} fullWidth={true}>
                <Grid container={true} ml={3} mr={3} justifyContent={'center'}>
                  <Grid item={true}>{'Ответить'}</Grid>
                </Grid>
              </Button>
            </Grid>
          ))}
      </Grid>
    </Fragment>
  );
};
