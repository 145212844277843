import { Grid, GridProps } from '@mui/material';
import { ImageViewButton } from '@/components/view/image/ImageViewButton';
import { Theme } from '@mui/material/styles';
import { TurnIcon } from '@/app/icons/view/TurnIcon';
import { ZoomInIcon } from '@/app/icons/view/ZoomInIcon';
import { ZoomOutIcon } from '@/app/icons/view/ZoomOutIcon';
import React, { FC } from 'react';

interface Props extends GridProps {
  onTurnRight?: () => void;
  onTurnLeft?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  canZoomIn?: boolean;
  canZoomOut?: boolean;
}

export const ImageViewControls: FC<Props> = (props: Props) => {
  const { onTurnRight, onTurnLeft, onZoomIn, onZoomOut, canZoomIn, canZoomOut, ...other } = props;
  return (
    <Grid container={true} direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={4} {...other}>
      {(onTurnRight || onTurnLeft) && (
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={1}>
            {onTurnRight && (
              <Grid item={true}>
                <ImageViewButton onClick={onTurnRight}>
                  <TurnIcon
                    sx={(theme: Theme) => ({
                      width: theme.spacing(3),
                      height: theme.spacing(3),
                      transform: 'scale(-1, 1)',
                    })}
                  />
                </ImageViewButton>
              </Grid>
            )}
            {onTurnLeft && (
              <Grid item={true}>
                <ImageViewButton onClick={onTurnLeft}>
                  <TurnIcon />
                </ImageViewButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {(onZoomIn || onZoomOut) && (
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={1}>
            {onZoomIn && (
              <Grid item={true}>
                <ImageViewButton disabled={canZoomIn === false} onClick={onZoomIn}>
                  <ZoomInIcon />
                </ImageViewButton>
              </Grid>
            )}
            {onZoomOut && (
              <Grid item={true}>
                <ImageViewButton disabled={canZoomOut === false} onClick={onZoomOut}>
                  <ZoomOutIcon />
                </ImageViewButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
