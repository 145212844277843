import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Box, Grid, Typography } from '@mui/material';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { getHelpDepartmentsRoute, getHelpRegionRoute } from '@/app/routes';
import React, { FC } from 'react';

interface Props {
  regions?: AtlasRegionDTO[];
  indexFrom?: number;
  indexTo?: number;
  handleChangeRegion: (region: AtlasRegionDTO) => void;
}

const RegionsList: FC<Props> = (props: Props) => {
  const { regions, indexFrom, indexTo, handleChangeRegion } = props;
  return (
    <Grid container={true} direction={'column'} rowSpacing={2.5}>
      {(regions || []).map((region: AtlasRegionDTO, index: number, regions: AtlasRegionDTO[]) => {
        const currentCharacter: string = region.name[0];
        let characterChange = false;
        if (index > 1) {
          const previousCharacter = regions[index - 1].name[0];
          characterChange = currentCharacter != previousCharacter;
        }
        return indexTo && indexFrom
          ? indexFrom <= index && index < indexTo && (
              <Grid item={true} key={region.id}>
                {characterChange && (
                  <Box
                    sx={{
                      margin: '-2px 0px 18px 0px',
                      borderRadius: '6px',
                      background: (theme: Theme) => theme.colors.grayBackground,
                    }}>
                    <Typography variant={'h2'} sx={{ padding: '0px 8px' }}>
                      {region.name[0]}
                    </Typography>
                  </Box>
                )}
                <RouteLink to={getHelpRegionRoute(region.id)}>
                  <TypographyLink onClick={() => handleChangeRegion(region)}>{region.name}</TypographyLink>
                </RouteLink>
              </Grid>
            )
          : indexTo
          ? index < indexTo && (
              <Grid item={true} key={region.id}>
                {characterChange && (
                  <Box
                    sx={{
                      margin: '-2px 0px 18px 0px',
                      borderRadius: '6px',
                      background: (theme: Theme) => theme.colors.grayBackground,
                    }}>
                    <Typography variant={'h2'} sx={{ padding: '0px 8px' }}>
                      {region.name[0]}
                    </Typography>
                  </Box>
                )}
                <RouteLink to={getHelpRegionRoute(region.id)}>
                  <TypographyLink onClick={() => handleChangeRegion(region)}>{region.name}</TypographyLink>
                </RouteLink>
              </Grid>
            )
          : indexFrom
          ? indexFrom <= index && (
              <Grid item={true} key={region.id}>
                {characterChange && (
                  <Box
                    sx={{
                      margin: '-2px 0px 18px 0px',
                      borderRadius: '6px',
                      background: (theme: Theme) => theme.colors.grayBackground,
                    }}>
                    <Typography variant={'h2'} sx={{ padding: '0px 8px' }}>
                      {region.name[0]}
                    </Typography>
                  </Box>
                )}
                <RouteLink to={getHelpRegionRoute(region.id)}>
                  <TypographyLink onClick={() => handleChangeRegion(region)}>{region.name}</TypographyLink>
                </RouteLink>
              </Grid>
            )
          : null;
      })}
    </Grid>
  );
};

export default RegionsList;
