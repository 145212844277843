import { Grid } from '@mui/material';
import { MastercardIcon } from '@/app/icons/payment/MastercardIcon';
import { MirIcon } from '@/app/icons/payment/MirIcon';
import { VisaIcon } from '@/app/icons/payment/VisaIcon';
import React, { FC } from 'react';

export const BankIcons: FC = () => (
  <Grid container={true} direction={'row'} spacing={3.5}>
    <Grid item={true}>
      <VisaIcon />
    </Grid>
    <Grid item={true}>
      <MastercardIcon />
    </Grid>
    <Grid item={true}>
      <MirIcon />
    </Grid>
  </Grid>
);
