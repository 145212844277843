import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const RightIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M16.2529 11.8691C16.2529 11.6318 16.1475 11.4121 15.9717 11.2451L9.42383 4.84668C9.26562 4.68848 9.06348 4.60059 8.83496 4.60059C8.36035 4.60059 8 4.96094 8 5.43555C8 5.65527 8.08789 5.875 8.24609 6.0332L14.2227 11.8691L8.24609 17.7139C8.08789 17.8721 8 18.0742 8 18.3115C8 18.7773 8.36035 19.1465 8.83496 19.1465C9.06348 19.1465 9.26562 19.0586 9.42383 18.9004L15.9717 12.502C16.1562 12.3174 16.2529 12.1152 16.2529 11.8691Z'
      }
    />
  </SvgIcon>
);
