import { CheckOrderStatusRequestDTO } from '@/types/sbp/CheckOrderStatusRequestDTO';
import { CheckQrStatusRequestDTO } from '@/types/sbp/CheckQrStatusRequestDTO';
import { CheckStatusOrderSbpDTO } from '@/types/sbp/CheckStatusOrderSbpDTO';
import { CheckedStatusOrderDTO } from '@/types/sbp/CheckedStatusOrderDTO';
import { CheckedStatusQrDTO } from '@/types/sbp/CheckedStatusQrDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { LetterPaymentStatusDTO } from '@/types/sbp/payment/LetterPaymentStatusDTO';
import { OnlinePaymentResponseDTO } from '@/types/OnlinePaymentResponseDTO';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { RefillBalanceDTO } from '@/types/balance/RefillBalanceDTO';
import { RefillCardDTO } from '@/types/balance/RefillCardDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createBalanceBill: build.mutation<OnlinePaymentResponseDTO, { dto: RefillBalanceDTO; provider: PaymentProvider }>({
      query: ({ dto, provider }) => ({
        url: '/payment/online-balance-payment',
        method: HttpMethod.POST,
        params: { provider: provider },
        body: dto,
      }),
      invalidatesTags: ['Balance', 'BalanceHistory'],
    }),
    createLetterBill: build.mutation<OnlinePaymentResponseDTO, { uuid: string; provider: PaymentProvider }>({
      query: ({ uuid, provider }) => ({
        url: '/payment/online-letter-payment',
        method: HttpMethod.GET,
        params: { uuid: uuid, provider: provider },
      }),
      invalidatesTags: ['Letters', 'LettersCount', 'LetterHistory', 'Senders'],
    }),
    createBillRefillCard: build.mutation<OnlinePaymentResponseDTO, { dto: RefillCardDTO; provider: PaymentProvider }>({
      query: ({ dto, provider }) => ({
        url: '/payment/online-card-payment',
        method: HttpMethod.POST,
        params: { provider: provider },
        body: dto,
      }),
      invalidatesTags: ['Cards'],
    }),
    createBillUnpaidCard: build.mutation<OnlinePaymentResponseDTO, { uuid: string; provider: PaymentProvider }>({
      query: ({ uuid, provider }) => ({
        url: '/payment/online-card-payment',
        method: HttpMethod.GET,
        params: { uuid: uuid, provider: provider },
      }),
      invalidatesTags: ['Cards'],
    }),
    checkStatusPayment: build.mutation<CheckStatusOrderSbpDTO, { sbpOrderId: string }>({
      query: (dto) => ({
        url: '/payment/online-payment-status-check',
        method: HttpMethod.POST,
        body: dto,
      }),
      invalidatesTags: ['Balance', 'BalanceHistory'],
    }),
    getQrStatus: build.mutation<CheckedStatusQrDTO, CheckQrStatusRequestDTO>({
      query: (dto: CheckQrStatusRequestDTO) => ({
        url: '/payment/sbp-qr-status',
        method: HttpMethod.POST,
        body: dto,
      }),
    }),
    getOrderStatus: build.mutation<CheckedStatusOrderDTO, CheckOrderStatusRequestDTO>({
      query: (dto: CheckOrderStatusRequestDTO) => ({
        url: '/payment/sbp-order-status',
        method: HttpMethod.POST,
        body: dto,
      }),
    }),
    getLetterPaymentStatus: build.query<LetterPaymentStatusDTO, string>({
      query: (letterUuid: string) => ({
        url: '/payment/status/letter',
        method: HttpMethod.GET,
        params: {
          uuid: letterUuid,
        },
      }),
    }),
  }),
});

export const {
  useCreateBalanceBillMutation,
  useCreateLetterBillMutation,
  useCreateBillRefillCardMutation,
  useCreateBillUnpaidCardMutation,
  useCheckStatusPaymentMutation,
  useGetQrStatusMutation,
  useGetOrderStatusMutation,
  useGetLetterPaymentStatusQuery,
} = paymentApiSlice;
