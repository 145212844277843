import { Box, CircularProgress, Grid } from '@mui/material';
import { FixedButton } from '@/components/common/button/FixedButton';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import React, { FC, Fragment, useEffect, useState } from 'react';

interface Props {
  buttonText: string;
  onClick: () => void;
  isLoading?: boolean;
  disabled: boolean;
  isMobile?: boolean;
  isInitiative?: boolean;
  isInitiativeSubmitAllowed?: boolean;
  policyChecked: boolean;
  onPolicyChange: (policyChecked: boolean) => void;
}

export const PaymentButton: FC<Props> = (props: Props) => {
  const {
    buttonText,
    onClick,
    isLoading,
    isMobile,
    disabled,
    isInitiative,
    isInitiativeSubmitAllowed,
    policyChecked,
    onPolicyChange,
  } = props;

  const [isYaBrowser, setIsYaBrowser] = useState<boolean>(false);

  useEffect((): void => {
    if (navigator.userAgent.indexOf('YaBrowser') >= 0) {
      setIsYaBrowser(true);
    }
  }, []);

  return (
    <Fragment>
      {isMobile ? (
        !isInitiative || (isInitiative && isInitiativeSubmitAllowed) ? (
          isYaBrowser ? (
            <Grid item={true} mt={2} width={'100%'}>
              <LoadingButton
                variant={'contained'}
                color={'primary'}
                size={'medium'}
                fullWidth={true}
                isLoading={isLoading}
                disabled={disabled}
                onClick={onClick}>
                {buttonText}
              </LoadingButton>
            </Grid>
          ) : (
            <MobileBar>
              <FixedButton disabled={disabled} color={'primary'} variant={'contained'} onClick={onClick}>
                <Grid container={true} justifyContent={'center'} sx={{ position: 'relative' }}>
                  {isLoading && (
                    <Grid item={true} sx={{ position: 'absolute', left: 0 }}>
                      <CircularProgress sx={{ color: '#ffffff' }} size={20} />
                    </Grid>
                  )}
                  <Grid item={true}> {buttonText}</Grid>
                </Grid>
              </FixedButton>
            </MobileBar>
          )
        ) : (
          <div />
        )
      ) : (
        <Box mt={2}>
          <PolicyLoadingButton
            variant={'contained'}
            color={'primary'}
            size={'medium'}
            fullWidth={true}
            isLoading={isLoading}
            disabled={disabled}
            onClick={onClick}
            policyChecked={policyChecked}
            onPolicyChange={onPolicyChange}>
            {buttonText}
          </PolicyLoadingButton>
        </Box>
      )}
    </Fragment>
  );
};
