import { Button, Grid } from '@mui/material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { FixedButton } from '@/components/common/button/FixedButton';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { MobileBar } from '@/components/home/MobileBar';
import { PhoneFormOne } from '@/components/profile/modals/phone/PhoneFormOne';
import { PhoneFormTwo } from '@/components/profile/modals/phone/PhoneFormTwo';
import { RightIcon } from '@/app/icons/RightIcon';
import { Theme } from '@mui/material/styles';
import React, { FC, useEffect, useRef, useState } from 'react';

interface Props {
  open: boolean;
  isAddPhone?: boolean;
  isMobile?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const PhoneNumberDialog: FC<Props> = (props: Props) => {
  const { open, isAddPhone, isMobile, onClose, onOpen } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSuccessChange, setSuccessChange] = useState<boolean>(false);
  const [newPhone, setNewPhone] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
  };
  const handleSubmitFormOne = (newPhone: string): void => {
    setStep(1);
    setNewPhone(newPhone);
  };
  const handleBackClick = (): void => {
    if (step === 0) {
      handleClose();
    } else if (step === 1) {
      if (isSuccessChange) {
        handleClose();
      } else {
        setStep(0);
      }
    }
  };
  const handleClickSubmit = (): void => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };
  useEffect(() => {
    if (onOpen && open) {
      onOpen();
    } else {
      setStep(0);
      setSuccessChange(false);
      setLoading(false);
      setNewPhone('');
    }
  }, [onOpen, open]);
  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={isAddPhone ? 'Добавить номер телефона' : 'Изменить номер телефона'}
      handleCloseButton={handleClose}
      PaperProps={{ sx: { minHeight: isMobile ? '0' : '420px' } }}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(2),
        overflowY: 'visible',
      })}
      actions={
        <Grid container={true} justifyContent={'space-between'} pb={isMobile ? 7 : 0}>
          <Grid item={true} xs={12} sm={4}>
            <Button
              sx={{ padding: (theme: Theme) => theme.spacing(1.375, 1) }}
              fullWidth={true}
              variant={'outlined'}
              onClick={handleBackClick}>
              <Grid container={true}>
                <Grid item={true} marginRight={'17px'}>
                  <RightIcon
                    sx={{
                      display: 'block',
                      transform: 'rotate(180deg)',
                    }}
                  />
                </Grid>
                <Grid item={true}>{'Назад'}</Grid>
              </Grid>
            </Button>
          </Grid>
          {step === 0 &&
            (isMobile ? (
              <MobileBar>
                <FixedButton color={'primary'} variant={'contained'} onClick={handleClickSubmit} disabled={isLoading}>
                  {'Далее'}
                </FixedButton>
              </MobileBar>
            ) : (
              <Grid item={true} xs={4}>
                <LoadingButton fullWidth={true} isLoading={isLoading} variant={'contained'} onClick={handleClickSubmit}>
                  {'Далее'}
                </LoadingButton>
              </Grid>
            ))}
        </Grid>
      }>
      {step === 0 ? (
        <PhoneFormOne
          initialPhone={newPhone}
          isAddPhone={isAddPhone}
          handleSubmit={handleSubmitFormOne}
          submitButtonRef={submitButtonRef}
          onLoading={setLoading}
        />
      ) : (
        <PhoneFormTwo newPhone={newPhone} setSuccessChange={setSuccessChange} isMobile={isMobile} />
      )}
    </ClientDialog>
  );
};
