import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { BalanceHistoryRequestDTO } from '@/types/balance/BalanceHistoryRequestDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';
import { formatISO } from 'date-fns';

export interface BalanceHistoryState {
  request: BalanceHistoryRequestDTO;
}

const initialState: BalanceHistoryState = {
  request: {
    searchRequest: {
      type: BalanceFilterType.ALL,
    },
    pageRequest: {
      pageNumber: 0,
      pageSize: 6,
      sortRequest: {
        properties: ['timestamp'],
        direction: 'DESC',
      },
    },
  },
};

export const balanceHistorySlice = createSlice({
  name: 'balanceHistory',
  initialState: initialState,
  reducers: {
    setBalanceHistoryRequest: (state: BalanceHistoryState, action: PayloadAction<BalanceHistoryRequestDTO>) => {
      state.request = action.payload;
    },
    setBalanceHistoryRequestPageNumber: (state: BalanceHistoryState, action: PayloadAction<number>) => {
      state.request = {
        ...state.request,
        pageRequest: {
          ...state.request.pageRequest,
          pageNumber: action.payload,
        },
      };
    },
    setBalanceHistoryRequestType: (state: BalanceHistoryState, action: PayloadAction<BalanceFilterType>) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          type: action.payload,
        },
      };
    },
    setBalanceHistoryRequestStartDate: {
      reducer: (state: BalanceHistoryState, action: PayloadAction<Date | string | undefined>) => {
        state.request = {
          ...state.request,
          searchRequest: {
            ...state.request.searchRequest,
            startDate: action.payload,
          },
        };
      },
      prepare: (date: Date | string | undefined) => {
        return { payload: date ? formatISO(new Date(date)) : undefined };
      },
    },
    setBalanceHistoryRequestEndDate: {
      reducer: (state: BalanceHistoryState, action: PayloadAction<Date | string | undefined>) => {
        state.request = {
          ...state.request,
          searchRequest: {
            ...state.request.searchRequest,
            endDate: action.payload,
          },
        };
      },
      prepare: (date: Date | string | undefined) => {
        return { payload: date ? formatISO(new Date(date)) : undefined };
      },
    },
    clearBalanceHistoryRequestDates: (state: BalanceHistoryState) => {
      state.request = {
        ...state.request,
        searchRequest: {
          ...state.request.searchRequest,
          startDate: undefined,
          endDate: undefined,
        },
      };
    },
  },
});

export const {
  setBalanceHistoryRequestPageNumber,
  setBalanceHistoryRequestType,
  setBalanceHistoryRequestStartDate,
  setBalanceHistoryRequestEndDate,
  clearBalanceHistoryRequestDates,
} = balanceHistorySlice.actions;

export const getBalanceHistoryRequest = (state: RootState): BalanceHistoryRequestDTO => state.balanceHistory.request;

export default balanceHistorySlice.reducer;
