import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

interface Props extends SvgIconProps {
  primary?: boolean;
}

export const AmountCardMobileIcon: FC<Props> = (props: Props) => {
  const { primary, ...other } = props;
  return (
    <SvgIcon viewBox={'0 0 96 56'} sx={{ width: '96px', height: '56px' }} {...other}>
      <mask
        id={'mask0_3787_12496'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'0'}
        y={'0'}
        width={'96'}
        height={'56'}>
        <path
          d={
            'M0 12C0 5.37258 5.37258 0 12 0H84C90.6274 0 96 5.37258 96 12V44C96 50.6274 90.6274 56 84 56H12C5.37258 56 0 50.6274 0 44V12Z'
          }
        />
      </mask>
      <g mask={'url(#mask0_3787_12496)'}>
        <rect width={'96'} height={'56'} rx={'12'} />
        <path d={'M85.3161 0L50 57H96V0H85.3161Z'} fill={primary ? '#6c93fa' : '#ecebf0'} />
      </g>
    </SvgIcon>
  );
};
