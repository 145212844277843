import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';

export interface IpLocationState {
  foreignState: boolean;
}

const initialState: IpLocationState = {
  foreignState: false,
};

export const ipLocationSlice = createSlice({
  name: 'ipLocation',
  initialState: initialState,
  reducers: {
    setForeignState: (state: IpLocationState, action: PayloadAction<string | undefined>) => {
      if (typeof action.payload == 'undefined') {
        return;
      }
      const payload = action.payload === 'true';
      if (state.foreignState != payload) {
        state.foreignState = payload;
      }
    },
  },
});

export const { setForeignState } = ipLocationSlice.actions;
export const isForeignState = (state: RootState): boolean => {
  return state.ipLocation.foreignState;
};

export default ipLocationSlice.reducer;
