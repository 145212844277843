export const ROUTE_HOME = '/';
export const ROUTE_MAIN = '/main';
export const ROUTE_MAIN_CREATE_ANSWER_DEPARTMENT = '/main/create/answer/department';
export const ROUTE_MAIN_CREATE_ANSWER_AUTHOR = '/main/create/answer/author';
export const ROUTE_MAIN_CREATE_ANSWER_CLIENT = '/main/create/answer/client';
export const ROUTE_MAIN_CREATE_ANSWER_PAYMENT = '/main/create/answer/payment';
export const ROUTE_MAIN_CREATE_ANSWER_FEEDBACK = '/main/create/answer/feedback';
export const ROUTE_MAIN_CREATE_FEEDBACK_SUCCESS = '/main/create/answer/feedback-success';
export const ROUTE_MAIN_CARD = '/main/card';
export const ROUTE_MAIN_CARD_CREATED = '/main/card/success-created';
export const ROUTE_MAIN_CARD_REFILLED = '/main/card/success-refilled';
export const ROUTE_MAIN_CARD_PAYED = '/main/card/success-payed';
export const ROUTE_CARD = '/card';
export const ROUTE_CARD_CREATED = '/card/success-created';
export const ROUTE_CARD_REFILLED = '/card/success-refilled';
export const ROUTE_CARD_PAYED = '/card/success-payed';
export const ROUTE_MAIN_CREATE_ANSWER = '/main/create/answer';
export const ROUTE_MAIN_CREATE_LETTER = '/main/create/letter';
export const ROUTE_MAIN_CREATE_LETTER_RECEIVER = '/main/create/letter/receiver';
export const ROUTE_MAIN_CREATE_ANSWER_RECEIVER = '/main/create/answer/receiver';
export const ROUTE_MAIN_CREATE_LETTER_DEPARTMENT = '/main/create/letter/department';
export const ROUTE_MAIN_CREATE_LETTER_CLIENT = '/main/create/letter/client';
export const ROUTE_MAIN_CREATE_LETTER_TEXT = '/main/create/letter/text';
export const ROUTE_MAIN_CREATE_LETTER_PAYMENT = '/main/create/letter/payment';
export const ROUTE_MAIN_CREATE_LETTER_AUTHOR = '/main/create/letter/author';
export const ROUTE_MAIN_CREATE_LETTER_SUCCESS = '/main/create/letter/success';
export const ROUTE_AUTHENTICATION = '/sign-in';
export const ROUTE_REGISTRATION = '/sign-up';
export const ROUTE_REGISTRATION_CONFIRM_REQUEST = '/sign-up/request';
export const ROUTE_REGISTRATION_CONFIRM_SUCCESS = '/sign-up/confirm';
export const ROUTE_REGISTRATION_SUCCESS = '/sign-up/success';
export const ROUTE_RESTORE = '/restore';
export const ROUTE_RESTORE_NEW = '/restore/new';
export const ROUTE_RESTORE_SUCCESS = '/restore/success';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_CHANGE_EMAIL = '/change-email';
export const ROUTE_ABOUT = '/about';
export const ROUTE_HELP = '/help';
export const ROUTE_HELP_WORK = '/help/work';
export const ROUTE_HELP_FAQ = '/help/faq';
export const ROUTE_HELP_REGIONS = '/help/regions';
export const ROUTE_HELP_REGIONS_ID = '/help/regions/:regId';
export const ROUTE_HELP_REGIONS_CHAR = '/help/regions/mobile';
export const ROUTE_HELP_DEPARTMENT = '/help/regions/:regId/:depId';
export const ROUTE_HELP_FEEDBACK = '/help/feedback';
export const ROUTE_HELP_FEEDBACK_NUMBER = '/help/feedback/:number';
export const ROUTE_INBOX = '/inbox';
export const ROUTE_INBOX_LETTER = '/inbox/letters/:id';
export const ROUTE_OUTBOX = '/outbox';
export const ROUTE_OUTBOX_LETTER = '/outbox/letters/:id';
export const ROUTE_DRAFTS = '/drafts';
export const ROUTE_CREATE = '/create';
export const ROUTE_CREATE_LETTER = '/create/letter';
export const ROUTE_CREATE_ANSWER = '/create/answer';
export const ROUTE_CREATE_REPLY = '/create/reply';
export const ROUTE_CREATE_LETTER_DEPARTMENT = '/create/letter/department';
export const ROUTE_CREATE_LETTER_CLIENT = '/create/letter/client';
export const ROUTE_CREATE_LETTER_TEXT = '/create/letter/text';
export const ROUTE_CREATE_LETTER_PAYMENT = '/create/letter/payment';
export const ROUTE_CREATE_LETTER_AUTHOR = '/create/letter/author';
export const ROUTE_CREATE_LETTER_SUCCESS = '/create/letter/success';
export const ROUTE_CREATE_REPLY_LETTER = '/create/reply/letter';
export const ROUTE_CREATE_REPLY_LETTER_ID = '/create/reply/letter/:id';
export const ROUTE_CREATE_ANSWER_DEPARTMENT = '/create/answer/department';
export const ROUTE_CREATE_ANSWER_SENDER = '/create/answer/sender';
export const ROUTE_CREATE_ANSWER_PAYMENT = '/create/answer/payment';
export const ROUTE_CREATE_REPLY_ANSWER = '/create/reply/answer';
export const ROUTE_CREATE_ANSWER_FEEDBACK = '/create/answer/feedback';
export const ROUTE_CREATE_FEEDBACK_SUCCESS = '/create/answer/feedback-success';
export const ROUTE_CHECK_LETTER_STATUS = '/info/check';
export const ROUTE_REDIRECT = '/letter/redirect';
export const ROUTE_REDIRECT_LETTER = '/letter/redirect/letter';
export const ROUTE_REDIRECT_DEPARTMENT = '/letter/redirect/department';
export const ROUTE_REDIRECT_SUCCESS = '/letter/redirect/success';
export const ROUTE_VIEW_INITIATIVE = '/initiative';
export const ROUTE_PAYMENT_INITIATIVE = '/initiative/payment/:uuid';
export const ROUTE_PAYMENT_INITIATIVE_SUCCESS = '/initiative/payment/success/:uuid';
export const ROUTE_ERROR = '/error';
export const ROUTE_ERROR_LETTER_PAYED = '/error/letter-payed';
export const ROUTE_ERROR_CARD_REFILL = '/error/card-refill';
export const ROUTE_ERROR_CARD_PAYED = '/error/card-payed';

export const getInboxLetterRoute = (letterId: number): string => {
  return ROUTE_INBOX_LETTER.replace(':id', String(letterId));
};

export const getCreateLetterReplyRoute = (letterId: number): string => {
  return ROUTE_CREATE_REPLY_LETTER_ID.replace(':id', String(letterId));
};

export const getOutboxLetterRoute = (letterId: number): string => {
  return ROUTE_OUTBOX_LETTER.replace(':id', String(letterId));
};

export const getHelpDepartmentsRoute = (regId: number, depId: number): string => {
  return ROUTE_HELP_DEPARTMENT.replace(':regId', String(regId)).replace(':depId', String(depId));
};

export const getHelpRegionRoute = (regionId: number): string => {
  return ROUTE_HELP_REGIONS_ID.replace(':regId', String(regionId));
};

export const getHelpFeedbackRoute = (letterNumber: number): string => {
  return ROUTE_HELP_FEEDBACK_NUMBER.replace(':number', String(letterNumber));
};

export const getInitiativeLetterPaymentRoute = (uuid: string): string => {
  return ROUTE_PAYMENT_INITIATIVE.replace(':uuid', uuid);
};

export const getRegistrationConfirmRequestRoute = (email: string): string => {
  return ROUTE_REGISTRATION_CONFIRM_REQUEST + `?email=${email}`;
};
