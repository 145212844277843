import { CardDTO } from '@/types/card/CardDTO';
import { CardDeleteDialog } from '@/components/card/CardDeleteDialog';
import { CardIcon } from '@/app/icons/profile/CardIcon';
import { Grid, IconButton, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { TrashIcon } from '@/app/icons/profile/TrashIcon';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  card: CardDTO;
  isLoading?: boolean;
}

export const CardBlock: FC<Props> = (props: Props) => {
  const { card, isLoading } = props;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Fragment>
      <Grid container={true} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
        <Grid item={true}>
          <Grid container={true} direction={'row'} spacing={2}>
            <Grid item={true} display={'flex'} minWidth={(theme: Theme) => (isMobile ? theme.spacing(8) : 'auto')}>
              <CardIcon
                sx={(theme: Theme) => ({
                  width: theme.spacing(4),
                  height: theme.spacing(3),
                  marginTop: theme.spacing(0.5),
                  [theme.breakpoints.down('sm')]: {
                    width: theme.spacing(5),
                    height: theme.spacing(3.75),
                  },
                })}
              />
            </Grid>
            <Grid item={true}>
              <Grid container={true} direction={'column'}>
                <Grid item={true}>
                  <Typography variant={'h4'}>
                    {isLoading ? <Skeleton /> : formatCurrency(card?.balance || 0, 0)}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography
                    variant={'body2'}
                    color={(theme: Theme) => theme.colors.grayText}>{`Ф-карта ${card.ourNumber}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true}>
          <IconButton size={'small'} onClick={() => setOpenDialog(true)}>
            <TrashIcon
              sx={{
                height: '20px',
                width: '20px',
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <CardDeleteDialog open={openDialog} card={card} isMobile={isMobile} onClose={() => setOpenDialog(false)} />
    </Fragment>
  );
};
