import * as Yup from 'yup';
import { MESSAGE_REQUIRED } from '@/utils/validation-utils';
import { getYear } from 'date-fns';

export const minBirthYear: number = getYear(new Date()) - 100;
export const maxBirthYear: number = getYear(new Date()) - 14;

export const yearSchema: Yup.NumberSchema = Yup.number()
  .required(MESSAGE_REQUIRED)
  .min(minBirthYear, 'Некорректный год')
  .max(maxBirthYear, 'Некорректный год')
  .typeError('Введите число');
