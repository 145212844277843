import { Button, ButtonProps, Theme } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  payAmount: number;
  setPayAmount: (payAmount: number) => void;
}

export const PayCardButton: FC<Props> = (props: Props) => {
  const { payAmount, setPayAmount } = props;
  const handleClick = () => {
    setPayAmount(payAmount);
  };
  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      onClick={handleClick}
      sx={{
        lineHeight: (theme: Theme) => theme.spacing(2.5),
        fontSize: (theme: Theme) => theme.spacing(1.75),
        padding: (theme: Theme) => theme.spacing(1.25, 2),
        borderRadius: (theme: Theme) => theme.spacing(1.5),
      }}>
      {formatCurrency(payAmount, 0)}
    </Button>
  );
};
