import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const EnvelopeIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 137 137'} sx={{ width: '137px', height: '137px' }} {...props}>
    <path
      d={
        'M137 28.5V34.3116V99.0313C137 106.122 131.247 111.875 124.156 111.875H12.8438C5.75293 111.875 0 106.122 0 99.0313V34.3383C0 33.0004 0 28.5 0 28.5H137Z'
      }
      fill={'url(#paint0_radial_1567_20409)'}
    />
    <path
      d={
        'M88.1402 67.5473C83.6449 70.8117 74.7078 78.732 68.5 78.625C62.2922 78.732 53.3551 70.8117 48.8598 67.5473C13.3521 41.7795 10.6496 39.5051 2.46172 33.11C0.909766 31.8791 0 30.0328 0 28.0527V25.125C0 20.7067 3.56215 17.125 7.98043 17.125H69.5H129.016C133.435 17.125 137 20.7067 137 25.125V28.0527C137 30.0328 136.09 31.9059 134.538 33.11C126.35 39.5318 123.648 41.7795 88.1402 67.5473Z'
      }
      fill={'#A4BAF4'}
    />
    <defs>
      <radialGradient
        id={'paint0_radial_1567_20409'}
        cx={'0'}
        cy={'0'}
        r={'1'}
        gradientUnits={'userSpaceOnUse'}
        gradientTransform={'translate(68.5 35.5) rotate(89.4093) scale(48.5026 88.1572)'}>
        <stop stopColor={'#6387E6'} />
        <stop offset={'0.967066'} stopColor={'#8CA5E8'} />
      </radialGradient>
    </defs>
  </SvgIcon>
);
