import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CheckIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M17.6823 7.76895C18.0861 8.14578 18.1079 8.77857 17.7311 9.18232L10.7311 16.6823C10.546 16.8806 10.2884 16.9952 10.0172 16.9999C9.74608 17.0045 9.48466 16.8989 9.29289 16.7071L5.79289 13.2071C5.40237 12.8166 5.40237 12.1834 5.79289 11.7929C6.18342 11.4024 6.81658 11.4024 7.20711 11.7929L9.9752 14.561L16.2689 7.81769C16.6458 7.41393 17.2786 7.39211 17.6823 7.76895Z'
      }
    />
  </SvgIcon>
);
