import { FooterLink } from '@/components/main/footer/FooterLink';
import { Grid, IconButton, Typography } from '@mui/material';
import { ROUTE_ABOUT, ROUTE_HELP, ROUTE_HELP_REGIONS } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { VkIcon } from '@/app/icons/social/VkIcon';
import { config } from '@/app/config';
import { format } from 'date-fns';
import React, { FC } from 'react';

interface Props {
  isMobile?: boolean;
}

export const FooterInfo: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  return (
    <Grid container={true} justifyContent={isMobile ? 'center' : 'space-between'} alignItems={'center'} wrap={'nowrap'}>
      <Grid item={true}>
        <Typography
          align={isMobile ? 'center' : 'left'}
          variant={'body2'}
          color={(theme: Theme) => theme.colors.grayText}>
          <span>{`© 2008—${format(new Date(), 'yyyy')} `}</span>
          {isMobile && <br />}
          <span>{'«Специальные Электронные Системы»'}</span>
        </Typography>
      </Grid>
      {!isMobile && (
        <Grid item={true}>
          <Grid container={true} alignItems={'center'} spacing={4} wrap={'nowrap'}>
            <Grid item={true}>
              <Grid container={true} alignItems={'center'} spacing={2}>
                <Grid item={true}>
                  <FooterLink to={ROUTE_ABOUT}>{'О сервисе'}</FooterLink>
                </Grid>
                <Grid item={true}>
                  <FooterLink to={ROUTE_HELP}>{'Помощь'}</FooterLink>
                </Grid>
                <Grid item={true}>
                  <FooterLink to={ROUTE_HELP_REGIONS}>{'Список учреждений'}</FooterLink>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true}>
              <IconButton size={'dense'} href={config.links.social.vk || ''} target={'_blank'}>
                <VkIcon
                  sx={{
                    width: (theme: Theme) => theme.spacing(3),
                    height: (theme: Theme) => theme.spacing(3),
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
