import { Grid } from '@mui/material';
import { MenuLink } from '@/components/common/MenuLink';
import { ROUTE_HELP_FAQ, ROUTE_HELP_FEEDBACK, ROUTE_HELP_REGIONS, ROUTE_HELP_WORK } from '@/app/routes';
import React, { FC } from 'react';

const HelpMenu: FC = () => {
  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true}>
        <MenuLink to={ROUTE_HELP_WORK} label={'Как работает\nФ-письмо'} style={{ textAlign: 'left' }} />
      </Grid>
      <Grid item={true}>
        <MenuLink to={ROUTE_HELP_FAQ} label={'Часто задаваемые\nвопросы'} style={{ textAlign: 'left' }} />
      </Grid>
      <Grid item={true}>
        <MenuLink to={ROUTE_HELP_REGIONS} label={'Справочник учреждений'} />
      </Grid>
      <Grid item={true}>
        <MenuLink to={ROUTE_HELP_FEEDBACK} label={'Обратная связь'} />
      </Grid>
    </Grid>
  );
};

export default HelpMenu;
