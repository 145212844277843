import { CheckLetterStatusDialog } from '@/components/letter/statusCheck/CheckLetterStatusDialog';
import { CheckLetterStatusForm } from './CheckLetterStatusForm';
import { CheckLetterStatusHistory } from '@/components/letter/statusCheck/CheckLetterStatusHistory';
import { Divider, Grid, Theme, Typography } from '@mui/material';
import { LetterStatusHistoryDTO } from '@/types/letter/LetterStatusHistoryDTO';
import { MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { ROUTE_HELP_FEEDBACK } from '@/app/routes';
import { useCheckLetterStatusMutation } from '@/services/api/letterApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useState } from 'react';

interface Props {
  isMobile: boolean;
}

export const CheckLetterStatusBlock: FC<Props> = (props: Props) => {
  const { isMobile } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSupportClick = (): void => {
    history.push(ROUTE_HELP_FEEDBACK);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);

  const [openLetterStatusDialog, setOpenLetterStatusDialog] = useState<boolean>(false);

  const [response, setResponse] = useState<LetterStatusHistoryDTO>();
  const [checkLetterStatus, { isLoading }] = useCheckLetterStatusMutation();
  const handleSubmitForm = async (email: string, letterNumber: number) => {
    setResponse(undefined);
    handleReCaptchaVerify().then(async (token) => {
      await checkLetterStatus({
        letterNumber: letterNumber,
        email: email,
        reCaptchaToken: token,
      })
        .unwrap()
        .then((dto) => {
          setResponse(dto);
          if (isMobile) {
            setOpenLetterStatusDialog(true);
          }
        })
        .catch(() => {
          enqueueSnackbar(MESSAGE_FETCH_ERROR, {
            variant: 'error',
          });
        });
    });
  };

  return (
    <Fragment>
      <Grid container={true} direction={'column'} sx={{ height: '100%' }} marginBottom={isMobile ? 5 : 0}>
        <Grid item={true} paddingBottom={isMobile ? 6 : 7}>
          {isMobile ? (
            <Grid container={true} direction={'column'}>
              <Grid item={true} marginTop={-0.5} paddingBottom={1.5}>
                <Typography variant={'h5'} align={'center'} color={(theme: Theme) => theme.colors.grayText}>
                  {'Проверка статуса письма'}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant={'h1'} lineHeight={'32px'}>
                  {'Данные о письме'}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant={'h1'}>{'Проверка статуса письма'}</Typography>
          )}
        </Grid>
        <Grid item={true} xs={isMobile}>
          <CheckLetterStatusForm isLoading={isLoading} handleSubmitForm={handleSubmitForm} isMobile={isMobile} />
        </Grid>
        {!isMobile && response && (
          <Grid container={true} direction={'column'} spacing={3} pt={4} pb={4}>
            <Grid item={true}>
              <Divider />
            </Grid>
            <Grid item={true}>
              <CheckLetterStatusHistory
                events={response.events}
                eventLogLoading={isLoading}
                letterNumber={response.letterNumber}
                email={response.email}
                responseStatus={response.responseStatus}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {isMobile && response && (
        <CheckLetterStatusDialog
          open={openLetterStatusDialog}
          events={response.events}
          letterNumber={response.letterNumber}
          email={response.email}
          responseStatus={response.responseStatus}
          onClose={() => setOpenLetterStatusDialog(false)}
          handleSupportClick={handleSupportClick}
        />
      )}
    </Fragment>
  );
};
