import { CardPaymentInfo } from '@/components/create/unauthorized/CardPaymentInfo';
import { CardService } from '@/types/card/CardService';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { Grid, Typography } from '@mui/material';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SpbPaymentContainer } from '@/components/common/sbp/SpbPaymentContainer';
import { formatCurrency } from '@/utils/string-utils';
import React, { FC } from 'react';

interface Props {
  amount: number;
  isMobile?: boolean;
  email: string;
  sbpOrderId?: string;
  sbpQR?: CreatedSbpQrDTO;
  isCardFillSuccess: boolean;
  cardService: CardService;
}

export const SbpCardPaymentContainer: FC<Props> = (props: Props) => {
  const { isMobile, amount, sbpOrderId, sbpQR, email, isCardFillSuccess, cardService } = props;
  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <Grid container={true} justifyContent={'space-between'}>
          <Grid item={true}>
            <Typography>{'Итого'}</Typography>
          </Grid>
          <Grid item={true}>
            <Typography>{formatCurrency(amount)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {sbpQR ? (
        <Grid item={true}>
          <SpbPaymentContainer
            sbpQR={sbpQR}
            sbpMethod={SbpMethod.CARD}
            cardService={cardService}
            email={email}
            isMobile={isMobile}
          />
        </Grid>
      ) : (
        <Grid item={true}>
          <CardPaymentInfo cardService={cardService} email={email} isPaymentSuccess={isCardFillSuccess} />
        </Grid>
      )}
    </Grid>
  );
};
