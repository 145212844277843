import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

interface Props extends SvgIconProps {
  isHoverColor?: boolean;
  primary?: boolean;
}

export const AmountCardIcon: FC<Props> = (props: Props) => {
  const { isHoverColor, primary, ...other } = props;
  return (
    <SvgIcon viewBox={'0 0 160 96'} sx={{ width: '160px', height: '96px' }} {...other}>
      <mask
        id={'mask0_3596_9267'}
        style={{ maskType: 'alpha' }}
        maskUnits={'userSpaceOnUse'}
        x={'0'}
        y={'0'}
        width={'160'}
        height={'96'}>
        <rect width={'160'} height={'96'} rx={'12'} />
      </mask>
      <g mask={'url(#mask0_3596_9267)'}>
        <rect width={'160'} height={'96'} rx={'12'} />
        <path
          d={'M152 -17.5L73.5 112.5L196 124.5L202.5 -23.5L152 -17.5Z'}
          fill={isHoverColor ? '' : primary ? '#6c93fa' : '#ecebf0'}
        />
      </g>
    </SvgIcon>
  );
};
