import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_HELP_REGIONS_CHAR } from '@/app/routes';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props {
  regions?: AtlasRegionDTO[];
  handleSetChar: (char: string) => void;
}

const MobileRegionsList: FC<Props> = (props: Props) => {
  const { regions, handleSetChar } = props;
  const history = useHistory();
  const handleClickChar = (char: string) => {
    handleSetChar(char);
    history.push(ROUTE_HELP_REGIONS_CHAR);
  };
  return (
    <Grid container={true} spacing={1}>
      {(regions || []).map((region: AtlasRegionDTO, index: number, regions: AtlasRegionDTO[]) => {
        const currentCharacter: string = region.name[0];
        let characterChange = false;
        if (index > 1) {
          const previousCharacter = regions[index - 1].name[0];
          characterChange = currentCharacter != previousCharacter;
        }
        return (
          characterChange && (
            <Grid item={true} key={region.id}>
              <Button
                onClick={() => handleClickChar(currentCharacter)}
                color={'secondary'}
                variant={'contained'}
                sx={{
                  padding: '11px 17px',
                  borderRadius: '12px',
                  minWidth: '50px',
                }}>
                <Typography variant={'h2'} lineHeight={'28px'}>
                  {currentCharacter}
                </Typography>
              </Button>
            </Grid>
          )
        );
      })}
    </Grid>
  );
};

export default MobileRegionsList;
