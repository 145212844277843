import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SendAnswerIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 325 296'} sx={{ width: '325px', height: '296px' }} {...props}>
    <g clipPath={'url(#clip0_1704_98568)'}>
      <g filter={'url(#filter0_d_1704_98568)'}>
        <mask id={'mask0_1704_98568'} maskUnits={'userSpaceOnUse'} x={'88'} y={'137'} width={'119'} height={'119'}>
          <path
            d={
              'M88.4744 190.327C87.6389 186.516 88.7088 182.536 91.3429 179.657L125.989 141.792C129.413 138.05 134.82 136.865 139.496 138.832L186.804 158.732C190.401 160.245 193.037 163.413 193.873 167.224L206.087 222.947C207.506 229.421 203.408 235.819 196.935 237.238L114.98 255.202C108.506 256.621 102.108 252.524 100.689 246.05L88.4744 190.327Z'
            }
            fill={'#C4C4C4'}
          />
        </mask>
        <g mask={'url(#mask0_1704_98568)'}>
          <path
            d={
              'M89.0971 193.167C88.8186 191.897 89.1752 190.57 90.0532 189.611L127.5 148.685C130.924 144.943 136.331 143.758 141.007 145.725L192.139 167.234C193.338 167.738 194.217 168.794 194.495 170.065L208.656 234.669L103.258 257.772L89.0971 193.167Z'
            }
            fill={'url(#paint0_radial_1704_98568)'}
          />
          <path
            d={
              'M100.689 246.05L89.66 195.736L208.656 234.669L114.98 255.202C108.506 256.621 102.108 252.524 100.689 246.05Z'
            }
            fill={'#A4BAF4'}
          />
          <path
            d={
              'M206.087 222.947L195.058 172.633L103.258 257.772L196.935 237.238C203.408 235.819 207.506 229.421 206.087 222.947Z'
            }
            fill={'#A4BAF4'}
          />
        </g>
      </g>
      <g filter={'url(#filter1_d_1704_98568)'}>
        <rect x={'162.012'} y={'121'} width={'113.846'} height={'139.242'} rx={'4'} fill={'#FBFBFF'} />
      </g>
      <g filter={'url(#filter2_dd_1704_98568)'}>
        <rect
          x={'242.471'}
          y={'203.32'}
          width={'63.9289'}
          height={'63.0532'}
          rx={'4'}
          transform={'rotate(9.8976 242.471 203.32)'}
          fill={'#FBFBFF'}
        />
      </g>
      <rect
        x={'245.991'}
        y={'208.621'}
        width={'55.1715'}
        height={'47.1534'}
        transform={'rotate(9.8976 245.991 208.621)'}
        fill={'#ABC2FD'}
      />
      <circle cx={'277.66'} cy={'247.66'} r={'5.75661'} transform={'rotate(9.8976 277.66 247.66)'} fill={'#ABC2FD'} />
      <circle
        cx={'269.548'}
        cy={'236.548'}
        r={'14.3023'}
        transform={'rotate(9.8976 269.548 236.548)'}
        fill={'#7390DE'}
      />
      <rect x={'177'} y={'138'} width={'83'} height={'5'} rx={'2.5'} fill={'#E8E7FC'} />
      <rect x={'177'} y={'183'} width={'52'} height={'5'} rx={'2.5'} fill={'#E8E7FC'} />
      <rect x={'177'} y={'153'} width={'83'} height={'5'} rx={'2.5'} fill={'#E8E7FC'} />
      <rect x={'177'} y={'168'} width={'83'} height={'5'} rx={'2.5'} fill={'#E8E7FC'} />
    </g>
    <defs>
      <filter
        id={'filter0_d_1704_98568'}
        x={'45'}
        y={'94.0007'}
        width={'197.656'}
        height={'201.771'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset dy={'4'} />
        <feGaussianBlur stdDeviation={'17'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47625 0 0 0 0 0.591098 0 0 0 0 0.9 0 0 0 0.4 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_1704_98568'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_1704_98568'} result={'shape'} />
      </filter>
      <filter
        id={'filter1_d_1704_98568'}
        x={'128.012'}
        y={'95'}
        width={'181.846'}
        height={'207.242'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset dy={'8'} />
        <feGaussianBlur stdDeviation={'17'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47625 0 0 0 0 0.591098 0 0 0 0 0.9 0 0 0 0.2 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_1704_98568'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_1704_98568'} result={'shape'} />
      </filter>
      <filter
        id={'filter2_dd_1704_98568'}
        x={'220.26'}
        y={'196.948'}
        width={'100.561'}
        height={'99.8486'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset dx={'2'} dy={'7'} />
        <feGaussianBlur stdDeviation={'7'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47625 0 0 0 0 0.591098 0 0 0 0 0.9 0 0 0 0.21 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_1704_98568'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset />
        <feGaussianBlur stdDeviation={'2'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47451 0 0 0 0 0.592157 0 0 0 0 0.898039 0 0 0 0.15 0'} />
        <feBlend mode={'normal'} in2={'effect1_dropShadow_1704_98568'} result={'effect2_dropShadow_1704_98568'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect2_dropShadow_1704_98568'} result={'shape'} />
      </filter>
      <radialGradient
        id={'paint0_radial_1704_98568'}
        cx={'0'}
        cy={'0'}
        r={'1'}
        gradientUnits={'userSpaceOnUse'}
        gradientTransform={'translate(155.146 235.161) rotate(-102.102) scale(86.2467 96.899)'}>
        <stop stopColor={'#6387E6'} />
        <stop offset={'0.967066'} stopColor={'#8CA5E8'} />
      </radialGradient>
      <clipPath id={'clip0_1704_98568'}>
        <rect width={'325'} height={'296'} fill={'white'} />
      </clipPath>
    </defs>
  </SvgIcon>
);
