import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Dot } from '@/components/common/Dot';
import { LetterTableDTO } from '@/types/letter/LetterTableDTO';
import { Theme } from '@mui/material/styles';
import { formatPrisonerShortName } from '@/utils/string-utils';
import { formatSmartDateTimeString } from '@/utils/date-utils';
import React, { FC } from 'react';

interface Props {
  isFetching: boolean;
  isLoading: boolean;
  letters: LetterTableDTO[];
  onLetterClick: (letter: LetterTableDTO) => void;
}

export const InboxList: FC<Props> = (props: Props) => {
  const { isFetching, isLoading, letters } = props;
  const loading: boolean = isFetching || isLoading;
  const handleRowClick = (letter: LetterTableDTO) => (): void => {
    const { onLetterClick } = props;
    if (letter) {
      onLetterClick(letter);
    }
  };
  return (
    <Grid container={true} direction={'column'}>
      {(loading ? Array.from(new Array(5)) : letters).map((letter: LetterTableDTO, index: number) => (
        <Grid key={loading ? index : letter.id} item={true}>
          <Box
            position={'relative'}
            borderBottom={'1px solid'}
            borderColor={(theme: Theme) => theme.colors.borderColor}
            paddingY={(theme: Theme) => theme.spacing(1.5)}
            onClick={handleRowClick(letter)}>
            <Grid container={true} direction={'column'} spacing={1} wrap={'nowrap'}>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'14px'} />
                ) : (
                  <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
                    {letter?.unread && <Dot />}
                    <Typography
                      variant={'body2'}
                      lineHeight={1}
                      whiteSpace={'nowrap'}
                      fontWeight={letter?.unread ? 500 : 400}>
                      {formatSmartDateTimeString(letter.answerDate)}
                    </Typography>
                    <Typography
                      variant={'body2'}
                      lineHeight={1}
                      whiteSpace={'nowrap'}
                      fontWeight={letter?.unread ? 500 : 400}>
                      {'#' + letter.number}
                    </Typography>
                  </Stack>
                )}
              </Grid>
              <Grid item={true}>
                {loading ? (
                  <Skeleton height={'20px'} />
                ) : (
                  <Typography
                    variant={'body2'}
                    color={(theme: Theme) => theme.colors.grayText}
                    whiteSpace={'nowrap'}
                    fontWeight={letter?.unread ? 500 : 400}>
                    {formatPrisonerShortName(letter.prisonerLastName, letter.prisonerFirstName)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {!loading && (
              <ChevronRight
                sx={(theme: Theme) => ({
                  position: 'absolute',
                  right: 0,
                  top: 'calc(50% - 12px)',
                  color: theme.colors.inputBorder,
                })}
              />
            )}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
