import { AttachFileViewDTO } from '@/types/attachment/AttachFileViewDTO';
import { AttachmentImage } from '@/components/view/AttachmentImage';
import { CollapseView } from '@/components/common/CollapseView';
import { EnclosureType } from '@/types/enclosure/EnclosureType';
import { EnclosureViewDTO } from '@/types/enclosure/EnclosureViewDTO';
import { Grid, Skeleton, Typography } from '@mui/material';
import { LetterViewDTO } from '@/types/letter/LetterViewDTO';
import { getEnclosureName, isNotBlank } from '@/utils/string-utils';
import NewsImage from '@assets/images/news.jpg';
import React, { FC, Fragment } from 'react';

interface Props {
  letter?: LetterViewDTO;
  isLoading?: boolean;
  onAttachFileClick: (attachFile: AttachFileViewDTO, name: string) => void;
  onEnclosureClick: (enclosure: EnclosureViewDTO, name: string) => void;
}

export const LetterView: FC<Props> = (props: Props) => {
  const { isLoading, letter, onAttachFileClick, onEnclosureClick } = props;

  const crosswordEnclosures: EnclosureViewDTO[] = (letter?.enclosures || []).filter(
    (enclosure: EnclosureViewDTO): boolean => {
      return EnclosureType.CROSSWORD === enclosure.type;
    }
  );

  const sudokuEnclosures: EnclosureViewDTO[] = (letter?.enclosures || []).filter(
    (enclosure: EnclosureViewDTO): boolean => {
      return EnclosureType.SUDOKU === enclosure.type;
    }
  );

  const newsEnclosures: EnclosureViewDTO[] = (letter?.enclosures || []).filter(
    (enclosure: EnclosureViewDTO): boolean => {
      return EnclosureType.NEWS === enclosure.type;
    }
  );

  return isLoading ? (
    <Fragment>
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'100%'} height={'24px'} />
      <Skeleton width={'60%'} height={'24px'} />
    </Fragment>
  ) : (
    <CollapseView wrapHeight={320}>
      <Grid container={true} direction={'column'} spacing={3}>
        {isNotBlank(letter?.messageText) && (
          <Grid item={true}>
            <Typography
              variant={'body1'}
              sx={{
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
              }}>
              {letter?.messageText}
            </Typography>
          </Grid>
        )}
        {(Boolean(letter?.attachFiles?.length) || Boolean(letter?.enclosures?.length)) && (
          <Grid item={true}>
            <Grid container={true} direction={'row'} spacing={2}>
              {(letter?.attachFiles || []).map((attachFile: AttachFileViewDTO, index: number) => {
                const name = `Фото${(letter?.attachFiles || []).length > 1 ? ` ${index + 1}` : ''}`;
                return (
                  <Grid item={true} key={attachFile.id}>
                    <AttachmentImage
                      fileName={name}
                      content={attachFile.content}
                      onClick={(): void => onAttachFileClick(attachFile, name)}
                    />
                  </Grid>
                );
              })}
              {sudokuEnclosures.map((enclosure: EnclosureViewDTO, index: number) => {
                const name = `${getEnclosureName(enclosure.type)}${sudokuEnclosures.length > 1 ? ` ${index + 1}` : ''}`;
                return (
                  <Grid item={true} key={enclosure.uuid}>
                    <AttachmentImage
                      fileName={name}
                      content={enclosure.content}
                      onClick={(): void => onEnclosureClick(enclosure, name)}
                    />
                  </Grid>
                );
              })}
              {crosswordEnclosures.map((enclosure: EnclosureViewDTO, index: number) => {
                const name = `${getEnclosureName(enclosure.type)}${
                  crosswordEnclosures.length > 1 ? ` ${index + 1}` : ''
                }`;
                return (
                  <Grid item={true} key={enclosure.uuid}>
                    <AttachmentImage
                      fileName={name}
                      content={enclosure.content}
                      onClick={(): void => onEnclosureClick(enclosure, name)}
                    />
                  </Grid>
                );
              })}
              {newsEnclosures.map((enclosure: EnclosureViewDTO, index: number) => {
                const name = `${getEnclosureName(enclosure.type)}${newsEnclosures.length > 1 ? ` ${index + 1}` : ''}`;
                return (
                  <Grid item={true} key={enclosure.uuid}>
                    <AttachmentImage fileName={name} image={NewsImage} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </CollapseView>
  );
};
