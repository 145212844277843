import { ClientInfoDTO } from '@/types/ClientInfoDTO';
import { RegionDTO } from '@/types/RegionDTO';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const createFormApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRegions: build.query<RegionDTO[], null>({
      query: () => ({
        url: '/static/regions',
        method: 'GET',
      }),
      providesTags: ['Regions'],
    }),
    getClientInfo: build.query<ClientInfoDTO, void>({
      query: () => ({
        url: '/static/client-info',
        method: 'GET',
      }),
    }),
    getSenders: build.query<SenderInfoDTO[], number | undefined>({
      query: (userId: number | undefined) => ({
        url: '/letter/senders',
        method: 'GET',
        params: {
          userId: userId,
        },
      }),
      providesTags: ['Senders'],
    }),
    getSenderToReply: build.mutation<SenderInfoDTO, number>({
      query: (letterId: number) => ({
        url: '/letter/reply-sender',
        method: 'POST',
        params: { letterId: letterId },
      }),
    }),
    getDepartment: build.mutation<SenderInfoDTO, number>({
      query: (letterId: number) => ({
        url: '/letter/department',
        method: 'GET',
        params: { letterId: letterId },
      }),
    }),
  }),
});

export const { useGetRegionsQuery, useGetClientInfoQuery, useGetSenderToReplyMutation, useGetSendersQuery } =
  createFormApiSlice;
