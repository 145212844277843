import { CheckCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { TimeIcon } from '@/app/icons/TimeIcon';
import React, { FC, ReactNode } from 'react';

interface Props {
  variant: 'success' | 'waiting' | 'info';
  title: string;
  message?: string;
  content: ReactNode;
}

export const PaymentStatusInfo: FC<Props> = (props: Props) => {
  const { variant, title, message, content } = props;

  const getColor = (variant: 'success' | 'waiting' | 'info', theme: Theme): string => {
    switch (variant) {
      case 'success':
        return theme.colors.green;
      case 'waiting':
        return theme.colors.primary;
      default:
        return theme.colors.primary;
    }
  };

  return (
    <Grid container={true} direction={'row'} wrap={'nowrap'} spacing={1}>
      <Grid item={true}>
        {variant === 'success' && <CheckCircle sx={{ color: (theme: Theme): string => getColor(variant, theme) }} />}
        {variant === 'waiting' && <TimeIcon sx={{ color: (theme: Theme): string => getColor(variant, theme) }} />}
      </Grid>
      <Grid item={true} xs={true}>
        <Grid container={true} direction={'column'} spacing={1}>
          <Grid item={true}>
            <Typography
              sx={{ color: (theme: Theme): string => getColor(variant, theme) }}
              variant={'body1'}
              fontWeight={500}>
              {title}
            </Typography>
          </Grid>
          {message && (
            <Grid item={true}>
              <Typography variant={'body1'}>{message}</Typography>
            </Grid>
          )}
          <Grid item={true}>{content}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
