import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SettingsIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 20 20'} sx={{ width: '20px', height: '20px' }} {...props}>
    <path
      d={
        'M16.2502 10.0003C16.2502 9.80866 16.2419 9.62533 16.2252 9.43366L17.7752 8.25866C18.1085 8.00866 18.2002 7.54199 17.9919 7.17533L16.4335 4.48366C16.2252 4.11699 15.7752 3.96699 15.3919 4.13366L13.6002 4.89199C13.2919 4.67533 12.9669 4.48366 12.6252 4.32533L12.3835 2.40033C12.3335 1.98366 11.9752 1.66699 11.5585 1.66699H8.45021C8.02521 1.66699 7.66688 1.98366 7.61688 2.40033L7.37521 4.32533C7.03354 4.48366 6.70854 4.67533 6.40021 4.89199L4.60854 4.13366C4.22521 3.96699 3.77521 4.11699 3.56688 4.48366L2.00854 7.18366C1.80021 7.55033 1.89188 8.00866 2.22521 8.26699L3.77521 9.44199C3.75854 9.62533 3.75021 9.80866 3.75021 10.0003C3.75021 10.192 3.75854 10.3753 3.77521 10.567L2.22521 11.742C1.89188 11.992 1.80021 12.4587 2.00854 12.8253L3.56688 15.517C3.77521 15.8837 4.22521 16.0337 4.60854 15.867L6.40021 15.1087C6.70854 15.3253 7.03354 15.517 7.37521 15.6753L7.61688 17.6003C7.66688 18.017 8.02521 18.3337 8.44188 18.3337H11.5502C11.9669 18.3337 12.3252 18.017 12.3752 17.6003L12.6169 15.6753C12.9585 15.517 13.2835 15.3253 13.5919 15.1087L15.3835 15.867C15.7669 16.0337 16.2169 15.8837 16.4252 15.517L17.9835 12.8253C18.1919 12.4587 18.1002 12.0003 17.7669 11.742L16.2169 10.567C16.2419 10.3753 16.2502 10.192 16.2502 10.0003ZM10.0335 12.917C8.42521 12.917 7.11688 11.6087 7.11688 10.0003C7.11688 8.39199 8.42521 7.08366 10.0335 7.08366C11.6419 7.08366 12.9502 8.39199 12.9502 10.0003C12.9502 11.6087 11.6419 12.917 10.0335 12.917Z'
      }
    />
  </SvgIcon>
);
