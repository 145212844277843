import { Box, Typography } from '@mui/material';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { TypographyLink } from '@/components/common/TypographyLink';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const ForeignStateAlert: FC = () => {
  const handleRedirect = (): void => {
    window.open(config.links.prisonmail, '_self');
  };

  return (
    <Box onClick={handleRedirect} sx={{ cursor: 'pointer' }}>
      <LetterAlert isWarn={true}>
        <Typography variant={'body1'} mb={3.5}>
          <Typography variant={'inherit'} component={'span'} fontWeight={600}>
            {'RU:'}
          </Typography>
          {' Для оплаты писем картой иностранного банка воспользуйтесь сторонним сервисом, например, '}
          <TypographyLink style={{ textDecoration: 'underline' }}>{'Prisonmail'}</TypographyLink>
        </Typography>
        <Typography variant={'body1'}>
          <Typography variant={'inherit'} component={'span'} fontWeight={600}>
            {'EN:'}
          </Typography>
          {' To pay for service with the card of a foreign bank use a third-party service, such as '}
          <TypographyLink style={{ textDecoration: 'underline' }}>{'Prisonmail'}</TypographyLink>
        </Typography>
      </LetterAlert>
    </Box>
  );
};
