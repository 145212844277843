import { ButtonProps, Typography } from '@mui/material';
import { MainNavigationRedirectLink } from '@/components/main/navigation/MainNavigationRedirectLink';
import { Path } from 'history';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import { useRouteMatch } from 'react-router-dom';
import React, { FC, Fragment } from 'react';

export interface MainNavigationItemProps extends ButtonProps {
  to: Path;
  label: string;
  value?: number;
  total?: number;
  size?: 'small' | 'medium';
  activeOnlyWhenExact?: boolean;
  isRedirect?: boolean;
}

export const MainNavigationItem: FC<MainNavigationItemProps> = (props: MainNavigationItemProps) => {
  const { to, label, activeOnlyWhenExact, isRedirect } = props;
  const match = useRouteMatch({
    path: to.toString(),
    exact: activeOnlyWhenExact,
  });
  const handleRedirect = () => {
    window.location.href = to;
  };
  return (
    <Fragment>
      {isRedirect ? (
        <MainNavigationRedirectLink href={to}>
          <Typography
            variant={'h4'}
            fontSize={'14px'}
            lineHeight={'14px'}
            onClick={handleRedirect}
            color={(theme: Theme) => (match ? theme.colors.grayText : theme.colors.primary)}>
            {label}
          </Typography>
        </MainNavigationRedirectLink>
      ) : (
        <RouteLink to={to}>
          <Typography
            variant={'h4'}
            fontSize={'14px'}
            lineHeight={'14px'}
            color={(theme: Theme) => (match ? theme.colors.grayText : theme.colors.primary)}>
            {label}
          </Typography>
        </RouteLink>
      )}
    </Fragment>
  );
};
