import { BalanceDTO } from '@/types/balance/BalanceDTO';
import { BalanceHistoryDTO } from '@/types/balance/BalanceHistoryDTO';
import { BalanceHistoryRequestDTO } from '@/types/balance/BalanceHistoryRequestDTO';
import { FillBalanceRequestDTO } from '@/types/balance/FillBalanceRequestDTO';
import { Page } from '@/types/dto';
import { TransferBalanceRequestDTO } from '@/types/balance/TransferBalanceRequestDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const balanceApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    transferBalance: build.mutation<BalanceDTO, TransferBalanceRequestDTO>({
      query: (dto: TransferBalanceRequestDTO) => ({
        url: '/balance/transfer',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['Balance', 'BalanceHistory', 'Cards'],
    }),
    fillFromCard: build.mutation<BalanceDTO, FillBalanceRequestDTO>({
      query: (dto: FillBalanceRequestDTO) => ({
        url: '/balance/fill',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['Balance', 'BalanceHistory', 'Cards'],
    }),
    getUserBalance: build.query<BalanceDTO, number | undefined>({
      query: (userId: number | undefined) => ({
        url: '/balance/user',
        method: 'GET',
        params: {
          userId: userId,
        },
      }),
      providesTags: ['Balance'],
    }),
    dismissCardImport: build.mutation<void, void>({
      query: () => ({
        url: '/balance/dismiss',
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getHistory: build.query<Page<BalanceHistoryDTO>, BalanceHistoryRequestDTO & { userId: number | undefined }>({
      query: (dto: BalanceHistoryRequestDTO & { userId: number | undefined }) => ({
        url: '/balance/history',
        method: 'POST',
        body: dto,
      }),
      providesTags: ['BalanceHistory'],
    }),
  }),
});

export const {
  useTransferBalanceMutation,
  useGetUserBalanceQuery,
  useDismissCardImportMutation,
  useFillFromCardMutation,
  useGetHistoryQuery,
} = balanceApiSlice;
