import { AttachIcon } from '@/app/icons/AttachIcon';
import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC, ReactNode } from 'react';

export interface AttachButtonProps extends ButtonProps {
  isLoading?: boolean;
  content?: ReactNode;
}

export const AttachButton: FC<AttachButtonProps> = (props: AttachButtonProps) => {
  const { isLoading, content, ...other } = props;

  return (
    <Button
      variant={'outlined'}
      color={'inherit'}
      sx={{
        display: 'flex',
        color: (theme: Theme) => theme.colors.grayText,
        width: (theme: Theme) => theme.spacing(3),
        height: (theme: Theme) => theme.spacing(2.5),
        minWidth: 'auto',
        border: (theme: Theme): string => '1px solid ' + theme.colors.borderColor,
        borderRadius: (theme: Theme) => theme.spacing(0.5),
        padding: 0,
      }}
      {...other}>
      {isLoading ? (
        <CircularProgress size={12} />
      ) : content ? (
        content
      ) : (
        <AttachIcon
          sx={{
            width: (theme: Theme) => theme.spacing(2),
            height: (theme: Theme) => theme.spacing(2),
            transform: 'rotate(45deg)',
          }}
        />
      )}
    </Button>
  );
};
