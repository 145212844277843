import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SupportIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M12 3C16.74 3 21 6.73 21 12.22C21.59 12.53 22 13.14 22 13.84V16.14C22 16.86 21.59 17.47 21 17.78V19C21 20.1 20.1 21 19 21H12C11.45 21 11 20.55 11 20C11 19.45 11.45 19 12 19H19V11.9C19 7.96 15.74 4.78 11.78 4.9C7.95 5.01 5 8.36 5 12.19V17C5 17.55 4.55 18 4 18C2.9 18 2 17.1 2 16V14C2 13.26 2.4 12.62 3 12.28C3 6.65 7.31 3 12 3ZM10 13C10 13.5523 9.55228 14 9 14C8.44772 14 8 13.5523 8 13C8 12.4477 8.44772 12 9 12C9.55228 12 10 12.4477 10 13ZM15 14C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12C14.4477 12 14 12.4477 14 13C14 13.5523 14.4477 14 15 14ZM12.05 6C15.04 6 17.52 8.18 18 11.03C14.88 11 12.19 9.19 10.88 6.56C10.35 9.24 8.49003 11.44 6.02003 12.45C5.76003 8.51 9.02003 6 12.05 6Z'
      }
    />
  </SvgIcon>
);
