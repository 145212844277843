import { Grid, IconButton, Typography } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, MouseEvent } from 'react';
import theme from '@/app/theme';

interface Props {
  photoCost: number;
  attachedFile?: File;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  fullWidth?: boolean;
}

export const AttachFilePanel: FC<Props> = (props: Props) => {
  const { photoCost, attachedFile, onClear, fullWidth } = props;
  return (
    <Grid
      container={true}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        background: theme.colors.grayBackground,
        borderRadius: '6px',
        minWidth: '310px',
      }}>
      <Grid item={true} xs={fullWidth}>
        <Grid container={true} alignItems={'center'}>
          <Grid item={true}>
            {attachedFile && (
              <img
                src={URL.createObjectURL(attachedFile)}
                style={{
                  display: 'flex',
                  width: 'auto',
                  height: '48px',
                  border: '1px solid ' + theme.colors.borderColor,
                  borderRadius: '6px 0px 0px 6px',
                }}
                alt={'attached-file'}
              />
            )}
          </Grid>
          <Grid item={true} ml={1}>
            <Typography variant={'body1'}>{`Фото +${formatCurrency(photoCost, 0)}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {onClear && (
        <Grid item={true} mr={1.5}>
          <IconButton disableRipple={true} onClick={onClear}>
            <CloseIcon color={'primary'} fontSize={'small'} sx={{ display: 'block' }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default AttachFilePanel;
