import { Grid, Typography } from '@mui/material';
import { ProfileIcon } from '@/app/icons/profile/ProfileIcon';
import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import React, { FC, Fragment } from 'react';

const UnauthorizedHeaderProfile: FC = () => {
  return (
    <Fragment>
      <Grid container={true} direction={'row'} alignItems={'center'} spacing={1.25}>
        <Grid item={true}>
          <Typography
            fontSize={'10px'}
            lineHeight={'16px'}
            letterSpacing={'0.03em'}
            textAlign={'right'}
            color={(theme: Theme) => theme.colors.grayText}
            pb={(theme: Theme) => theme.spacing(0.25)}
            sx={{ textTransform: 'uppercase' }}>
            {'Личный кабинет'}
          </Typography>
          <RouteLink to={ROUTE_AUTHENTICATION}>
            <Typography
              fontSize={'14px'}
              lineHeight={'14px'}
              fontWeight={500}
              paddingLeft={7}
              textAlign={'right'}
              color={(theme: Theme) => theme.colors.primary}>
              {'Войти'}
            </Typography>
          </RouteLink>
        </Grid>
        <Grid item={true}>
          <Grid
            container={true}
            alignItems={'center'}
            columnSpacing={0.75}
            sx={{
              color: (theme: Theme) => theme.colors.primary,
            }}>
            <Grid item={true} display={'flex'}>
              <ProfileIcon
                sx={{
                  width: (theme: Theme) => theme.spacing(4),
                  height: (theme: Theme) => theme.spacing(4),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UnauthorizedHeaderProfile;
