import { Add, Remove } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { NumberSelectorButton } from '@/components/common/button/NumberSelectorButton';
import { OutlinedField, OutlinedFieldProps } from '@/components/common/field/OutlinedField';
import { onlyNumberFormat } from '@/utils/string-utils';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

export interface NumberSelectorFieldProps extends OutlinedFieldProps {
  minValue: number;
  maxValue: number;
  step: number;
  onValueChange: (value: number) => void;
  timeout?: number;
}

export const NumberSelectorField: FC<NumberSelectorFieldProps> = (props: NumberSelectorFieldProps) => {
  const { value, minValue, maxValue, step, onValueChange, timeout, ...other } = props;

  const [inputValue, setInputValue] = useState<string | undefined>(String(value));

  const handleChange = useCallback(
    (newValue: string | undefined): void => {
      if (newValue !== inputValue) {
        let parsedValue: number = parseInt(onlyNumberFormat(newValue || String(minValue)));
        parsedValue = Math.round(parsedValue / step) * step;
        if (parsedValue < minValue) {
          parsedValue = minValue;
        } else if (parsedValue > maxValue) {
          parsedValue = maxValue;
        }
        setInputValue(String(parsedValue));
        onValueChange(parsedValue);
      }
    },
    [inputValue, maxValue, minValue, onValueChange, step]
  );

  const handleIncrease = (): void => {
    const currentValue: number = parseInt(String(inputValue || minValue));
    handleChange(String(currentValue + step));
  };

  const handleDecrease = (): void => {
    const currentValue: number = parseInt(String(inputValue || minValue));
    handleChange(String(currentValue - step));
  };

  useEffect(() => {
    if (timeout) {
      const timer: NodeJS.Timeout = setTimeout((): void => {
        handleChange(inputValue);
      }, timeout);
      return (): void => {
        clearTimeout(timer);
      };
    } else {
      handleChange(inputValue);
    }
  }, [handleChange, inputValue, timeout]);

  return (
    <OutlinedField
      value={inputValue}
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      startAdornment={
        <InputAdornment position={'start'}>
          <NumberSelectorButton
            disabled={parseInt(String(inputValue || minValue)) <= minValue}
            onClick={handleDecrease}>
            <Remove />
          </NumberSelectorButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position={'end'}>
          <NumberSelectorButton
            disabled={parseInt(String(inputValue || minValue)) >= maxValue}
            onClick={handleIncrease}>
            <Add />
          </NumberSelectorButton>
        </InputAdornment>
      }
      labelStyle={{ overflow: 'visible' }}
      inputProps={{
        style: {
          ...(!props.multiline && {
            textOverflow: 'ellipsis',
          }),
          width: '24px',
        },
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        handleChange(e.target.value);
      }}
      {...other}
    />
  );
};
