import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Decor from '@assets/icons/decor.svg';
import React, { FC } from 'react';

const useStyles = makeStyles(() => ({
  line: {
    position: 'static',
    backgroundImage: `url(${Decor})`,
    minHeight: '6px',
  },
}));

export const TopLine: FC = () => {
  const classes = useStyles();
  return <Grid container={true} className={classes.line} />;
};
