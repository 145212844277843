import { CardCodeForm } from '@/components/create/card/CardCodeForm';
import { CardService } from '@/types/card/CardService';
import { CreateCardAdvantages } from '@/components/create/card/CreateCardAdvantages';
import { CreateCardForm } from '@/components/create/card/CreateCardForm';
import { CreateCardTabs } from '@/components/create/card/menu/CreateCardTabs';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { Grid, Typography } from '@mui/material';
import { RootState } from '@/app/store';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { getSbpQR } from '@/services/sbpSlice';
import { useAppSelector } from '@/app/hooks';
import React, { FC, SyntheticEvent, useState } from 'react';

export const CreateCardsBlock: FC = () => {
  //Если ожидается полата по QR, то открываем вкладку с пополнением
  const refillCardSbpQR: CreatedSbpQrDTO | undefined = useAppSelector((state: RootState) =>
    getSbpQR(state, SbpMethod.CARD, CardService.REFILL)
  );
  const isRefillCardSbpActive = typeof refillCardSbpQR !== 'undefined';

  const [tabsValue, setTabsValue] = useState(isRefillCardSbpActive ? 1 : 0);

  const handleChangeTab = (event: SyntheticEvent, newValue: number): void => {
    setTabsValue(newValue);
  };

  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} paddingBottom={6}>
        <Grid container={true}>
          <Grid item={true}>
            <Typography variant={'h1'} whiteSpace={'pre-line'}>
              {'Закажите Ф-карту, чтобы оплачивать\nписьма и услуги сервиса Ф-письмо'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} paddingBottom={8.75}>
        <Grid container={true} columnSpacing={6.5}>
          <Grid item={true} xs={true}>
            <CreateCardAdvantages
              title={'Удобная оплата\nписем и услуг'}
              description={'Можно пополнить карту на любую сумму, а затем с помощью неё оплачивать письма'}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <CreateCardAdvantages
              title={'Совместное\nпользование картой'}
              description={'Создайте карту, которой будут оплачивать письма вы и ваши близкие'}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <CreateCardAdvantages
              title={'Удобные способы\nпополнения'}
              description={'СБП, банковский перевод, платежные карты, QIWI'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} paddingBottom={6}>
        <CreateCardTabs value={tabsValue} handleChange={handleChangeTab} />
      </Grid>
      <Grid item={true} paddingBottom={2}>
        {tabsValue === 0 ? <CreateCardForm /> : <CardCodeForm />}
      </Grid>
    </Grid>
  );
};
