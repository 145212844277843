import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const CardIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 32 24'} sx={{ width: '32px', height: '24px' }} {...props}>
    <mask
      id={'mask0_1017_53397'}
      style={{ maskType: 'alpha' }}
      maskUnits={'userSpaceOnUse'}
      x={'0'}
      y={'0'}
      width={'32'}
      height={'24'}>
      <rect width={'32'} height={'24'} rx={'4'} fill={'#6387E6'} />
    </mask>
    <g mask={'url(#mask0_1017_53397)'}>
      <rect width={'32'} height={'24'} rx={'4'} fill={'#6387E6'} />
      <path d={'M-5.6001 26.8L21.5999 -2H35.9999V26.8H-5.6001Z'} fill={'#C9D8FF'} />
    </g>
    <path
      d={
        'M20.5169 16.7998C20.4316 16.7998 20.359 16.77 20.2993 16.7102C20.2396 16.6505 20.2097 16.578 20.2097 16.4926V15.0974H19.2369C19.1516 15.0974 19.079 15.0676 19.0193 15.0078C18.9596 14.9481 18.9297 14.8756 18.9297 14.7902V14.3934C18.9297 14.2996 18.9596 14.227 19.0193 14.1758C19.079 14.1161 19.1516 14.0862 19.2369 14.0862H20.2097V13.395H19.2369C19.1516 13.395 19.079 13.3652 19.0193 13.3054C18.9596 13.2457 18.9297 13.1732 18.9297 13.0878V12.371C18.9297 12.2772 18.9596 12.2046 19.0193 12.1534C19.079 12.0937 19.1516 12.0638 19.2369 12.0638H20.2097V8.15984C20.2097 8.06598 20.2353 7.98918 20.2865 7.92944C20.3462 7.86971 20.423 7.83984 20.5169 7.83984H23.9473C24.9798 7.83984 25.7905 8.08304 26.3793 8.56944C26.9681 9.05584 27.2625 9.76411 27.2625 10.6942C27.2625 11.5817 26.9681 12.2558 26.3793 12.7166C25.7905 13.1689 24.9798 13.395 23.9473 13.395H22.0657V14.0862H24.1393C24.2332 14.0862 24.3057 14.1161 24.3569 14.1758C24.4166 14.227 24.4465 14.2996 24.4465 14.3934V14.7902C24.4465 14.8756 24.4166 14.9481 24.3569 15.0078C24.3057 15.0676 24.2332 15.0974 24.1393 15.0974H22.0657V16.4926C22.0657 16.578 22.0358 16.6505 21.9761 16.7102C21.9164 16.77 21.8396 16.7998 21.7457 16.7998H20.5169ZM23.8833 12.0638C24.3782 12.0638 24.7537 11.9486 25.0097 11.7182C25.2742 11.4793 25.4065 11.1337 25.4065 10.6814C25.4065 10.2462 25.2785 9.90491 25.0225 9.65744C24.775 9.40144 24.3953 9.27344 23.8833 9.27344H22.0401V12.0638H23.8833Z'
      }
      fill={'#6387E6'}
    />
  </SvgIcon>
);
