import { CardService } from '@/types/card/CardService';
import { PaymentStatusInfo } from '@/components/create/unauthorized/PaymentStatusInfo';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  cardService: CardService;
  email: string;
  message?: string;
}

export const CardPaymentSuccessInfo: FC<Props> = (props: Props) => {
  const { cardService, email } = props;

  return (
    <PaymentStatusInfo
      variant={'success'}
      title={cardService === CardService.CREATE ? 'Ф-карта оформлена' : 'Баланс пополнен'}
      content={
        <Typography variant={'body1'} maxWidth={'285px'}>
          {cardService === CardService.CREATE
            ? 'Мы отправили данные карты, код доступа и уведомление об оплате на почту '
            : 'Уведомление об оплате вы получите на '}
          <Typography component={'span'} variant={'inherit'} fontWeight={500}>
            {email}
          </Typography>
        </Typography>
      }
    />
  );
};
