import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const SendLetterIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 325 296'} sx={{ width: '325px', height: '296px' }} {...props}>
    <g clipPath={'url(#clip0_1704_98574)'}>
      <g filter={'url(#filter0_d_1704_98574)'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M157.908 249.516C157.356 249.643 156.792 249.314 156.644 248.766C152.148 232.191 136.998 220 119 220C100.258 220 84.6039 233.22 80.8541 250.844C80.6825 251.651 79.6479 251.985 78.9882 251.49C76.9011 249.926 74.3087 249 71.5 249C65.4093 249 60.3361 253.356 59.2258 259.123C59.1301 259.62 58.7079 260 58.2015 260H51C48.2386 260 46 262.239 46 265C46 267.761 48.2386 270 51 270H178C180.761 270 183 267.761 183 265C183 263.943 182.672 262.962 182.112 262.154C181.99 261.978 181.915 261.774 181.89 261.561C181.821 260.954 181.615 260.339 181.253 259.739C178.068 254.465 170.87 249 162.5 249C160.921 249 159.385 249.178 157.908 249.516Z'
          }
          fill={'#FBFBFF'}
        />
      </g>
      <g filter={'url(#filter1_d_1704_98574)'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M334.902 116.774C330.758 99.6876 315.36 87 297 87C281.833 87 268.688 95.658 262.238 108.301C260.15 107.461 257.877 107 255.5 107C245.283 107 237 115.517 237 126.024C237 126.363 237.009 126.688 237.026 127H229C226.239 127 224 129.239 224 132C224 134.761 226.239 137 229 137H356C358.761 137 361 134.761 361 132C361 130.471 360.314 129.103 359.233 128.186C359.181 128.052 359.123 127.919 359.06 127.785C355.785 120.821 348.705 116 340.5 116C338.559 116 336.682 116.27 334.902 116.774Z'
          }
          fill={'#FBFBFF'}
        />
      </g>
      <g filter={'url(#filter2_f_1704_98574)'}>
        <path d={'M193.5 265L112.5 132.5L165.5 148.5L295 220L193.5 265Z'} fill={'#A1B4F6'} fillOpacity={'0.6'} />
      </g>
      <mask id={'mask0_1704_98574'} maskUnits={'userSpaceOnUse'} x={'104'} y={'130'} width={'141'} height={'125'}>
        <path
          d={
            'M244.928 211.158L238.641 246.488C237.956 250.333 237.614 252.256 236.625 253.169C235.764 253.964 234.597 254.341 233.433 254.201C232.096 254.039 230.694 252.681 227.888 249.964L227.888 249.964L104 130L105 130.5L244.928 211.158Z'
          }
          fill={'url(#paint0_linear_1704_98574)'}
        />
      </mask>
      <g mask={'url(#mask0_1704_98574)'}>
        <path
          d={
            'M244.928 211.158L238.641 246.488C237.956 250.333 237.614 252.256 236.625 253.169C235.764 253.964 234.597 254.341 233.433 254.201C232.096 254.039 230.694 252.681 227.888 249.964L227.888 249.964L104 130L105 130.5L244.928 211.158Z'
          }
          fill={'url(#paint1_linear_1704_98574)'}
        />
        <path d={'M234.5 257.5L219.741 226.752L206.147 229.95L234.5 257.5Z'} fill={'#7390DE'} />
      </g>
      <path
        d={
          'M154.603 253.933L104.937 139.075C103.204 135.065 102.337 133.06 102.717 131.696C103.048 130.511 103.906 129.544 105.043 129.074C106.351 128.534 108.445 129.156 112.633 130.399L286.236 181.942C291.273 183.437 293.792 184.185 294.594 185.476C295.289 186.594 295.389 187.982 294.862 189.189C294.254 190.581 291.869 191.683 287.099 193.887L244.528 213.558L121.172 140.21C116.604 137.494 114.32 136.136 113.871 136.304C113.487 136.448 113.23 136.811 113.222 137.221C113.212 137.701 115.251 139.405 119.328 142.813L119.328 142.813L219.741 226.751L163.512 257.028C161.381 258.176 160.316 258.749 159.301 258.746C158.409 258.743 157.544 258.443 156.842 257.892C156.044 257.265 155.564 256.155 154.603 253.933Z'
        }
        fill={'url(#paint2_linear_1704_98574)'}
      />
    </g>
    <defs>
      <filter
        id={'filter0_d_1704_98574'}
        x={'12'}
        y={'186'}
        width={'205'}
        height={'118'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset />
        <feGaussianBlur stdDeviation={'17'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47625 0 0 0 0 0.591098 0 0 0 0 0.9 0 0 0 0.2 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_1704_98574'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_1704_98574'} result={'shape'} />
      </filter>
      <filter
        id={'filter1_d_1704_98574'}
        x={'190'}
        y={'53'}
        width={'205'}
        height={'118'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feColorMatrix
          in={'SourceAlpha'}
          type={'matrix'}
          values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
          result={'hardAlpha'}
        />
        <feOffset />
        <feGaussianBlur stdDeviation={'17'} />
        <feComposite in2={'hardAlpha'} operator={'out'} />
        <feColorMatrix type={'matrix'} values={'0 0 0 0 0.47625 0 0 0 0 0.591098 0 0 0 0 0.9 0 0 0 0.2 0'} />
        <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_1704_98574'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_1704_98574'} result={'shape'} />
      </filter>
      <filter
        id={'filter2_f_1704_98574'}
        x={'68.5'}
        y={'88.5'}
        width={'270.5'}
        height={'220.5'}
        filterUnits={'userSpaceOnUse'}
        colorInterpolationFilters={'sRGB'}>
        <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
        <feBlend mode={'normal'} in={'SourceGraphic'} in2={'BackgroundImageFix'} result={'shape'} />
        <feGaussianBlur stdDeviation={'22'} result={'effect1_foregroundBlur_1704_98574'} />
      </filter>
      <linearGradient
        id={'paint0_linear_1704_98574'}
        x1={'138.5'}
        y1={'96.5'}
        x2={'228'}
        y2={'228.5'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#6387E6'} />
        <stop offset={'1'} stopColor={'#809EEB'} />
      </linearGradient>
      <linearGradient
        id={'paint1_linear_1704_98574'}
        x1={'138.5'}
        y1={'96.5'}
        x2={'228'}
        y2={'228.5'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#6387E6'} />
        <stop offset={'1'} stopColor={'#809EEB'} />
      </linearGradient>
      <linearGradient
        id={'paint2_linear_1704_98574'}
        x1={'133.5'}
        y1={'158.5'}
        x2={'201'}
        y2={'235.5'}
        gradientUnits={'userSpaceOnUse'}>
        <stop stopColor={'#A4BAF4'} />
        <stop offset={'0.942708'} stopColor={'#C5D5FF'} />
      </linearGradient>
      <clipPath id={'clip0_1704_98574'}>
        <path
          d={
            'M0 12C0 5.37258 5.37258 0 12 0H313C319.627 0 325 5.37258 325 12V284C325 290.627 319.627 296 313 296H12C5.37257 296 0 290.627 0 284V12Z'
          }
          fill={'white'}
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
