import { AttachmentCheckBox } from '@/components/create/letter/AttachmentCheckBox';
import { CreateFormAttachFile } from '@/components/create/letter/CreateFormAttachFile';
import { Grid, Theme, Typography } from '@mui/material';
import { SudokuCountSelectorField } from '@/components/common/field/SudokuCountSelectorField';
import { formatCurrency } from '@/utils/string-utils';
import React, { ChangeEvent, FC } from 'react';

interface Props {
  attachmentCost: number;
  allowAttachments?: boolean;
  allowSudoku?: boolean;
  allowCrossword?: boolean;
  allowNews?: boolean;
  attachFile: File | undefined;
  onAttachFile: (file: File | undefined) => void;
  sudokuAttached?: boolean;
  onSudokuAttached?: (e: ChangeEvent<HTMLInputElement>) => void;
  crosswordAttached?: boolean;
  onCrosswordAttached?: (e: ChangeEvent<HTMLInputElement>) => void;
  newsAttached?: boolean;
  onNewsAttached?: (e: ChangeEvent<HTMLInputElement>) => void;
  sudokuCount: number;
  onSudokuCountChange: (sudokuCount: number) => void;
  isMobile?: boolean;
}

export const CreateFormAttachments: FC<Props> = (props: Props) => {
  const {
    attachmentCost,
    allowAttachments,
    allowSudoku,
    allowNews,
    attachFile,
    onAttachFile,
    sudokuAttached,
    onSudokuAttached,
    sudokuCount,
    onSudokuCountChange,
    newsAttached,
    onNewsAttached,
    isMobile,
  } = props;

  return (
    <Grid container={true} direction={'column'} alignItems={'start'} spacing={isMobile ? 1.5 : 2}>
      <Grid item={true}>
        <CreateFormAttachFile
          attachmentCost={attachmentCost}
          uploadEnabled={allowAttachments}
          attachFile={attachFile}
          onAttachFile={onAttachFile}
        />
      </Grid>
      <Grid item={true}>
        <Grid container={true} direction={'row'} wrap={'wrap'} columnSpacing={6} rowSpacing={1.5}>
          <Grid item={true}>
            <Grid container={true} direction={'column'} spacing={2}>
              <Grid item={true}>
                <AttachmentCheckBox
                  label={`Судоку + ${formatCurrency(attachmentCost, 0)}`}
                  isUpdatable={false}
                  captionText={'Каждый раз уникальное'}
                  checked={sudokuAttached}
                  disabled={(!allowAttachments || !allowSudoku) && !sudokuAttached}
                  onChange={onSudokuAttached}
                />
              </Grid>
              {sudokuAttached && (
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={2} alignItems={'center'}>
                    <Grid item={true}>
                      <SudokuCountSelectorField
                        value={sudokuCount}
                        minValue={0}
                        maxValue={5}
                        step={1}
                        onValueChange={onSudokuCountChange}
                      />
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'h4'} minWidth={(theme: Theme) => theme.spacing(8)}>{`+ ${formatCurrency(
                        sudokuCount * attachmentCost,
                        0
                      )}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item={true}>
            <AttachmentCheckBox
              label={`Главные новости + ${formatCurrency(attachmentCost, 0)}`}
              isUpdatable={false}
              captionText={'Свежие новости на момент отправки письма'}
              checked={newsAttached}
              disabled={(!allowAttachments || !allowNews) && !newsAttached}
              onChange={onNewsAttached}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
