import { Box, Skeleton } from '@mui/material';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { LetterHistoryContent } from '@/components/letter/history/LetterHistoryContent';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props {
  events?: EventLogDTO[];
  isLoading: boolean;
}

export const LetterHistory: FC<Props> = (props: Props) => {
  const { events, isLoading } = props;
  return isLoading ? (
    <Skeleton variant={'rectangular'} width={'100%'} height={'200px'} sx={{ borderRadius: '12px' }} />
  ) : (
    <Box
      sx={(theme: Theme) => ({
        borderRadius: '12px',
        background: theme.colors.tableHover,
        padding: theme.spacing(1, 0, 2),
      })}>
      <LetterHistoryContent events={events} />
    </Box>
  );
};
