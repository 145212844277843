import { Divider, Grid, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  title: string;
  description?: string;
}

export const CreateCardAdvantages: FC<Props> = (props: Props) => {
  const { title, description } = props;

  return (
    <Grid container={true} direction={'column'}>
      <Grid item={true} paddingBottom={2}>
        <Divider
          sx={{
            width: (theme: Theme) => theme.spacing(5),
            borderBottomWidth: (theme: Theme) => theme.spacing(0.25),
            borderColor: (theme: Theme) => theme.colors.primary,
          }}
        />
      </Grid>
      <Grid item={true} paddingBottom={1}>
        <Typography whiteSpace={'pre-line'} variant={'h4'}>
          {title}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
          {description ? description : 'Банковский перевод, платежные карты, СБП, QIWI;'}
        </Typography>
      </Grid>
    </Grid>
  );
};
