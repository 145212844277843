import { FormControl, FormHelperText, FormLabel, InputLabel, OutlinedInput, OutlinedInputProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import InputMask from 'react-input-mask';
import React, { FC } from 'react';

export interface OutlinedFieldProps extends OutlinedInputProps {
  size?: 'small' | 'medium';
  helperText?: string;
  withLabel?: boolean;
  isNotAbsoluteHelperText?: boolean;
  mask: string;
  maskPlaceholder: string;
  alwaysShowMask?: boolean;
}

export const MaskedOutlinedField: FC<OutlinedFieldProps> = (props: OutlinedFieldProps) => {
  const {
    size,
    label,
    error,
    helperText,
    withLabel,
    isNotAbsoluteHelperText,
    mask,
    maskPlaceholder,
    alwaysShowMask,
    ...other
  } = props;
  return (
    <FormControl
      variant={'outlined'}
      size={size}
      fullWidth={true}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(withLabel ? 3 : 0),
      })}>
      {label &&
        (props.placeholder ? (
          <FormLabel error={error} id={props.name + '-input-label'}>
            {label}
          </FormLabel>
        ) : (
          <InputLabel error={error} id={props.name + '-input-label'}>
            {label}
          </InputLabel>
        ))}
      <OutlinedInput
        sx={{ paddingRight: (theme: Theme) => theme.spacing(1) }}
        inputComponent={InputMask as any}
        inputProps={{
          mask: mask,
          maskPlaceholder: maskPlaceholder,
          alwaysShowMask: alwaysShowMask,
          style: {
            ...(!props.multiline && {
              textOverflow: 'ellipsis',
            }),
          },
        }}
        error={error}
        {...other}
      />
      {helperText && (
        <FormHelperText
          error={error}
          id={props.name + '-input-helperText'}
          sx={
            isNotAbsoluteHelperText
              ? { position: 'inherit' }
              : {
                  position: 'absolute',
                  transform: size == 'small' ? 'translate(0, 40px)' : 'translate(0, 48px)',
                }
          }>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
