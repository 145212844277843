import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const TimeIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      fillRule={'evenodd'}
      clipRule={'evenodd'}
      d={
        'M12 22.2547C17.6635 22.2547 22.2546 17.6636 22.2546 12.0002C22.2546 6.33672 17.6635 1.74561 12 1.74561C6.3366 1.74561 1.74548 6.33672 1.74548 12.0002C1.74548 17.6636 6.3366 22.2547 12 22.2547ZM11.1275 4.8505C11.1275 4.3407 11.5408 3.92742 12.0506 3.92742C12.5604 3.92742 12.9736 4.3407 12.9736 4.8505V11.4784L16.847 12.253C17.3469 12.353 17.6711 12.8393 17.5711 13.3392C17.4711 13.8391 16.9848 14.1633 16.4849 14.0633L11.8863 13.1436C11.805 13.129 11.7273 13.1037 11.6548 13.0693C11.5297 13.0101 11.4217 12.9242 11.3367 12.8204C11.2515 12.7166 11.1885 12.5939 11.155 12.4595C11.1354 12.3817 11.1259 12.3006 11.1275 12.218V4.8505Z'
      }
    />
  </SvgIcon>
);
