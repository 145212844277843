import { BinocularsIcon } from '@/app/icons/main/BinocularsIcon';
import { Grid } from '@mui/material';
import { HeaderButton } from '@/components/main/header/HeaderButton';
import { HeaderMobileButton } from '@/components/main/header/HeaderMobileButton';
import { HomeIcon } from '@/app/icons/main/HomeIcon';
import { MoneyIcon } from '@/app/icons/MoneyIcon';
import {
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_INBOX,
  ROUTE_MAIN_CREATE_ANSWER,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_REDIRECT_LETTER,
} from '@/app/routes';
import { RedirectIcon } from '@/app/icons/main/RedirectIcon';
import { SendAnswerIcon } from '@/app/icons/main/SendAnswerIcon';
import { SendLetterIcon } from '@/app/icons/main/SendLetterIcon';
import { SmallHeaderButton } from '@/components/main/header/SmallHeaderButton';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const ButtonsBlock: FC = () => {
  return (
    <Grid container={true} direction={'row'} spacing={2}>
      <Grid item={true} md={4} sm={6} xs={12}>
        <HeaderMobileButton topLabel={'Написать'} bottomLabel={'письмо'} route={ROUTE_MAIN_CREATE_LETTER}>
          <SendAnswerIcon sx={{ width: '100%', maxWidth: '290px', height: 'auto' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} md={4} sm={6} xs={12}>
        <HeaderMobileButton topLabel={'Заказать'} bottomLabel={'ответ'} route={ROUTE_MAIN_CREATE_ANSWER}>
          <SendLetterIcon sx={{ width: '100%', maxWidth: '290px', height: 'auto' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} md={4} xs={12}>
        <Grid container={true} direction={'column'} spacing={2} height={'calc(100% + 16px)'}>
          <Grid item={true} xs={true}>
            <HeaderButton label={'Войти / Создать'} active={true} smallLabel={'личный кабинет'} route={ROUTE_INBOX}>
              <HomeIcon sx={{ width: '60px', height: '60px' }} />
            </HeaderButton>
          </Grid>
          <Grid item={true} xs={true}>
            <Grid container={true} direction={'row'} wrap={'nowrap'} spacing={2} height={'calc(100% + 16px)'}>
              <Grid item={true} xs={true}>
                <SmallHeaderButton
                  label={'Узнать статус'}
                  labelVariant={'h5'}
                  smallLabel={'письма'}
                  smallLabelVariant={'h5'}
                  route={ROUTE_CHECK_LETTER_STATUS}>
                  <BinocularsIcon sx={{ width: '32px', height: '32px' }} />
                </SmallHeaderButton>
              </Grid>
              <Grid item={true} xs={true}>
                <SmallHeaderButton
                  label={'Перевести письмо'}
                  labelVariant={'h5'}
                  smallLabel={'в другое учреждение'}
                  smallLabelVariant={'h5'}
                  route={ROUTE_REDIRECT_LETTER}>
                  <RedirectIcon sx={{ width: '32px', height: '32px' }} />
                </SmallHeaderButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={true}>
            <SmallHeaderButton
              label={'Перевести деньги'}
              labelVariant={'h2'}
              smallLabel={'заключённому'}
              smallLabelVariant={'h2'}
              href={config.links.money}
              orange={true}>
              <MoneyIcon sx={{ width: '32px', height: '32px' }} />
            </SmallHeaderButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
