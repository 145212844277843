import { Grid, Typography } from '@mui/material';
import { MaskedOutlinedField } from '@/components/common/field/MaskedOutlinedField';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentRadio, PaymentRadioProps } from '@/components/common/payment/PaymentRadio';
import { Theme } from '@mui/material/styles';
import { isNotBlank, onlyNumberFormat } from '@/utils/string-utils';
import React, { ChangeEvent, FC } from 'react';

interface Props extends PaymentRadioProps {
  currentValue?: string;
  cardNumber: string;
  onCardNumberChange: (cardNumber: string) => void;
  cardCode: string;
  onCardCodeChange: (cardCode: string) => void;
  errorMessage?: string;
  isLetterLoading?: boolean;
}

export const CardNumberPaymentRadio: FC<Props> = (props: Props) => {
  const {
    currentValue,
    cardNumber,
    onCardNumberChange,
    cardCode,
    onCardCodeChange,
    errorMessage,
    isLetterLoading,
    ...other
  } = props;

  const handleChangeNumber = (value: string): void => {
    if (isNotBlank(value)) {
      onCardNumberChange(onlyNumberFormat(value));
    } else {
      onCardNumberChange('');
    }
  };

  const handleChangeCode = (value: string): void => {
    if (isNotBlank(value)) {
      onCardCodeChange(onlyNumberFormat(value));
    } else {
      onCardCodeChange('');
    }
  };

  const valueEquals = (method: PaymentMethod): boolean => {
    const value: { method: PaymentMethod } = currentValue ? JSON.parse(currentValue) : { method: PaymentMethod.SBP };
    return value?.method === method;
  };

  return (
    <PaymentRadio
      {...other}
      value={JSON.stringify({ method: PaymentMethod.CARD })}
      label={'Оплата Ф-картой'}
      caption={
        valueEquals(PaymentMethod.CARD) && (
          <Grid container={true} direction={'column'} spacing={0.5}>
            <Grid item={true}>
              <Grid container={true} direction={'row'} spacing={2}>
                <Grid item={true} xs={8}>
                  <MaskedOutlinedField
                    type={'string'}
                    label={'Номер Ф-карты'}
                    value={cardNumber}
                    disabled={isLetterLoading}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeNumber(e.target.value)}
                    error={(cardNumber !== undefined && cardNumber.length > 9) || !!errorMessage}
                    helperText={cardNumber !== undefined && cardNumber.length > 9 ? 'Некорректный номер Ф-карты' : ''}
                    mask={'99 999 99'}
                    maskPlaceholder={'X'}
                    alwaysShowMask={true}
                    withLabel={true}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <MaskedOutlinedField
                    type={'string'}
                    label={'Пин-код'}
                    value={cardCode}
                    disabled={isLetterLoading}
                    onChange={(e: ChangeEvent<HTMLInputElement>): void => handleChangeCode(e.target.value)}
                    error={(cardCode !== '' && cardCode.length > 4) || !!errorMessage}
                    helperText={cardCode !== '' && cardCode.length > 4 ? 'Некорректный пин-код' : ''}
                    mask={'9999'}
                    maskPlaceholder={'*'}
                    alwaysShowMask={true}
                    withLabel={true}
                  />
                </Grid>
              </Grid>
            </Grid>
            {errorMessage && (
              <Grid item={true}>
                <Typography
                  color={(theme: Theme) => theme.palette.error.main}
                  fontSize={'12px'}
                  lineHeight={'16px'}
                  whiteSpace={'pre-line'}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
          </Grid>
        )
      }
    />
  );
};
