import { AppBar, AppBarProps } from '@mui/material';
import React, { FC } from 'react';

type Props = AppBarProps;

export const MobileBar: FC<Props> = (props: Props) => {
  const { children, ...other } = props;
  return (
    <AppBar position={'fixed'} color={'default'} sx={{ top: 'auto', bottom: 0 }} component={'div'} {...other}>
      {children}
    </AppBar>
  );
};
