import { BinocularsIcon } from '@/app/icons/main/BinocularsIcon';
import { Grid } from '@mui/material';
import { HeaderMobileButton } from '@/components/main/header/HeaderMobileButton';
import { HomeIcon } from '@/app/icons/main/HomeIcon';
import { MoneyIcon } from '@/app/icons/MoneyIcon';
import {
  ROUTE_CHECK_LETTER_STATUS,
  ROUTE_INBOX,
  ROUTE_MAIN_CREATE_ANSWER,
  ROUTE_MAIN_CREATE_LETTER,
  ROUTE_REDIRECT_LETTER,
} from '@/app/routes';
import { RedirectIcon } from '@/app/icons/main/RedirectIcon';
import { SendAnswerMobileIcon } from '@/app/icons/main/SendAnswerMobileIcon';
import { SendLetterMobileIcon } from '@/app/icons/main/SendLetterMobileIcon';
import { config } from '@/app/config';
import React, { FC } from 'react';

export const MobileButtonsBlock: FC = () => {
  return (
    <Grid container={true} spacing={1.5}>
      <Grid item={true} xs={6}>
        <HeaderMobileButton topLabel={'Написать'} bottomLabel={'письмо'} route={ROUTE_MAIN_CREATE_LETTER}>
          <SendLetterMobileIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} xs={6}>
        <HeaderMobileButton topLabel={'Заказать'} bottomLabel={'ответ'} route={ROUTE_MAIN_CREATE_ANSWER}>
          <SendAnswerMobileIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} xs={6}>
        <HeaderMobileButton
          active={true}
          topLabel={'Войти / Создать'}
          bottomLabel={'личный кабинет'}
          route={ROUTE_INBOX}>
          <HomeIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} xs={6}>
        <HeaderMobileButton topLabel={'Узнать статус'} bottomLabel={'письма'} route={ROUTE_CHECK_LETTER_STATUS}>
          <BinocularsIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} xs={6}>
        <HeaderMobileButton
          topLabel={'Перевести письмо'}
          bottomLabel={'в другое учреждение'}
          route={ROUTE_REDIRECT_LETTER}>
          <RedirectIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
      <Grid item={true} xs={6}>
        <HeaderMobileButton
          topLabel={'Перевести деньги'}
          bottomLabel={'заключённому'}
          href={config.links.money}
          orange={true}>
          <MoneyIcon sx={{ width: '40px', height: '40px' }} />
        </HeaderMobileButton>
      </Grid>
    </Grid>
  );
};
