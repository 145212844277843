import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '@/app/icons/CloseIcon';
import { Theme } from '@mui/material/styles';
import { formatShortDateString } from '@/utils/date-utils';
import React, { FC } from 'react';

interface Props {
  type: BalanceFilterType;
  startDate?: Date;
  endDate?: Date;
  onClear: () => void;
}

export const MobileBalanceFilterButton: FC<Props> = (props: Props) => {
  const { type, startDate, endDate, onClear } = props;
  const getLabel = (type: BalanceFilterType): string => {
    switch (type) {
      case BalanceFilterType.ALL: {
        return 'Все операции';
      }
      case BalanceFilterType.INCOME: {
        return 'Пополнения';
      }
      case BalanceFilterType.EXPENSE: {
        return 'Списания';
      }
    }
  };
  const getDateLabel = (startDate?: Date, endDate?: Date): string => {
    if (startDate && endDate) {
      return ' за ' + formatShortDateString(startDate) + ' — ' + formatShortDateString(endDate);
    }
    return '';
  };
  return (
    <Box
      sx={(theme: Theme) => ({
        background: theme.colors.grayBackground,
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1.25, 2),
      })}>
      <Grid container={true} direction={'row'} alignItems={'center'} spacing={1.5}>
        <Grid item={true}>
          <Typography variant={'h5'}>
            {getLabel(type)}
            {getDateLabel(startDate, endDate)}
          </Typography>
        </Grid>
        <Grid item={true}>
          <IconButton color={'primary'} size={'dense'} sx={{ display: 'flex' }} onClick={onClear}>
            <CloseIcon sx={{ width: '12px', height: '12px' }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
