export enum PasswordChangStatus {
  CHANGED = 'CHANGED',
  WRONG_OLD_PASSWORD = 'WRONG_OLD_PASSWORD',
  MIN_LENGTH = 'MIN_LENGTH',
  WITHOUT_NUMBERS = 'WITHOUT_NUMBERS',
  WITHOUT_CAPITAL_LETTER = 'WITHOUT_CAPITAL_LETTER',
}

export interface PasswordChangeResponseDTO {
  status: PasswordChangStatus;
}
