import { AtlasDepartmentDTO } from '@/types/atlas/AtlasDepartmentDTO';
import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { Button, ButtonProps, Grid, Theme } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { RouteLink } from '@/components/common/RouteLink';
import { getHelpDepartmentsRoute } from '@/app/routes';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  department: AtlasDepartmentDTO;
  region: AtlasRegionDTO | null;
}

export const DepartmentButton: FC<Props> = (props: Props) => {
  const { department, region, ...other } = props;
  return (
    <Button
      fullWidth={true}
      variant={'contained'}
      color={'secondary'}
      sx={{
        padding: '8px 8px 7px 16px',
        height: '100%',
      }}
      {...other}>
      <Grid container={true} justifyContent={'space-between'} wrap={'nowrap'}>
        <Grid item={true}>
          {region?.id ? (
            <RouteLink to={getHelpDepartmentsRoute(region.id, department.id)}>{department.name}</RouteLink>
          ) : (
            department.name
          )}
        </Grid>
        <Grid item={true}>
          <Grid container={true}>
            <ChevronRight
              sx={{
                color: (theme: Theme) => theme.colors.grayText,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Button>
  );
};
