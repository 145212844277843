import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ShieldIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M14.2222 12.4095L14.7822 14.6623C14.8711 15.0172 14.4533 15.2978 14.1156 15.108L12 13.9197L9.87556 15.108C9.53778 15.2978 9.12 15.0172 9.20889 14.6623L9.77778 12.4178L7.91111 10.9241C7.60889 10.6848 7.76889 10.2309 8.16 10.1979L10.6311 9.99989L11.5911 7.88734C11.7422 7.549 12.2578 7.549 12.4089 7.88734L13.3689 9.99163L15.84 10.1897C16.2311 10.2227 16.3911 10.6766 16.0889 10.9159L14.2222 12.4095ZM5.05778 5.70877C4.41778 5.97284 4 6.56699 4 7.21891V11.0974C4 15.6774 7.41333 19.9602 12 21C16.5867 19.9602 20 15.6774 20 11.0974V7.21891C20 6.56699 19.5822 5.97284 18.9422 5.70877L12.72 3.14235C12.2578 2.95255 11.7333 2.95255 11.28 3.14235L5.05778 5.70877Z'
      }
    />
  </SvgIcon>
);
