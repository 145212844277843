import { Grid, Theme, Typography } from '@mui/material';
import { RightIcon } from '@/app/icons/RightIcon';
import { TypographyLink } from '@/components/common/TypographyLink';
import React, { FC } from 'react';

const ProgressSteps: FC = () => {
  const handleOnLink = (mark: string): void => {
    document.getElementById(mark)?.scrollIntoView();
  };
  return (
    <Grid
      container={true}
      columnSpacing={'13px'}
      alignItems={'center'}
      sx={{
        background: (theme: Theme) => theme.colors.grayBackground,
        borderRadius: '6px',
        padding: '4px 16px',
        marginLeft: '0',
        width: '100%',
      }}>
      <Grid item={true} sx={{ marginLeft: '-13px' }} onClick={() => handleOnLink('createPayContent')}>
        <Typography variant={'h4'}>
          <TypographyLink>{'Создание и оплата письма'}</TypographyLink>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true}>
          <RightIcon
            sx={{
              color: (theme: Theme) => theme.colors.textColor,
              height: '16px',
              width: '16px',
            }}
          />
        </Grid>
      </Grid>
      <Grid item={true} onClick={() => handleOnLink('progressCensoredContent')}>
        <Typography variant={'h4'}>
          <TypographyLink>{'Обработка и цензура'}</TypographyLink>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true}>
          <RightIcon
            sx={{
              color: (theme: Theme) => theme.colors.textColor,
              height: '16px',
              width: '16px',
            }}
          />
        </Grid>
      </Grid>
      <Grid item={true} onClick={() => handleOnLink('deliveryContent')}>
        <Typography variant={'h4'}>
          <TypographyLink>{'Вручение'}</TypographyLink>
        </Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true}>
          <RightIcon
            sx={{
              color: (theme: Theme) => theme.colors.textColor,
              height: '16px',
              width: '16px',
            }}
          />
        </Grid>
      </Grid>
      <Grid item={true} onClick={() => handleOnLink('answerContent')}>
        <Typography variant={'h4'}>
          <TypographyLink>{'Ответ на письмо'}</TypographyLink>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProgressSteps;
