import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const ExclamationIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 13 12'} sx={{ width: '13px', height: '12px' }} fill={'none'} {...props}>
    <path
      d={
        'M6.67383 11.6758C9.7793 11.6758 12.3516 9.10352 12.3516 5.99805C12.3516 2.89258 9.77344 0.320312 6.66797 0.320312C3.5625 0.320312 0.990234 2.89258 0.990234 5.99805C0.990234 9.10352 3.56836 11.6758 6.67383 11.6758ZM6.66797 7.00586C6.36914 7.00586 6.19922 6.83594 6.19336 6.52539L6.11719 3.58984C6.10547 3.28516 6.33398 3.0625 6.66211 3.0625C6.98438 3.0625 7.22461 3.28516 7.21289 3.5957L7.13086 6.52539C7.125 6.8418 6.95508 7.00586 6.66797 7.00586ZM6.66797 8.88086C6.32227 8.88086 6.02344 8.61133 6.02344 8.27148C6.02344 7.93164 6.31641 7.66211 6.66797 7.66211C7.01953 7.66211 7.30664 7.92578 7.30664 8.27148C7.30664 8.61719 7.01367 8.88086 6.66797 8.88086Z'
      }
      fill={'#af7036'}
    />
  </SvgIcon>
);
