import { FORBIDDEN, UNAUTHORIZED } from '@/constants/HttpStatus';
import { Middleware, MiddlewareAPI, isRejected } from '@reduxjs/toolkit';
import { signOut } from '@/services/authSlice';

export const unauthorizedMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejected(action)) {
    const status = action.payload?.status;
    if (status === UNAUTHORIZED || status === FORBIDDEN) {
      api.dispatch(signOut());
    }
  }
  return next(action);
};
