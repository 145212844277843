export enum LetterSimpleStatus {
  ALL = 'ALL',
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  WAITING_FOR_ANSWER = 'WAITING_FOR_ANSWER',
  ANSWER_UPLOADED = 'ANSWER_UPLOADED',
  ANSWER_CENSORED = 'ANSWER_CENSORED',
  LETTER_CENSORED = 'LETTER_CENSORED',
  ATTACHMENT_CENSORED = 'ATTACHMENT_CENSORED',
  ADDRESSEE_NOT_IN_DEPARTMENT = 'ADDRESSEE_NOT_IN_DEPARTMENT',
  ADDRESSEE_LEFT_DEPARTMENT = 'ADDRESSEE_LEFT_DEPARTMENT',
  ADDRESSEE_IS_FREE = 'ADDRESSEE_IS_FREE',
  CITY_COURT = 'CITY_COURT',
  PAID_INITIATIVE = 'PAID_INITIATIVE',
}
