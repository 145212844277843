import { AuthorDTO } from '@/types/AuthorDTO';
import { Buffer } from 'buffer';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { SenderDTO } from '@/types/SenderDTO';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { formatUserFullName } from '@/utils/string-utils';
import { v4 as uuid } from 'uuid';

export interface CreateFormState {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
  author: AuthorDTO | null;
  sender: SenderDTO | null;
  letterText?: string;
  isAnswerOrdered: boolean;
  bufferFileEncode?: string;
  typeFile?: string;
  cardAuthorEmail: string;
  answersCount: number;
  sudokuAttached: boolean;
  sudokuCount: number;
  crosswordAttached: boolean;
  newsAttached: boolean;
  isLetterSent: boolean;
  paymentMethod: PaymentMethod;
  paymentProvider?: PaymentProvider;
  letterNumber?: number;
  letterUuid?: string;
  letterDraftId?: number;
  idempotencyKey?: string;
}

const initialState: CreateFormState = {
  region: null,
  department: null,
  isAnswerOrdered: false,
  author: null,
  sender: null,
  cardAuthorEmail: '',
  answersCount: 1,
  isLetterSent: false,
  paymentMethod: PaymentMethod.SBP,
  paymentProvider: PaymentProvider.ALFA,
  idempotencyKey: uuid(),
  sudokuAttached: false,
  sudokuCount: 1,
  crosswordAttached: false,
  newsAttached: false,
};

export const createFormSlice = createSlice({
  name: 'createForm',
  initialState,
  reducers: {
    setRegionAndDepartment(
      state: CreateFormState,
      action: PayloadAction<{
        region: RegionDTO | null;
        department: DepartmentDTO | null;
      }>
    ): void {
      state.region = action.payload.region;
      state.department = action.payload.department;
    },
    setAuthor(state: CreateFormState, action: PayloadAction<AuthorDTO>): void {
      state.author = action.payload;
    },
    setSender(state: CreateFormState, action: PayloadAction<SenderDTO>): void {
      state.sender = action.payload;
    },
    setLetterTextForm(state: CreateFormState, action: PayloadAction<string>): void {
      state.letterText = action.payload;
    },
    setAnswerOrderedForm(state: CreateFormState, action: PayloadAction<boolean>): void {
      state.isAnswerOrdered = action.payload;
    },
    setAnswersCountForm(state: CreateFormState, action: PayloadAction<number>): void {
      state.answersCount = action.payload;
    },
    setSudokuAttachedState(state: CreateFormState, action: PayloadAction<boolean>): void {
      state.sudokuAttached = action.payload;
    },
    setSudokuCountState(state: CreateFormState, action: PayloadAction<number>): void {
      state.sudokuCount = action.payload;
    },
    setCrosswordAttachedState(state: CreateFormState, action: PayloadAction<boolean>): void {
      state.crosswordAttached = action.payload;
    },
    setNewsAttachedState(state: CreateFormState, action: PayloadAction<boolean>): void {
      state.newsAttached = action.payload;
    },
    setLetterSentForm(state: CreateFormState, action: PayloadAction<boolean>): void {
      state.isLetterSent = action.payload;
    },
    setLetterNumber(state: CreateFormState, action: PayloadAction<number>): void {
      state.letterNumber = action.payload;
    },
    setLetterUuid(state: CreateFormState, action: PayloadAction<string | undefined>): void {
      state.letterUuid = action.payload;
    },
    setPaymentMethodForm(state: CreateFormState, action: PayloadAction<PaymentMethod>): void {
      state.paymentMethod = action.payload;
    },
    setPaymentProviderForm(state: CreateFormState, action: PayloadAction<PaymentProvider | undefined>): void {
      state.paymentProvider = action.payload;
    },
    clearFormState(state: CreateFormState): void {
      state.isAnswerOrdered = false;
      state.letterText = undefined;
      state.bufferFileEncode = undefined;
      state.typeFile = undefined;
      state.region = null;
      state.department = null;
      state.sender = null;
      state.author = null;
      state.answersCount = 1;
      state.isLetterSent = false;
      state.letterNumber = undefined;
      state.letterUuid = undefined;
      state.paymentMethod = PaymentMethod.SBP;
      state.paymentProvider = PaymentProvider.ALFA;
      state.letterDraftId = undefined;
      state.idempotencyKey = uuid();
      state.sudokuAttached = false;
      state.sudokuCount = 1;
      state.crosswordAttached = false;
      state.newsAttached = false;
    },
    deleteAttachFile(state: CreateFormState): void {
      state.bufferFileEncode = undefined;
      state.typeFile = undefined;
    },
    setCreateInfoFromDTO(state: CreateFormState, action: PayloadAction<SenderInfoDTO>): void {
      state.region = action.payload.region;
      state.department = action.payload.department;
      state.sender = action.payload.sender;
      state.isAnswerOrdered = false;
      state.letterText = undefined;
      state.bufferFileEncode = undefined;
      state.typeFile = undefined;
      state.answersCount = 1;
      state.sudokuAttached = false;
      state.sudokuCount = 1;
      state.crosswordAttached = false;
      state.newsAttached = false;
    },
    setAuthorFromUser(state: CreateFormState, action: PayloadAction<UserDTO>): void {
      state.author = {
        name: formatUserFullName(action.payload),
        phone: action.payload.phone || '',
        email: action.payload.email,
      };
    },
    setPhoneNumber(state: CreateFormState, action: PayloadAction<string>): void {
      state.author = {
        email: state.author?.email || '',
        name: state.author?.name || '',
        phone: action.payload,
      };
    },
    setBufferFile(state: CreateFormState, action: PayloadAction<string>): void {
      state.bufferFileEncode = action.payload;
    },
    setTypeFile(state: CreateFormState, action: PayloadAction<string>): void {
      state.typeFile = action.payload;
    },
    clearLetterTextAndFile(state: CreateFormState): void {
      state.letterText = undefined;
      state.bufferFileEncode = undefined;
      state.typeFile = undefined;
      state.sudokuAttached = false;
      state.crosswordAttached = false;
      state.newsAttached = false;
    },
    setCardAuthorEmail(state: CreateFormState, action: PayloadAction<string>): void {
      state.cardAuthorEmail = action.payload;
    },
    setLetterDraftId(state: CreateFormState, action: PayloadAction<number>): void {
      state.letterDraftId = action.payload;
    },
    clearLetterDraftId(state: CreateFormState): void {
      state.letterDraftId = undefined;
    },
    refreshIdempotencyKey(state: CreateFormState): void {
      state.idempotencyKey = uuid();
    },
  },
});

export const {
  setRegionAndDepartment,
  setAuthor,
  setSender,
  setCreateInfoFromDTO,
  setPhoneNumber,
  setAnswerOrderedForm,
  setLetterTextForm,
  clearFormState,
  setBufferFile,
  setTypeFile,
  deleteAttachFile,
  clearLetterTextAndFile,
  setCardAuthorEmail,
  setAnswersCountForm,
  setLetterSentForm,
  setLetterNumber,
  setLetterUuid,
  setPaymentMethodForm,
  setPaymentProviderForm,
  setLetterDraftId,
  clearLetterDraftId,
  refreshIdempotencyKey,
  setCrosswordAttachedState,
  setSudokuAttachedState,
  setSudokuCountState,
  setNewsAttachedState,
} = createFormSlice.actions;

export const getSudokuAttached = (state: RootState): boolean => {
  return state.createForm.sudokuAttached;
};

export const getSudokuCount = (state: RootState): number => {
  return state.createForm.sudokuCount;
};

export const getCrosswordAttached = (state: RootState): boolean => {
  return state.createForm.crosswordAttached;
};

export const getNewsAttached = (state: RootState): boolean => {
  return state.createForm.newsAttached;
};

export const getAnswerOrder = (state: RootState): boolean => {
  return state.createForm.isAnswerOrdered;
};

export const getAnswersCount = (state: RootState): number => {
  return state.createForm.answersCount;
};

export const getAuthor = (state: RootState): AuthorDTO | null => {
  return state.createForm.author;
};

export const getSender = (state: RootState): SenderDTO | null => {
  return state.createForm.sender;
};

export const getCardAuthorEmail = (state: RootState): string => {
  return state.createForm.cardAuthorEmail;
};

export const getLetterSent = (state: RootState): boolean => {
  return state.createForm.isLetterSent;
};

export const getLetterNumber = (state: RootState): number | undefined => {
  return state.createForm.letterNumber;
};

export const getLetterUuid = (state: RootState): string | undefined => {
  return state.createForm.letterUuid;
};

export const getPaymentMethod = (state: RootState): PaymentMethod => {
  return state.createForm.paymentMethod;
};

export const getPaymentProvider = (state: RootState): PaymentProvider | undefined => {
  return state.createForm.paymentProvider;
};

export const getLetterDraftId = (state: RootState): number | undefined => {
  return state.createForm.letterDraftId;
};

export const getIdempotencyKey = (state: RootState): string | undefined => {
  return state.createForm.idempotencyKey;
};

export const getAttachFile = (state: RootState): File | undefined => {
  if (state.createForm.bufferFileEncode && state.createForm.typeFile) {
    const decoded: Buffer = Buffer.from(state.createForm.bufferFileEncode, 'base64');
    const blob: Blob = new Blob([decoded], {
      type: state.createForm.typeFile,
    });
    return new File([blob], `attachFile.${state.createForm.typeFile.split('/')[1]}`, {
      type: state.createForm.typeFile,
    });
  }
  return undefined;
};
