import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/app/store';

export interface ProfileState {
  isNewEmail: boolean;
}

const initialState: ProfileState = {
  isNewEmail: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setNewEmail: (state: ProfileState, action: PayloadAction<boolean>) => {
      state.isNewEmail = action.payload;
    },
  },
});

export const getNewEmail = (state: RootState): boolean => {
  return state.profile.isNewEmail;
};

export const { setNewEmail } = profileSlice.actions;

export default profileSlice.reducer;
