import { Grid, Typography } from '@mui/material';
import NavigatePreviousStep from '@/components/create/NavigatePreviousStep';
import React, { FC, Fragment, ReactNode } from 'react';

interface Props {
  previousFormPath?: string;
  previousFormLabel?: ReactNode;
  tittle?: ReactNode;
  isMobile?: boolean;
  mobileTittle?: ReactNode;
}

export const PageHeader: FC<Props> = (props: Props) => {
  const { previousFormPath, previousFormLabel, tittle, isMobile, mobileTittle } = props;
  return (
    <Fragment>
      {previousFormLabel && (
        <Grid container={true} mb={isMobile ? 1 : 0}>
          <NavigatePreviousStep to={previousFormPath}>{previousFormLabel}</NavigatePreviousStep>
        </Grid>
      )}
      <Typography variant={isMobile ? 'h2' : 'h1'}>{isMobile ? mobileTittle : tittle}</Typography>
    </Fragment>
  );
};
