import { NumberSelectorField, NumberSelectorFieldProps } from '@/components/common/field/NumberSelectorField';
import React, { FC } from 'react';

type Props = NumberSelectorFieldProps;

export const AnswerCountSelectorField: FC<Props> = (props: Props) => {
  const { minValue, maxValue, helperText, ...other } = props;

  return (
    <NumberSelectorField
      name={'answersCount'}
      minValue={minValue}
      maxValue={maxValue}
      label={'Количество бланков ответа'}
      withLabel={true}
      isNotAbsoluteHelperText={true}
      helperText={helperText ? helperText : `Не более ${maxValue} бланков`}
      {...other}
    />
  );
};
