import { AttachFilePanel } from '@/components/create/AttachFilePanel';
import { Button, Tooltip } from '@mui/material';
import { formatCurrency } from '@/utils/string-utils';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, RefObject, useRef } from 'react';

interface Props {
  attachmentCost: number;
  uploadEnabled?: boolean;
  attachFile: File | undefined;
  onAttachFile: (file: File | undefined) => void;
}

export const CreateFormAttachFile: FC<Props> = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { attachmentCost, attachFile, uploadEnabled, onAttachFile } = props;
  const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = (): void => {
    inputRef.current?.click();
  };

  const handleUploadChange = (): void => {
    if (inputRef.current?.files) {
      const supportedFileTypes: Set<string> = new Set(['jpeg', 'jpg', 'png']);
      const imageType: string = inputRef.current.files[0].type.split('/')[1];
      if (supportedFileTypes.has(imageType)) {
        onAttachFile(inputRef.current.files[0]);
      } else {
        enqueueSnackbar('Поддерживаются только изображения в форматах JPG, JPEG и PNG', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Fragment>
      {attachmentCost &&
        (attachFile ? (
          <AttachFilePanel
            attachedFile={attachFile}
            photoCost={attachmentCost}
            onClear={(): void => {
              onAttachFile(undefined);
            }}
          />
        ) : (
          <Fragment>
            <Tooltip
              title={'Фотографию распечатают на черно-белом лазерном принтере на обычной бумаге'}
              disableInteractive={true}>
              <div>
                <Button
                  size={'medium'}
                  fullWidth={true}
                  color={'primary'}
                  variant={'outlined'}
                  disabled={!uploadEnabled}
                  sx={{ padding: '11px 30px' }}
                  onClick={() => handleUploadButtonClick()}>
                  {`Приложить фотографию + ${formatCurrency(attachmentCost, 0)}`}
                </Button>
              </div>
            </Tooltip>
            <input
              type={'file'}
              hidden={true}
              ref={inputRef}
              accept={'image/png, image/jpeg'}
              onChange={handleUploadChange}
            />
          </Fragment>
        ))}
    </Fragment>
  );
};
