import * as Yup from 'yup';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Form, Formik, FormikProps } from 'formik';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PageHeader } from '@/components/common/PageHeader';
import { RootState } from '@/app/store';
import { emailSchema } from '@/validation/emailSchema';
import { letterNumberSchema } from '@/validation/letterNumberSchema';
import { setLetterInfo } from '@/services/departmentRedirectFormSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory } from 'react-router-dom';
import React, { ChangeEvent, FC } from 'react';

interface FormValues {
  letterNumber?: number;
  email: string;
}

const validationSchema = Yup.object({
  letterNumber: letterNumberSchema,
  email: emailSchema,
});

const CreateFormDepartment: FC<CreateFormProps> = (props: CreateFormProps) => {
  const { tittle, nextFormPath, previousFormPath, previousFormLabel, isMobile, mobileTittle } = props;
  const departmentRedirectFormReducer = useAppSelector((state: RootState) => {
    return state.departmentRedirectForm;
  });
  const initialValues: FormValues = {
    letterNumber: departmentRedirectFormReducer.letterNumber || undefined,
    email: departmentRedirectFormReducer.email || '',
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleSubmit = (value: FormValues) => {
    dispatch(
      setLetterInfo({
        letterNumber: value.letterNumber,
        email: value.email,
      })
    );
    if (nextFormPath) {
      history.push(nextFormPath);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Form>
            <Grid
              container={true}
              direction={'column'}
              sx={{
                paddingBottom: (theme: Theme) => (isMobile ? theme.spacing(6) : theme.spacing(0)),
              }}>
              <Grid item={true}>
                <Typography
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  fontWeight={'500'}
                  align={'center'}
                  marginBottom={'15px'}
                  color={(theme: Theme) => theme.colors.grayText}>
                  {'Перенаправить письмо в другое учреждение'}
                </Typography>
              </Grid>
              <Grid item={true} marginBottom={isMobile ? '48px' : '79px'}>
                <PageHeader
                  tittle={tittle}
                  previousFormLabel={previousFormLabel}
                  previousFormPath={previousFormPath}
                  isMobile={isMobile}
                  mobileTittle={mobileTittle}
                />
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={isMobile ? 6 : 3}>
                  <Grid item={true} xs={isMobile ? 12 : 4}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Номер письма'}
                      name={'letterNumber'}
                      value={values.letterNumber}
                      error={touched.letterNumber && Boolean(errors.letterNumber)}
                      helperText={touched.letterNumber ? errors.letterNumber : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Электронная почта отправителя'}
                      name={'email'}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email ? errors.email : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                    />
                  </Grid>
                  {isMobile ? (
                    <MobileBar>
                      <FixedButton
                        disabled={!(values.letterNumber && values.email)}
                        color={'primary'}
                        variant={'contained'}
                        onClick={submitForm}>
                        {'Продолжить'}
                      </FixedButton>
                    </MobileBar>
                  ) : (
                    <Grid item={true} xs={true}>
                      <Button
                        disabled={!(values.letterNumber && values.email)}
                        onClick={submitForm}
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        size={'medium'}>
                        {'Продолжить'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateFormDepartment;
