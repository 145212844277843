import { Button, Grid, Typography } from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { ROUTE_REGISTRATION_SUCCESS } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useRequestConfirmationEmailMutation } from '@/services/api/authApiSlice';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useEffect, useState } from 'react';

export const RegistrationConfirmRequest: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [email, setEmail] = useState<string>();
  const [requestConfirmationEmail] = useRequestConfirmationEmailMutation();
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const email: string | null = params.get('email');
    if (email) {
      setEmail(email);
    }
  }, []);
  const handleRequestClick = (): void => {
    if (email) {
      requestConfirmationEmail(email)
        .unwrap()
        .then(() => {
          history.replace(ROUTE_REGISTRATION_SUCCESS);
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message, { variant: 'error' });
        });
    }
  };
  return (
    <Fragment>
      {email && (
        <Grid container={true} direction={'column'} alignItems={'center'} spacing={4} mt={14} mb={17}>
          <Grid item={true}>
            <Typography variant={'h2'} textAlign={'center'}>
              {'Чтобы пользоваться личным кабинетом, подтвердите регистрацию с помощью ссылки в письме'}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Button
              color={'primary'}
              variant={'outlined'}
              onClick={handleRequestClick}
              sx={{ minWidth: (theme: Theme) => theme.spacing(33) }}>
              {'Запросить ссылку повторно'}
            </Button>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};
