import { format, isToday } from 'date-fns';
import { ru } from 'date-fns/locale';

export const formatShortFullDateString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    return format(d, 'dd.MM.yyyy', { locale: ru });
  }
  return 'NaD';
};

export const formatShortDateString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'd MMM', { locale: ru });
    } else {
      return format(d, 'd MMM yyyy', { locale: ru });
    }
  }
  return 'NaD';
};

export const formatTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    return format(d, 'HH:mm', { locale: ru });
  }
  return 'NaD';
};

export const formatMonthString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    return format(d, 'LLLL', { locale: ru });
  }
  return 'NaD';
};

export const formatDateTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    return format(d, 'd MMMM HH:mm', { locale: ru });
  }
  return 'NaD';
};

export const formatShortDateTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'd MMM HH:mm', { locale: ru });
    } else {
      return format(d, 'd MMM yyyy HH:mm', { locale: ru });
    }
  }
  return 'NaD';
};

export const formatSmartDateTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    if (isToday(d)) {
      return format(d, 'HH:mm', { locale: ru });
    } else if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'd MMM', { locale: ru });
    }
    return format(d, 'd MMM yyyy', { locale: ru });
  }
  return 'NaD';
};

export const formatSmartZonedDateTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    if (isToday(d)) {
      return format(d, 'HH:mm', { locale: ru });
    } else if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'd MMM', { locale: ru });
    }
    return format(d, 'd MMM yyyy', { locale: ru });
  }
  return 'NaD';
};

export const formatSmartShortDateTimeString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    if (isToday(d)) {
      return format(d, 'HH:mm', { locale: ru });
    } else if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'd MMM HH:mm', { locale: ru });
    }
    return format(d, 'd MMM yyyy HH:mm', { locale: ru });
  }
  return 'NaD';
};

export const formatSmartShortTimeDateString = (date: Date | string): string => {
  if (date) {
    const d: Date = new Date(date);
    if (isToday(d)) {
      return format(d, 'HH:mm', { locale: ru });
    } else if (d.getFullYear() === new Date().getFullYear()) {
      return format(d, 'HH:mm d MMM', { locale: ru });
    }
    return format(d, 'HH:mm d MMM yyyy', { locale: ru });
  }
  return 'NaD';
};

export const formatTimeDateStringWithoutOffset = (dateString: string): string => {
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() + offset);
  return format(dateWithoutOffset, 'HH:mm dd MMMM yyyy.', { locale: ru });
};
