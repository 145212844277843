import { Box, Grid, Stack, Typography } from '@mui/material';
import { DraftsBlock } from '@/components/home/drafts/DraftsBlock';
import { EventCarousel } from '@/components/home/event/EventCarousel';
import { FixedButton } from '@/components/common/button/FixedButton';
import { InboxBlock } from '@/components/home/inbox/InboxBlock';
import { LetterCountDTO } from '@/types/letter/LetterCountDTO';
import { MobileBar } from '@/components/home/MobileBar';
import { OutboxBlock } from '@/components/home/outbox/OutboxBlock';
import { ROUTE_DRAFTS, ROUTE_INBOX, ROUTE_OUTBOX } from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SenderInfoDTO } from '@/types/create/SenderInfoDTO';
import { SystemEventDTO } from '@/types/event/system/SystemEventDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { convertSystemEvent } from '@/utils/event-utils';
import { getCurrentUser } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import { useGetNewsQuery, useGetSystemEventsQuery } from '@/services/api/eventApiSlice';
import React, { FC, useState } from 'react';
import SenderChooseDialog from '@/components/create/SenderChooseDialog';

interface Props {
  inboxCount?: LetterCountDTO;
  outboxCount?: LetterCountDTO;
  senders?: SenderInfoDTO[];
  letterDraftsCount?: number;
  isLoadingSenders?: boolean;
}

export const MobileHomePage: FC<Props> = (props: Props) => {
  const { inboxCount, outboxCount, senders, isLoadingSenders, letterDraftsCount } = props;

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);

  const {
    data: events,
    isLoading,
    isFetching,
  } = useGetSystemEventsQuery(authUser?.id, {
    skip: !authUser,
  });

  const { data: news } = useGetNewsQuery();

  const allEvents: SystemEventDTO[] = [...(events || []), ...(news || [])]
    .sort((e1: SystemEventDTO, e2: SystemEventDTO) => {
      const date1: number = e1.timestamp ? new Date(e1.timestamp).getTime() : 0;
      const date2: number = e2.timestamp ? new Date(e2.timestamp).getTime() : 0;
      return date2 - date1;
    })
    .slice(0, 3);

  const loading: boolean = isLoading || isFetching;

  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<'letter' | 'answer'>('letter');

  const handleClick = async (type: 'letter' | 'answer'): Promise<void> => {
    setType(type);
    setOpen(true);
  };

  return (
    <Box pb={(theme: Theme) => theme.spacing(6)}>
      <Grid container={true} direction={'column'} wrap={'nowrap'} spacing={5}>
        <Grid item={true}>
          <Grid container={true} direction={'column'} flexWrap={'nowrap'} spacing={3}>
            <Grid item={true}>
              <Box mx={(theme: Theme) => theme.spacing(-2)}>
                <EventCarousel
                  events={(allEvents || []).map((event: SystemEventDTO) => convertSystemEvent(event))}
                  isLoading={loading}
                />
              </Box>
            </Grid>
            <Grid item={true} xs={true}>
              <Switch>
                <Route exact={true} path={ROUTE_INBOX}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <Stack direction={'row'} spacing={1}>
                        <Typography variant={'h2'} fontSize={'20px'} lineHeight={'24px'} fontWeight={500}>
                          {'Входящие'}
                        </Typography>
                        {!isNaN(Number(inboxCount?.totalCount)) && (
                          <Typography
                            variant={'h2'}
                            fontSize={'20px'}
                            lineHeight={'24px'}
                            fontWeight={400}
                            color={(theme: Theme) => theme.colors.grayText}>
                            {inboxCount?.totalCount}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item={true}>
                      <InboxBlock />
                    </Grid>
                  </Grid>
                </Route>
                <Route exact={true} path={ROUTE_OUTBOX}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <Stack direction={'row'} spacing={1}>
                        <Typography variant={'h2'} fontSize={'20px'} lineHeight={'24px'} fontWeight={500}>
                          {'Исходящие'}
                        </Typography>
                        {!isNaN(Number(outboxCount?.totalCount)) && (
                          <Typography
                            variant={'h2'}
                            fontSize={'20px'}
                            lineHeight={'24px'}
                            fontWeight={400}
                            color={(theme: Theme) => theme.colors.grayText}>
                            {outboxCount?.totalCount}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item={true}>
                      <OutboxBlock isSearchFieldEnable={senders && senders.length > 1} />
                    </Grid>
                  </Grid>
                </Route>
                <Route exact={true} path={ROUTE_DRAFTS}>
                  <Grid container={true} direction={'column'} spacing={2}>
                    <Grid item={true}>
                      <Stack direction={'row'} spacing={1}>
                        <Typography variant={'h2'} fontSize={'20px'} lineHeight={'24px'} fontWeight={500}>
                          {'Черновики'}
                        </Typography>
                        {!isNaN(Number(letterDraftsCount)) && (
                          <Typography
                            variant={'h2'}
                            fontSize={'20px'}
                            lineHeight={'24px'}
                            fontWeight={400}
                            color={(theme: Theme) => theme.colors.grayText}>
                            {letterDraftsCount}
                          </Typography>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item={true}>
                      <DraftsBlock />
                    </Grid>
                  </Grid>
                </Route>
                <Redirect to={ROUTE_INBOX} />
              </Switch>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MobileBar>
        <Grid container={true} direction={'row'} wrap={'nowrap'}>
          <Grid item={true} xs={true}>
            <FixedButton
              disabled={isLoadingSenders}
              color={'primary'}
              variant={'contained'}
              onClick={() => handleClick('letter')}>
              {'Написать письмо'}
            </FixedButton>
          </Grid>
          <Grid item={true} xs={true}>
            <FixedButton
              disabled={isLoadingSenders}
              color={'primary'}
              variant={'outlined'}
              onClick={() => handleClick('answer')}>
              {'Заказать ответ'}
            </FixedButton>
          </Grid>
        </Grid>
      </MobileBar>
      <SenderChooseDialog
        open={open}
        type={type}
        isMobile={true}
        senders={senders}
        handleCloseButton={() => setOpen(false)}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};
