import { Grid } from '@mui/material';
import { Logo } from '@/app/icons/Logo';
import { ROUTE_HOME } from '@/app/routes';
import { RouteLink } from '@/components/common/RouteLink';
import { Theme } from '@mui/material/styles';
import HeaderProfile from '@/components/common/HeaderProfile';
import React, { FC } from 'react';

interface Props {
  profile: boolean;
}

export const Header: FC<Props> = (props: Props) => {
  const { profile } = props;
  return (
    <Grid container={true} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item={true}>
        <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
          <Grid item={true} display={'flex'}>
            <RouteLink to={ROUTE_HOME}>
              <Logo
                color={'primary'}
                sx={{
                  width: (theme: Theme) => theme.spacing(10.875),
                  height: (theme: Theme) => theme.spacing(8),
                }}
              />
            </RouteLink>
          </Grid>
        </Grid>
      </Grid>
      {profile && (
        <Grid item={true}>
          <HeaderProfile />
        </Grid>
      )}
    </Grid>
  );
};
