import { CheckCircle } from '@mui/icons-material';
import { ClientDialog } from '@/components/common/modal/ClientDialog';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { Grid, Typography } from '@mui/material';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SpbPaymentContainer } from '@/components/common/sbp/SpbPaymentContainer';
import { Theme } from '@mui/material/styles';
import { apiSlice } from '@/services/api/apiSlice';
import { clearBalanceByLetterPageState, clearBalanceSbpState, setBalanceSuccessFill } from '@/services/sbpSlice';
import { formatCurrency } from '@/utils/string-utils';
import { useAppDispatch } from '@/app/hooks';
import React, { FC, useEffect } from 'react';

interface Props {
  open: boolean;
  amount: number;
  isMobile?: boolean;
  isLetterPage?: boolean;
  email: string;
  sbpQR?: CreatedSbpQrDTO;
  isBalanceFillSuccess: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

export const SbpBalancePaymentDialog: FC<Props> = (props: Props) => {
  const { open, isMobile, isLetterPage, onClose, onSuccess, amount, sbpQR, email, isBalanceFillSuccess } = props;
  const dispatch = useAppDispatch();
  const handleClose = (): void => {
    if (onClose) {
      if (isLetterPage) {
        dispatch(clearBalanceByLetterPageState());
      } else {
        dispatch(clearBalanceSbpState());
        dispatch(setBalanceSuccessFill(false));
      }
      onClose();
    }
  };

  useEffect((): void => {
    if (isBalanceFillSuccess) {
      apiSlice.util.invalidateTags(['Balance', 'BalanceHistory']);
    }
  }, [isBalanceFillSuccess]);

  return (
    <ClientDialog
      open={open}
      isMobile={isMobile}
      label={'Пополнить баланс'}
      handleCloseButton={handleClose}
      sxContent={(theme: Theme) => ({
        marginTop: theme.spacing(-1.5),
        [theme.breakpoints.up('xs')]: {
          maxWidth: '510px',
          minHeight: '506px',
        },
      })}>
      <Grid container={true} direction={'column'} spacing={2}>
        <Grid item={true}>
          <Grid container={true} justifyContent={'space-between'}>
            <Grid item={true}>
              <Typography>{'Итого'}</Typography>
            </Grid>
            <Grid item={true}>
              <Typography>{formatCurrency(amount)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {sbpQR && (
          <Grid item={true}>
            <SpbPaymentContainer
              sbpQR={sbpQR}
              sbpMethod={SbpMethod.BALANCE}
              email={email}
              isMobile={isMobile}
              isLetterPage={isLetterPage}
              onSuccess={onSuccess}
            />
          </Grid>
        )}
        {isBalanceFillSuccess && (
          <Grid item={true}>
            <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
              <Grid item={true}>
                <CheckCircle sx={{ color: (theme: Theme) => theme.colors.green }} />
              </Grid>
              <Grid item={true} xs={true}>
                <Grid container={true} direction={'column'} spacing={1}>
                  <Grid item={true}>
                    <Typography variant={'body1'} fontWeight={500} sx={{ color: (theme: Theme) => theme.colors.green }}>
                      {'Баланс пополнен'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'body1'} maxWidth={'285px'}>
                      {'Уведомление об оплате вы получите на '}
                      <Typography component={'span'} variant={'inherit'} fontWeight={500}>
                        {email}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </ClientDialog>
  );
};
