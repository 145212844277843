import { Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { formatCurrency, getNumEnding } from '@/utils/string-utils';
import React, { FC } from 'react';
import theme from '@/app/theme';

const useLetterCostPanelStyles = makeStyles(() =>
  createStyles({
    label: {
      color: theme.colors.grayText,
      paddingBottom: '4px',
    },
  })
);

interface Props {
  allPrice?: number;
  countCharacters?: number;
  pages?: number;
  file?: boolean;
  answer?: boolean;
  fromCreateAnswer?: boolean;
}

const LetterCostPanel: FC<Props> = (props: Props) => {
  const { allPrice, countCharacters, pages, file, answer, fromCreateAnswer } = props;
  const classes = useLetterCostPanelStyles();
  return (
    <Grid container={true} direction={'column'} rowSpacing={2} wrap={'nowrap'}>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {'Стоимость'}
        </Typography>
        {fromCreateAnswer ? (
          <Typography variant={'body1'}>{`${formatCurrency(allPrice || 0, 2)}`}</Typography>
        ) : (
          <Typography variant={'body1'}>{`Письмо: ${countCharacters} ${getNumEnding(countCharacters || 0, [
            'символ',
            'символа',
            'символов',
          ])}, ${pages || '...'} ${getNumEnding(pages || 0, ['страница', 'страницы', 'страниц'])}${
            file ? ', Фото' : ''
          }${answer ? ', Ответ на письмо' : ''} - ${formatCurrency(allPrice || 0, 2)}`}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default LetterCostPanel;
