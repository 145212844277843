import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const FsinetIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon
    width={'24'}
    height={'24'}
    viewBox={'0 0 24 24'}
    fill={'none'}
    sx={{ width: '24px', height: '24px', display: 'flex', fill: 'none' }}
    {...props}>
    <path
      d={
        'M18.3261 21C16.5368 22.2601 14.3548 23 12 23C9.64517 23 7.46318 22.2601 5.67383 21M18.3261 3C16.5368 1.73994 14.3548 1 12 1C9.64517 1 7.46318 1.73994 5.67383 3'
      }
      strokeLinecap={'round'}
    />
    <rect x={'2.5'} y={'14.5'} width={'3'} height={'3'} />
    <rect x={'7.5'} y={'12.5'} width={'3'} height={'5'} />
    <rect x={'12.5'} y={'9.5'} width={'3'} height={'8'} />
    <rect x={'17.5'} y={'6.5'} width={'3'} height={'11'} />
  </SvgIcon>
);
