export enum SbpOrderStatus {
  STARTED = '0',
  CONFIRMED = '1',
  ACCEPTED = '2',
  CANCELED = '3',
  REFUNDED = '4',
  ACS = '5',
  REJECTED = '6',
  ERROR = '-1',
}
