import { AppDispatch } from '@/app/store';
import { BalanceFilterDialog } from '@/components/balance/BalanceFilterDialog';
import { BalanceFilterType } from '@/types/balance/BalanceFilterType';
import { BalanceHistoryRequestDTO } from '@/types/balance/BalanceHistoryRequestDTO';
import { BalanceHistoryTable } from '@/components/balance/BalanceHistoryTable';
import { Button, Grid, Pagination, Typography } from '@mui/material';
import { FilterIcon } from '@/app/icons/FilterIcon';
import { MobileBalanceFilterButton } from '@/components/balance/MobileBalanceFilterButton';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import {
  clearBalanceHistoryRequestDates,
  getBalanceHistoryRequest,
  setBalanceHistoryRequestEndDate,
  setBalanceHistoryRequestPageNumber,
  setBalanceHistoryRequestStartDate,
  setBalanceHistoryRequestType,
} from '@/services/balanceHistorySlice';
import { getCurrentUser } from '@/services/authSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetHistoryQuery } from '@/services/api/balanceApiSlice';
import React, { ChangeEvent, FC, Fragment, useState } from 'react';

export const MobileBalanceHistory: FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const request: BalanceHistoryRequestDTO = useAppSelector(getBalanceHistoryRequest);
  const type: BalanceFilterType = request.searchRequest.type;
  const startDate: Date | undefined = request.searchRequest.startDate
    ? new Date(request.searchRequest.startDate)
    : undefined;
  const endDate: Date | undefined = request.searchRequest.endDate ? new Date(request.searchRequest.endDate) : undefined;
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data, isFetching, isLoading } = useGetHistoryQuery({ ...request, userId: authUser?.id }, { skip: !authUser });
  const [open, setOpen] = useState<boolean>(false);
  const handlePage = (page: number): void => {
    dispatch(setBalanceHistoryRequestPageNumber(page - 1));
  };
  const handleClearFilter = (): void => {
    dispatch(clearBalanceHistoryRequestDates());
    dispatch(setBalanceHistoryRequestStartDate(undefined));
    dispatch(setBalanceHistoryRequestEndDate(undefined));
    dispatch(setBalanceHistoryRequestType(BalanceFilterType.ALL));
  };
  return (
    <Fragment>
      <Grid container={true} direction={'column'} spacing={2}>
        <Grid item={true}>
          <Grid container={true} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h4'}>{'История операций'}</Typography>
            </Grid>
            <Grid item={true}>
              <Button
                color={'primary'}
                variant={'text'}
                disableRipple={true}
                sx={(theme: Theme) => ({
                  margin: theme.spacing(-1.5, -1),
                  padding: theme.spacing(1.5, 1),
                  '&:hover': {
                    background: 'none',
                  },
                })}
                onClick={() => setOpen(true)}>
                <Grid container={true} direction={'row'} alignItems={'center'} spacing={0.5} wrap={'nowrap'}>
                  <Grid item={true} display={'flex'}>
                    <FilterIcon />
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'h4'}>{'Фильтр'}</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {(type !== BalanceFilterType.ALL || (!!startDate && !!endDate)) && (
          <Grid item={true}>
            <Grid container={true} direction={'row'} spacing={1}>
              <Grid item={true}>
                <MobileBalanceFilterButton
                  type={type}
                  startDate={startDate}
                  endDate={endDate}
                  onClear={handleClearFilter}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item={true}>
          <BalanceHistoryTable
            isFetching={isFetching}
            isLoading={isLoading}
            items={data?.content || []}
            isMobile={true}
          />
        </Grid>
        <Grid item={true} display={'flex'} justifyContent={'center'}>
          <Pagination
            count={data?.totalPages || 1}
            page={(request.pageRequest?.pageNumber || 0) + 1}
            onChange={(e: ChangeEvent<unknown>, page: number) => {
              handlePage(page);
            }}
          />
        </Grid>
      </Grid>
      <BalanceFilterDialog
        open={open}
        type={type}
        startDate={startDate}
        endDate={endDate}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
