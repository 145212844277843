import { EventLogDTO } from '@/types/event/EventLogDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { SystemEventDTO } from '@/types/event/system/SystemEventDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const eventApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEventLog: build.query<EventLogDTO[], number>({
      query: (letterId: number) => ({
        url: '/event/letter',
        method: HttpMethod.GET,
        params: {
          letterId: letterId,
        },
      }),
      providesTags: ['LetterHistory', 'SystemEvents'],
    }),
    getSystemEvents: build.query<SystemEventDTO[], number | undefined>({
      query: (userId: number | undefined) => ({
        url: '/event/system',
        method: HttpMethod.GET,
        params: {
          userId: userId,
        },
      }),
      providesTags: ['SystemEvents'],
    }),
    getNews: build.query<SystemEventDTO[], void>({
      query: () => ({
        url: '/event/news',
        method: HttpMethod.GET,
      }),
      providesTags: ['SystemEvents'],
    }),
  }),
});

export const { useGetEventLogQuery, useGetSystemEventsQuery, useGetNewsQuery } = eventApiSlice;
