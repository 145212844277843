import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { LetterNumberDTO } from '@/types/letter/LetterNumberDTO';
import { RegistrationSbpDTO } from '@/types/sbp/RegistrationSbpDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const sbpPaymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    userBalanceRefill: build.mutation<CreatedSbpQrDTO, { amount: number }>({
      query: (dto) => ({
        url: '/payment/sbp-balance-refill',
        method: 'POST',
        body: dto,
      }),
    }),
    cardBalanceRefill: build.mutation<CreatedSbpQrDTO, { cardUUID: string; amount: number }>({
      query: (dto) => ({
        url: '/payment/sbp-card-refill',
        method: 'POST',
        body: dto,
      }),
    }),
    createQr: build.mutation<CreatedSbpQrDTO, { sbpOrderId: string }>({
      query: (dto) => ({
        url: '/payment/sbp-create-qr',
        method: 'POST',
        body: dto,
      }),
    }),
    registerOrder: build.mutation<RegistrationSbpDTO, LetterNumberDTO>({
      query: (dto) => ({
        url: '/payment/create-sbp-order',
        method: 'POST',
        body: dto,
      }),
    }),
  }),
});

export const {
  useCreateQrMutation,
  useUserBalanceRefillMutation,
  useCardBalanceRefillMutation,
  useRegisterOrderMutation,
} = sbpPaymentApiSlice;
