import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { getAuthenticated } from '@/services/authSlice';
import { useAppSelector } from '@/app/hooks';
import React, { FC } from 'react';

type Props = RouteProps;

export const PrivateRoute: FC<Props> = (props: Props) => {
  const isAuthenticated: boolean = useAppSelector(getAuthenticated);
  const { ...other } = props;
  if (isAuthenticated) {
    return <Route {...other} />;
  } else {
    return <Redirect to={ROUTE_AUTHENTICATION} />;
  }
};
