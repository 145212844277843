import { Box, Button, ButtonProps, Grid, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

interface Props extends ButtonProps {
  topLabel: string;
  bottomLabel?: string;
  route?: string;
  active?: boolean;
  orange?: boolean;
}

export const HeaderMobileButton: FC<Props> = (props: Props) => {
  const { topLabel, bottomLabel, active, orange, children, route, href, ...other } = props;
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const history = useHistory();

  const handleNavigation = (): void => {
    if (route) {
      history.push(route);
    } else if (href) {
      window.open(href, '_blank');
    }
  };

  return (
    <Button
      variant={'contained'}
      color={active ? 'primary' : 'secondary'}
      sx={(theme: Theme) => ({
        border: orange ? `2px solid ${theme.colors.orange2}` : 'none',
        borderRadius: theme.spacing(1.5),
        width: '100%',
        height: '100%',
        padding: isMobile ? theme.spacing(1, 1.5, 1.5, 2) : theme.spacing('16px', 0, 0, '24px'),
        textAlign: 'left',
        '& .children': {
          color: orange ? theme.colors.orange2 : 'primary',
        },
        '&:hover': {
          color: orange ? 'white' : 'primary',
          backgroundColor: orange ? theme.colors.orange2 : theme.colors.darkGrayBackground,
          '& .children': {
            color: 'white',
          },
        },
        '@media (hover: none)': {
          color: `${active ? 'white' : theme.colors.textColor} !important`,
          backgroundColor: `${active ? 'primary' : theme.colors.grayBackground} !important`,
          '& .children': {
            color: `${orange ? theme.colors.orange2 : 'primary'} !important`,
          },
        },
      })}
      onClick={handleNavigation}
      {...other}>
      <Box position={'relative'} width={'100%'} height={'100%'}>
        <Grid container={true} direction={'column'} height={'100%'}>
          <Grid item={true}>
            <Typography variant={isMobile ? 'h4' : 'h1'}>{topLabel}</Typography>
          </Grid>
          <Grid item={true} xs={true}>
            <Grid
              container={true}
              direction={'column'}
              justifyContent={'space-between'}
              spacing={1}
              height={'calc(100% + 8px)'}>
              <Grid item={true}>
                <Typography variant={isMobile ? 'h4' : 'h1'}>{bottomLabel}</Typography>
              </Grid>
              <Grid
                item={true}
                display={'flex'}
                flex={1}
                alignSelf={'end'}
                alignItems={'end'}
                justifyContent={'end'}
                className={'children'}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Button>
  );
};
