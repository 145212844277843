import * as Yup from 'yup';
import { AppDispatch } from '@/app/store';
import { BalanceState } from '@/types/BalanceState';
import { ButtonLink } from '@/components/common/ButtonLink';
import { CheckIcon } from '@/app/icons/CheckIcon';
import { EyeCrossedIcon } from '@/app/icons/EyeCrossedIcon';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { FORBIDDEN, UNAUTHORIZED } from '@/constants/HttpStatus';
import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';
import { Grid, IconButton, InputAdornment, Stack, Typography, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import {
  MESSAGE_INVALID_CREDENTIALS,
  MESSAGE_REQUIRED,
  MESSAGE_UNKNOWN,
  MESSAGE_USER_DISABLED,
} from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import {
  ROUTE_HOME,
  ROUTE_INBOX,
  ROUTE_REGISTRATION,
  ROUTE_RESTORE,
  getRegistrationConfirmRequestRoute,
} from '@/app/routes';
import { SignInDTO } from '@/types/auth/SignInDTO';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { emailSchema } from '@/validation/emailSchema';
import { getCurrentUser, signIn, signOut } from '@/services/authSlice';
import { getNewEmail, setNewEmail } from '@/services/profileSlice';
import { isBlank } from '@/utils/string-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { useSignInMutation } from '@/services/api/authApiSlice';
import React, { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

interface FormValues {
  email?: string;
  password?: string;
}

export const AuthenticationForm: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const isNewEmailConfirm: boolean = useAppSelector(getNewEmail);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const dispatch: AppDispatch = useAppDispatch();
  const history = useHistory();
  const { state } = useLocation<BalanceState>();
  const [login, { isLoading }] = useSignInMutation();
  const initialValues: FormValues = {
    email: '',
    password: '',
  };
  const initialErrors: FormikErrors<FormValues> = {
    email: isBlank(initialValues.email) ? MESSAGE_REQUIRED : undefined,
    password: isBlank(initialValues.password) ? MESSAGE_REQUIRED : undefined,
  };
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    email: emailSchema.required(MESSAGE_REQUIRED).nullable(),
    password: Yup.string().required(MESSAGE_REQUIRED).nullable(),
  });
  useEffect(() => {
    if (authUser) {
      history.replace(state && state.redirectRoute ? state.redirectRoute : ROUTE_INBOX);
    }
  }, [authUser, history, state]);
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const email: string | null = params.get('email');
    const password: string | null = params.get('password');
    if (email && password) {
      initialValues.password = password;
      initialValues.email = email;
    }
  }, [initialValues]);

  const handleShowPasswordClick = (): void => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>): Promise<void> => {
    const dto: SignInDTO = {
      username: values.email || '',
      password: values.password || '',
    };
    await login(dto)
      .unwrap()
      .then((user: UserDTO) => {
        dispatch(signIn(user));
        dispatch(setNewEmail(false));
        //проверяем пришел ли пользователь с оплаты
        history.replace(state && state.redirectRoute ? state.redirectRoute : ROUTE_HOME);
      })
      .catch((e: { status: number }) => {
        const { setFieldError } = helpers;
        if (e.status === UNAUTHORIZED) {
          setFieldError('email', '');
          setFieldError('password', MESSAGE_INVALID_CREDENTIALS);
        } else if (e.status === FORBIDDEN) {
          setFieldError('email', '');
          setFieldError('password', MESSAGE_USER_DISABLED);
          if (values.email) {
            history.push(getRegistrationConfirmRequestRoute(values.email));
          }
        } else {
          setFieldError('email', '');
          setFieldError('password', MESSAGE_UNKNOWN);
        }
        setError(true);
        dispatch(signOut());
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {({ values, touched, errors, setFieldValue, setFieldTouched, submitForm }: FormikProps<FormValues>) => {
        const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>) => {
          setFieldTouched(e.target.name, true, false);
          setFieldValue(e.target.name, e.target.value, true);
          setError(false);
        };
        return (
          <Grid container={true} direction={'column'} alignItems={'center'} spacing={5}>
            {isNewEmailConfirm ? (
              <Grid item={true}>
                <Grid container={true} direction={'column'} alignItems={'center'} spacing={1}>
                  <Grid item={true}>
                    <CheckIcon
                      sx={{
                        width: (theme: Theme) => theme.spacing(4),
                        height: (theme: Theme) => theme.spacing(4),
                        display: 'block',
                        color: (theme: Theme) => theme.colors.green,
                      }}
                    />
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'h1'} align={'center'}>
                      {'Адрес электронной почты обновлён!'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'h1'} align={'center'}>
                      {'Используйте новый адрес и новый пароль для входа:'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item={true}>
                <Grid container={true} direction={'column'} alignItems={'center'} spacing={isMobile ? 0.5 : 2}>
                  <Grid item={true}>
                    <Typography
                      variant={'h1'}
                      sx={(theme: Theme) => ({
                        lineHeight: '20px',
                        [theme.breakpoints.down('sm')]: {
                          lineHeight: '32px',
                        },
                      })}>
                      {'Вход в Личный Кабинет'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Stack direction={'column'} alignItems={'center'}>
                      <Typography variant={'body2'}>{'Еще нет личного кабинета?'}</Typography>
                      <ButtonLink color={'primary'} to={ROUTE_REGISTRATION} state={state} noHover={true}>
                        <Typography variant={'body2'} fontWeight={500}>
                          {'Зарегистрироваться'}
                        </Typography>
                      </ButtonLink>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item={true} width={'100%'}>
              <Grid container={true} direction={'column'} spacing={3}>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    fullWidth={true}
                    name={'email'}
                    label={'Электронная почта'}
                    withLabel={true}
                    required={true}
                    value={values.email}
                    error={error || (touched.email && Boolean(errors.email))}
                    helperText={touched.email ? errors.email : ''}
                    onChange={handleSetFieldValue}
                  />
                </Grid>
                <Grid item={true} width={'100%'}>
                  <OutlinedField
                    fullWidth={true}
                    name={'password'}
                    label={'Пароль'}
                    withLabel={true}
                    required={true}
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    error={error || (touched.password && Boolean(errors.password))}
                    helperText={touched.password ? errors.password : ''}
                    endAdornment={
                      <InputAdornment position={'end'}>
                        <IconButton
                          aria-label={'toggle password visibility'}
                          size={'dense'}
                          edge={'end'}
                          sx={{ padding: 0, margin: 0 }}
                          onClick={handleShowPasswordClick}
                          onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                          }}>
                          {showPassword ? (
                            <EyeIcon
                              sx={{
                                width: (theme: Theme) => theme.spacing(4),
                                height: (theme: Theme) => theme.spacing(4),
                              }}
                            />
                          ) : (
                            <EyeCrossedIcon
                              sx={{
                                width: (theme: Theme) => theme.spacing(4),
                                height: (theme: Theme) => theme.spacing(4),
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    onChange={handleSetFieldValue}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} width={'100%'}>
              <Grid container={true} direction={'column'} alignItems={'center'} spacing={3}>
                <Grid item={true} width={'100%'}>
                  <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    fullWidth={true}
                    type={'submit'}
                    onClick={submitForm}
                    isLoading={isLoading}
                    disabled={isLoading || !values.email || !values.password}>
                    {'Войти'}
                  </LoadingButton>
                </Grid>
                <Grid item={true}>
                  <ButtonLink color={'primary'} to={ROUTE_RESTORE}>
                    <Typography variant={'body2'}>{'Восстановить пароль'}</Typography>
                  </ButtonLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
