import React, { FC, useEffect } from 'react';

interface Props {
  readyIndicator?: any | undefined;
}

export const SnapReady: FC<Props> = (props: Props) => {
  const { readyIndicator } = props;

  useEffect(() => {
    (window as any).snapWaitForSelector = 'div.snap-ready';
  }, []);

  return <div style={{ display: 'none' }} className={readyIndicator ? 'snap-ready' : 'snap-loading'} />;
};
