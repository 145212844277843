import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const LettersIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 123 96'} sx={{ width: '123px', height: '96px' }} {...props}>
    <mask
      id={'mask0_6570_98360'}
      // style={'mask-type:alpha'}
      maskUnits={'userSpaceOnUse'}
      x={'57'}
      y={'9'}
      width={'60'}
      height={'58'}>
      <path
        d={
          'M63.2353 24.5757C64.2452 20.8066 67.0246 17.7639 70.6872 16.418L88.2198 9.9749C92.9806 8.22535 98.3273 9.65798 101.575 13.5535L113.538 27.8997C116.037 30.8966 116.922 34.9214 115.912 38.6904L110.783 57.8346C109.067 64.2362 102.487 68.0352 96.0858 66.3199L66.5909 58.4167C60.1893 56.7014 56.3903 50.1214 58.1056 43.7198L63.2353 24.5757Z'
        }
        fill={'#c4c4c4'}
      />
    </mask>
    <g mask={'url(#mask0_6570_98360)'}>
      <path
        d={
          'M63.3862 24.0136C63.7228 22.7572 64.6493 21.743 65.8702 21.2943L87.297 13.4202C92.0578 11.6707 97.4044 13.1033 100.653 16.9989L115.272 34.5315C116.105 35.5304 116.4 36.872 116.063 38.1284L107.677 69.426L55 55.3113L63.3862 24.0136Z'
        }
        fill={'#e5e4eb'}
      />
      <path
        d={
          'M58.1054 43.7207L63.3073 24.3071L107.677 69.4266L66.5907 58.4177C60.1891 56.7024 56.3901 50.1223 58.1054 43.7207Z'
        }
        fill={'#f3f2f7'}
      />
      <path
        d={
          'M110.783 57.8331L115.985 38.4195L54.9999 55.3094L96.0859 66.3184C102.487 68.0337 109.068 64.2347 110.783 57.8331Z'
        }
        fill={'#f3f2f7'}
      />
    </g>
    <mask
      id={'mask1_6570_98360'}
      // style={'mask-type:alpha'}
      maskUnits={'userSpaceOnUse'}
      x={'6'}
      y={'20'}
      width={'74'}
      height={'74'}>
      <path
        d={
          'M6.49274 55.2615C5.65727 51.45 6.72719 47.4702 9.36128 44.5914L27.6906 24.5595C31.1146 20.8175 36.5214 19.6324 41.1967 21.5991L66.2247 32.1273C69.8214 33.6403 72.4579 36.8076 73.2934 40.6191L79.6058 69.4172C81.0248 75.8909 76.9271 82.2893 70.4534 83.7083L27.0962 93.212C20.6225 94.631 14.2241 90.5333 12.8051 84.0596L6.49274 55.2615Z'
        }
        fill={'#c4c4c4'}
      />
    </mask>
    <g mask={'url(#mask1_6570_98360)'}>
      <path
        d={
          'M6.56196 55.5761C6.28347 54.3056 6.64011 52.979 7.51814 52.0194L28.6481 28.9267C32.0721 25.1847 37.479 23.9996 42.1543 25.9663L71.0064 38.1031C72.2053 38.6075 73.0841 39.6632 73.3626 40.9338L82.1751 81.1375L15.3744 95.7799L6.56196 55.5761Z'
        }
        fill={'#e5e4eb'}
      />
      <path
        d={
          'M12.8054 84.0586L6.75649 56.4627L82.1753 81.1379L27.0964 93.2109C20.6227 94.6299 14.2244 90.5323 12.8054 84.0586Z'
        }
        fill={'#f3f2f7'}
      />
      <path
        d={
          'M79.6059 69.4145L73.557 41.8186L15.3746 95.7785L70.4535 83.7055C76.9272 82.2865 81.0249 75.8882 79.6059 69.4145Z'
        }
        fill={'#f3f2f7'}
      />
    </g>
  </SvgIcon>
);
