import { Box, Grid, Typography } from '@mui/material';
import { LettersIcon } from '@/app/icons/LettersIcon';
import { LockPersonIcon } from '@/app/icons/LockPersonIcon';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

export const RegistrationBanner: FC = () => {
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      position={'relative'}
      width={'100%'}
      height={'min-content'}
      borderRadius={'12px'}
      maxWidth={(theme: Theme) => theme.spacing(80)}
      padding={(theme: Theme) => theme.spacing(1.5, 4)}
      border={(theme: Theme) => `1px solid ${theme.colors.grayBackground}`}>
      <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
        <Grid item={true} flex={2} marginY={1.5}>
          <Grid container={true} direction={'row'} wrap={'nowrap'} spacing={2}>
            <Grid item={true}>
              <Box
                width={32}
                height={32}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                borderRadius={'50%'}
                color={'white'}
                sx={{
                  backgroundColor: (theme: Theme) => theme.colors.primary,
                }}>
                <LockPersonIcon />
              </Box>
            </Grid>
            <Grid item={true}>
              <Typography variant={'h4'} marginTop={0.5} marginBottom={0.5} color={'primary'}>
                {'Ваш личный кабинет'}
              </Typography>
              <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                {'Зарегистрируйтесь, чтобы сохранять историю переписок и отправлять письма дешевле'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} flex={1} display={'flex'} justifyContent={'end'}>
          <LettersIcon />
        </Grid>
      </Grid>
    </Box>
  );
};
