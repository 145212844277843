import * as Yup from 'yup';
import { ChangeDepartmentDTO } from '@/types/letter/ChangeDepartmentDTO';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { Divider, Grid, MenuItem, Theme, Typography } from '@mui/material';
import { ErrorDTO } from '@/types/ErrorDTO';
import { Form, Formik, FormikProps, getIn } from 'formik';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { LetterStatusDTO } from '@/types/letter/LetterStatusDTO';
import { MESSAGE_FETCH_ERROR } from '@/utils/validation-utils';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { OutlinedSelect } from '@/components/common/field/OutlinedSelect';
import { PageHeader } from '@/components/common/PageHeader';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import { ROUTE_HELP_FAQ, ROUTE_PROFILE } from '@/app/routes';
import { RegionDTO } from '@/types/RegionDTO';
import { RegionSelectField } from '@/components/common/field/RegionSelectField';
import { RootState } from '@/app/store';
import { TypographyLink } from '@/components/common/TypographyLink';
import { departmentDTOSchema } from '@/validation/departmentDTOSchema';
import { emailSchema } from '@/validation/emailSchema';
import { formatTimeDateStringWithoutOffset } from '@/utils/date-utils';
import { letterNumberSchema } from '@/validation/letterNumberSchema';
import { regionDTOSchema } from '@/validation/regionDTOSchema';
import { setLetterInfo, setRegionAndDepartment, setStatus } from '@/services/departmentRedirectFormSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useChangeDepartmentMutation } from '@/services/api/letterApiSlice';
import { useGetRegionsQuery } from '@/services/api/createFormApiSlice';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, Fragment, useCallback, useEffect, useState } from 'react';

interface FormValues {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
  letterNumber: number | undefined;
  email: string;
}

const validationSchema = Yup.object({
  region: regionDTOSchema,
  department: departmentDTOSchema,
  email: emailSchema,
  letterNumber: letterNumberSchema,
});

const DepartmentChangeForm: FC<CreateFormProps> = (props: CreateFormProps) => {
  const { tittle, isMobile } = props;
  const { enqueueSnackbar } = useSnackbar();
  const departmentRedirectFormReducer = useAppSelector((state: RootState) => {
    return state.departmentRedirectForm;
  });
  const { data: regions, isFetching } = useGetRegionsQuery(null, { refetchOnMountOrArgChange: true });
  const initialValues: FormValues = {
    region: departmentRedirectFormReducer.region,
    department: departmentRedirectFormReducer.department,
    letterNumber: departmentRedirectFormReducer.letterNumber,
    email: departmentRedirectFormReducer.email || '',
  };
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [changeDepartment, { isLoading: isDepartmentChanging }] = useChangeDepartmentMutation();
  const resultDTO = departmentRedirectFormReducer.status;
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    return await executeRecaptcha('checkLetterStatusAction');
  }, [executeRecaptcha]);

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const handleSubmit = async (values: FormValues): Promise<void> => {
    handleReCaptchaVerify().then(async (token) => {
      dispatch(setStatus({ status: null }));
      dispatch(
        setRegionAndDepartment({
          region: values.region,
          department: values.department,
        })
      );
      dispatch(
        setLetterInfo({
          email: values.email,
          letterNumber: values.letterNumber,
        })
      );
      const dto: ChangeDepartmentDTO = {
        letterNumber: values.letterNumber,
        email: values.email,
        departmentId: values.department?.id,
      };
      await changeDepartment({ dto: dto, reCaptchaToken: token })
        .unwrap()
        .then((result: LetterStatusDTO) => {
          dispatch(
            setStatus({
              status: result,
            })
          );
        })
        .catch((e: { status: number; data: ErrorDTO }) => {
          enqueueSnackbar(e.data?.message ? e.data.message : MESSAGE_FETCH_ERROR, { variant: 'error' });
        });
    });
  };
  const handleProfileClick = () => {
    history.push(ROUTE_PROFILE);
  };
  const handleFaqClick = () => {
    history.push(ROUTE_HELP_FAQ + '?scroll=6');
  };
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);
    const departmentId: string | null = params.get('departmentId');
    if (regions && departmentId) {
      const region: RegionDTO | undefined = regions?.find((region: RegionDTO) => {
        return region?.departments?.some((department: DepartmentDTO) => {
          return department.id === parseInt(departmentId);
        });
      });
      const department: DepartmentDTO | undefined = region?.departments?.find((department: DepartmentDTO) => {
        return department.id === parseInt(departmentId);
      });
      if (region && department) {
        dispatch(
          setRegionAndDepartment({
            region: region,
            department: department,
          })
        );
      }
    }
    dispatch(
      setStatus({
        status: null,
      })
    );
  }, [dispatch, history, isFetching, location.search, regions]);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched, isValid } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        return (
          <Form>
            <Grid
              container={true}
              direction={'column'}
              sx={{
                paddingBottom: (theme: Theme) => (isMobile ? theme.spacing(6) : theme.spacing(0)),
              }}>
              <Grid item={true} marginBottom={'40px'}>
                <PageHeader tittle={tittle} isMobile={isMobile} />
              </Grid>
              <Grid item={true} marginBottom={'40px'}>
                <Typography variant={'h2'}>{'Данные о письме'}</Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={4}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Номер письма'}
                      name={'letterNumber'}
                      value={values.letterNumber}
                      disabled={isDepartmentChanging}
                      error={touched.letterNumber && Boolean(errors.letterNumber)}
                      helperText={touched.letterNumber ? errors.letterNumber : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={4}>
                    <OutlinedField
                      fullWidth={true}
                      label={'Электронная почта отправителя'}
                      name={'email'}
                      value={values.email}
                      disabled={isDepartmentChanging}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email ? errors.email : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSetFieldValue(e.target.name, e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} marginBottom={'40px'} marginTop={'40px'}>
                <Typography variant={'h2'}>{'Куда перенаправить'}</Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={3}>
                  <Grid item={true} xs={4}>
                    <RegionSelectField
                      regions={regions || []}
                      region={values.region}
                      isLoading={isFetching}
                      disabled={isDepartmentChanging}
                      error={touched.region && Boolean(errors.region)}
                      helperText={
                        touched.region && Boolean(errors.region)
                          ? getIn(errors.region, 'departments')
                            ? getIn(errors.region, 'departments')
                            : errors.region
                          : ''
                      }
                      onChange={(value: RegionDTO | null) => {
                        handleSetFieldValue('region', value);
                        if (value === null) {
                          setFieldValue('department', null);
                          setFieldTouched('department', false);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <OutlinedSelect
                      size={'medium'}
                      displayEmpty={true}
                      onChange={(event) => {
                        const selectDepartment = values.region?.departments.find(
                          (department) => department.id === (event.target.value as number)
                        );
                        handleSetFieldValue('department', selectDepartment);
                      }}
                      value={values.department ? values.department.id : ''}
                      disabled={Boolean(errors.region) || !values.region || isDepartmentChanging}
                      label={'Выберите учреждение'}
                      MenuProps={{ PaperProps: { sx: { marginTop: '3px' } } }}
                      helperText={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                          ? errors.department
                          : ''
                      }
                      error={
                        touched.department && Boolean(errors.department) && !(Boolean(errors.region) || !values.region)
                      }>
                      {values.region?.departments &&
                        values.region.departments.map((value) => (
                          <MenuItem key={value.id} value={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                    </OutlinedSelect>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <PolicyLoadingButton
                      variant={'contained'}
                      color={'primary'}
                      size={'medium'}
                      fullWidth={true}
                      disabled={
                        !(isValid && values.department && values.email && values.letterNumber && !isDepartmentChanging)
                      }
                      isLoading={isDepartmentChanging}
                      policyChecked={policyChecked}
                      onPolicyChange={setPolicyChecked}
                      onClick={submitForm}>
                      {'Перенаправить'}
                    </PolicyLoadingButton>
                  </Grid>
                </Grid>
                {resultDTO && !resultDTO.error && (
                  <Fragment>
                    <Grid item={true} mt={4}>
                      <Divider />
                    </Grid>
                    <Grid item={true} mt={3}>
                      <Typography variant={'h1'}>{'Письмо № ' + resultDTO.letterNumber + ' перенаправлено'}</Typography>
                    </Grid>
                    <Grid item={true} mt={3}>
                      <Typography variant={'body1'}>
                        {resultDTO.message}
                        <br />
                        {'Следите за доставкой в '}
                        <TypographyLink onClick={handleProfileClick}>{'Личном кабинете'}</TypographyLink>
                        {' или в рассылке на электронную почту '}
                        <br />
                        <a href={'mailto:' + values?.email} target={'_blank'} rel={'noopener noreferrer'}>
                          <TypographyLink>{values?.email + '.'}</TypographyLink>
                        </a>
                      </Typography>
                    </Grid>
                  </Fragment>
                )}
                {resultDTO && resultDTO.error && (
                  <Fragment>
                    <Grid item={true} mt={4}>
                      <Divider />
                    </Grid>
                    <Grid item={true} mt={3}>
                      <Grid item={true} mt={3}>
                        <Typography variant={'h1'}>
                          {'Письмо № ' + resultDTO.letterNumber + ' не перенаправлено'}
                        </Typography>
                      </Grid>
                      <Grid item={true} mt={3}>
                        <LetterAlert error={!resultDTO.isSameDepartment} isWarn={resultDTO.isSameDepartment}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${resultDTO.message} ${
                                resultDTO.letterUpdatedAtDateTime
                                  ? formatTimeDateStringWithoutOffset(resultDTO.letterUpdatedAtDateTime)
                                  : ''
                              }`,
                            }}
                          />
                          {resultDTO.isSameDepartment && (
                            <Typography>
                              {'Подробнее об этом '}
                              <TypographyLink onClick={() => handleFaqClick()}>{'в разделе "Помощь".'}</TypographyLink>
                            </Typography>
                          )}
                        </LetterAlert>
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DepartmentChangeForm;
