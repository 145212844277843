import * as Yup from 'yup';
import { AuthorForm } from '@/components/create/AuthorForm';
import { CreateFormProps } from '@/types/create/CreateFormProps';
import { CreateFormValues } from '@/types/create/CreateFormValues';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, Theme, Typography } from '@mui/material';
import { LetterAlert } from '@/components/create/letter/LetterAlert';
import { MobileBar } from '@/components/home/MobileBar';
import { PageHeader } from '@/components/common/PageHeader';
import { RootState } from '@/app/store';
import { UserDTO } from '@/types/user/UserDTO';
import { emailSchema } from '@/validation/emailSchema';
import { formatUserFullName, removeExtraSpaces } from '@/utils/string-utils';
import { fullNameSchema } from '@/validation/fullNameSchema';
import { getAuthor, getSender, setAuthor } from '@/services/createFormSlice';
import { getCurrentUser } from '@/services/authSlice';
import { phoneFieldSchema } from '@/validation/phoneSchema';
import { useAppSelector } from '@/app/hooks';
import { useDispatch } from 'react-redux';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useHistory } from 'react-router-dom';
import React, { FC, useState } from 'react';

const validationSchema = Yup.object({
  authorFullName: fullNameSchema,
  authorPhoneNumber: phoneFieldSchema,
  authorEmail: emailSchema.oneOf([Yup.ref('authorConfirmEmail')], 'Поля не совпадают'),
  authorConfirmEmail: emailSchema.oneOf([Yup.ref('authorEmail')], 'Поля не совпадают'),
});

const CreateFormAuthorMobile: FC<CreateFormProps> = (props: CreateFormProps) => {
  const { nextFormPath, previousFormPath, previousFormLabel, errorRedirectPath, isMobile, mobileTittle } = props;

  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department = createFormReducer.department;
  const region = createFormReducer.region;
  const sender = useAppSelector(getSender);

  const history = useHistory();

  if (!(department && region && sender)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  const author = useAppSelector(getAuthor);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });

  const initialValues = {
    prisonerLastName: '',
    prisonerFirstName: '',
    prisonerMiddleName: '',
    prisonerBirthDate: '',
    authorFullName: author?.name || (user ? formatUserFullName(user) : ''),
    authorPhoneNumber: author?.phone || (user && user.phone ? user.phone : ''),
    authorEmail: author?.email || (user ? user.email : ''),
    authorConfirmEmail: author?.email || (user ? user.email : ''),
  };

  const dispatch = useDispatch();

  const handleSubmit = (values: CreateFormValues) => {
    dispatch(
      setAuthor({
        name: removeExtraSpaces(values.authorFullName),
        phone: values.authorPhoneNumber,
        email: values.authorEmail,
      })
    );
    if (nextFormPath) {
      history.push(nextFormPath);
    }
  };
  const [emailWarn, setEmailWarn] = useState<boolean>(false);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<CreateFormValues>) => {
        const { submitForm, setFieldValue, setFieldTouched, errors } = formikProps;

        const handleSetFieldValue = (field: string, value: unknown, isErrorField: boolean) => {
          //setFieldTouched(field, true, false);
          setFieldValue(field, value, isErrorField);
        };

        return (
          <Form>
            <Grid
              container={true}
              direction={'column'}
              sx={{
                paddingBottom: (theme: Theme) => (isMobile ? theme.spacing(6) : theme.spacing(0)),
              }}>
              <Grid item={true} mb={5}>
                <PageHeader
                  previousFormLabel={previousFormLabel}
                  previousFormPath={previousFormPath}
                  isMobile={isMobile}
                  mobileTittle={mobileTittle}
                />
              </Grid>
              <Grid item={true}>
                <AuthorForm
                  formikProps={formikProps}
                  handleSetFieldValue={handleSetFieldValue}
                  handleValidEmail={setEmailWarn}
                  isMobile={isMobile}
                />
              </Grid>
              {emailWarn && (
                <Grid item={true} mt={3}>
                  <LetterAlert>
                    <Typography variant={'body1'}>
                      {'Введённая почта не совпадает с указанной в настройках личного кабинета.'}
                    </Typography>
                  </LetterAlert>
                </Grid>
              )}
              <Grid item={true} marginTop={'32px'}>
                <MobileBar>
                  <FixedButton color={'primary'} variant={'contained'} onClick={submitForm}>
                    {'Продолжить'}
                  </FixedButton>
                </MobileBar>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateFormAuthorMobile;
