import { AtlasDepartmentDTO } from '@/types/atlas/AtlasDepartmentDTO';
import { AtlasRegionDTO } from '@/types/atlas/AtlasRegionDTO';
import { DepartmentButton } from '@/components/help/departments/DepartmentButton';
import { Grid } from '@mui/material';
import { ROUTE_HELP_REGIONS, getHelpDepartmentsRoute } from '@/app/routes';
import { RootState } from '@/app/store';
import { setAtlasRegion } from '@/services/helpSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useHistory, useParams } from 'react-router-dom';
import React, { FC } from 'react';

interface Params {
  regId: string;
}

interface Props {
  regions?: AtlasRegionDTO[];
}

export const Departments: FC<Props> = (props: Props) => {
  const { regions } = props;
  const { regId } = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  let region: AtlasRegionDTO | null = useAppSelector((state: RootState) => {
    return state.help.region;
  });
  if (!region && regions) {
    const currentRegion: AtlasRegionDTO | undefined = regions.find((region: AtlasRegionDTO) => {
      return region.id === parseInt(regId);
    });
    if (currentRegion) {
      region = currentRegion;
      dispatch(setAtlasRegion(currentRegion));
    } else {
      history.replace(ROUTE_HELP_REGIONS);
    }
  }
  const handleClickDepartment = (depId: number) => {
    if (region) {
      history.push(getHelpDepartmentsRoute(region.id, depId));
    }
  };
  return (
    <Grid container={true} direction={'row'} rowSpacing={1} columnSpacing={3}>
      {(region?.departments || []).map((department: AtlasDepartmentDTO) => (
        <Grid item={true} key={department.id} xs={12} sm={6}>
          <DepartmentButton
            region={region}
            department={department}
            onClick={() => handleClickDepartment(department.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};
