import { Box, Typography } from '@mui/material';
import { MainNavigationItem, MainNavigationItemProps } from '@/components/main/navigation/MainNavigationItem';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

interface Props extends MainNavigationItemProps {
  count?: number;
}

export const MainNavigationCountItem: FC<Props> = (props: Props) => {
  const { count, ...other } = props;
  return (
    <Box position={'relative'} display={'flex'}>
      <MainNavigationItem {...other} />
      {Boolean(count) && (
        <Box
          position={'absolute'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          borderRadius={'50%'}
          minWidth={'16px'}
          minHeight={'16px'}
          right={'-12px'}
          top={'-12px'}
          sx={{
            backgroundColor: (theme: Theme) => theme.colors.red,
          }}>
          <Typography variant={'label2'} color={'white'}>
            {(count || 0) > 99 ? 99 : count}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
