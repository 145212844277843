import { DepartmentDTO } from '@/types/DepartmentDTO';
import { LetterStatusDTO } from '@/types/letter/LetterStatusDTO';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';

export interface DepartmentRedirectFormState {
  region: RegionDTO | null;
  department: DepartmentDTO | null;
  letterNumber?: number;
  email?: string;
  status: LetterStatusDTO | null;
}

const initialState: DepartmentRedirectFormState = {
  region: null,
  department: null,
  letterNumber: undefined,
  email: undefined,
  status: null,
};

export const departmentRedirectFormSlice = createSlice({
  name: 'departmentRedirectForm',
  initialState,
  reducers: {
    setRegionAndDepartment(
      state: DepartmentRedirectFormState,
      action: PayloadAction<{
        region: RegionDTO | null;
        department: DepartmentDTO | null;
      }>
    ) {
      state.region = action.payload.region;
      state.department = action.payload.department;
    },
    setLetterInfo(
      state: DepartmentRedirectFormState,
      action: PayloadAction<{
        letterNumber?: number;
        email?: string;
      }>
    ) {
      state.letterNumber = action.payload.letterNumber;
      state.email = action.payload.email;
    },
    setStatus(
      state: DepartmentRedirectFormState,
      action: PayloadAction<{
        status: LetterStatusDTO | null;
      }>
    ) {
      state.status = action.payload.status;
    },
    clearFormState(state: DepartmentRedirectFormState) {
      state.region = null;
      state.department = null;
      state.letterNumber = undefined;
      state.email = undefined;
    },
  },
});

export const { setRegionAndDepartment, setLetterInfo, setStatus, clearFormState } = departmentRedirectFormSlice.actions;

export default departmentRedirectFormSlice.reducer;

export const getLetterNumber = (state: RootState): number | undefined => {
  return state.departmentRedirectForm.letterNumber;
};

export const getEmail = (state: RootState): string | undefined => {
  return state.departmentRedirectForm.email;
};

export const getStatus = (state: RootState): LetterStatusDTO | null => {
  return state.departmentRedirectForm.status;
};
