import { FeedbackBlock } from '@/components/help/FeedbackBlock';
import { Footer } from '@/components/main/footer/Footer';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import {
  ROUTE_HELP_DEPARTMENT,
  ROUTE_HELP_FAQ,
  ROUTE_HELP_FEEDBACK,
  ROUTE_HELP_FEEDBACK_NUMBER,
  ROUTE_HELP_REGIONS,
  ROUTE_HELP_REGIONS_ID,
  ROUTE_HELP_WORK,
} from '@/app/routes';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import DepartmentPage from '@/components/help/departments/DepartmentPage';
import FaqBlock from '@/components/help/FaqBlock';
import HelpMenu from '@/components/help/HelpMenu';
import React, { FC } from 'react';
import RegionsBlock from '@/components/help/RegionsBlock';
import WorkBlock from '@/components/help/WorkBlock';

const HelpPage: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const location = useLocation();
  return (
    <Grid container={true} direction={'column'} sx={{ minHeight: '100%' }}>
      {!isMobile && (
        <Grid item={true} marginBottom={'36px'}>
          <Typography variant={'h1'}>{'Помощь'}</Typography>
        </Grid>
      )}
      <Grid item={true} xs={true}>
        <Grid container={true} columnSpacing={isMobile ? 0 : 7}>
          {!isMobile && (
            <Grid item={true}>
              <HelpMenu />
            </Grid>
          )}
          <Grid item={true} xs={true}>
            <Switch>
              <Route path={ROUTE_HELP_WORK}>
                <WorkBlock />
              </Route>
              <Route path={ROUTE_HELP_FAQ}>
                <FaqBlock />
              </Route>
              <Route path={[ROUTE_HELP_REGIONS, ROUTE_HELP_REGIONS_ID]} exact={true}>
                <RegionsBlock />
              </Route>
              <Route path={ROUTE_HELP_DEPARTMENT}>
                <DepartmentPage />
              </Route>
              <Route exact={true} path={[ROUTE_HELP_FEEDBACK, ROUTE_HELP_FEEDBACK_NUMBER]}>
                <FeedbackBlock />
              </Route>
              <Redirect to={ROUTE_HELP_WORK} />
            </Switch>
          </Grid>
        </Grid>
      </Grid>
      {isMobile && !location.pathname.includes(ROUTE_HELP_FEEDBACK) && (
        <Grid item={true} mt={5}>
          <Footer isMobile={isMobile} />
        </Grid>
      )}
    </Grid>
  );
};

export default HelpPage;
