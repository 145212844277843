import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const AddPrimaryIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 16 16'} sx={{ width: '16px', height: '16px' }} {...props}>
    <path
      d={
        'M7.00012 15.0829C7.00565 15.5635 7.50006 16.0055 8.00276 15.9999C8.50547 15.9944 9.00012 15.5635 9.00565 15.0829L9.00565 8.9946L15.0829 9.00012C15.5746 8.9946 16.0055 8.50812 15.9999 8.00541C15.9944 7.50271 15.5801 7.00012 15.0829 7.00012L9.00012 7.00012L9.00568 0.917075C9.00568 0.419894 8.5055 -0.00547101 8.00279 5.32125e-05C7.50009 0.00557744 7.00568 0.425418 7.00015 0.917074L7.00565 7.00012H0.917072C0.436465 7.00565 -0.00547297 7.50271 5.12467e-05 8.00541C0.00557547 8.50812 0.436465 8.9946 0.911547 8.9946H7.00012V15.0829Z'
      }
      fill={'#6387E6'}
    />
  </SvgIcon>
);
