import { AnswerCountSelectorField } from '@/components/common/field/AnswerCountSelectorField';
import { CreateFormValues } from '@/types/create/CreateFormValues';
import { DesktopDatePicker, MobileDatePicker } from '@mui/lab';
import { FormikProps } from 'formik';
import { Grid, IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { maxBirthYear, minBirthYear } from '@/validation/yearSchema';
import { setYear } from 'date-fns';
import EventIcon from '@mui/icons-material/Event';
import React, { ChangeEvent, FC, Fragment, useRef } from 'react';

interface Props {
  formikProps: FormikProps<CreateFormValues>;
  handleSetFieldValue: (field: string, value: unknown, isErrorFields: boolean) => void;
  isMobile?: boolean;
  disabled?: boolean;
  fromCreateAnswer?: boolean;
}

export const SenderForm: FC<Props> = (props: Props) => {
  const { formikProps, handleSetFieldValue, isMobile, disabled, fromCreateAnswer } = props;
  const { values, errors } = formikProps;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickDate = (): void => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChangeAnswersCount = (answerCount: number): void => {
    const isErrorFields = Boolean(errors.answersCount);
    handleSetFieldValue('answersCount', answerCount, isErrorFields);
  };

  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 6 : 3}>
          <Grid item={true} xs={true}>
            <OutlinedField
              fullWidth={true}
              label={'Фамилия'}
              name={'lastName'}
              disabled={disabled}
              value={values.prisonerLastName ? String(values.prisonerLastName) : ''}
              error={Boolean(errors.prisonerLastName)}
              helperText={errors.prisonerLastName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('prisonerLastName', e.target.value, Boolean(errors.prisonerLastName));
              }}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <OutlinedField
              fullWidth={true}
              label={'Имя'}
              name={'firstName'}
              disabled={disabled}
              value={values.prisonerFirstName ? String(values.prisonerFirstName) : ''}
              error={Boolean(errors.prisonerFirstName)}
              helperText={errors.prisonerFirstName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('prisonerFirstName', e.target.value, Boolean(errors.prisonerFirstName));
              }}
            />
          </Grid>
          <Grid item={true} xs={true}>
            <OutlinedField
              fullWidth={true}
              label={'Отчество'}
              name={'middleName'}
              disabled={disabled}
              value={values.prisonerMiddleName ? String(values.prisonerMiddleName) : ''}
              error={Boolean(errors.prisonerMiddleName)}
              helperText={errors.prisonerMiddleName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleSetFieldValue('prisonerMiddleName', e.target.value, Boolean(errors.prisonerMiddleName));
              }}
            />
          </Grid>
          <Grid item={true} xs={true}>
            {isMobile ? (
              <MobileDatePicker
                inputRef={inputRef}
                views={['year']}
                label={'Год рождения'}
                disabled={disabled}
                DialogProps={{
                  PaperProps: {
                    sx: {
                      padding: '0px',
                      '& div div .MuiFormControl-root': {
                        marginTop: '12px',
                      },
                    },
                  },
                }}
                cancelText={'Отмена'}
                okText={'Принять'}
                maxDate={setYear(new Date(), maxBirthYear)}
                minDate={setYear(new Date(), minBirthYear)}
                value={values.prisonerBirthDate ? String(values.prisonerBirthDate) : ''}
                onChange={(date) => {
                  handleSetFieldValue('prisonerBirthDate', date?.getFullYear(), Boolean(errors.prisonerBirthDate));
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    fullWidth={true}
                    {...params}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    error={Boolean(errors.prisonerBirthDate)}
                    helperText={errors.prisonerBirthDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <IconButton onClick={() => handleClickDate()}>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            ) : (
              <DesktopDatePicker
                views={['year']}
                label={'Год рождения'}
                disabled={disabled}
                maxDate={setYear(new Date(), maxBirthYear)}
                minDate={setYear(new Date(), minBirthYear)}
                value={values.prisonerBirthDate ? String(values.prisonerBirthDate) : ''}
                onChange={(date) => {
                  handleSetFieldValue('prisonerBirthDate', date?.getFullYear(), Boolean(errors.prisonerBirthDate));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      shrink: false,
                    }}
                    error={Boolean(errors.prisonerBirthDate)}
                    FormHelperTextProps={{
                      sx: {
                        position: 'absolute',
                        transform: 'translate(0, 48px)',
                      },
                    }}
                    helperText={errors.prisonerBirthDate}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {fromCreateAnswer && (
        <Fragment>
          <Grid item={true}>
            <Grid container={true} direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 6 : 3}>
              <Grid item={true}>
                <AnswerCountSelectorField
                  disabled={disabled}
                  value={values.answersCount}
                  minValue={0}
                  maxValue={10}
                  step={1}
                  error={Boolean(errors.answersCount)}
                  helperText={errors.answersCount}
                  onValueChange={handleChangeAnswersCount}
                />
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
