import { CardCodeField } from '@/components/card/CardCodeField';
import { CardDTO } from '@/types/card/CardDTO';
import { Grid, Typography } from '@mui/material';
import { LoadingButton } from '@/components/common/button/LoadingButton';
import { Theme } from '@mui/material/styles';
import { formatCurrency } from '@/utils/string-utils';
import { useSnackbar } from 'notistack';
import { useTransferBalanceMutation } from '@/services/api/balanceApiSlice';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  card: CardDTO;
}

export const CardImportRow: FC<Props> = (props: Props) => {
  const { card } = props;
  const [transferBalance, { isLoading, isSuccess }] = useTransferBalanceMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [isValid, setValid] = useState<boolean>(false);
  const [code, setCode] = useState<string | undefined>(undefined);
  const handleTransfer = async (card: CardDTO): Promise<void> => {
    await transferBalance({ cardId: card.id, code: code || '' })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Баланс пополнен', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Ошибка пополнения баланса', { variant: 'success' });
      });
  };
  const isTransferAvailable: boolean = isValid && !isSuccess;
  return (
    <Grid container={true} direction={'column'} spacing={1}>
      {isSuccess || !(card.balance > 0) ? (
        <Grid item={true}>
          <Typography variant={'body1'} color={(theme: Theme) => theme.colors.grayText}>
            {`Деньги с карты № ${card.ourNumber} переведены.`}
          </Typography>
        </Grid>
      ) : (
        <Fragment>
          <Grid item={true}>
            <Typography variant={'h4'}>{`№ ${card.ourNumber}, Баланс ${formatCurrency(card.balance, 0)}`}</Typography>
          </Grid>
          <Grid item={true}>
            <Grid container={true} direction={'row'} rowSpacing={1} columnSpacing={2}>
              <Grid item={true} xs={12} sm={7}>
                <CardCodeField
                  cardNumber={card.ourNumber}
                  onSuccess={(code: string) => {
                    setValid(true);
                    setCode(code);
                  }}
                  onError={(code: string) => {
                    setValid(false);
                    setCode(code);
                  }}
                />
              </Grid>
              {isTransferAvailable && (
                <Grid item={true} xs={12} sm={5}>
                  <LoadingButton
                    isLoading={isLoading}
                    variant={'contained'}
                    color={'primary'}
                    fullWidth={true}
                    onClick={() => handleTransfer(card)}
                    sx={(theme: Theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        marginBottom: theme.spacing(2),
                      },
                    })}>
                    <Typography variant={'h4'} whiteSpace={'nowrap'}>
                      {`Перевести ${formatCurrency(card.balance, 0)}`}
                    </Typography>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};
