import * as Yup from 'yup';
import { Button, CircularProgress, Grid, Skeleton, Typography } from '@mui/material';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { emailSchema } from '@/validation/emailSchema';
import { formatPhoneNumber } from '@/utils/string-utils';
import React, { ChangeEvent, FC } from 'react';
import theme from '@/app/theme';

const useLetterInfoStyles = makeStyles(() =>
  createStyles({
    label: {
      color: theme.colors.grayText,
      paddingBottom: '4px',
    },
  })
);

const validationSchema = Yup.object({
  email: emailSchema.oneOf([Yup.ref('confirmEmail')], 'Поля не совпадают'),
  confirmEmail: emailSchema.oneOf([Yup.ref('email')], 'Поля не совпадают'),
});

interface FormValues {
  email?: string;
  confirmEmail?: string;
}

interface Props {
  isMobile: boolean;
  sender?: string;
  receiver?: string;
  phone?: string;
  email?: string;
  onEmailChange: (email: string) => void;
  isEmailConfirmed: boolean;
  isEmailLoading?: boolean;
  isLetterLoading?: boolean;
}

export const InitiativeLetterInfo: FC<Props> = (props: Props) => {
  const { sender, receiver, phone, email, isMobile, onEmailChange, isEmailConfirmed, isEmailLoading, isLetterLoading } =
    props;

  const classes = useLetterInfoStyles();

  const initialValues: FormValues = {
    email: email || '',
    confirmEmail: email || '',
  };

  const confirm = (values: FormValues): void => {
    if (values.email) {
      onEmailChange(values.email);
    }
  };

  return (
    <Grid container={true} direction={'column'} rowSpacing={2} wrap={'nowrap'} mb={isMobile ? 4 : 0}>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {'Отправитель'}
        </Typography>
        <Typography variant={'body1'}>{isLetterLoading ? <Skeleton width={'96px'} /> : sender}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {'Получатель'}
        </Typography>
        <Typography variant={'body1'}>{isLetterLoading ? <Skeleton width={'144px'} /> : receiver}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant={'body2'} className={classes.label}>
          {'Телефон'}
        </Typography>
        <Typography variant={'body1'}>
          {isLetterLoading ? <Skeleton width={'136px'} /> : formatPhoneNumber(phone)}
        </Typography>
      </Grid>
      {((email && isEmailConfirmed) || isLetterLoading) && (
        <Grid item={true}>
          <Typography variant={'body2'} className={classes.label}>
            {'Электронная почта'}
          </Typography>
          <Typography variant={'body1'}> {isLetterLoading ? <Skeleton width={'200px'} /> : email}</Typography>
        </Grid>
      )}
      {!isEmailConfirmed && !isLetterLoading && (
        <Grid item={true} sm={isMobile ? 12 : 11}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={true}
            onSubmit={confirm}>
            {({
              values,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              submitForm,
              isValid,
            }: FormikProps<FormValues>) => {
              const handleSetFieldValue = (e: ChangeEvent<HTMLInputElement>): void => {
                setFieldTouched(e.target.name, true, false);
                setFieldValue(e.target.name, e.target.value, true);
              };
              return (
                <Grid container={true} direction={'column'} spacing={2}>
                  <Grid item={true} width={'100%'}>
                    <OutlinedField
                      fullWidth={true}
                      name={'email'}
                      label={'Электронная почта'}
                      withLabel={true}
                      required={true}
                      value={values.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email ? errors.email : ''}
                      onChange={handleSetFieldValue}
                      isNotAbsoluteHelperText={true}
                    />
                  </Grid>
                  <Grid item={true} width={'100%'}>
                    <OutlinedField
                      fullWidth={true}
                      name={'confirmEmail'}
                      label={'Подтвердите электронную почту'}
                      withLabel={true}
                      required={true}
                      value={values.confirmEmail}
                      error={touched.confirmEmail && Boolean(errors.confirmEmail)}
                      helperText={touched.confirmEmail ? errors.confirmEmail : ''}
                      onChange={handleSetFieldValue}
                      isNotAbsoluteHelperText={true}
                    />
                  </Grid>
                  <Grid item={true} width={'100%'}>
                    <Typography variant={'body2'} color={(theme: Theme) => theme.colors.grayText}>
                      {'На указанную электронную почту придет квитанция об оплате'}
                    </Typography>
                  </Grid>
                  {isMobile ? (
                    <MobileBar>
                      <FixedButton
                        variant={'contained'}
                        disabled={!isValid || values.email === '' || values.confirmEmail === '' || isEmailLoading}
                        onClick={submitForm}>
                        <Grid container={true} justifyContent={'center'} sx={{ position: 'relative' }}>
                          {isEmailLoading && (
                            <Grid item={true} sx={{ position: 'absolute', left: 0 }}>
                              <CircularProgress sx={{ color: '#ffffff' }} size={20} />
                            </Grid>
                          )}
                          <Grid item={true}>{'Добавить почту'}</Grid>
                        </Grid>
                      </FixedButton>
                    </MobileBar>
                  ) : (
                    <Grid item={true} width={'100%'}>
                      <Button
                        variant={'contained'}
                        disabled={!isValid || values.email === '' || values.confirmEmail === '' || isEmailLoading}
                        onClick={submitForm}
                        style={{ width: isMobile ? '100%' : '60%' }}>
                        {'Добавить почту'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            }}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
};
