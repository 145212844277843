import { IconButton, IconButtonProps, Theme } from '@mui/material';
import { OutlineCloseIcon } from '@/app/icons/OutlineCloseIcon';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { FC } from 'react';

interface Props extends IconButtonProps {
  snackbarKey: SnackbarKey;
}

export const BannerCloseButton: FC<Props> = (props: Props) => {
  const { snackbarKey } = props;
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      onClick={() => {
        if (closeSnackbar) {
          closeSnackbar(snackbarKey);
        }
      }}>
      <OutlineCloseIcon
        sx={{
          width: (theme: Theme) => theme.spacing(3),
          height: (theme: Theme) => theme.spacing(3),
          display: 'block',
        }}
      />
    </IconButton>
  );
};
