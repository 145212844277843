import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_AUTHENTICATION } from '@/app/routes';
import { SuccessIcon } from '@/components/common/SuccessIcon';
import { Theme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

export const RestoreSuccessForm: FC = () => {
  const history = useHistory();
  return (
    <Grid container={true} direction={'column'} alignItems={'center'} spacing={4} mt={5} mb={8}>
      <Grid item={true}>
        <Grid container={true} direction={'column'} alignItems={'center'} spacing={2}>
          <Grid item={true}>
            <SuccessIcon />
          </Grid>
          <Grid item={true}>
            <Typography variant={'h2'} textAlign={'center'}>
              {'Ссылка для восстановления пароля отправлена на вашу электронную почту'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => history.replace(ROUTE_AUTHENTICATION)}
          sx={{ minWidth: (theme: Theme) => theme.spacing(33) }}>
          {'Войти'}
        </Button>
      </Grid>
    </Grid>
  );
};
