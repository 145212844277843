import * as Yup from 'yup';
import { DepartmentDTO } from '@/types/DepartmentDTO';

export const departmentDTOSchema: Yup.SchemaOf<DepartmentDTO> = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  letterCost: Yup.number().required(),
  photoCost: Yup.number().required(),
  unauthorizedLetterCost: Yup.number().nullable(),
  unauthorizedPhotoCost: Yup.number().nullable(),
  message: Yup.string().notRequired(),
  colony: Yup.boolean().notRequired(),
  allowSending: Yup.boolean().notRequired(),
  allowPhoto: Yup.boolean().notRequired(),
  allowSudoku: Yup.boolean().notRequired(),
  allowCrossword: Yup.boolean().notRequired(),
  allowNews: Yup.boolean().notRequired(),
  isWarnMessage: Yup.boolean().notRequired(),
}).typeError('Выберите учреждение');
