import { RootState } from '@/app/store';
import { config } from '@/app/config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const baseQuery = fetchBaseQuery({
  baseUrl: config.baseUrl,
  prepareHeaders: (headers: Headers, { getState }) => {
    const state: RootState = getState() as RootState;
    const token: string | undefined = state.auth.user?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQuery,
  tagTypes: [
    'Letters',
    'LettersCount',
    'Regions',
    'Balance',
    'BalanceHistory',
    'User',
    'Cards',
    'Senders',
    'LetterHistory',
    'SystemEvents',
    'LetterDrafts',
    'LetterDraftsCount',
  ],
  endpoints: () => ({}),
});
