import { DepartmentChangeFormDepartment } from '@/components/letter/department/DepartmentChangeFormDepartment';
import { ROUTE_REDIRECT_DEPARTMENT, ROUTE_REDIRECT_LETTER, ROUTE_REDIRECT_SUCCESS } from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import DepartmentChangeForm from '@/components/letter/department/DepartmentChangeForm';
import DepartmentChangeFormLetter from '@/components/letter/department/DepartmentChangeFormLetter';
import React, { FC } from 'react';

export const DepartmentChangeBlock: FC = () => {
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  return (
    <Switch>
      <Route exact={true} path={ROUTE_REDIRECT_LETTER}>
        {isMobile ? (
          <DepartmentChangeFormLetter
            mobileTittle={'1/2: Введите данные о письме'}
            nextFormPath={ROUTE_REDIRECT_DEPARTMENT}
            errorRedirectPath={ROUTE_REDIRECT_LETTER}
            isMobile={isMobile}
          />
        ) : (
          <DepartmentChangeForm
            tittle={'Перенаправить письмо в другое учреждение'}
            errorRedirectPath={ROUTE_REDIRECT_LETTER}
            isMobile={isMobile}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_REDIRECT_DEPARTMENT}>
        {isMobile ? (
          <DepartmentChangeFormDepartment
            mobileTittle={'2/2: Выберите учреждение'}
            nextFormPath={ROUTE_REDIRECT_SUCCESS}
            previousFormPath={ROUTE_REDIRECT_LETTER}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_REDIRECT_LETTER}
            isMobile={isMobile}
          />
        ) : (
          <DepartmentChangeForm
            tittle={'Перенаправить письмо в другое учреждение'}
            errorRedirectPath={ROUTE_REDIRECT_LETTER}
            isMobile={isMobile}
          />
        )}
      </Route>
      <Redirect to={ROUTE_REDIRECT_LETTER} />
    </Switch>
  );
};
