import * as Yup from 'yup';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { Grid } from '@mui/material';
import { MobileBar } from '@/components/home/MobileBar';
import { OutlinedField } from '@/components/common/field/OutlinedField';
import { PolicyLoadingButton } from '@/components/common/button/PolicyLoadingButton';
import { PolicyStatementCheckBox } from '@/components/common/PolicyStatementCheckBox';
import { emailSchema } from '@/validation/emailSchema';
import { isBlank } from '@/utils/string-utils';
import { letterNumberSchema } from '@/validation/letterNumberSchema';
import React, { ChangeEvent, FC, Fragment, useState } from 'react';

interface FormValues {
  letterNumber: string;
  email: string;
}

const validationSchema = Yup.object({
  letterNumber: letterNumberSchema,
  email: emailSchema,
});

interface Props {
  isLoading: boolean;
  handleSubmitForm: (email: string, letterNumber: number) => void;
  isMobile: boolean;
}

export const CheckLetterStatusForm: FC<Props> = (props: Props) => {
  const { isLoading, handleSubmitForm, isMobile } = props;

  const initialValues: FormValues = {
    letterNumber: '',
    email: '',
  };

  const [policyChecked, setPolicyChecked] = useState<boolean>(false);

  const handleSubmit = (values: FormValues) => {
    handleSubmitForm(values.email, parseInt(values.letterNumber));
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormValues>) => {
        const { values, errors, touched, submitForm, setFieldValue, setFieldTouched } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown) => {
          setFieldTouched(field, true, false);
          setFieldValue(field, value, true);
        };
        const isDisabledButton =
          isBlank(values.email) ||
          (touched.email && Boolean(errors.email)) ||
          isBlank(values.letterNumber) ||
          (touched.letterNumber && Boolean(errors.letterNumber)) ||
          isLoading ||
          !policyChecked;
        return (
          <Grid
            container={true}
            spacing={isMobile ? 6 : 3}
            sx={{ height: isMobile ? '100%' : 'auto' }}
            direction={isMobile ? 'column' : 'row'}
            wrap={'nowrap'}>
            <Grid item={true} xs={!isMobile}>
              <OutlinedField
                fullWidth={true}
                label={'Номер письма'}
                name={'letterNumber'}
                value={values.letterNumber}
                error={touched.letterNumber && Boolean(errors.letterNumber)}
                disabled={isLoading}
                helperText={touched.letterNumber ? errors.letterNumber : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleSetFieldValue(e.target.name, e.target.value);
                }}
              />
            </Grid>
            <Grid item={true} xs={!isMobile}>
              <OutlinedField
                fullWidth={true}
                label={'Эл. адрес отправителя'}
                name={'email'}
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                disabled={isLoading}
                helperText={touched.email ? errors.email : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleSetFieldValue(e.target.name, e.target.value);
                }}
              />
            </Grid>
            <Grid item={true} xs={true} sx={{ flexGrow: 1 }}>
              {isMobile ? (
                <Fragment>
                  <Grid container={true} sx={{ height: '100%' }} alignItems={'flex-end'}>
                    <Grid item={true} xs={true} marginBottom={-3}>
                      <PolicyStatementCheckBox
                        checked={policyChecked}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPolicyChecked(e.target.checked)}
                      />
                    </Grid>
                  </Grid>
                  <MobileBar>
                    <FixedButton
                      variant={'contained'}
                      type={'submit'}
                      fullWidth={true}
                      onClick={submitForm}
                      disabled={isDisabledButton}>
                      {'Узнать статус'}
                    </FixedButton>
                  </MobileBar>
                </Fragment>
              ) : (
                <PolicyLoadingButton
                  variant={'contained'}
                  type={'submit'}
                  fullWidth={true}
                  disabled={isDisabledButton}
                  isLoading={isLoading}
                  policyChecked={policyChecked}
                  onPolicyChange={setPolicyChecked}
                  onClick={submitForm}>
                  {'Узнать статус'}
                </PolicyLoadingButton>
              )}
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};
