import { PaymentStatusInfo } from '@/components/create/unauthorized/PaymentStatusInfo';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  email?: string;
  message?: string;
  isInitiative?: boolean;
}

export const LetterPaymentSuccessInfo: FC<Props> = (props: Props) => {
  const { email, message, isInitiative } = props;

  return (
    <PaymentStatusInfo
      variant={'success'}
      title={isInitiative ? 'Инициативное письмо оплачено' : 'Письмо принято к доставке'}
      message={message}
      content={
        <Typography variant={'body1'} maxWidth={'285px'}>
          {'Уведомление об оплате вы получите на '}
          <Typography component={'span'} variant={'inherit'} fontWeight={500}>
            {email}
          </Typography>
        </Typography>
      }
    />
  );
};
