import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_CREATE_LETTER } from '@/app/routes';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import React, { FC } from 'react';

const useTittleInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      borderRadius: '6px',
      background: theme.colors.grayBackground,
      padding: '16px',
    },
  })
);

interface Props {
  region?: string;
  department?: string;
  prisonerFirstName?: string;
  prisonerMiddleName?: string;
  prisonerLastName?: string;
  isChanged?: boolean;
  isMobile?: boolean;
}

const TittleInfo: FC<Props> = (props: Props) => {
  const classes = useTittleInfoStyles();
  const { region, department, prisonerFirstName, prisonerMiddleName, prisonerLastName, isChanged, isMobile } = props;

  const history = useHistory();
  return (
    <Grid
      container={true}
      direction={'row'}
      className={classes.container}
      sx={{ color: (theme: Theme) => (isChanged ? theme.colors.primary : '') }}>
      <Grid container={true} alignItems={'center'} justifyContent={'space-between'} spacing={isMobile ? 1 : 0}>
        <Grid item={true}>
          <Grid container={true} spacing={isMobile ? 2 : 5}>
            <Grid item={true}>
              <Grid container={true} direction={'column'}>
                <Grid item={true} mb={0.5}>
                  <Typography
                    variant={'label1'}
                    sx={{
                      display: 'block',
                      textTransform: 'uppercase',
                      color: (theme: Theme) => theme.colors.grayText,
                    }}>
                    {'Учреждение'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant={'h4'} fontWeight={400}>{`${department}, ${region}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {prisonerFirstName && prisonerMiddleName && (
              <Grid item={true}>
                <Grid container={true} direction={'column'}>
                  <Grid item={true} mb={0.5}>
                    <Typography
                      variant={'label1'}
                      sx={{
                        display: 'block',
                        textTransform: 'uppercase',
                        color: (theme: Theme) => theme.colors.grayText,
                      }}>
                      {'Адресат'}
                    </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant={'h4'} fontWeight={400}>
                      {`${prisonerLastName} ${prisonerFirstName} ${prisonerMiddleName}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isChanged && (
          <Grid item={true}>
            <Button
              size={'small'}
              onClick={() => {
                history.push(ROUTE_CREATE_LETTER);
              }}>
              {'Изменить'}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TittleInfo;
