import { Button, ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React, { FC } from 'react';

type Props = ButtonProps;

export const ImageViewButton: FC<Props> = (props: Props) => {
  const { children, ...other } = props;
  return (
    <Button
      variant={'contained'}
      sx={(theme: Theme) => ({
        color: theme.palette.getContrastText('rgba(0, 0, 0, 0.8)'),
        width: theme.spacing(6),
        minWidth: 'auto',
        height: theme.spacing(6),
        minHeight: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
        '&:disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      })}
      {...other}>
      {children}
    </Button>
  );
};
