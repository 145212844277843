import { PasswordChangeResponseDTO } from '@/types/user/PasswordChangeResponseDTO';
import { PasswordRequestDTO } from '@/types/auth/PasswordRequestDTO';
import { PasswordRestoreDTO } from '@/types/user/PasswordRestoreDTO';
import { SignInDTO } from '@/types/auth/SignInDTO';
import { SignUpDTO } from '@/types/auth/SignUpDTO';
import { UserDTO } from '@/types/user/UserDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const attachFileApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation<UserDTO, SignInDTO>({
      query: (dto: SignInDTO) => ({
        url: '/auth/login',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['User'],
    }),
    signUp: build.mutation<UserDTO, SignUpDTO>({
      query: (dto: SignUpDTO) => ({
        url: '/users/register',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['User'],
    }),
    restorePasswordRequest: build.mutation<void, PasswordRequestDTO>({
      query: (dto: PasswordRequestDTO) => ({
        url: '/users/restore',
        method: 'POST',
        body: dto,
      }),
    }),
    restorePassword: build.mutation<PasswordChangeResponseDTO, PasswordRestoreDTO>({
      query: (dto: PasswordRestoreDTO) => ({
        url: '/users/restore/new',
        method: 'POST',
        body: dto,
      }),
    }),
    getCurrentUser: build.query<UserDTO, number | undefined>({
      query: (userId: number | undefined) => ({
        url: '/users/current',
        method: 'GET',
        params: {
          userId: userId,
        },
      }),
      providesTags: ['User'],
    }),
    confirmRegistration: build.mutation<void, string>({
      query: (activateToken: string) => ({
        url: '/users/register/confirm',
        method: 'GET',
        params: { activateToken: activateToken },
      }),
    }),
    requestConfirmationEmail: build.mutation<void, string>({
      query: (username: string) => ({
        url: '/users/register/confirm-request',
        method: 'GET',
        params: { username },
      }),
    }),
    confirmChangeEmail: build.mutation<void, string>({
      query: (activateToken: string) => ({
        url: '/users/change-email/confirm',
        method: 'GET',
        params: { activateToken: activateToken },
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useRestorePasswordRequestMutation,
  useRestorePasswordMutation,
  useGetCurrentUserQuery,
  useConfirmRegistrationMutation,
  useRequestConfirmationEmailMutation,
  useConfirmChangeEmailMutation,
} = attachFileApiSlice;
