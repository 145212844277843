import { CancelIcon } from '@/app/icons/CancelIcon';
import {
  Fade,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { Theme } from '@mui/material/styles';
import React, { CSSProperties, FC, MouseEvent } from 'react';

export interface OutlinedFieldProps extends OutlinedInputProps {
  size?: 'small' | 'medium';
  helperText?: string;
  onClear?: (e: MouseEvent<HTMLButtonElement>) => void;
  withLabel?: boolean;
  isNotAbsoluteHelperText?: boolean;
  inputProps?: InputBaseComponentProps;
  labelStyle?: CSSProperties;
}

export const OutlinedField: FC<OutlinedFieldProps> = (props: OutlinedFieldProps) => {
  const {
    size,
    onClear,
    label,
    error,
    helperText,
    withLabel,
    isNotAbsoluteHelperText,
    fullWidth,
    inputProps,
    labelStyle,
    ...other
  } = props;
  return (
    <FormControl
      variant={'outlined'}
      size={size}
      fullWidth={fullWidth}
      sx={(theme: Theme) => ({
        marginTop: theme.spacing(withLabel ? 3 : 0),
      })}>
      {label &&
        (props.placeholder ? (
          <FormLabel error={error} id={props.name + '-input-label'} style={{ ...labelStyle }}>
            {label}
          </FormLabel>
        ) : (
          <InputLabel error={error} id={props.name + '-input-label'} style={{ ...labelStyle }}>
            {label}
          </InputLabel>
        ))}
      <OutlinedInput
        sx={{
          paddingRight: (theme: Theme) => theme.spacing(props.endAdornment ? 1 : 0),
        }}
        inputProps={{
          style: {
            ...(!props.multiline && {
              textOverflow: 'ellipsis',
            }),
          },
          ...inputProps,
        }}
        endAdornment={
          onClear ? (
            <InputAdornment position={'end'}>
              <Fade in={Boolean(props.value)}>
                <IconButton
                  size={'dense'}
                  edge={'end'}
                  sx={{ padding: 0, margin: 0 }}
                  onClick={onClear}
                  onMouseDown={(e: MouseEvent<HTMLButtonElement>): void => {
                    e.preventDefault();
                  }}>
                  <CancelIcon
                    sx={{
                      width: (theme: Theme) => theme.spacing(4),
                      height: (theme: Theme) => theme.spacing(4),
                    }}
                  />
                </IconButton>
              </Fade>
            </InputAdornment>
          ) : null
        }
        error={error}
        {...other}
      />
      {helperText && (
        <FormHelperText
          error={error}
          id={props.name + '-input-helperText'}
          sx={
            isNotAbsoluteHelperText
              ? { position: 'inherit' }
              : {
                  position: 'absolute',
                  transform: size == 'small' ? 'translate(0, 40px)' : 'translate(0, 48px)',
                }
          }>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
