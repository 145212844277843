import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import { DepartmentChangeDialog } from '@/components/letter/DepartmentChangeDialog';
import { DownIcon } from '@/app/icons/DownIcon';
import { EventLogDTO } from '@/types/event/EventLogDTO';
import { LetterSimpleStatus } from '@/types/letter/LetterSimpleStatus';
import { StatusChip } from '@/components/common/StatusChip';
import { Theme } from '@mui/material/styles';
import { TypographyLink } from '@/components/common/TypographyLink';
import { formatSmartShortDateTimeString } from '@/utils/date-utils';
import { getHelpFeedbackRoute } from '@/app/routes';
import { getStatusLabel } from '@/utils/status-utils';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, SyntheticEvent, useEffect } from 'react';

interface Props {
  events?: EventLogDTO[];
}

export const LetterHistoryContent: FC<Props> = (props: Props) => {
  const { events } = props;
  const history = useHistory();
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleChange = (eventId: number) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? eventId : false);
  };
  useEffect(() => {
    setExpanded(events?.at(0)?.id || false);
  }, [events]);
  return (
    <Fragment>
      {events?.map((event: EventLogDTO, index: number) => {
        const isAnswerUploaded: boolean = event?.statuses?.includes(LetterSimpleStatus.ANSWER_UPLOADED);
        return (
          <Box
            key={event.id}
            sx={(theme: Theme) => ({
              position: 'relative',
              paddingLeft: theme.spacing(1.25),
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
              },
            })}>
            <Box
              sx={(theme: Theme) => ({
                position: 'absolute',
                top: theme.spacing(2.125),
                bottom: index < events?.length - 1 ? theme.spacing(-2.125) : 0,
                left: theme.spacing(1.625),
                width: theme.spacing(0.25),
                background: theme.colors.lightBlueColor,
                [theme.breakpoints.down('sm')]: {
                  left: theme.spacing(0.375),
                  display: index === events?.length - 1 ? 'none' : 'block',
                },
              })}
            />
            <Box
              sx={(theme: Theme) => ({
                position: 'absolute',
                top: theme.spacing(1.625),
                left: theme.spacing(1.25),
                width: theme.spacing(1),
                height: theme.spacing(1),
                borderRadius: '50%',
                background: expanded !== event.id ? 'white' : theme.colors.lightBlueColor,
                border: theme.spacing(0.25) + ' solid ' + theme.colors.lightBlueColor,
                [theme.breakpoints.down('sm')]: {
                  left: 0,
                },
              })}
            />
            <Accordion
              square={true}
              disableGutters={true}
              expanded={expanded === event.id}
              sx={{
                boxShadow: 'none',
                background: 'transparent',
                '&::before': {
                  display: 'none',
                },
              }}
              onChange={handleChange(event.id)}>
              <AccordionSummary
                expandIcon={
                  <DownIcon
                    sx={(theme: Theme) => ({
                      width: theme.spacing(3),
                      height: theme.spacing(3),
                      [theme.breakpoints.down('sm')]: {
                        width: theme.spacing(2),
                        height: theme.spacing(2),
                      },
                    })}
                  />
                }
                sx={{
                  alignItems: 'end',
                  '& .MuiAccordionSummary-content': {
                    margin: 0,
                  },
                  minHeight: 'auto',
                  padding: (theme: Theme) => theme.spacing(1, 2),
                }}>
                <Grid container={true} direction={'column'} spacing={expanded === event.id ? 0.25 : 0}>
                  <Grid item={true}>
                    <Typography variant={'body3'} component={'p'} color={(theme: Theme) => theme.colors.grayText}>
                      {formatSmartShortDateTimeString(event.timestamp)}
                    </Typography>
                  </Grid>
                  {expanded === event.id ? (
                    <Grid item={true}>
                      <Grid container={true} direction={'row'} spacing={0.5}>
                        {event.statuses.map((status: LetterSimpleStatus) => (
                          <Grid item={true} key={status}>
                            <StatusChip status={status} style={{ cursor: 'pointer' }} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item={true}>
                      <Typography
                        variant={'body3'}
                        component={'p'}
                        fontWeight={500}
                        color={(theme: Theme) => theme.colors.grayText}>
                        {event.statuses
                          .map((status: LetterSimpleStatus) => {
                            return getStatusLabel(status);
                          })
                          .join(', ')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container={true} direction={'column'} spacing={1.25}>
                  <Grid item={true}>
                    <Typography
                      variant={'body3'}
                      component={'p'}
                      dangerouslySetInnerHTML={{ __html: event.readableStatus }}
                      sx={(theme: Theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          lineHeight: '20px',
                        },
                        '& a': {
                          textDecoration: 'underline',
                          color: theme.colors.primary,
                          ':hover': {
                            color: theme.colors.primaryDark,
                          },
                        },
                      })}
                    />
                  </Grid>
                  {index === 0 && !!event?.letter?.departmentChangeAllowed && (
                    <Grid item={true}>
                      <Typography
                        variant={'body3'}
                        component={'div'}
                        sx={(theme: Theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            lineHeight: '20px',
                          },
                        })}>
                        {'Вы можете бесплатно '}
                        <TypographyLink onClick={() => setOpen(true)}>
                          {'переслать письмо в другое учреждение.'}
                        </TypographyLink>
                      </Typography>
                    </Grid>
                  )}
                  {isAnswerUploaded && (
                    <Grid item={true}>
                      <Typography
                        variant={'body3'}
                        component={'div'}
                        sx={(theme: Theme) => ({
                          [theme.breakpoints.down('sm')]: {
                            lineHeight: '20px',
                          },
                        })}>
                        {'Повторно получить ответ можно, обратившись в '}
                        <TypographyLink
                          onClick={() => {
                            history.push(getHelpFeedbackRoute(event?.letter?.number));
                          }}>
                          {'техническую поддержку'}
                        </TypographyLink>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        );
      })}
      <DepartmentChangeDialog
        open={open}
        email={events?.at(0)?.letter?.email}
        letterNumber={events?.at(0)?.letter?.number}
        sender={events?.at(0)?.letter?.sender}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Fragment>
  );
};
