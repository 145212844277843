import { AuthorDTO } from '@/types/AuthorDTO';
import { SenderDTO } from '@/types/SenderDTO';
import { isBlank } from '@/utils/string-utils';

export const MESSAGE_REQUIRED = 'Обязательное поле';
export const MESSAGE_INVALID_CARD_CODE = 'Необходимо ввести 4-цифры';
export const MESSAGE_INVALID_EMAIl = 'Некорректный адрес электронной почты';
export const MESSAGE_INVALID_CREDENTIALS = 'Неверный адрес электронной почты или пароль';
export const MESSAGE_USER_DISABLED = 'Адрес электронной почты не подтверждён';
export const MESSAGE_UNKNOWN = 'Неизвестная ошибка';
export const MESSAGE_FULL_NAME_REQUIRED = 'Укажите, как минимум, Фамилию и Имя';
export const MESSAGE_FULL_NAME_ALLOWED_CHARACTERS = 'Недопустимые символы';
export const MESSAGE_INVALID_LETTER_NUMBER = 'Номер должен состоять только из цифр';
export const MESSAGE_FETCH_ERROR = 'Ошибка соединения с сервером';
export const MESSAGE_INVALID_PHONE = 'Некорректный формат номера';
export const MESSAGE_INVALID_AMOUNT = 'Некорректная сумма';
export const MESSAGE_INVALID_ANSWERS_COUNT = 'Некорректное число ответов';
export const MESSAGE_PASSWORD_CAPITAL_LETTER = 'В пароле нет заглавных букв';
export const MESSAGE_PASSWORD_NUMBER = 'В пароле нет цифр';
export const MESSAGE_MIN_PASSWORD_LENGTH = 'Пароль меньше 8 символов';
export const MESSAGE_PASSWORD_NOT_EQUALS = 'Пароли не совпадают';
export const MESSAGE_WRONG_OLD_PASSWORD = 'Неверный текущий пароль';
export const MESSAGE_WRONG_PASSWORD = 'Неверный пароль';
export const MESSAGE_WRONG_EMAIL = 'Пользователь с таким е-мейлом уже зарегистрирован';
export const MESSAGE_EMAIL_NOT_EQUALS = 'Е-мейлы не совпадают';

export const allowedFullNameCharsRegExp = /^[А-яёЁa-zA-Z-\s]+$/g;

export const fullNameRegExp =
  /^\s*(?<lastName>[А-яёЁa-zA-Z-]+)\s+(?<firstName>[А-яёЁa-zA-Z-]+)(?<middleName>[А-яёЁa-zA-Z-\s]+)?\s*$/;

export const cardCodeRegExp = /^\d{4}$/;

export const passwordCapitalLetterRegExp = /.*[A-Z]+.*/g;

export const passwordNumberRegExp = /.*[0-9]+.*/g;

export const MAX_LETTER_COUNT = 21840;

export const validateAuthor = (author: AuthorDTO | null): boolean => {
  return !!author && !isBlank(author.name);
};

export const validateSender = (sender: SenderDTO | null): boolean => {
  return !!sender && !isBlank(sender.lastName) && !isBlank(sender.firstName);
};
