import { Grid, Skeleton, Theme, Typography, useMediaQuery } from '@mui/material';
import { PageHeader } from '@/components/common/PageHeader';
import { useGetInitiativeLetterQuery } from '@/services/api/letterApiSlice';
import { useParams } from 'react-router-dom';
import React, { FC, Fragment } from 'react';

interface Params {
  uuid: string;
}

export const InitiativeLetterPaymentSuccess: FC = () => {
  const { uuid } = useParams<Params>();

  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });

  const { data: letter, isLoading: isLetterLoading } = useGetInitiativeLetterQuery(uuid, { skip: !uuid });

  return (
    <Grid container={true} direction={'column'} spacing={2}>
      <Grid item={true}>
        <PageHeader
          tittle={
            <Fragment>
              <Grid container={true} direction={'row'} spacing={1}>
                <Grid item={true}>
                  <Typography variant={'inherit'} component={'span'}>
                    {'Оплата инициативного письма'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {'№'}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {isLetterLoading ? <Skeleton width={'136px'} /> : letter?.number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>{'принята!'}</Grid>
              </Grid>
            </Fragment>
          }
          isMobile={isMobile}
          mobileTittle={
            <Fragment>
              <Grid container={true} direction={'row'} spacing={1}>
                <Grid item={true}>
                  <Typography variant={'inherit'} component={'span'}>
                    {'Оплата инициативного письма'}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Grid container={true} direction={'row'} spacing={1} wrap={'nowrap'}>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {'№'}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography variant={'inherit'} component={'span'}>
                        {isLetterLoading ? <Skeleton width={'136px'} /> : letter?.number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true}>{'принята!'}</Grid>
              </Grid>
            </Fragment>
          }
        />
      </Grid>
      <Grid item={true} mb={3}>
        <Typography variant={'body1'}>
          {'Оплата зарегистрирована банком. Ожидайте уведомление об оплате письма на электронную почту '}
          <Typography fontWeight={500} component={'span'}>
            {isLetterLoading ? <Skeleton width={'200px'} /> : letter?.recipientEmail}
          </Typography>
          {'.'}
        </Typography>
      </Grid>
    </Grid>
  );
};
