import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { EyeIcon } from '@/app/icons/EyeIcon';
import { ImageViewDialog } from '@/components/view/image/ImageViewDialog';
import { Theme } from '@mui/material/styles';
import React, { FC, Fragment, useState } from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  content: string;
  isMobile?: boolean;
  isLoading?: boolean;
  isInitiative?: boolean;
}

export const ImagePreview: FC<Props> = (props: Props) => {
  const { title, subtitle, content, isMobile, isLoading, isInitiative } = props;
  const [hover, setHover] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Fragment>
      {isLoading ? (
        <Skeleton
          width={'100%'}
          height={isInitiative ? (isMobile ? '80px' : '100px') : isMobile ? '300px' : '400px'}
          variant={'rectangular'}
          sx={{ borderRadius: '12px' }}
        />
      ) : (
        <Box
          sx={{
            position: 'relative',
            height: isInitiative ? (isMobile ? '100px' : '200px') : isMobile ? '300px' : '400px',
            cursor: 'pointer',
            overflow: 'hidden',
            borderRadius: '12px',
            border: (theme: Theme) => `1px solid ${theme.colors.borderColor}`,
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => setOpen(true)}>
          <img
            src={`data:image/jpeg;base64,${content}`}
            alt={''}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'top',
              borderRadius: '12px',
            }}
          />
          {hover && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.3)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <EyeIcon
                sx={(theme: Theme) => ({
                  color: 'white',
                  width: theme.spacing(12.5),
                  height: theme.spacing(12.5),
                })}
              />
            </Box>
          )}
        </Box>
      )}
      <ImageViewDialog
        open={open}
        title={
          <Grid container={true} direction={'row'} alignItems={'center'} spacing={2}>
            <Grid item={true}>
              <Typography variant={'h1'} fontSize={'24px'} lineHeight={'20px'}>
                {title}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography
                variant={'label2'}
                fontSize={'12px'}
                lineHeight={'20px'}
                color={(theme: Theme) => theme.colors.grayText}>
                {subtitle}
              </Typography>
            </Grid>
          </Grid>
        }
        content={content}
        onClose={() => setOpen(false)}
      />
    </Fragment>
  );
};
