import { SvgIcon } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React, { FC } from 'react';

export const WalletIcon: FC<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox={'0 0 24 24'} sx={{ width: '24px', height: '24px' }} {...props}>
    <path
      d={
        'M13 14V10C13 8.9 13.89 8 15 8H21V5C21 3.9 20.1 3 19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V16H15C13.89 16 13 15.1 13 14ZM16 10C15.45 10 15 10.45 15 11V13C15 13.55 15.45 14 16 14H22V10H16Z'
      }
    />
  </SvgIcon>
);
