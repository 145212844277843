import { AnswerViewDTO } from '@/types/answer/AnswerViewDTO';
import { apiSlice } from '@/services/api/apiSlice';

export const answerApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAnswer: build.query<AnswerViewDTO, number>({
      query: (letterId: number) => ({
        url: '/answer/letter',
        method: 'get',
        params: {
          letterId: letterId,
        },
      }),
    }),
  }),
});

export const { useGetAnswerQuery } = answerApiSlice;
