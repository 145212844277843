import { AmountDTO } from '@/types/AmountDTO';
import { CardAmountButton } from '@/components/create/card/buttons/CardAmountButton';
import { CardAmountMobileButton } from '@/components/create/card/buttons/CardAmountMobileButton';
import { Grid } from '@mui/material';
import { LETTER_COST } from '@/utils/currency-utils';
import React, { FC } from 'react';

interface Props {
  amounts?: AmountDTO[];
  currentAmountValue: number;
  setAmountValue: (amount: number) => void;
  isMobile?: boolean;
}

export const CardAmountButtonsGroup: FC<Props> = (props: Props) => {
  const { amounts, currentAmountValue, setAmountValue, isMobile } = props;

  return (
    <Grid container={true} spacing={2}>
      {amounts?.map((amount: AmountDTO) => (
        <Grid item={true} key={amount.id}>
          {isMobile ? (
            <CardAmountMobileButton
              amount={amount.value}
              amountId={amount.id}
              countLetters={Math.floor(amount.value / LETTER_COST)}
              currentValue={currentAmountValue}
              setAmount={setAmountValue}
            />
          ) : (
            <CardAmountButton
              amount={amount.value}
              amountId={amount.id}
              countLetters={Math.floor(amount.value / LETTER_COST)}
              currentValue={currentAmountValue}
              setAmount={setAmountValue}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
