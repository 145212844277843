import * as Yup from 'yup';
import { Button, Grid, Theme } from '@mui/material';
import { CreateFormAnswerProps } from '@/types/create/CreateFormAnswerProps';
import { CreateFormValues } from '@/types/create/CreateFormValues';
import { FixedButton } from '@/components/common/button/FixedButton';
import { Formik, FormikProps } from 'formik';
import { MobileBar } from '@/components/home/MobileBar';
import { PageHeader } from '@/components/common/PageHeader';
import { RootState } from '@/app/store';
import { SenderForm } from '@/components/create/SenderForm';
import { UserDTO } from '@/types/user/UserDTO';
import { answersCountSchema } from '@/validation/answersCountSchema';
import { clearBalanceByLetterPageState } from '@/services/sbpSlice';
import { formatUserFullName, removeExtraSpaces } from '@/utils/string-utils';
import {
  getAnswersCount,
  getAuthor,
  getSender,
  setAnswerOrderedForm,
  setAnswersCountForm,
  setAuthor,
  setSender,
} from '@/services/createFormSlice';
import { getCurrentUser } from '@/services/authSlice';
import { prisonerMiddleNameSchema } from '@/validation/prisonerMiddleNameSchema';
import { prisonerNameSchema } from '@/validation/prisonerNameSchema';
import { useAppSelector } from '@/app/hooks';
import { useDispatch } from 'react-redux';
import { useGetCurrentUserQuery } from '@/services/api/authApiSlice';
import { useHistory } from 'react-router-dom';
import { yearSchema } from '@/validation/yearSchema';
import React, { ChangeEvent, FC } from 'react';
import TittleInfo from '@/components/create/TittleInfo';

const validationSchema = Yup.object({
  prisonerLastName: prisonerNameSchema,
  prisonerFirstName: prisonerNameSchema,
  prisonerMiddleName: prisonerMiddleNameSchema,
  prisonerBirthDate: yearSchema,
  answersCount: answersCountSchema,
});

const CreateFormSender: FC<CreateFormAnswerProps> = (props: CreateFormAnswerProps) => {
  const {
    tittle,
    nextFormPath,
    previousFormPath,
    previousFormLabel,
    errorRedirectPath,
    isMobile,
    mobileTittle,
    fromCreateAnswer,
  } = props;

  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department = createFormReducer.department;
  const region = createFormReducer.region;

  const history = useHistory();

  if (!(department && region)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  const sender = useAppSelector(getSender);
  const author = useAppSelector(getAuthor);
  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const { data: user } = useGetCurrentUserQuery(authUser?.id, {
    skip: !authUser,
  });
  const answersCount = useAppSelector(getAnswersCount);

  const initialValues = {
    prisonerLastName: sender?.lastName || '',
    prisonerFirstName: sender?.firstName || '',
    prisonerMiddleName: sender?.middleName || '',
    prisonerBirthDate: sender?.age.toString() || '',
    authorFullName: author?.name || (user ? formatUserFullName(user) : ''),
    authorPhoneNumber: author?.phone || (user && user.phone ? user.phone : ''),
    authorEmail: author?.email || (user ? user.email : ''),
    authorConfirmEmail: author?.email || (user ? user.email : ''),
    answersCount: answersCount,
  };

  const dispatch = useDispatch();

  const handleSubmit = (values: CreateFormValues) => {
    dispatch(
      setSender({
        lastName: removeExtraSpaces(values.prisonerLastName),
        firstName: removeExtraSpaces(values.prisonerFirstName),
        middleName: removeExtraSpaces(values.prisonerMiddleName),
        age: parseInt(String(values.prisonerBirthDate)),
      })
    );
    if (fromCreateAnswer) {
      dispatch(setAnswerOrderedForm(true));
      dispatch(setAnswersCountForm(values.answersCount ? values.answersCount : 1));
      dispatch(
        setAuthor({
          name: removeExtraSpaces(values.authorFullName),
          phone: values.authorPhoneNumber,
          email: values.authorEmail,
        })
      );
    }
    dispatch(clearBalanceByLetterPageState());
    if (nextFormPath) {
      history.push(nextFormPath);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      validateOnChange={true}
      onSubmit={handleSubmit}>
      {(formikProps: FormikProps<CreateFormValues>) => {
        const { submitForm, setFieldValue, setFieldTouched, errors } = formikProps;
        const handleSetFieldValue = (field: string, value: unknown, isErrorField: boolean) => {
          //setFieldTouched(field, true, false);
          setFieldValue(field, value, isErrorField);
        };
        const handleAnswersCountChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const value = parseInt(event.target.value);
          if (value) {
            //setFieldTouched('answersCount', true, false);
            setFieldValue('answersCount', value, true);
          }
        };
        return (
          <Grid
            container={true}
            direction={'column'}
            sx={{
              paddingBottom: (theme: Theme) => (isMobile ? theme.spacing(6) : theme.spacing(0)),
            }}>
            <Grid item={true} mb={isMobile ? 6 : 2}>
              <PageHeader
                tittle={tittle}
                previousFormLabel={previousFormLabel}
                previousFormPath={previousFormPath}
                isMobile={isMobile}
                mobileTittle={mobileTittle}
              />
            </Grid>
            {!isMobile && (
              <Grid item={true} mb={7}>
                <TittleInfo region={region?.name} department={department?.name} />
              </Grid>
            )}
            <Grid item={true}>
              <SenderForm
                formikProps={formikProps}
                handleSetFieldValue={handleSetFieldValue}
                isMobile={isMobile}
                fromCreateAnswer={fromCreateAnswer}
              />
            </Grid>
            {isMobile ? (
              <MobileBar>
                <FixedButton color={'primary'} variant={'contained'} onClick={submitForm}>
                  {'Продолжить'}
                </FixedButton>
              </MobileBar>
            ) : (
              <Grid item={true} marginTop={'32px'}>
                <Grid container={true} direction={'row'} justifyContent={'flex-end'}>
                  <Grid item={true} xs={4}>
                    <Button
                      onClick={submitForm}
                      variant={'contained'}
                      color={'primary'}
                      fullWidth={true}
                      size={'medium'}>
                      {'Продолжить'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        );
      }}
    </Formik>
  );
};

export default CreateFormSender;
